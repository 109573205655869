@charset "UTF-8";

.agsScoutOfferRecapitulate {
  @include card;
  position: relative;
  line-height: 1.57;
  border-left: 3px solid $color-green;

  &__content {
    @include contentMargin;
    padding: 30px 28px 30px;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: $color-black;
  }

  &__introducer {
    font-size: 1.4rem;
    line-height: 20px;
    margin-top: 15px;
    text-align: left;
    color: $color-gray;
  }

  &__agentIcon {
    margin-left: 15px;
    white-space: nowrap;
  }
}

.agsScoutAgreement {
  @include card;
  position: relative;
  line-height: 1.57;
  background-color: $color-yellow;

  &__content {
    @include contentMargin;
    padding: 30px 28px 30px;
    text-align: center;
  }

  &__check {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    padding: 0 15px;
    color: $color-black;

    & > span,
    a {
      font-weight: normal;
    }
  }

  &__caution {
    font-size: 1.4rem;
    line-height: 21px;
    color: $color-gray;
    text-align: left;
    width: 640px;
    margin: 10px auto 0 auto;
  }
}

.checkboxLargeAgs {
  @include checkboxLarge;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
