@charset "UTF-8";

// Swiper
@import url('https://cdnjs.cloudflare.com/ajax/libs/Swiper/2.7.6/idangerous.swiper.min.css');

.topCarousel {
  $component: &;
  position: relative;

  &[data-carousel="banner"] {
    #{$component}__item {
      img {
        height: 100px;
        width: 415px;
      }
    }
  }

  &--companiesList {
    &[data-carousel="banner"] {
      #{$component}__item {
        img {
          height: 110px;
          width: 460px;
        }
      }
    }
  }

  // チラつきを抑える
  &__list {
    width: 99999px;
  }

  &__item {
    padding-top: 1rem;

    a {
      @include onHover;
      color: inherit;
      display: block;
      margin-right: 20px;
    }

    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }

  &__control {
    background-color: $color_white;
    border-radius: 99999px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
    cursor: pointer;
    height: 40px;
    outline: 0;
    position: absolute;
    top: 75px;
    z-index: 2;
    width: 40px;

    &::after {
      border-right: 2px solid $color_keyColor;
      border-top: 2px solid $color_keyColor;
      content: "";
      display: block;
      height: 8.5px;
      left: 14px;
      position: absolute;
      top: 16px;
      width: 8.5px;
    }

    &--prev {
      left: 0;
      transform: translateX(-50%);

      &::after {
        left: 17px;
        transform: rotate(-135deg);
      }
    }

    &--next {
      right: 0;
      transform: translateX(50%);

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__pagination {
    margin-top: 15px;
    text-align: center;
  }

  &__paginationBullet {
    background-color: $color_white;
    border: 1px solid $color_keyColor;
    border-radius: 99999px;
    cursor: pointer;
    display: inline-block;
    height: 10px;
    outline: 0;
    width: 10px;

    &--active {
      background-color: $color_keyColor;
    }
  }

  &__paginationBullet + &__paginationBullet {
    margin-left: 10px;
  }
  //カルーセルボタンの位置を枠に合わせる
  &--hasClient #{$component}__control {
    top: 85px;
  }
}
