@charset "UTF-8";

.jobBorderedList {
  width: 100%;
  display: inline-block;

  &__item {
    display: inline-block;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
    width: 100%;

    &:first-child {
      border-top: 1px solid #ddd;
    }

    &--interview {
      background-color: #fff;
      padding: 10px;
      margin-bottom: 10px;
    }

    a {
      @include onHover;
      display: block;
      overflow: hidden;
    }
  }
}
