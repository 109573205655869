@charset "UTF-8";

.scoutAlert {
  @include scoutBlock;

  &__inner {
    text-align: center;
    padding: 24px 30px 30px 30px;
  }

  &__title {
    font-size: 2rem;
    font-weight: $scout_bold;
    margin-bottom: 8px;
    letter-spacing: 0.05em;

    &-impact {
      color: $scout-red;
    }
  }

  &__note {
    font-size: 1.4rem;
    font-weight: $scout_bold;
    margin-bottom: 5.8px;
  }

  &__startBtn {
    @include scoutBtn();

    & .label-sm {
      font-size: 1.6rem;
      display: block;
      margin-bottom: 10px;
    }
  }
}
