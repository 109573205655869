@charset "UTF-8";

.topPopup {
  $component: &;
  position: relative;

  &__item {
    display: none;
    left: 0;
    padding-top: 14px;
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    z-index: 2;
  }

  &--newArrival {
    #{$component}__item {
      left: auto;
      right: -2.7rem;
      top: 1.25rem;
      width: 326px;
    }
  }
}
