@charset "UTF-8";

/* 通常テーブル
----------------------------------------------------- */
.table {
  width: 100%;
  table-layout: fixed;
  margin: 20px 0;
  text-align: left;
  border: 1px solid #{$color_subBorder};
  border-bottom: none;
}

.table__head,
.table__body {
  border-bottom: 1px solid #{$color_subBorder};
}

.table__head {
  width: 240px;
  padding: 20px;
  color: $color_heading;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  background-color: #dfeff5;
}

.table__body {
  padding: 20px;
  background-color: $color_white;
}

.table__text {
  font-size: 1.4rem;
}

.table + .btnBox {
  margin-top: 30px;
}

/* 通常テーブル 2カラム用
----------------------------------------------------- */
.tableCondition {
  @extend .table;
}

.tableCondition__head {
  @extend .table__head;
  width: 170px;
}

.tableCondition__body {
  @extend .table__body;
}

/* 入力フォーム用
----------------------------------------------------- */
.formTable {
  @extend .table;

  &--noBorder {
    border: none;
  }
}

.formTable__head,
.formTable__body {
  border-bottom: 1px solid #{$color_subBorder};
}

.formTable__head {
  vertical-align: top;
  width: 240px;
  padding: 30px 20px;
  color: $color_heading;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  background-color: #f5f5f5;
}

.formTable__head .linkArrowS {
  display: inline-block;
  margin-top: 10px;
  background-position-y: 5px;
}

.formTable__head .labelRequired {
  float: right;
}

.formTable__head .textSup {
  clear: both;
  display: block;
  padding-top: 5px;
  color: #555;
  font-weight: normal;
}

.formTable__head--low {
  @extend .formTable__head;
  padding: 20px;
}

.formTable__head--noBorder {
  @extend .formTable__head;
  border: none;
  background-color: white;
}

.formTable__body {
  vertical-align: middle;
  padding: 20px 20px 20px 50px;
  word-wrap: break-word;
}

.formTable__body * {
  letter-spacing: 0;
}

.formTable__body--noBorder {
  @extend .formTable__body;
  border: none;
}

.formTable__row {
  @include clearfix;
  clear: both;
  display: block;
  margin-bottom: 10px;
}

.formTable__row:last-child {
  margin-bottom: 0;
}

.formTable__col {
  float: left;
  margin-left: 20px;
}

.formTable__col--checkbox {
  float: left;
  margin-left: 30px;
}

.formTable__col + .formTable__col--checkbox {
  margin-top: 10px;
}

.formTable__error + .formTable__col,
.formTable__col:first-of-type,
.formTable__col--checkbox:first-of-type {
  margin-left: 0;
}

.formTable__body .inputTextM {
  margin-bottom: 10px;
}

.formTable__body > .inputTextM:last-child {
  margin-bottom: 0;
}

.formTable__col label,
.formTable__col button,
.formTable__col input {
  display: inline-block;
  vertical-align: middle;
}

.formTable__col label {
  margin-right: 10px;
  color: #333;
  font-size: 1.2rem;
}

.formTable__col input + label,
.formTable__col button + .formTable__label {
  margin-left: 10px;
  margin-right: 0;
}

.formTable__error {
  display: none;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffe5e6;
}

.formTable__errorText {
  position: relative;
  z-index: 1;
  padding-left: 10px;
  color: $color_red;
  font-size: 1.1rem;
  line-height: 1.5;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.formTable__errorText::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0.45em;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: $color_red;
  border-radius: 50%;
}

.formTable__errorText a {
  color: $color_red;
  text-decoration: underline;
}

.formTable__errorText a:hover {
  text-decoration: none;
}

.formTable__icon {
  @include clearfix;
  @include iconKey() {
    position: relative;
  }
}

.formTable__icon--tel {
  @extend .formTable__icon;
  display: inline-block;
}

.formTable__icon--tel:nth-child(even) {
  margin-left: 50px;
}

.formTable__icon--tel input {
  width: 160px;
}

.formTable__icon::before {
  position: absolute;
  z-index: 1;
  top: 9px;
  left: -33px;
}

.formTable__agentItem {
  position: relative;
  z-index: 1;
  margin-top: 10px;
  padding-left: 27px;

  .tooltip {
    position: absolute;
    display: none;
  }

  .iconQuestion {
    z-index: 104;

    &:hover + .tooltip {
      display: block;
      margin: 20px 0 0 31px;
      z-index: 103;
    }
  }

  &--hasTooltip {
    z-index: auto;
  }
}

.formTable__agentItem:first-child {
  margin-top: 0;
  padding-left: 0;
}

.formTable__agentItem .text {
  padding-left: 27px;
}

.formTable__agentIcon {
  position: absolute;
  top: 0;
  left: 4px;
  z-index: 1;
}

.formTable .addCondition--application {
  margin-left: -50px;
}

.formTable__body .applicationSelect__col {
  letter-spacing: -0.4em;
}

.formTable__body .addConditionList {
  margin: 0 0 0 -20px;
}

.formTable__body .addConditionList:first-child:last-child {
  margin: 0 0 -10px -20px;
}

.formTableGray__head {
  background: #f5f5f5;
  border-bottom: 1px solid $color_subBorder;
  color: #333;
  padding: 20px;
  width: 240px;
}

.formTableGray__head--disabled {
  @extend .formTableGray__head;
  opacity: 0.5;
}

.formTableGray__headLow {
  background: #f5f5f5;
  border-bottom: 1px solid $color_subBorder;
  color: #333;
  padding: 20px;
  width: 240px;
}

.formTableGray__headLow--disabled {
  @extend .formTableGray__headLow;
  opacity: 0.5;
}

.formTableGray__body {
  background-color: $color_white;
  border-bottom: 1px solid $color_subBorder;
  color: $color_text;
  padding: 20px 20px 20px 50px;
  word-wrap: break-word;
}

.formTableGray__body--disabled {
  @extend .formTableGray__body;
  opacity: 0.5;
}

.table__body .table__text {
  word-break: break-all;
}

/* 入力フォーム用 2カラム
----------------------------------------------------- */
.settingTable {
  @extend .formTable;
}

.settingTable__head {
  @extend .formTable__head;
  width: 170px;
}

.settingTable__body {
  @extend .formTable__body;
}

.settingTable__body .inputText {
  margin-bottom: 10px;
}

.settingTable__body .inputText:last-child {
  margin-bottom: 0;
}

.settingTable__head--low {
  @extend .formTable__head--low;
  width: 170px;
}

.settingTable__error {
  @extend .formTable__error;
}

.settingTable__errorText {
  @extend .formTable__errorText;
}

.settingTable__col {
  @extend .formTable__col;
}

//希望職種
.settingTable .addCondition {
  margin-left: -50px;
}

.addCondition .settingTable__error {
  margin-left: 50px;
}

.settingTable .addCondition .btnSelect {
  width: 398px;
}

/* 応募・管理メッセージ用 2カラム
----------------------------------------------------- */
.applicationTable {
  @extend .table;
}

.applicationTable__head:first-child,
.applicationTable__body:first-child {
  width: 196px;
  border-right: 1px solid #{$color_subBorder};
}

.applicationTable__head,
.applicationTable__body {
  border-bottom: 1px solid #{$color_subBorder};
}

.applicationTable__head {
  padding: 5px;
  color: $color_heading;
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
}

.applicationTable__body {
  padding: 20px;
  vertical-align: top;
}

.applicationTable__body:first-child {
  font-weight: bold;
}

.applicationTable__body--row {
  @extend .applicationTable__body;
  font-weight: normal;
}

.applicationTable__body--row :first-child {
  font-weight: normal;
}

.applicationTable__body .checkbox > input,
.applicationTable__body .checkbox--disabled > input {
  vertical-align: middle;
}

.applicationTable__body .checkbox__icon,
.applicationTable__body .checkbox--disabled__icon {
  top: 50%;
  transform: translateY(-50%);
}

.applicationTable__body .checkbox__text,
.applicationTable__body .checkbox--disabled__text {
  display: inline-block;
  vertical-align: middle;
}

.applicationTable__text,
.applicationTable__btn {
  display: table-cell;
  vertical-align: top;
}

.applicationTable__btn {
  padding-left: 20px;
}

.applicationTable__btn [class*="btn"] {
  width: 67px;
  min-width: 67px;
  padding: 6px 0;
}

/* 面接応募日時テーブル用
----------------------------------------------------- */
.interviewApplicationDatetimeTable {
  @extend .table;
  margin: 5px 0 50px;

  &__head {
    vertical-align: middle;
    text-align: center;
    padding: 20px 20px 20px 20px;
    color: $color_white;
    border: 1px solid $color_disable02;
    background-color: $color_keyColor;
  }

  &__head.dimmed {
    color: #a6afb4;
    background-color: #536e7e; /* 固有色 */
  }

  &__date {
    vertical-align: middle;
    text-align: center;
    padding: 20px 20px 20px 20px;
    color: $color_heading;
    border: 1px solid $color_subBorder;
    background-color: #eff7fa; /* 固有色 */
  }

  &__body {
    vertical-align: middle;
    text-align: center;
    padding: 20px 20px 20px 20px;
    color: $color_heading;
    border: 1px solid $color_subBorder;
    background-color: $color_white;

    &__gray {
      background-color: #efefef; /* 固有色 */
    }

    &__selected {
      background-color: #fffbe4; /* 固有色 */
    }
  }

  &__body.dimmed {
    background-color: #ccc;
  }

  &__limit {
    font-weight: 700;
    text-align: right;
    padding: 15px 0 0 0;

    &__day {
      padding: 5px;
      font-size: 2.2rem;
      color: $color_red;
    }
  }
}

/* 面接応募日時確認テーブル用
----------------------------------------------------- */
.interviewApplicationDatetimeConfirmTable {
  @extend .table;
  width: 910px;
  margin: 0 20px;

  &.conf {
    margin: 0 0 0 20px;
  }

  &__annotation {
    margin: 0 20px;
    padding: 30px 0 10px 0;
    font-size: 1.2rem;

    &__red {
      color: $color_red;
    }

    &.conf {
      padding: 20px 0 10px 0;
    }

    &.conf_noBottomPad {
      padding: 20px 0 0 0;
    }
  }

  &__head--low {
    @extend .formTable__head;
    padding: 20px;
    width: 140px;
    background-color: $color_keyColor_light;
    border-right: 1px solid #{$color_subBorder};

    &.conf {
      text-align: center;
      border-width: 0 1px 1px 0;
    }
  }

  &__body {
    @extend .table__body;
    vertical-align: middle;
    padding: 20px 20px 20px 50px;
  }

  &__row.notSelected {
    display: none;
  }
}

/* サービス設定用 2カラム
----------------------------------------------------- */
.serviceTable {
  @extend .formTable;
  margin: 10px 0 20px;
}

.serviceTable__head {
  @extend .formTable__head;
  width: 170px;
}

.serviceTable__body {
  @extend .formTable__body;
}

.serviceTable__body .inputText {
  margin-bottom: 10px;
}

.serviceTable__body .inputText:last-child {
  margin-bottom: 0;
}

.serviceTable__head--low {
  @extend .formTable__head--low;
  width: 569px;
  font-weight: normal;
}

.serviceTable__error {
  @extend .formTable__error;
}

.serviceTable__errorText {
  @extend .formTable__errorText;
}

.serviceTable__col {
  @extend .formTable__col;
}

/* 希望条件テーブル
----------------------------------------------------- */
.conditionsTable {
  @extend .formTable;
}

.conditionsTable__head {
  @extend .formTable__head;
}

.conditionsTable__body {
  @extend .formTable__body;
}

.conditionsTable__error {
  @extend .formTable__error;
}

.conditionsTable__errorText {
  @extend .formTable__errorText;
}

.conditionsTable__col {
  @extend .formTable__col;
}

.conditionsTable__body .select,
.conditionsTable__body .btnSelect {
  width: 400px;
}

.conditionsTable__col--checkbox {
  float: left;
  margin-left: 30px;
}

.conditionsTable__col + .conditionsTable__col--checkbox {
  margin-top: 10px;
}

.conditionsTable__error + .conditionsTable__col,
.conditionsTable__col:first-of-type,
.conditionsTable__col--checkbox:first-of-type {
  margin-left: 0;
}

.conditionsTable .addCondition__row {
  margin-left: -50px;
}

/* 検索テーブル
----------------------------------------------------- */
.searchTable {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  font-size: 1.2rem;
  margin-bottom: 20px;
  width: 100%;

  &__content {
    width: 100%;

    &:last-of-type {
      //
      .searchTable__body {
        position: relative;

        &--unset {
          position: unset;
        }
      }
    }
  }
}

.searchTable__head,
.searchTable__btn,
.searchTable__body {
  background-color: #e8f7fd;
  border-bottom: 1px solid $color_border;
  border-right: 1px solid $color_border;
  padding: 10px 0 10px 16px;
}

.searchTable__head {
  border-right: none;
  vertical-align: top;
  white-space: nowrap;
  text-align: left;
}

.searchTable__body:last-of-type {
  border-right: none;
}

.searchTable__title {
  border-left: 2px solid $color_keyColor;
  display: inline-block;
  font-weight: bold;
  font-size: 1.4rem;
  padding-left: 10px;
}

.searchTable__body .checkbox {
  margin-right: 8px;
  margin-bottom: 6px;
}

.searchTable__body .selectS {
  height: auto;
  width: 116px;
}

.searchTable__body .selectS span {
  line-height: 1.65em;
  padding: 0 6px;
}

.searchTable__body .inputText {
  font-size: 1.2rem;
  height: auto;
  width: 110px;
  padding: 0;
}

.searchTable__btn {
  border-right: none;
  vertical-align: top;
}

.searchTable__btn + .searchTable__body {
  padding-top: 12px;
  padding-left: 0;
}

.searchTable .btnAddS,
.searchTable .btnRemoveS {
  border-radius: 3px;
  font-size: 1.2rem;
  padding: 3px 10px;
  margin-right: 4px;
  min-height: auto;
  min-width: auto;
  white-space: nowrap;
}

.searchTable > .searchTable__content:last-child .btnAddS,
.searchTable > .searchTable__content:last-child .btnRemoveS,
.searchTable > .searchTable__content:last-child .btnSecondaryS {
  position: absolute;
}

.searchTable > .searchTable__content:last-child .btnAddS,
.searchTable > .searchTable__content:last-child .btnRemoveS {
  top: 10px;
  left: 16px;
}

.searchTable > .searchTable__content:last-child .btnSecondaryS {
  top: 10px;
  right: 16px;
  min-height: 20px;
  padding: 8px 20px;
}

.searchTable__body .btnBox {
  margin: 0 0 10px;
}

.searchTable__question {
  position: relative;
  display: inline-block;
}

.searchTable__question .iconQuestion {
  z-index: 12;
}

.searchTable__question .iconQuestion:hover + .tooltip {
  display: block;
}

.searchTable__question:hover .iconQuestion {
  cursor: pointer;
  opacity: 0.5;
}

.searchTable__question .tooltip {
  display: none;
  position: absolute;
  z-index: 10;
  right: -27px;
  bottom: -19px;
  transform: translateY(100%);
}

.searchTable__question .tooltip .tooltip__text {
  margin-bottom: 10px;
}

.searchTable__question .iconFont--question::before {
  background-color: $color_keyColor;
  border-radius: 12px;
  color: $color_white;
  padding: 4px;
  font-size: 1.2rem;
}

.searchTableInner {
  margin-right: 20px;
  width: 800px;
  float: right;
}

.searchTableInner__head,
.searchTableInner__body {
  border: 1px solid $color_border;
  padding: 12px 12px 6px;
}

.searchTableInner__head {
  font-weight: bold;
  white-space: nowrap;
}

.searchTableInnerAccess {
  float: right;
  width: 100%;

  &__button {
    text-align: left;

    button {
      margin-top: -2px;
    }
  }

  &__head {
    text-align: right;
    padding: 10px;
    width: 100px;
  }

  &__body {
    vertical-align: middle;
  }
}

/* MY検索条件テーブル
----------------------------------------------------- */
.mySearchTable {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  font-size: 1.2rem;
  margin-bottom: 20px;
  width: 100%;
}

.mySearchTable__content {
  background-color: $color_white;
  width: 100%;
}

.mySearchTable__head,
.mySearchTable__body,
.mySearchTable__check {
  position: relative;
  border-bottom: 1px solid $color_subBorder;
  border-right: 1px solid $color_subBorder;
  padding: 20px;
}

.mySearchTable__head {
  background-color: #f5f5f5;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 20px;
  text-align: left;
  vertical-align: top;
  width: 180px;
  white-space: nowrap;
}

.mySearchTable__body:last-of-type {
  border-right: none;
}

.mySearchTable__check {
  padding-bottom: 6px;
}

.mySearchTable__check .checkbox {
  margin-right: 8px;
  margin-bottom: 12px;
  width: 23%;
}

.mySearchTable__body .selectS {
  width: 116px;
}

.mySearchTable__body .selectS span {
  padding: 0 6px;
}

.mySearchTable__body .inputText {
  width: 200px;
  padding: 0;
}

.mySearchTable .btnAddS,
.mySearchTable .btnRemoveS {
  border-radius: 3px;
  margin-right: 4px;
  min-height: auto;
  min-width: auto;
}

.mySearchTable__body .btnBox {
  margin: 0 0 10px;
}

.mySearchTable__question {
  position: relative;
  display: inline-block;
}

.mySearchTable__question .iconQuestion {
  z-index: 12;
}

.mySearchTable__question .iconQuestion:hover + .tooltip {
  display: block;
}

.mySearchTable__question:hover .iconQuestion {
  cursor: pointer;
  opacity: 0.5;
}

.mySearchTable__question .tooltip {
  display: none;
  position: absolute;
  z-index: 10;
  right: -27px;
  bottom: -19px;
  transform: translateY(100%);
}

.mySearchTable__question .tooltip .tooltip__text {
  margin-bottom: 10px;
}

.mySearchTable__question .iconFont--question::before {
  background-color: $color_keyColor;
  border-radius: 12px;
  color: $color_white;
  padding: 4px;
  font-size: 1.2rem;
}

.mySearchTableInner {
  margin-right: -10px;
}

.mySearchTableInner__head,
.mySearchTableInner__body {
  border-bottom: 1px dotted $color_subBorder;
  border-collapse: 1px;
}

.mySearchTableInner__head {
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  padding: 12px;
}

.mySearchTableInner__body {
  padding: 12px 0 0 12px;
}

.mySearchTableInner tr:last-child .mySearchTableInner__head,
.mySearchTableInner tr:last-child .mySearchTableInner__body {
  border-bottom: none;
}

.mySearchTableInner__body .checkbox {
  margin-bottom: 12px;
  width: 30%;
}

.mySearchTable__info {
  margin-bottom: 24px;
}

.mySearchTable__error {
  @extend .formTable__error;
}

.mySearchTable__errorText {
  @extend .formTable__errorText;
}

.mySearchTable__col {
  @extend .formTable__col;
  display: table;
}

.mySearchTable__col--btn {
  display: table-cell;
  width: 116px;
}

.mySearchTable__col--area {
  display: table-cell;
}

.mySearchTable__col .checkbox {
  margin-bottom: 8px;
}

/* 希望条件テーブル
----------------------------------------------------- */
.searchResultTable {
  @extend .formTable;
}

.searchResultTable__head {
  @extend .formTable__head;
  width: 169px;
  padding: 20px;
}

.searchResultTable__body {
  @extend .formTable__body;
  padding: 20px;
}

/* セミナーテーブル
----------------------------------------------------- */
.seminarTable {
  @extend .formTable;
}

.seminarTable__head {
  @extend .formTable__head;
  padding: 8px 20px;
  text-align: center;
  border-left: 1px solid #ddd;
}

.seminarTable__head:nth-child(1) {
  width: 120px;
}

.seminarTable__head:nth-child(2) {
  width: 88px;
}

.seminarTable__head:nth-child(3) {
  width: 110px;
}

.seminarTable__head:nth-child(4) {
  width: 88px;
}

.seminarTable__head:nth-child(5) {
  width: 245px;
}

.seminarTable__head:nth-child(6) {
  width: 107px;
}

.seminarTable__head:nth-child(7) {
  width: 135px;
}

.seminarTable__body {
  @extend .formTable__body;
  vertical-align: top;
  padding: 20px;
  border-left: 1px solid #ddd;
}

.seminarTable__body:nth-child(7) {
  padding: 16px 20px;
}

.seminarTable__head:first-child,
.seminarTable__body:first-child {
  border-left: none;
}

.seminarTable .btnPrimaryS,
.seminarTable .btnDisabledS,
.seminarTable .btnSubS {
  min-width: 100%;
  padding: 12px 5px;
}

.seminarTable .btnDefaultSS {
  min-width: 100%;
  padding: 7px 5px;
}

.seminarTable .btnDisabledS,
.seminarTable .btnSubS {
  cursor: default;
  box-shadow: none;
}

.seminarTable .btnSubS:hover {
  opacity: 1;
}

/* スケジュール
----------------------------------------------------- */
.scheduleTable {
  @extend .formTable;
}

.scheduleTable__head {
  @extend .formTable__head;
  padding: 20px;
}

.scheduleTable__body {
  @extend .formTable__body;
  padding: 20px;
}

/* 募集要項
----------------------------------------------------- */
.jobOfferTable {
  @extend .formTable;
}

.jobOfferTable__head {
  @extend .formTable__head;
  padding: 20px;
}

.jobOfferTable__body {
  @extend .formTable__body;
  vertical-align: top;
  padding: 20px;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.jobOfferTable__body hr {
  margin: 20px 0;
  border: solid $color_subBorder;
  border-width: 1px 0 0;
}

.jobOfferTable__body .jobOfferInfo__labelFeature:last-child {
  margin: 0 0 -8px -8px;
}

.jobOfferTable__img {
  float: right;
  width: 280px;
  margin: 0 0 40px 40px;
}

.jobOfferTable__img img {
  vertical-align: top;
}

.jobOfferTable__img .textS {
  display: block;
  margin: 18px 0 0;
}

.jobOfferTable__iconQuestion {
  position: relative;
  display: inline-block;
}

.jobOfferTable__iconQuestion .iconQuestion:hover + .tooltip {
  display: block;
}

.jobOfferTable__iconQuestion .tooltip {
  position: absolute;
  top: 40px;
  right: -26px;
  display: none;
}

.jobOfferTable__iconQuestion .tooltip span {
  display: block;
}

.jobOfferTable__iconQuestion .tooltip__text + .tooltip__text {
  margin: 20px 0 0;
}

.jobOfferTable__interview {
  display: block;
  width: 130px;
}

.jobOfferTable__interview img {
  vertical-align: top;
}

.jobOfferTable__interview .textS {
  display: block;
  margin: 10px 0 0;
}

.jobOfferTable__workLocation {
  margin: 2em 0 0;
}

.jobOfferTable__workLocation .textTopic {
  padding: 0;
}
