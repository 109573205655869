@charset "UTF-8";

.labelPremiumBenefit {
  &-scout {
    vertical-align: middle;
    letter-spacing: -.4em;

    .labelPremiumBenefit__title {
      padding: 1px 20px 1px 9px;
      line-height: 1.8rem;
      font-weight: $scout_bold;
      font-size: 1.2rem;
      letter-spacing: normal;
    }

    .labelPremiumBenefit__text {
      padding: 1px 14px 1px 9px;
      line-height: 1.8rem;
      font-size: 1.2rem;
      font-weight: $scout_bold;
      letter-spacing: normal;
    }
  }
}
