@charset "UTF-8";

.topGnavi {
  background-color: $color_white;
  box-shadow: 0 2px 5px 0 rgba(107, 107, 107, .3);
  padding: 6px 0 15px 0;
  position: sticky;
  top: 2.5rem;
  z-index: 19;

  &__noSticky {
    position: relative;
    box-shadow: 0 4px 5px -2px rgba(107, 107, 107, .3);
    top: 0;
    margin-top: -3px;

    &--noShadow {
      position: relative;
      box-shadow: none;
      top: 0;
      margin-top: -3px;
    }
  }

  &__container {
    @include clearfix;

    .topMenuList {
      float: left;
    }

    .topSubMenuList {
      float: right;
    }
  }
}
