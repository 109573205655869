@charset "UTF-8";

.jobList {
  $component: &;
  @include clearfix;
  border: 1px solid $color_keyColor_light;

  &__title {
    position: relative;
    padding: 10px 34px 10px 8px;
    border-top: 0;

    &--sub {
      padding: 10px 8px;
    }
  }

  &__text {
    color: $color_keyColor;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.3;
  }

  &--cell {
    #{$component}__item {
      float: left;
      width: 50%;

      &:nth-child(odd) {
        border-right: 1px solid $color_keyColor_light;

        #{$component}__title {
          border-right: 0;
        }
      }

      &:nth-child(2) {
        border-top: 0;
      }
    }
  }

  &--money {
    #{$component}__text {
      color: #000;
    }
  }

  &__content {
    display: none;
  }

  &__item + &__item {
    border-top: 1px solid $color_keyColor_light;
  }

  &__toggleAccordion {
    background-color: $color_keyColor;
    border: 0;
    border-radius: 99999px;
    color: $color_white;
    cursor: pointer;
    display: inline-block;
    height: 17px;
    line-height: 17px;
    outline: 0;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;

    &::before {
      content: "\e60e";
      content: "＋";
      display: inline-block;
      font-weight: bold;
    }
  }

  &__new {
    font-size: 1.3rem;
    line-height: 1.3;
  }
}

[data-jobcategory-accordion="open"] {
  .jobList {
    &__content {
      display: block;
    }

    &__toggleAccordion {
      &::before {
        content: "−";
        transform: translateY(-1px);
      }
    }
  }
}

.jobNaviGroup__content {
  .navItem {
    padding: 10px 3rem 10px .5rem;
  }
}
