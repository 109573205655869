@charset "UTF-8";

.jobSearchList {
  color: #888;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 50px;

  &__item {
    padding: 0 20px;
    color: #888;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 45px;

    a {
      color: #888;
      @include onHover;
    }
  }

  &__linkBox + &__linkBox {
    &::before {
      content: " | ";
    }
  }
}
