@charset "UTF-8";

/*! =======================================================================
 * File        : base.css
 * DESCRIPTION : デフォルト値を設定
 * AUTHOR      : i-studio Co.,Ltd.
 * ------------------------------------------------------------------------
 * (C)Mynavi Corporation
========================================================================== */

/* base
----------------------------------------------------- */
html {
  color: #555;
  font-size: 62.5%;
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}

button {
  padding: 0;
}

input {
  @include placeholderColor;
  margin: 0;
  border: 1px solid #bbb;
}

input[type=radio],
input[type=checkbox] {
  padding: 0;
  border: none;
}

a {
  color: #00aaeb;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

u {
  text-decoration: none;
}

blockquote {
  display: inline;
}

.cf::after {
  content: "";
  display: table;
  clear: both;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
