@charset "UTF-8";

// 企業情報ページ共通スタイル

/* 企業情報／企業上部パーツ
----------------------------------------------------------------- */
.companyInfo {
  min-height: 90px;
  margin-bottom: 10px;

  .leftLogoBlock {
    float: left;
    padding: 0 14px 0 0;
  }

  .useFloat {
    overflow: auto;
  }

  .boxAdjust {
    overflow: hidden;
  }

  &__headingPage {
    border: none;
    padding: 0;
    padding-top: 10px;
  }

  &__icon-text {
    color: #333;
    display: flex;
    font-size: 14px;
  }

  &__icon-parent {
    align-items: center;
    display: flex;
    margin-right: 24px;

    &:first-child {
      max-width: calc(100% - 310px);
    }
  }

  &__info {
    color: #333;
    font-size: 14px;
  }
}

/* 企業情報／お知らせメールパーツ
----------------------------------------------------------------- */
.companyMail {
  //padding: 20px;

  &__text {
    color: red;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }

  .btnDefaultL {
    padding: 13px 40px;
  }

  .btnText {
    color: #7a7a7a;
  }

  > button:hover {
    opacity: 1;
    cursor: default;
  }

  > button:disabled {
    background-color: $color_disable02;
    border-color: $color_disable02;
    pointer-events: none;
  }
}
