@charset "UTF-8";

//スカウト詳細情報
.scoutDetailInfoArea {
  padding: 30px 0 10px 0;
  border-bottom: 1px solid $scout-title-boder;
  font-size: 1.4rem;
  line-height: 1.42;
}

//スカウト詳細情報
.scoutDetailInfo {
  $component: &;
  @include card;
  position: relative;
  line-height: 1.57;
  z-index: 1;

  &.scoutReadMore--open .scoutReadMore--close .scoutReadMore__content {
    height: auto;
  }

  //希望職種にマッチの状態
  &--hope {
    #{$component}__wrap {
      border-left: 3px solid $scout-match-red;
    }

    #{$component}__match::before {
      content: "";
      display: block;
      width: 63px;
      height: 63px;
      min-width: 63px;
      min-height: 63px;
      max-width: 63px;
      max-height: 63px;
      background: url(#{$scout_img_path}icon_hope.png) no-repeat;
      background-size: 100% auto;
    }
  }

  //経験職種にマッチの状態
  &--experience {
    #{$component}__wrap {
      border-left: 3px solid $scout-match-yellow;
    }

    #{$component}__match::before {
      content: "";
      display: block;
      width: 63px;
      height: 63px;
      min-width: 63px;
      min-height: 63px;
      max-width: 63px;
      max-height: 63px;
      background: url(#{$scout_img_path}icon_experience.png) no-repeat;
      background-size: 100% auto;
    }
  }

  //企業の条件にマッチの状態
  &--terms {
    #{$component}__wrap {
      border-left: 3px solid $scout-match-gray;
    }

    #{$component}__match::before {
      content: "";
      display: block;
      width: 63px;
      height: 63px;
      min-width: 63px;
      min-height: 63px;
      max-width: 63px;
      max-height: 63px;
      background: url(#{$scout_img_path}icon_terms.png) no-repeat;
      background-size: 100% auto;
    }
  }

  //期限切れ
  &--expired {
    #{$component}__wrap {
      background: $scout-detail-gray-bg;
      border-left: 3px solid $scout-detail-gray-bg;
    }

    #{$component}__footer::before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 20px;
      height: 60px;
      width: 96%;
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
      background: $scout-detail-gray-bg;
    }
  }

  //コンテンツ
  &__content {
    @include contentMargin;
    padding: 20px;
  }

  //要約
  &__recapitulate {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }

  //タイトル
  &__title {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 5px 0;
    color: $color-blue;
  }

  //マッチアイコン
  &__match {
    padding-right: 20px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  //概要
  &__overview {
    padding-right: 10px;
    margin-right: auto;
  }

  //受信日
  &__date {
    color: #555;
    font-size: 1.2rem;
    font-weight: 300;
  }

  //受信日＋スカウト限定求人
  &__detailHeader {
    color: #555;
    font-size: 1.2rem;
    font-weight: 300;

    .date {
      vertical-align: middle;
    }

    .scoutLimited {
      @extend .scoutBenefits__icon;
      color: $scout-red;
      border-color: $scout-red;
      margin-left: 10px;
      vertical-align: middle;

      &::before {
        content: url(#{$scout_path}scoutLimited.png);
        vertical-align: top;
      }
    }
  }

  //期限
  &__remain {
    border-left: 1px solid $scout-title-boder;
    margin-right: -20px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }

  //期限日
  &__remainDate {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    margin: auto;
    text-align: center;
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: 600;

    //期限切れ
    &--expierd {
      font-size: 1.4rem;
      font-weight: 600;
      color: $color-gray;
    }

    //辞退済み
    &--declined {
      font-size: 1.4rem;
      font-weight: 600;
      color: $color-gray;
    }

    //応募済み
    &--submitted {
      font-size: 1.4rem;
      font-weight: 600;
    }

    //あとN日
    &--days {
      font-size: 1.4rem;
      font-weight: 600;

      #{$component}__remainImpact {
        font-size: 3rem;
        font-weight: 600;
      }
    }

    //本日締切
    &--today {
      font-size: 1.4rem;
      font-weight: 600;

      #{$component}__remainImpact {
        font-size: 3rem;
        font-weight: 600;
      }
    }
  }

  //期限日強調部
  &__remainImpact {
    font-size: 3rem;
    font-weight: 600;
  }

  //紹介会社
  &__company {
    font-size: 1.4rem;
    font-weight: 300;
  }

  //詳細情報
  &__body {
    font-size: 1.2rem;
    font-weight: 300;
    word-wrap: break-word;

    &::after {
      content: "";
      clear: both;
      display: block;
    }
  }

  //htmlBody
  .scoutReadMore--hasHtml &__htmlBody {
    margin-bottom: 20px;
  }

  &__photo {
    float: right;
    margin: 0 0 30px 30px;
  }

  //一覧表示画像
  &__pictureFile {
    width: 216px;
    height: 162px;
  }

  //あなたへの提示条件
  &__presentationCondTitle {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 15px;
  }

  //detail以下のコンテンツ
  &__detail &__content {
    padding: 20px 25px;
    border-top: 1px solid $scout-container-border;
  }

  //フッター
  &__footer {
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 20px;
      height: 60px;
      width: 96%;
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
      background: $scout-detail-white-bg;
    }
  }

  //readMore
  &__more {
    font-size: 1.4rem;
    font-weight: 300;
    text-decoration: underline;
    padding: 20px 25px;
    bottom: 0;
    position: absolute;
  }

  //セミナー申し込み
  &__seminarEntry {
    padding: 30px 20px 10px 20px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  //actionArea
  &__actionArea {
    padding-top: 20px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }

  //キープ・辞退エリア
  &__action {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }

  &__action .btnList__item {
    margin: 0 0 0 20px;
    width: auto;
  }

  //セミナー申込ボタン
  &__btnSeminar {
    @include btn;
    min-width: 100px;
    font-size: 1.4rem;
    font-weight: 600;
    color: #666;
    padding: 10px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #bbb;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-align: center;

    &--disabled {
      @include disabled;
    }
  }

  //特典
  &__privilege {
    padding: 10px 0;
  }
}

//readMore
.scoutReadMore {
  $component: &;
  @include scoutReadMore;

  &--hasHtml {
    #{$component}__content {
      height: 1px;
    }

    #{$component}__footer::before {
      content: none;
    }
  }
}
