@charset "UTF-8";

#js__considerationHistoryRecommend,
#js__historyRecommendCommon {
  .topCard {
    &__title {
      // 3行の高さに合わせる
      height: $top-card-title-size * $top-card-title-line-height * 3;
    }

    &__text {
      height: 48px;
    }
  }
}
