@charset "UTF-8";

.jobListSub {
  $component: &;

  &__item {
    position: relative;
    padding: 10px 8px;
    border-top: 0;
    background-color: #f8f8f8;
  }

  &__text {
    color: $color_text;
    font-size: 1.3rem;
    line-height: 1.3em;
  }
}
