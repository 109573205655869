@charset "UTF-8";

.jobHero {
  @include clearfix;
  margin-bottom: 4rem;

  &__update {
    color: $color_heading;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 1rem 0 0 0;
    text-align: right;

    a {
      color: inherit;
    }
  }
}
