@charset "UTF-8";

//スカウト再アプローチ
.scoutReApproach {
  padding: 20px 70px 20px 28px;
  background: $color-white;
  border-top: 1px solid $scout-container-border;

  //タイトル
  &__title {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }

  //受診日
  &__date {
    color: $color-gray;
    font-size: 1.2rem;
    font-weight: 300;
  }
}
