@charset "UTF-8";

/* 求人カセット
----------------------------------------------------- */
.cassetteJobOffer {
  @include card;
  display: table;
  table-layout: fixed;

  &__selectAll {
    margin-bottom: 40px;
    margin-top: 10px;

    &.smallMargin {
      margin-bottom: 0;
    }

    & .checkbox__selectAll {
      align-items: center;
      background: #dfeef5;
      border: 1px solid $color_keyColor;
      border-radius: 4px;
      display: inline-flex;
      padding: 10px 14px;

      &--icon {
        left: 13px;
        top: auto;

        &.checkboxBlue__icon {
          left: 4px;
        }
      }

      &--info {
        color: $color_keyColor;
        font-weight: 600;
        line-height: normal;
        margin-left: 10px;

        span {
          font-size: 12px;
        }
      }

      &--disabled {
        background-color: $color_subBorder;
        border: 1px solid transparent;
        border-color: $color_disable02;

        &.checkbox {
          cursor: default;
        }

        & .checkboxBlue__icon {
          &::before {
            background-color: #e9e9e9;
          }
        }

        & .checkbox__selectAll--info {
          color: $color_text;
        }
      }
    }

    + .considerationResult {
      .considerationResult__item {
        &:first-of-type {
          margin-top: 20px;
        }
      }
    }

    .jobOfferTable__iconQuestion {
      top: 11px;
      z-index: 2;
    }
  }

  & &__select--disabled {
    background-color: #ddd;

    &.checkbox {
      cursor: default;
    }

    & .checkbox__icon {
      &::before {
        background: none;
        background-color: #e9e9e9;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }

  label.checkbox + * + &__container {
    .cassetteJobOffer__heading {
      .cassetteJobOffer__info {
        top: 20px;
      }

      .cassetteJobOffer__info + .cassetteJobOffer__name {
        padding: 30px 0 0;
      }
    }
  }

  .bulkEntryMatchingJob {
    .cassetteJobOffer__container {
      .cassetteJobOffer__heading {
        .cassetteJobOffer__info {
          top: 20px;
        }

        .cassetteJobOffer__info + .cassetteJobOffer__name {
          padding: 30px 0 0;
        }
      }

      .cassetteRecruitRecommend__label,
      .cassetteRecruitRecommend__label--attention {
        + .cassetteJobOffer__heading {
          .cassetteJobOffer__info {
            top: 20px;
          }

          .cassetteJobOffer__info + .cassetteJobOffer__name {
            padding: 10px 0 0;
          }
        }
      }
    }
  }

  label.checkbox + * + &__container {
    .cassetteJobOffer__heading {
      .cassetteJobOffer__info {
        top: 20px;
      }

      .cassetteJobOffer__info + .cassetteJobOffer__name {
        padding: 30px 0 0;
      }
    }
  }

  .matchingJob:has(.bulkEntryMatchingJob) {
    background-color: $color_white;
    border: 5px solid $color_matching;
    border-radius: 4px 4px 0 0;
    box-shadow: $boxShadow_defult;
    margin: 0;
    padding: 0;

    .cassetteJobOffer__heading {
      background-color: $color_matchingLight;
    }
  }

  &__container:has(.cassetteRecruitRecommend__label--attention) {
    border: 2px solid #f18b00;
  }

  &__content:has(.tooltipMatching) {
    padding: 30px 20px 20px 20px;
  }
}

.blockUnit + .cassetteJobOffer__selectAll {
  margin-top: 40px;
}

.cassetteJobOffer .cassetteJobOffer__select--disabled.checkbox:hover {
  background-color: #ddd;
}

.cassetteJobOffer > a:hover {
  text-decoration: none;
}

.cassetteJobOffer .checkbox,
.cassetteJobOffer__container {
  display: table-cell;
  vertical-align: middle;
}

a .cassetteJobOffer__container {
  color: $color_text;
}

.cassetteJobOffer .checkbox {
  overflow: hidden;
  width: 36px;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid #ddd;
}

.cassetteJobOffer .checkbox:hover {
  background-color: rgba(221, 221, 221, 0.4);
}

.cassetteJobOffer .checkbox__icon {
  position: relative;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);

  &.checkbox__icon--large {
    transform: translate(-50%, -65%) scale(1.3);
  }
}

.cassetteJobOffer .checkbox__icon::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.cassetteJobOffer__container {
  position: relative;

  .cassetteJobOffer__heading + .cassetteJobOffer__attribute {
    padding: 0 20px;
  }
}

.cassetteJobOffer__heading {
  padding: 20px 20px 0;
}

.cassetteJobOffer__heading > [class*="label"] {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 20px;
  padding: 2px 8px 2px 22px;
}

.cassetteJobOffer__state,
.cassetteJobOffer__date {
  float: right;
  font-size: 1rem;

  &.fontLarge {
    font-size: 12px;
  }
}

.cassetteJobOffer__state + * {
  padding-right: 200px;
}

.cassetteJobOffer__info {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 20px;
}

.cassetteJobOffer__info [class*="label"] {
  margin-right: 4px;
}

.cassetteJobOffer__heading > .cassetteJobOffer__info + * {
  padding: 20px 0 0;
}

.cassetteJobOffer__heading > [class*="label"] + * {
  padding: 20px 0 0;
}

.cassetteJobOffer__attribute {
  margin-top: 6px;
}

.cassetteJobOffer__attributeLabel {
  display: inline-block;
}

.cassetteJobOffer__attributeLabelLast {
  float: right;
}

.cassetteJobOffer__name {
  margin-bottom: 5px;
  font-size: 1.4rem;
}

a .cassetteJobOffer__overview {
  color: $color_keyColor;
}

.cassetteJobOffer__overview {
  font-size: 1.5rem;
  font-weight: bold;
}

.cassetteJobOffer__overview [class*="label"]:first-of-type {
  margin-left: 20px;
}

.cassetteJobOffer__overview [class*="label"] {
  margin-left: 10px;
}

.cassetteJobOffer__content {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 20px;

  &.paddingLarge {
    padding-right: 22px;
    padding-left: 22px;
  }
}

.cassetteJobOffer__img,
.cassetteJobOffer__condition,
.cassetteJobOffer__entry {
  display: table-cell;
  vertical-align: top;
}

.cassetteJobOffer__entry--disabled {
  @extend .cassetteJobOffer__entry;
  padding-top: 23px;
}

.cassetteJobOffer__img {
  width: 203px;
}

.cassetteJobOffer__img a:hover {
  opacity: 0.5;
}

.cassetteJobOffer__img a {
  display: inline-block;
  vertical-align: top;
}

.cassetteJobOffer__img img {
  width: 183px;
  height: auto;
  vertical-align: top;
}

.cassetteJobOffer__condition {
  padding-right: 33px;
}

.cassetteJobOffer__condition .table {
  margin: 0;
}

.cassetteJobOffer__condition .table__head {
  width: 105px;
  padding: 8px 10px;
  font-size: 1.2rem;
}

.cassetteJobOffer__condition .table__body {
  overflow: hidden;
  padding: 8px 10px;
  font-size: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cassetteJobOffer__condition .btnAnswer {
  margin-top: 10px;
}

.cassetteJobOffer__entry {
  width: 180px;

  .btnInterst {
    padding: 18px 10px;

    &--disabled {
      color: $color_text;
      font-size: 14px;
      letter-spacing: -.5px;
      line-height: 1;
      padding: 22px 16px;

      &::before {
        margin-top: -3px;
        padding-right: 4px;
        padding-top: 0;
      }
    }
  }
}

a .cassetteJobOffer__entry [class^="btn"]:hover {
  opacity: 1;
}

.cassetteJobOffer__deadline {
  margin-bottom: 6px;
  color: #000;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
}

.cassetteJobOffer__deadline strong {
  color: $color_red;
  font-size: 2.4rem;
  font-weight: bold;
}

.cassetteJobOffer__entry .btnPrimaryL,
.cassetteJobOffer__entry .btnSecondaryL,
.cassetteJobOffer__entry .btnDisabledL {
  width: 100%;
  min-width: 0;
  height: 58px;
  margin-bottom: 22px;
  padding: 18px 20px;
}

.cassetteJobOffer__entry .btnInterst,
.cassetteJobOffer__entry .btnInterst--disabled {
  width: 100%;
  min-width: 0;
  height: 58px;
  margin-bottom: 22px;
}

.cassetteJobOffer__entry .btnEnter {
  margin-bottom: 22px;
}

.cassetteJobOffer__switch {
  position: relative;
  vertical-align: middle;
  font-size: 1.1rem;
  text-align: right;
}

.cassetteJobOffer__switch .toggleSwitch {
  vertical-align: middle;
  margin-left: 5px;
  z-index: 2;
}

.cassetteJobOffer__switch .toggleSwitch:hover + .tooltip {
  display: block;
}

.cassetteJobOffer .tooltip {
  position: absolute;
  top: 46px;
  right: 0;
  z-index: 1;
  display: none;
  width: 230px;
  text-align: center;
}

.cassetteJobOffer .tooltip__content {
  padding: 12px 20px;
}

.cassetteJobOffer .tooltip__title {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.5;
}

.cassetteJobOffer__questionnaire {
  margin-top: 10px;
  font-size: 1.2rem;
}

.cassetteJobOffer__questionnaire .textBoldS {
  margin-bottom: 10px;
}

.cassetteJobOffer__questionnaireItem,
.cassetteJobOffer__questionnaireA {
  margin-top: 5px;
}

.cassetteJobOffer__questionnaireQ {
  font-weight: bold;
}

.cassetteJobOffer__questionnaire [class*="label"] {
  margin-right: 8px;
}

/* レコメンド形式カセット
----------------------------------------------------- */
.cassetteRecommend {
  height: 490px;
  background-color: #f0f0f3;
}

.cassetteRecommend + .cassetteRecommend {
  margin-top: 20px;
}

.cassetteRecommend > .headingBlock {
  margin-bottom: 25px;
  padding-left: 0;
  font-size: 1.7rem;
}

.cassetteRecommend__content {
  overflow: hidden;
  position: relative;
  width: 950px;
  height: 370px;
  margin: 0 auto;
  padding-top: 5px;
}

.cassetteRecommend__content .loading {
  padding-top: 160px;
}

.cassetteRecommend__content > .linkArrowS {
  position: relative;
  z-index: 20;
  float: right;
  margin: 20px 0 0;
}

.cassetteRecommend .cassetteRecommend__content:first-child {
  padding-top: 60px;
  height: 490px;
}

.cassetteRecommend__carousel {
  position: relative;
  top: 0;
  left: 50%;
  width: 1254px;
  height: 330px;
  margin-left: -627px;
}

.cassetteRecommend__carousel::before,
.cassetteRecommend__carousel::after {
  content: "";
  height: 400px;
  width: 180px;
  position: absolute;
  top: 0;
  z-index: 10;
}

.cassetteRecommend__carousel::before {
  background: url(#{$img_path}shadeLeft.png) 0 0 repeat-y;
  left: 14px;
}

.cassetteRecommend__carousel::after {
  background: url(#{$img_path}shadeRight.png) 0 0 repeat-y;
  right: 14px;
}

.cassetteRecommend__item {
  @include card($width: 194px, $margin: 0 18px 18px 0) {
    background-color: inherit;
    position: relative;
    float: left;
    height: inherit;
    width: 194px;
    max-width: inherit;
  }
}

.cassetteRecommend__item--first {
  @extend .cassetteRecommend__item;
  margin-left: 212px;
}

.cassetteRecommend__item--dummy {
  @extend .cassetteRecommend__item;
  box-shadow: none;
}

.cassetteRecommend__all {
  background-color: #e0eff5;
  border: 1px solid $color_keyColor;
  color: $color_keyColor;
  display: table;
  text-align: center;
  height: 100%;
  width: 194px;
  font-size: 1.5rem;
}

.cassetteRecommend__all span {
  display: table-cell;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8;
  vertical-align: middle;
}

.cassetteRecommend__all:hover {
  text-decoration: none;
  opacity: 0.5;
}

.cassetteRecommend__item:last-of-type {
  margin-right: 0;
}

.cassetteRecommend__detail,
.cassetteRecommend__login {
  background-color: #fff;
  display: block;
  height: inherit;
}

.cassetteRecommend__detail:hover {
  opacity: 0.5;
  text-decoration: none;
}

.cassetteRecommend__entry {
  margin-top: 39px;
}

.cassetteRecommend__entryItem {
  margin-bottom: 14px;
  text-align: center;
}

.cassetteRecommend__item img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.cassetteRecommend__title {
  padding: 17px 20px;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.58333;
}

.cassetteRecommend__name {
  padding: 0 20px 18px;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
}

.cassetteRecommend__location,
.cassetteRecommend__condition {
  font-size: 1.1rem;
  color: #777;
  line-height: 1.6363;
  padding: 0 20px;
}

.cassetteRecommend__name,
.cassetteRecommend__location,
.cassetteRecommend__condition {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cassetteRecommend__item .btnFavorite {
  position: absolute;
  z-index: 2;
  right: 12px;
  bottom: 12px;
}

.cassetteRecommend__item .btnFavorite:hover + .iconTooltip {
  right: 1px;
  bottom: -13px;
  display: block;
}

.cassetteRecommend__item .btnFavorite--done:hover + .iconTooltip,
.cassetteRecommend__item .btnFavorite[class*="--done"]:hover + .iconTooltip {
  display: none;
}

[class^="cassetteRecommend__number--"] {
  border-radius: 15.5px;
  color: #fff;
  font-size: 1.3em;
  left: -12px;
  line-height: 31px;
  height: 31px;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 31px;
  z-index: 10;
}

.cassetteRecommend__number--1 {
  background-color: #c9a040;
}

.cassetteRecommend__number--2 {
  background-color: #9d9e9e;
}

.cassetteRecommend__number--3 {
  background-color: #91623d;
}

.cassetteRecommend__number--over {
  background-color: #3fbced;
}

.cassetteRecommend__number--limited {
  border-radius: 0;
  background-color: #f00;
  border: 1px solid #f00;
  color: #fff;
  font-size: 1.3em;
  left: 0;
  line-height: 2.1538;
  padding: 0 8px;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 10;
  width: auto;
}

.cassetteRecommend__prev,
.cassetteRecommend__next {
  position: absolute;
  top: 135px;
  z-index: 20;
  width: 44px;
  height: 46px;
  border: none;
  font-size: 0;
  cursor: pointer;
}

.cassetteRecommend__prev:hover,
.cassetteRecommend__next:hover {
  opacity: 0.5;
}

.cassetteRecommend__prev {
  left: 160px;
  background: url(#{$img_path}sprite.png) -230px -91px no-repeat;
}

.cassetteRecommend__next {
  right: 160px;
  background: url(#{$img_path}sprite.png) -186px -91px no-repeat;
}

.cassetteRecommend__link {
  display: block;
  padding: 20px 0;
}

.cassetteRecommend__list {
  box-sizing: border-box;
  display: block;
  height: 260px;
  margin: 0 20px;
  overflow: hidden;
  position: relative;
}

.cassetteRecommend__listItem {
  display: table;
  margin: 0 0 10px;
  position: relative;
  z-index: 1;
}

.cassetteRecommend__listItem:nth-child(4)::before {
  content: "";
  background-image: url(#{$img_path}shadeBottom.png);
  position: absolute;
  top: -8px;
  z-index: 2;
  height: 29px;
  width: 100%;
}

.cassetteRecommend__listTitle {
  background-color: #00aaeb;
  box-sizing: border-box;
  color: #fff;
  display: table-cell;
  font-size: 1.3rem;
  height: 70px;
  line-height: 1.5384;
  padding: 0 9px;
  vertical-align: middle;
  width: 58px;
}

.cassetteRecommend__listText {
  background-color: #e0eff5;
  box-sizing: border-box;
  color: #666;
  display: table-cell;
  font-size: 1.2rem;
  height: 70px;
  line-height: 1.6666;
  padding: 0 12px;
  vertical-align: middle;
  width: 98px;
}

.cassetteRecommend__time {
  color: #888;
  display: block;
  font-size: 1.1rem;
  margin: 19px 0 0;
  text-align: center;
}

.cassetteRecommend__footer {
  margin: 0 auto;
  text-align: right;
  font-size: 1.1rem;
  width: 900px;
  margin-top: 19px;
}

.cassetteRecommend__desireCondition {
  display: inline-block;
  margin-right: 30px;
}

.cassetteRecommend__setting {
  display: inline-block;
  margin-right: 30px;
}

.cassetteRecommend__update {
  display: inline-block;
}

/* 気になる用アンケートカセット
----------------------------------------------------- */
.cassetteQuestionnaire {
  @include modal;
  padding: 0;
  font-size: 1.4rem;
}

.cassetteQuestionnaire__name {
  width: 778px;
  margin: 0 auto;
  padding: 28px 100px 17px 0;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cassetteQuestionnaire__heading {
  padding: 12px 0;
  text-align: center;
  background-color: $color_keyColor;
}

.cassetteQuestionnaire__Question {
  position: relative;
  z-index: 1;
  width: 778px;
  margin: 0 auto;
  padding: 20px;
  font-weight: bold;
  background-color: $color_white;
}

.cassetteQuestionnaire__Question::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-color: transparent;
  border-top-color: $color_white;
  border-style: solid;
  border-width: 10px 8px;
  transform: translate(-50%, 100%);
}

.cassetteQuestionnaire__Question .labelQuestion {
  margin-right: 10px;
  font-weight: normal;
}

.cassetteQuestionnaire__content {
  width: 778px;
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
}

.cassetteQuestionnaire__text {
  margin: 30px 0;
}

.cassetteQuestionnaire__text em {
  color: #e50000;
}

.cassetteQuestionnaire .btnBox {
  letter-spacing: -0.4em;
}

.cassetteQuestionnaire__content .btnBox .btnDefaultS {
  width: 178px;
  height: 54px;
  margin: 0 22px 0 0;
  padding: 8px 10px;
}

.cassetteQuestionnaire__content .btnDefaultS:last-child {
  margin-right: 0;
}

/* 画像+テキストで、リンク先を説明する場合に使用する※カード内
----------------------------------------------------- */
.cassetteBanner {
  @include clearfix;
  @include cassetteBanner;
}

/* 画像+テキストで、リンク先を説明する場合に使用する※カード内
----------------------------------------------------- */
.cassetteChangeBanner {
  @include clearfix;
  @include cassetteBanner;
  margin-right: -20px;
}

.cassetteChangeBanner .cassetteChangeBanner__item {
  border-color: $color_keyColor;
  margin-bottom: 0;
}

.cassetteChangeBanner .cassetteChangeBanner__item:nth-of-type(n+4) {
  margin-top: 15px;
}

.cassetteChangeBanner .cassetteChangeBanner__text {
  text-overflow: ellipsis;
}

.cassetteChangeBanner .cassetteChangeBanner__title {
  margin-top: -2px;
  padding: 0 12px 9px;
  font-size: 1.5rem;
}

.cassetteChangeBanner .cassetteChangeBanner__title em {
  font-size: 1.3rem;
}

.cassetteChangeBanner .cassetteChangeBanner__lead {
  color: $color_text;
  display: block;
  min-height: 77px;
  padding: 0 12px 9px;
  font-size: 1.2rem;
}

/* 外部サイトへのリンク時に使用する※カード内
----------------------------------------------------- */
.cassetteRelate {
  border: 1px solid #99ddf7;
  border-bottom: none;
}

.cassetteRelate__item {
  display: table;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #99ddf7;
}

.cassetteRelate__item:hover {
  opacity: 0.5;
  text-decoration: none;
}

.cassetteRelate__img,
.cassetteRelate__details {
  display: table-cell;
  vertical-align: middle;
}

.cassetteRelate__img {
  width: 68px;
  padding-right: 24px;
}

.cassetteRelate__title {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: bold;
}

.cassetteRelate__text {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.7;
}

/* プレミアムスカウト一覧カセット
----------------------------------------------------- */
.cassettePremiumScout {
  @extend .cassetteRecommend;
}

.cassettePremiumScout .headingBlock {
  margin-bottom: 30px;
}

.cassettePremiumScout__content {
  @extend .cassetteRecommend__content;
  height: 380px;
}

.cassettePremiumScout__carousel {
  @extend .cassetteRecommend__carousel;
  height: 310px;
}

.cassettePremiumScout__carousel::before,
.cassettePremiumScout__carousel::after {
  top: -3px;
}

.cassettePremiumScout__carousel .loading {
  padding: 150px 0;
}

.cassettePremiumScout__item {
  @extend .cassetteRecommend__item;
}

.cassettePremiumScout__item--dummy {
  @extend .cassettePremiumScout__item;
  box-shadow: none;
}

.cassettePremiumScout__item--first {
  @extend .cassettePremiumScout__item;
}

.cassettePremiumScout__item:last-of-type {
  margin-right: 0;
}

.cassettePremiumScout__detail {
  @extend .cassetteRecommend__detail;
}

.cassettePremiumScout__detail:hover {
  opacity: 0.5;
  text-decoration: none;
}

.cassettePremiumScout__status {
  height: 30px;
  padding-top: 10px;
  color: #333;
  font-size: 1.2rem;
  text-align: center;
}

.cassettePremiumScout__status em {
  color: $color_red;
}

.cassettePremiumScout__title {
  @extend .cassetteRecommend__title;
  padding: 16px 16px 14px;
}

.cassettePremiumScout__name {
  overflow: hidden;
  padding: 2px 16px 16px;
  color: #444;
  font-size: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cassettePremiumScout__detail > [class^="label"] {
  margin-left: 16px;
}

//未キープ
.cassettePremiumScout__keep {
  @include iconFont($fontSize: 2rem, $icon: "\e919") {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    color: $color_disable02;
  }
}

.cassettePremiumScout__keep:hover {
  cursor: pointer;
}

.cassettePremiumScout__keep:hover + .iconTooltip {
  right: -13px;
  bottom: -15px;
  display: block;
}

//キープ済み
.cassettePremiumScout__keep--done {
  @extend .cassettePremiumScout__keep;
  color: $color_keep;
}

//有効期限切れ・辞退済み
.cassettePremiumScout__keep--cancel {
  @extend .cassettePremiumScout__keep;
  color: $color_disable;
}

//キープ済み 有効期限切れ・辞退済み
.cassettePremiumScout__keep--doneCancel {
  @extend .cassettePremiumScout__keep;
  color: #ffebac;
}

.cassettePremiumScout__keep--done:hover,
.cassettePremiumScout__keep--cancel:hover,
.cassettePremiumScout__keep--doneCancel:hover {
  cursor: default;
}

.cassettePremiumScout__keep--done:hover + .iconTooltip,
.cassettePremiumScout__keep--cancel:hover + .iconTooltip,
.cassettePremiumScout__keep--doneCancel:hover + .iconTooltip {
  display: none;
}

.cassettePremiumScout__prev {
  @extend .cassetteRecommend__prev;
}

.cassettePremiumScout__next {
  @extend .cassetteRecommend__next;
}

.cassettePremiumScout .error__title {
  font-size: 1.4rem;
}

/* カルーセル jsスタイル
----------------------------------------------------- */
.js__carousel {
  height: inherit;
}

/* スカウト 詳細
----------------------------------------------------- */
.situation + .cassetteScoutCompany {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #{$color_subBorder};
}

.cassetteScoutCompany__info {
  @include clearfix;
  margin-bottom: 15px;
  letter-spacing: -0.4em;
}

.cassetteScoutCompany__type,
.cassetteScoutCompany__info [class^="label"],
.cassetteScoutCompany__info .textEm,
.cassetteScoutCompany__date {
  display: inline-block;
  vertical-align: middle;
}

.cassetteScoutCompany__info .labelScoutLimited,
.cassetteScoutCompany__info .textEm,
.cassetteScoutCompany__date,
.cassetteScoutCompany__status {
  letter-spacing: 0;
}

.cassetteScoutCompany__type:hover {
  opacity: 0.5;
  cursor: pointer;
}

.cassetteScoutCompany__info .labelScoutLimited {
  margin-right: 5px;
}

.cassetteScoutCompany__date {
  margin-left: 20px;
  font-size: 1.2rem;
}

.cassetteScoutCompany__status {
  float: right;
}

.cassetteScoutCompany__status em {
  color: #f00;
}

.cassetteScoutCompany .card__content {
  @include clearfix;
}

.cassetteScoutCompany__image {
  float: left;
  margin-right: 16px;
}

.cassetteScoutCompany__image:hover {
  opacity: 0.5;
}

.cassetteScoutCompany__image img {
  vertical-align: top;
}

.cassetteScoutCompany__overview {
  display: block;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.cassetteScoutCompany .card .textEm {
  margin-bottom: 5px;
}

/* 応募・メッセージ管理 詳細
----------------------------------------------------- */
.cassetteApplicationDetail__info {
  @include clearfix;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.cassetteApplicationDetail__date {
  float: left;
}

.cassetteApplicationDetail__replyDate {
  float: right;
}

.cassetteApplicationDetail .card:first-of-type .headingBlock {
  padding-bottom: 40px;
}

.cassetteApplicationDetail .description .headingBlock {
  border-top: none;
}

.cassetteApplicationDetail .select {
  margin-bottom: 20px;
}

.cassetteApplicationDetail .formTable__head {
  width: 127px;
}

.cassetteApplicationDetail .formTable__body {
  padding: 20px 20px 20px 35px;
  vertical-align: top;
}

.cassetteApplicationDetail .formTable__body__interviewTime {
  vertical-align: middle;
}

.cassetteApplicationDetail__note {
  margin-top: 20px;
}

.cassetteApplicationDetail__note .textSup + .textSup {
  margin-top: 24px;
}

.cassetteApplicationDetail .formTable__body [class*="inputText"] + .textSup {
  margin-top: 8px;
}

.cassetteApplicationDetail .formTable__body .cassetteApplicationDetail__note {
  margin-top: 10px;
}

.cassetteApplicationDetail .formTable__body .cassetteApplicationDetail__note .textSup {
  margin: 0;
  padding-left: 1em;
  text-indent: -1em;
}

.cassetteApplicationDetail .formTable__body .textArea {
  min-height: 160px;
}

.cassetteApplicationDetail .description .text:last-of-type {
  margin-bottom: 0;
}

.cassetteApplicationDetail .description__content + .description__content {
  margin-top: -20px;
  padding: 20px 28px 40px;
}

.cassetteApplicationDetail .select__text--placeholder {
  color: $color_text;
}

/* 企業への質問
----------------------------------------------------- */
.cassetteOfferRecapitulate {
  @include card;
  position: relative;
  line-height: 1.57;
  border-top: 2px solid $color_keyColor;
}

.cassetteOfferRecapitulate__content {
  @include contentMargin;
  padding: 30px 28px 40px;
}

.cassetteOfferRecapitulate__content--2col {
  @extend .cassetteOfferRecapitulate__content;
  display: table;
  table-layout: fixed;
  width: 100%;
  font-size: 1.2rem;
}

.cassetteOfferRecapitulate__content--2col .cassetteOfferRecapitulate__text {
  display: table-cell;
  vertical-align: top;
  width: 670px;
  line-height: 1.6;
}

.cassetteOfferRecapitulate__date {
  display: table-cell;
  vertical-align: bottom;
  line-height: 1.8;
  font-size: 1.2rem;
  text-align: right;
}

.cassetteOfferRecapitulate__content + .cassetteOfferRecapitulate__content {
  margin-top: -20px;
  padding-top: 20px;
  border-top: 1px solid #{$color_subBorder};
}

.cassetteOfferRecapitulate__job {
  color: $color_heading;
  font-size: 1.8rem;
  font-weight: bold;
}

.cassetteOfferRecapitulate__job [class*="label"] {
  margin-right: 5px;
}

.cassetteOfferRecapitulate__job [class*="label"]:first-of-type {
  margin-left: 5px;
}

.cassetteOfferRecapitulate__job [class*="label"]:last-of-type {
  margin-right: 0;
}

.cassetteOfferRecapitulate__location {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.cassetteOfferRecapitulate__text em {
  color: $color_red;
  font-weight: bold;
}

.cassetteOfferRecapitulate__check,
.cassetteOfferRecapitulate__check .checkbox {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 36px;
  height: 100%;
}

.cassetteOfferRecapitulate__check .checkbox:hover {
  background-color: rgba(221, 221, 221, 0.4);
}

.cassetteOfferRecapitulate__check .checkbox__icon {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.cassetteOfferRecapitulate__check + .cassetteOfferRecapitulate__content {
  margin-left: 36px;
  padding: 20px;
  border-left: 1px solid #{$color_subBorder};
}

.cassetteOfferRecapitulate__label {
  position: absolute;
  top: -15px;
  right: 28px;
}

.cassetteOfferRecapitulate__label [class*="label"] {
  display: block;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
  border-radius: 4px;
}

.cassetteOfferRecapitulate__label .labelUpdate {
  background-color: $color_keyColor;
}

.cassetteOfferRecapitulate__attribute {
  letter-spacing: -0.4em;
}

.cassetteOfferRecapitulate__attributeLabel {
  display: inline-block;
  margin: 0 0 0 10px;
  letter-spacing: 0;
}

.cassetteOfferRecapitulate__attributeLabel:first-child {
  margin: 0;
}

.cassetteOfferRecapitulate__attributeLabelLast {
  float: right;
  letter-spacing: 0;
}

.cassetteOfferRecapitulate__attributeLabelLast .labelWoman:hover {
  opacity: 0.5;
  text-decoration: none;
}

.cassetteOfferRecapitulate__deadline {
  float: right;
  margin: 0 0 10px 10px;
}

.cassetteOfferRecapitulate__deadline [class*="label"] {
  margin: 0 8px 0 0;
}

.cassetteOfferRecapitulate__deadlineRemainder {
  @extend .cassetteRecruit__deadlineRemainder;
}

.cassetteOfferRecapitulate--other {
  @extend .cassetteOfferRecapitulate;
  border-top: none;
}

.cassetteOfferRecapitulate--other__heading {
  @extend .cassetteOfferRecapitulate__content;
  color: $color_heading;
  font-size: 1.6rem;
  font-weight: bold;
}

.cassetteOfferRecapitulate--other__heading [class*="label"] {
  margin: 0 0 0 8px;
}

.cassetteOfferRecapitulate--other__content {
  @extend .cassetteOfferRecapitulate__content;
}

.cassetteOfferRecapitulate--other__job {
  @extend .cassetteOfferRecapitulate__job;
  color: $color_keyColor;
  font-size: 1.6rem;
}

.cassetteOfferRecapitulate--other__attribute {
  @extend .cassetteOfferRecapitulate__attribute;
  margin: 10px 0 0;
}

.cassetteOfferRecapitulate--other__attributeLabel {
  @extend .cassetteOfferRecapitulate__attributeLabel;
}

.cassetteOfferRecapitulate--other__attributeLabelLast {
  @extend .cassetteOfferRecapitulate__attributeLabelLast;
}

/* 求人カセット
----------------------------------------------------- */
.cassetteRecruit {
  position: relative;
  margin: 20px 0;
  padding-top: 1px;
}

.cassetteRecruit__tag {
  position: absolute;
  top: 0;
  background-color: #0068b8;
  color: $color_white;
  padding: 8px 20px;
}

.cassetteRecruit__content {
  @include card;
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;
}

.cassetteRecruit__tag + .cassetteRecruit__content,
.cassetteRecruitRecommend__tag + .cassetteRecruitRecommend__content {
  margin-top: 32px;
}

.cassetteRecruit__label,
.cassetteRecruitRecommend__label {
  position: absolute;
  top: -8px;
  right: 30px;
  background-color: $color_red;
  border-radius: 4px;
  color: $color_white;
  font-weight: bold;
  font-size: 2rem;
  padding: 10px 20px;
  line-height: 1;
}

.cassetteRecruit__tag--scoutCrown,
.cassetteRecruitRecommend__tag--scoutCrown {
  overflow: hidden;
  position: absolute;
  top: 1px;
  right: -33px;
  z-index: -1;
  width: 34px;
  padding: 0;
  border-radius: 4px;
  box-shadow: $boxShadow_defult;
  box-sizing: border-box;
}

.cassetteRecruit__tag--scoutCrown img,
.cassetteRecruitRecommend__tag--scoutCrown img {
  vertical-align: top;
}

.cassetteRecruit__tag--scoutCrown + [class*="__content"],
.cassetteRecruitRecommend__tag--scoutCrown + [class*="__content"] {
  z-index: 2;
}

.cassetteRecruitRecommend__label--attention {
  @extend .cassetteRecruitRecommend__label;
  background-color: #f09300;
}

.cassetteRecruit__heading {
  border-top: 2px solid $color_keyColor;
  border-bottom: 1px solid $color_subBorder;
  padding: 28px 25px 20px;
}

.cassetteRecruit__heading .labelAlready {
  margin-top: 8px;
  float: right;
}

.cassetteRecruit__deadline {
  position: absolute;
  top: 28px;
  right: 28px;
  line-height: 2em;
}

.cassetteRecruit__deadlineRemainder {
  font-size: 1.2rem;
}

.cassetteRecruit__deadlineRemainder strong {
  color: $color_red;
  font-size: 2rem;
  font-weight: bold;
}

.cassetteRecruit__deadline [class*="label"] {
  margin-right: 6px;
}

.cassetteRecruit__name,
.cassetteRecruitRecommend__name {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 8px;
  width: 740px;
}

.cassetteRecruit__copy,
.cassetteRecruitRecommend__copy {
  color: $color_keyColor;
  font-size: 2rem;
  font-weight: bold;
}

.cassetteRecruit__copy [class*="label"],
.cassetteRecruitRecommend__copy [class*="label"] {
  margin-top: -4px;
  margin-left: 10px;
  vertical-align: middle;
}

.cassetteRecruit__copy .labelAlready {
  margin-top: 0;
}

.cassetteRecruit__catchCopy,
.cassetteRecruitRecommend__catchCopy {
  margin: 10px 0 -10px;
  color: #ff5254;
  font-size: 1.6rem;
  font-weight: bold;
}

.cassetteRecruit__detail {
  padding: 12px 25px;
}

.cassetteRecruit__point:hover,
.cassetteRecruitRecommend__point:hover {
  opacity: 0.5;
}

.cassetteRecruit__catchCopy {
  color: #ff5254;
  font-size: 1.6rem;
  font-weight: bold;
}

.cassetteRecruit__attribute + .cassetteRecruit__point,
.cassetteRecruitRecommend__attribute + .cassetteRecruitRecommend__point {
  display: block;
  margin-top: 22px;
}

.cassetteRecruit__attributeLabel,
.cassetteRecruitRecommend__attributeLabel {
  display: inline-block;
}

.cassetteRecruit__attributeLabelLast,
.cassetteRecruitRecommend__attributeLabelLast {
  float: right;
}

.cassetteRecruit__main,
.cassetteRecruitRecommend__main {
  width: 680px;
  float: left;
  padding-bottom: 16px;
}

.cassetteRecruit__mainM,
.cassetteRecruitRecommend__mainM {
  @extend .cassetteRecruit__main;
  width: 720px;
}

.cassetteRecruit__mainL,
.cassetteRecruitRecommend__mainL {
  @extend .cassetteRecruit__main;
  width: 760px;
}

.cassetteRecruit__mainLL,
.cassetteRecruitRecommend__mainLL {
  @extend .cassetteRecruit__main;
  width: 100%;
}

.cassetteRecruit__main .tableCondition,
.cassetteRecruitRecommend__main .tableCondition {
  margin-bottom: 6px;
}

.cassetteRecruit__main .tableCondition__head,
.cassetteRecruitRecommend__main .tableCondition__head {
  padding: 10px;
  width: 120px;
  font-size: 1.2rem;
}

.cassetteRecruit__main .tableCondition__body,
.cassetteRecruitRecommend__main .tableCondition__body {
  padding: 10px;
  font-size: 1.2rem;
}

.cassetteRecruit__img,
.cassetteRecruitRecommend__img {
  width: 200px;
  float: right;
  padding-bottom: 16px;
}

.cassetteRecruit__imgM,
.cassetteRecruitRecommend__imgM {
  @extend .cassetteRecruit__img;
  width: 160px;
}

.cassetteRecruit__imgS,
.cassetteRecruitRecommend__imgS {
  @extend .cassetteRecruit__img;
  width: 120px;
}

.cassetteRecruit__img img,
.cassetteRecruitRecommend__img img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.cassetteRecruit__img a,
.cassetteRecruitRecommend__img a {
  display: block;
}

.cassetteRecruit__img a:first-child,
.cassetteRecruitRecommend__img a:first-child {
  margin-top: 20px;
}

.cassetteRecruit__point + .cassetteRecruit__point {
  margin-top: 20px;
}

.cassetteRecruit__bottom,
.cassetteRecruitRecommend__bottom {
  border-top: 1px solid $color_subBorder;
  clear: both;
  padding: 26px 25px 20px;
  position: relative;
}

.cassetteRecruitRecommend__bottom {
  padding: 26px 23px 20px;
}

.cassetteRecruit__bottom .linkArrowS,
.cassetteRecruitRecommend__bottom .linkArrowS {
  position: absolute;
  top: 50%;
  margin-top: -12px;
}

.cassetteRecruit__bottom .btnInterst,
.cassetteRecruitRecommend__bottom .btnInterst {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 148px;
}

.cassetteRecruit__updateDate,
.cassetteRecruit__endDate,
.cassetteRecruitRecommend__updateDate,
.cassetteRecruitRecommend__endDate {
  margin-bottom: 6px;
  font-size: 1.2rem;
  text-align: right;
}

// 求人検索一覧画面、求人の企業ロゴ
.leftLogoBlock {
  float: left;
  padding: 0 14px 0 0;
}

.boxAdjust {
  overflow: hidden;
}

.cassetteUseFloat {
  overflow: auto;
}

/* 求人カセット（おすすめ）
----------------------------------------------------- */
.cassetteRecruitRecommend {
  @extend .cassetteRecruit;
}

.cassetteRecruitRecommend__tag {
  @extend .cassetteRecruit__tag;
  background-color: #f18b00;
  font-size: 1.2rem;
  font-weight: bold;
}

.cassetteRecruitRecommend__content {
  @extend .cassetteRecruit__content;
  border: 2px solid #f18b00;
}

.cassetteRecruitRecommend__heading {
  @extend .cassetteRecruit__heading;
  border-top: none;
  background-color: #fff6cc;
  padding: 28px 23px 20px;
}

.cassetteRecruitRecommend__label + .cassetteJobOffer__heading {
  border-top: none;
  padding: 40px 20px 0;

  .cassetteJobOffer__name {
    padding: 0;
  }
}

.cassetteRecruitRecommend__label--attention + .cassetteJobOffer__heading {
  border-top: none;
  background-color: #fff6cc;
  padding: 40px 20px 0;

  .cassetteJobOffer__name {
    padding: 0;
  }
}

.cassetteRecruitRecommend__deadline {
  @extend .cassetteRecruit__deadline;
}

.cassetteRecruitRecommend__deadline [class*="label"] {
  margin-right: 6px;
}

.cassetteRecruitRecommend__heading .labelAlready {
  margin-top: 8px;
  float: right;
}

.cassetteRecruitRecommend__detail {
  padding: 12px 23px;
}

/* 求人カセット（おすすめ）
----------------------------------------------------- */
.cassettePickup {
  @include card;
  position: relative;
  margin: 20px auto;
  line-height: 1.57;
  border-top: 2px solid $color_keyColor;
}

.cassettePickup__content {
  @include contentMargin;
  padding: 30px 28px 40px;
}

.cassettePickup__content + .cassettePickup__content {
  margin-top: -20px;
  padding-top: 20px;
  border-top: 1px solid #{$color_subBorder};
}

.cassettePickup__title {
  font-weight: bold;
  padding-left: 100px;
  position: relative;
  font-size: 1.8rem;
}

.cassettePickup__title [class*="label"] {
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  top: 4px;
}

.cassettePickup__title a {
  color: $color_text;
}

.cassettePickup__content .text {
  color: $color_keyColor;
  font-weight: bold;
  font-size: 1.6rem;
}

.cassettePickup__content .text [class*="label"] {
  margin-left: 6px;
}

/* 新着求人カセット
----------------------------------------------------- */
.cassetteInformation {
  @include card;
  @include clearfix;
  padding: 20px;
}

.cassetteInformation__img {
  float: left;
}

.cassetteInformation__content {
  padding-left: 120px;
}

.cassetteInformation__title {
  border-bottom: 1px solid $color_subBorder;
  font-weight: bold;
  padding-bottom: 18px;
  margin-bottom: 10px;
}

.cassetteInformation__title span:first-child {
  font-size: 1.6rem;
  vertical-align: middle;
  margin-right: 8px;
}

.cassetteInformation__subTitle {
  font-size: 1.2rem;
}

.cassetteInformation__title [class*="label"] {
  margin-right: 8px;
}

.cassetteInformation__list .linkArrow,
.cassetteInformation__list [class*="label"] {
  margin-right: 8px;
}

.cassetteInformation__listItem {
  margin-bottom: 6px;
}

/* 人気求人一覧専用
----------------------------------------------------- */

.popularMainBox .cassetteRecommend__carousel::before,
.popularMainBox .cassetteRecommend__carousel::after {
  content: "";
  height: 340px;
  width: 180px;
  position: absolute;
  top: 0;
  z-index: 10;
}

.popularMainBox .cassetteRecommend__title {
  word-break: break-all;
}

/* イベントカセット
----------------------------------------------------- */
.cassetteEvent {
  @include card($width: 100%);
  display: table;
  table-layout: fixed;

  & > a:hover {
    text-decoration: none;
  }

  & .checkbox,
  &__container {
    display: table-cell;
    vertical-align: middle;
  }

  a &__container {
    color: $color_text;
  }

  & .checkbox {
    overflow: hidden;
    width: 36px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid #ddd;
  }

  & .checkbox:hover {
    background-color: rgba(221, 221, 221, 0.4);
  }

  & .checkbox__icon {
    position: relative;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }

  & .checkbox__icon::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__container {
    position: relative;
  }

  &__heading {
    padding: 20px 20px 0;
  }

  &__heading > [class*="label"] {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 20px;
    padding: 2px 8px 2px 22px;
  }

  &__state,
  &__date {
    float: right;
    font-size: 1rem;
  }

  &__state + * {
    padding-right: 200px;
  }

  &__info {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 20px;
  }

  &__info [class*="label"] {
    margin-right: 4px;
  }

  &__heading > &__info + * {
    padding: 20px 0 0;
  }

  &__heading > [class*="label"] + * {
    padding: 20px 0 0;
  }

  &__attribute {
    margin-top: 6px;
  }

  &__attributeLabel {
    display: inline-block;
  }

  &__attributeLabelLast {
    float: right;
  }

  &__name {
    margin-bottom: 5px;
    font-size: 1.4rem;
  }

  a &__overview {
    color: $color_keyColor;
  }

  &__overview {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__overview [class*="label"]:first-of-type {
    margin-left: 20px;
  }

  &__overview [class*="label"] {
    margin-left: 10px;
  }

  &__content {
    border-top: 1px solid #ddd;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 20px;
  }

  &__img,
  &__condition,
  &__entry {
    display: table-cell;
    vertical-align: top;
    width: 338px;
  }

  &__entry--disabled {
    @extend .cassetteEvent__entry;
    padding-top: 23px;
  }

  &__img {
    width: 203px;
  }

  &__img a:hover {
    opacity: 0.5;
  }

  &__img a {
    display: inline-block;
    vertical-align: top;
  }

  &__img img {
    width: 183px;
    height: auto;
    vertical-align: top;
  }

  &__condition {
    padding-right: 33px;
    width: 445px;
  }

  &__condition .table {
    margin: 0;
  }

  &__condition .table__head {
    width: 105px;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: 600;
  }

  &__condition .table__body {
    overflow: hidden;
    padding: 8px 10px;
    font-size: 14px;
    font-weight: 300;
  }

  &__condition .btnAnswer {
    margin-top: 10px;
  }

  &__entry {
    width: 180px;
  }

  a &__entry [class^="btn"]:hover {
    opacity: 1;
  }

  &__deadline {
    margin-bottom: 6px;
    color: #000;
    font-size: 1.3rem;
    line-height: 1;
    text-align: center;
  }

  &__deadline strong {
    color: $color_red;
    font-size: 2.4rem;
    font-weight: bold;
  }

  &__entry .btnPrimaryL,
  &__entry .btnSecondaryL,
  &__entry .btnSubL,
  &__entry .btnDisabledL {
    font-weight: 600;
    width: 100%;
    min-width: 0;
    height: 50px;
    margin-top: 10px;
    padding: 14px 20px;
  }

  .event__join + .event__item &__entry .btnSubL {
    margin-top: 0;
  }

  &__entry .btnEnter {
    margin-bottom: 22px;
  }

  &__switch {
    position: relative;
    vertical-align: middle;
    font-size: 1.1rem;
    text-align: right;
  }

  &__switch .toggleSwitch {
    vertical-align: middle;
    margin-left: 5px;
    z-index: 2;
  }

  &__switch .toggleSwitch:hover + .tooltip {
    display: block;
  }

  & .tooltip {
    position: absolute;
    top: 46px;
    right: 0;
    z-index: 1;
    display: none;
    width: 230px;
    text-align: center;
  }

  & .tooltip__content {
    padding: 12px 20px;
    width: fit-content;
  }

  & .tooltip__title {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
  }

  &__questionnaire {
    margin-top: 10px;
    font-size: 1.2rem;
  }

  &__questionnaire .textBoldS {
    margin-bottom: 10px;
  }

  &__questionnaireItem,
  &__questionnaireA {
    margin-top: 5px;
  }

  &__questionnaireQ {
    font-weight: bold;
  }

  &__questionnaire [class*="label"] {
    margin-right: 8px;
  }
}
