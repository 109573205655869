@charset "UTF-8";

/* 重要度:高
----------------------------------------------------------------- */
.btnPrimary {
  @include btn() {
    width: 100%;
    min-height: 50px;
    color: $color_white;
    background-color: $color_red;
    border-color: $color_red;
  }
}

.btnPrimaryL {
  @include btn() {
    min-width: 290px;
    min-height: 50px;
    color: $color_white;
    background-color: $color_red;
    border-color: $color_red;
  }
}

.btnPrimaryM {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 222px;
    min-height: 44px;
    color: $color_white;
    background-color: $color_red;
    border-color: $color_red;
  }
}

.btnPrimaryS {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 156px;
    min-height: 44px;
    color: $color_white;
    background-color: $color_red;
    border-color: $color_red;
  }
}

.btnPrimarySS {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem) {
    min-width: 98px;
    min-height: 32px;
    color: $color_white;
    background-color: $color_red;
    border-color: $color_red;
  }
}

.btnPrimary--disabled {
  @extend .btnPrimary;
  @include disabled;
}

.btnPrimaryL--disabled {
  @extend .btnPrimaryL;
  @include disabled;
}

.btnPrimaryM--disabled {
  @extend .btnPrimaryM;
  @include disabled;
}

.btnPrimaryS--disabled {
  @extend .btnPrimaryS;
  @include disabled;
}

.btnPrimarySS--disabled {
  @extend .btnPrimarySS;
  @include disabled;
}

/* 重要度:高
----------------------------------------------------------------- */
.btnSecondary {
  @include btn() {
    width: 100%;
    min-height: 50px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
}

.btnSecondaryL {
  @include btn() {
    min-width: 290px;
    min-height: 50px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
}

.btnSecondaryM {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 222px;
    min-height: 44px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
}

.btnSecondaryS {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 156px;
    min-height: 44px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
}

.btnSecondarySS {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem) {
    min-width: 98px;
    min-height: 32px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
}

.btnSecondary--disabled {
  @extend .btnSecondary;
  @include disabled;
}

.btnSecondaryL--disabled {
  @extend .btnSecondaryL;
  @include disabled;
}

.btnSecondaryL--disabledBlue {
  @extend .btnSecondaryL;
  @include disabled;
  background-color: $color_keyColor;
  border-color: $color_keyColor;
  color: $color_white;
  opacity: .3;
}

.btnSecondaryM--disabled {
  @extend .btnSecondaryM;
  @include disabled;
}

.btnSecondaryS--disabled {
  @extend .btnSecondaryS;
  @include disabled;
}

.btnSecondarySS--disabled {
  @extend .btnSecondarySS;
  @include disabled;
}

/* 重要度:中
----------------------------------------------------------------- */
.btnDefault {
  @include btn() {
    width: 100%;
    min-height: 50px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

.btnDefaultL {
  @include btn() {
    min-width: 290px;
    min-height: 50px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

.btnDefaultL--checked {
  @extend .btnDefaultL;
  background-color: $color_keyColor_light;
}

.btnDefaultL--checked::before {
  content: "\e917";
  margin: 10px 10px 0 0;
  z-index: 1;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  font-family: mynavi; /* stylelint-disable-line */
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  speak: none;
  text-decoration: none;
  text-rendering: auto;
  text-transform: none;
  vertical-align: top;
}

.btnDefaultM {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 222px;
    min-height: 44px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

.btnDefaultS {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 156px;
    min-height: 44px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

.btnDefaultSS {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem) {
    min-width: 98px;
    min-height: 32px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

.btnDefault--disabled {
  @extend .btnDefault;
  @include disabled;
}

.btnDefaultL--disabled {
  @extend .btnDefaultL;
  @include disabled;
}

.btnDefaultM--disabled {
  @extend .btnDefaultM;
  @include disabled;
}

.btnDefaultS--disabled {
  @extend .btnDefaultS;
  @include disabled;
}

.btnDefaultSS--disabled {
  @extend .btnDefaultSS;
  @include disabled;
}

[class*="btnDefault"] .loading {
  @include loading($height: 13px, $top: -7px);
  position: relative;
  display: inline-block;
  width: 40px;
  height: 14px;
  margin: 0 0 0 5px;
  padding: 0 20px;
  background-color: transparent;
}

[class*="btnDefault"] .loading span {
  display: block;
}

[class*="btnDefault"] .loading span::after {
  background-color: $color_keyColor;
}

/* 重要度:低
----------------------------------------------------------------- */
.btnSub {
  @include btn() {
    width: 100%;
    min-height: 50px;
    color: $color_text;
    background-color: $color_white;
    border-color: $color_text;
  }
}

.btnSubL {
  @include btn() {
    min-width: 290px;
    min-height: 50px;
    color: $color_text;
    background-color: $color_white;
    border-color: $color_text;
  }

  &__gray {
    color: $color_subText;
    border-color: $color_subText;
  }
}

.btnSubM {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 200px;
    min-height: 44px;
    color: $color_text;
    background-color: $color_white;
    border-color: $color_text;
  }
}

.btnSubS {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 156px;
    min-height: 44px;
    color: $color_text;
    background-color: $color_white;
    border-color: $color_text;
  }
}

.btnSubSS {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem) {
    min-width: 98px;
    min-height: 32px;
    color: $color_text;
    background-color: $color_white;
    border-color: $color_text;
  }
}
@mixin btnSubSS {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem) {
    min-width: 98px;
    min-height: 32px;
    color: $color_text;
    background-color: $color_white;
    border-color: $color_text;
  }
}

.btnSub--disabled {
  @extend .btnSub;
  @include disabled;
}

.btnSubL--disabled {
  @extend .btnSubL;
  @include disabled;
}

.btnSubM--disabled {
  @extend .btnSubM;
  @include disabled;
}

.btnSubS--disabled {
  @extend .btnSubS;
  @include disabled;
}

.btnSubSS--disabled {
  @extend .btnSubSS;
  @include disabled;
}

/* 特別 優先度に関係なく特別に強調したい場合に使用
----------------------------------------------------------------- */
.btnSpecial {
  @include btn() {
    width: 100%;
    min-height: 50px;
    color: $color_red;
    background-color: $color_white;
    border-color: $color_red;
  }
}

.btnSpecialL {
  @include btn() {
    min-width: 290px;
    min-height: 50px;
    color: $color_red;
    background-color: $color_white;
    border-color: $color_red;
  }
}

.btnSpecialM {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 222px;
    min-height: 44px;
    color: $color_red;
    background-color: $color_white;
    border-color: $color_red;
  }
}

.btnSpecialS {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    min-width: 156px;
    min-height: 44px;
    color: $color_red;
    background-color: $color_white;
    border-color: $color_red;
  }
}

.btnSpecialSS {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem) {
    min-width: 98px;
    min-height: 32px;
    color: $color_red;
    background-color: $color_white;
    border-color: $color_red;
  }
}

.btnSpecial--disabled {
  @extend .btnSpecial;
  @include disabled;
}

.btnSpecialL--disabled {
  @extend .btnSpecialL;
  @include disabled;
}

.btnSpecialM--disabled {
  @extend .btnSpecialL;
  @include disabled;
}

.btnSpecialS--disabled {
  @extend .btnSpecialL;
  @include disabled;
}

.btnSpecialSS--disabled {
  @extend .btnSpecialL;
  @include disabled;
}

/* 閉じるボタン
----------------------------------------------------------------- */
.btnClose {
  @include btn($fontSize: 1.3rem, $padding: 9px 8px 8px 28px) {
    position: relative;
    z-index: 1;
    min-width: 81px;
    color: $color_keyColor;
    line-height: 1;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
  @include iconFont($fontSize: 1.3rem, $icon: "\e626");

  &.marginLeftAuto {
    margin-left: auto;
  }
  &.marginRightAuto {
    margin-right: auto;
  }
}

.btnClose::before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

/* 削除ボタン
----------------------------------------------------------------- */
.btnDelete {
  @include btn($fontSize: 1.4rem, $padding: 8px 10px) {
    min-width: 98px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

.btnDelete--disabled {
  @extend .btnDelete;
  color: $color_disable02;
  background-color: $color_white;
  border-color: $color_disable02;
  box-shadow: none;
  cursor: default;
}

.btnDelete--disabled:hover {
  opacity: 1;
}

.btnDelete--invisible {
  display: none;
}

/* Disabled状態で使用する ※企業・システム側の都合によって非活性となった場合に使用
----------------------------------------------------------------- */

.btnDisabled {
  @include btn($boxShadow: none) {
    width: 100%;
    min-height: 50px;
    color: $color_text;
    background-color: $color_disable02;
    border-color: $color_disable02;
  }
}

.btnDisabledL {
  @include btn($boxShadow: none) {
    min-width: 290px;
    min-height: 50px;
    color: $color_text;
    background-color: $color_disable02;
    border-color: $color_disable02;
  }
}

.btnDisabledM {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem, $boxShadow: none) {
    min-width: 222px;
    min-height: 44px;
    color: $color_text;
    background-color: $color_disable02;
    border-color: $color_disable02;
  }
}

.btnDisabledS {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem, $boxShadow: none) {
    min-width: 156px;
    min-height: 44px;
    color: $color_text;
    background-color: $color_disable02;
    border-color: $color_disable02;
  }
}

.btnDisabledSS {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem, $boxShadow: none) {
    min-width: 100px;
    min-height: 32px;
    color: $color_text;
    background-color: $color_disable02;
    border-color: $color_disable02;
    pointer-events: none;
  }
}

[class*="btnDisabled"]:hover {
  opacity: 1;
  cursor: default;
}

/* ボタンテキストに対して補足情報を表示したい場合に使用
----------------------------------------------------------------- */
.btnEnter {
  @include btn($padding: 3px 20px 4px) {
    min-width: 180px;
    color: $color_white;
    background-color: $color_red;
    border-color: $color_red;
  }
}

.btnEnter__balloon {
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 116px;
  height: 16px;
  margin-bottom: 6px;
  padding: 0 5px;
  color: $color_red;
  font-size: 1.1rem;
  line-height: 16px;
  background-color: $color_white;
  border-radius: 32px;
}

.btnEnter__balloon::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 3px;
  border-top-color: $color_white;
  transform: translate(-50%, 100%);
}

.btnEnter__text {
  display: block;
}

/* yahoo ログインボタン
----------------------------------------------------------------- */
.btnYahoo {
  @include btnSns() {
    background-color: #f03;
    border-color: #f03;
  }

  &--large {
    width: 258px;
  }

  &::before {
    left: 5px;
  }
}

/* Facebook ログインボタン
----------------------------------------------------------------- */
.btnFacebook {
  @include btnSns($iconPos: -305px -92px) {
    background-color: #1877f2;
    border-color: #1877f2;
    border-radius: 3px;
    line-height: 40px;
    padding-left: 40px;

    &--large {
      width: 258px;
    }

    &::before {
      background: url(../img/common/facebook_logo_circle_white.png)  no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -webkit-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      height: 28px;
      left: 6px;
      width: 28px;
    }

    &::after {
      top: -1px;
      width: 40px;
      height: 40px;
      background-color: #1877f2;
    }
  }
}

/* LINE ログインボタン
----------------------------------------------------------------- */
.btnLine {
  @include btnSns($iconPos: -305px -92px) {
    background-color: #00c300;
    border-color: #00c300;
    border-radius: 5px;
    line-height: 40px;
    padding-left: 40px;

    &--large {
      width: 258px;
    }

    &::before {
      background: url(../img/common/LineLoginButtonImage/btn_base.png)  no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -webkit-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      height: 40px;
      width: 40px;
    }

    &::after {
      background-color: #00c300;
      height: 40px;
      left: 1px;
      top: -1px;
      width: 40px;
    }
  }
}

/* Fake ログインボタン (btnのheightとwidthと同じだが、機能はない)
----------------------------------------------------------------- */
.btnSnsFake {
  height: 40px;
  width: 130px;
  margin-left: 15px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

/* 追従要素内 ボタン チェックした求人
----------------------------------------------------------------- */
// 気になるリストページ
.btnChackJob {
  @include btn($fontSize: 1.4rem, $padding: 8px 30px) {
    min-width: 145px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }

  &--readiness {
    background-color: red;
    border-color: red;
    color: $color-white;
  }
}

/* モーダル 削除ボタン
----------------------------------------------------------------- */
.btnDeleteIcon {
  @include btn($fontSize: 1.4rem, $padding: 12px 20px) {
    position: relative;
    z-index: 1;
    min-width: 184px;
    color: $color_red;
    background-color: $color_white;
    border-color: $color_red;
  }
}

.btnDeleteIcon::before {
  content: "";
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 18px;
  margin-right: 8px;
  background: url(#{$img_path}/sprite.png) -77px -160px no-repeat;
}

.btnDeleteIcon--disabled {
  @extend .btnDeleteIcon ;
  @include disabled;
}

/* アンケートに回答ボタン
----------------------------------------------------------------- */
.btnAnswer {
  @include btn($fontSize: 1.2rem, $padding: 4px 10px 4px 40px) {
    position: relative;
    z-index: 1;
    min-width: 174px;
    color: $color_keyColor;
    text-align: left;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
  @include iconFont($fontSize: 1.4rem, $icon: "\e91d");
}

.btnAnswer::before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  font-weight: normal;
  transform: translateY(-50%);
}

/* お気に入りボタン
----------------------------------------------------------------- */
.btnFavorite {
  @include iconFont($fontSize: 2rem, $icon: "\e91e");
  overflow: hidden;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  color: $color_disable02;
  font-size: 0;
  cursor: pointer;
}

.btnFavorite--done {
  @extend .btnFavorite;
  color: #fece30;
}

/* 会員登録
----------------------------------------------------------------- */
.btnSignUp {
  @extend .btnPrimaryL;
}

.btnSignUp__label {
  vertical-align: middle;
  font-size: 1.9rem;
}

.btnSignUp__attention {
  vertical-align: middle;
  margin-right: 9px;
  padding: 4px 6px;
  color: $color_red;
  font-size: 1.4rem;
  background-color: #fff;
  border-radius: 1.4rem;
}

.btnSignUp__note {
  vertical-align: -0.15em;
  font-size: 1.4rem;
}

/* 会員登録して気になる保存
----------------------------------------------------------------- */
.btnSignUp__label--save {
  display: block;
  margin-top: 5px;
  font-size: 1.8rem;
}

/* 設定一覧
----------------------------------------------------------------- */
.btnSetting {
  @include btn($padding: 7px 10px, $fontSize: 1.4rem) {
    min-width: 97px;
    min-height: 32px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
}

.btnSetting--stoped {
  @extend .btnSetting;
  color: $color_white;
  background-color: $color_disable02;
  cursor: default;
  border: none;
  box-shadow: none;
}

/* 追加
----------------------------------------------------------------- */
.btnAdd {
  @include btn($padding: 12px 20px, $fontSize: 1.4rem) {
    position: relative;
    z-index: 1;
    min-width: 156px;
    min-height: 44px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
  @include iconFont($fontSize: 1rem, $icon: "\e60e");
}

.btnAdd::before {
  margin-right: 8px;
  font-weight: normal;
  vertical-align: middle;
}

.btnAdd--disabled {
  @extend .btnAdd;
  @include disabled;
}

/* 追加 サイズ小
----------------------------------------------------------------- */
.btnAddS {
  @include btn($padding: 7px 15px, $fontSize: 1.4rem) {
    position: relative;
    z-index: 1;
    min-width: 100px;
    min-height: 34px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
  @include iconFont($fontSize: 1rem, $icon: "\e60e");
}

.btnAddS::before {
  margin-right: 8px;
  font-weight: normal;
  vertical-align: middle;
}

.btnAddS--disabled {
  @extend .btnAddS;
  @include disabled;
}

/* 追加 サイズ小
----------------------------------------------------------------- */
.btnRemoveS {
  @include btn($padding: 7px 15px, $fontSize: 1.4rem) {
    position: relative;
    z-index: 1;
    min-width: 100px;
    min-height: 34px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
  @include iconFont($fontSize: 1rem, $icon: "\e60d");
}

.btnRemoveS::before {
  margin-right: 8px;
  font-weight: normal;
  vertical-align: middle;
}

.btnRemoveS--disabled {
  @extend .btnRemoveS;
  @include disabled;
}

/* キープボタン
----------------------------------------------------------------- */
.btnKeep {
  @include btn() {
    width: 100%;
    min-height: 50px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
  @include iconFont($fontSize:2rem, $icon: "\e919");
}

.btnKeep::before {
  margin-right: 8px;
  color: $color_disable02;
  font-weight: normal;
}

//キープ済み
.btnKeep--done {
  @extend .btnKeep;
  @include disabled;
}

.btnKeep--done::before {
  color: #fece30;
}

///未キープかつ有効期限切れ,辞退済み
.btnKeep--cancel {
  @extend .btnKeep;
  color: $color_disable02;
  border-color: $color_disable02;
  box-shadow: none;
  cursor: default;
}

.btnKeep--cancel:hover {
  opacity: 1;
}

.btnKeep--cancel::before {
  color: #555;
}

/* 辞退
----------------------------------------------------------------- */
.btnDecline {
  @include btn() {
    width: 100%;
    min-height: 50px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

//辞退済み
.btnDecline--done {
  @extend .btnDecline;
  @include disabled;
}

///未辞退かつ有効期限切れ
.btnDecline--cancel {
  @extend .btnDecline;
  color: $color_disable02;
  border-color: $color_disable02;
  box-shadow: none;
  cursor: default;
}

.btnDecline--cancel:hover {
  opacity: 1;
}

/* ファイル追加ボタン
----------------------------------------------------------------- */
.btnAddFile {
  @include btn($padding: 7px 15px, $fontSize: 1.4rem) {
    position: relative;
    min-width: 100px;
    min-height: 34px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
    cursor: pointer;
  }
  @include iconFont($fontSize: 1rem, $icon: "\e60e");
}

.btnAddFile__text {
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.btnAddFile input {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  cursor: pointer;
}

.btnAddFile::before {
  position: relative;
  z-index: 1;
  margin-right: 8px;
  font-weight: normal;
  vertical-align: middle;
}

.btnAddFile--disabled {
  @extend .btnAddFile;
  @include disabled;
}

/* 気になるボタン
----------------------------------------------------------------- */
.btnSave {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #00aaeb;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 #bbb6a8;
  color: $color_text;
  cursor: pointer;
  display: flex;
  font-size: 1.7rem;
  font-weight: 700;
  height: 58px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.3;
  margin-bottom: 22px;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover {
    opacity: .5;
    text-decoration: none;
  }

  &--disabled {
    @extend .btnSave;
    @include disabled;

    color: $color_text;
    font-size: 14px;
    letter-spacing: -.5px;
    line-height: 1;

    &::before {
      margin-top: -3px;
      padding-right: 4px;
      padding-top: 0;
    }

    .iconFont--interestStar {
      &::before {
        line-height: 0.5;
      }
    }
  }
}

.iconFont--interestStar {
  @include iconFont($fontSize: 1.6rem, $icon: "\e91e");

  &::before {
    color: #fbb553;
    padding-top: 2px;
    padding-right: 6px;
  }
}


.btnInterst {
  @extend .btnDefaultL;
  @include iconFont($fontSize: 1.6rem, $icon: "\e91e");
  position: relative;
  color: #555;
}

.btnInterst::before {
  color: #fbb553;
  padding-top: 2px;
  padding-right: 6px;
}

.btnInterst--disabled {
  @extend .btnInterst;
  @include disabled;
}

.btnInterst .tooltipMatching,
.btnSave .tooltipMatching {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

/* 気になるボタン
----------------------------------------------------------------- */
.btnSearch {
  @extend .btnPrimaryL;
  &.maxWidth290 {
    max-width: 290px
  }
}

.btnSearch * {
  line-height: 1;
}

.btnSearch .text,
.btnSearch .iconFont--search,
.btnSearch .iconFont--search::before,
.btnSearch .textAttentionL em {
  vertical-align: middle;
}

.btnSearch .iconFont--search {
  position: relative;
  top: -2px;
  margin-right: 8px;
}

.btnSearch .textAttentionL {
  position: relative;
  bottom: -1px;
  display: inline-block;
  margin: 0 4px;
  color: $color_white;
  vertical-align: bottom;
  line-height: 1;
}

.btnSearch__text {
  margin: 0 2px;
  vertical-align: middle;
  font-size: 2rem;
}

.btnSearch .loading {
  @include loading($height: 15px, $top: -2px);
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 0 0 0 5px;
  padding: 0 30px;
  background-color: transparent;
}

.btnSearch .loading span {
  display: block;
}

.btnSearch .loading span::after {
  background-color: $color_white;
}

/* 検索条件変更
----------------------------------------------------------------- */
.btnSearchCondition {
  @extend .btnDefaultS;
  position: relative;
}

.btnSearchCondition::before,
.btnSearchCondition::after {
  position: absolute;
  top: 50%;
  display: inline-block;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "mynavi";
  font-size: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-rendering: auto;
  text-transform: none;
  vertical-align: top;
  transform: translateY(-50%);
}

.btnSearchCondition::before {
  content: "\e623";
  left: 15px;
}

.btnSearchCondition::after {
  content: "\e60a";
  right: 15px;
}

/* ページ内リンク
----------------------------------------------------------------- */
.btnAncer {
  @include btn($padding: 6px 20px, $fontSize: 1.4rem) {
    min-width: 134px;
    min-height: 34px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
  @include iconFont($fontSize: 1.5rem, $icon: "\e602");
}

.btnAncer::before {
  vertical-align: middle;
  margin: 0 8px 0 0;
  font-weight: normal;
}

// 気になる マッチング
.btnBookmarkMatching {
  background-color: $color_matchingLight;
  border-color: $color_matchingLight;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15);
  color: $color_matching;
}

/* 途中保存機能　履歴書内容確認BOX
----------------------------------------------------------------- */

.btnHasNewResume_secondary {
  @include btn($padding: 0, $fontSize: 1.4rem) {
    width: 160px;
    height: 38px;
    border: solid red 1px;
    border-radius: 13px;
    background-color: red;
    line-height: 38px;
    display: inline-block;
    margin-right: 20px;
    color: $color_white;
  }
}

.btnHasNewResume_default {
  @include btn($padding: 0, $fontSize: 1.4rem) {
    color: #555;
    width: 160px;
    height: 38px;
    border-radius: 13px;
    line-height: 38px;
    display: inline-block;
    margin-left: 20px;
    background-color: $color_white;
    border-color: $color_keyColor;
  }
}

.btnChangeResume {
  @include btn($padding: 0) {
    font-size: 14px;
    margin-left: 18px;
    float: left;
    height: 44px;
    width: 80px;
    line-height: 44px;
    text-align: center;
    outline: none;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
  }
}

/* ボタンへの注釈
----------------------------------------------------------------- */
.btnAnnotationBox {
  color: $color_subText;
  font-size: 1.2rem;
  text-align: center;

  &__text {
    text-align: left;
    display: inline-block;
    vertical-align: top;
    width: auto;
    padding: 0 20px;
    letter-spacing: 0;
    line-height: 1.3;
    min-width: 290px;
    min-height: 50px;
  }
}

/* 応募企業へ面接日時を送るボタン
----------------------------------------------------------------- */
.btnBox_EntryInterview {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 36px;
  margin: 0 auto 30px;
  padding: 4px 0 0;
  width: 354px;

  .btnInterviewEntry {
    align-items: center;
    background: $color_white;
    border: 1px solid #6b6b6b;
    border-radius: 4px;
    color: #6b6b6b;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: 700;
    gap: 10px;
    height: 36px;
    justify-content: center;
    line-height: 24px;
    text-align: center;
    width: 354px;

    &:hover {
      background-color: #f0f0f0;
      border-color: $color_heading;
      box-shadow: 0 2px 4px rgba(255, 255, 255, 0.486);
      text-decoration: none;
    }
  }
}
