@charset "UTF-8";

.bookmarkTop {
  margin: auto;
  width: 1140px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  &__title {
    color: #333;
    font-size: 6rem;
    font-weight: 600;
    letter-spacing: .08em;
  }

  &__summary {
    color: #555;
    font-size: 14px;
    letter-spacing: 0.06em;
    line-height: 20px;
    margin-top: 12.5px;
    width: 500px;
  }
}

.bookmarkTopHeader {
  align-items: start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto;
  width: 950px;

  &__title {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.01em;
    line-height: 54px;
  }

  &__summary {
    color: #555;
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    margin-top: 10px;

    &--bold {
      font-weight: 700;
    }
  }

  &__help {
    display: block;
    font-size: 15px;
    font-weight: 500;
    gap: 5px;
    line-height: 1.5;
    margin: 14px 0 27px 0;
    padding: 4px 10px;

    .searchTable__question {
      top: -1px;
    }

    .searchTable__question .tooltip {
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      height: 101px;
      right: -136px;
      top: -64px;
      width: 300px;

      .tooltip__content {
        padding: 14px 9px 11px 10px;

        .tooltip__text {
          font-size: 12px;
          font-weight: 400;
          line-height: 19.2px;
          margin-bottom: 0;

          &--bold {
            font-size: 12px;
            font-weight: 600;
            line-height: 19.2px;
          }
        }
      }
    }

    .searchTable__question .tooltip::before {
      border-width: 29px 17px;
      right: 129px;
    }
    .searchTable__question .tooltip::after {
      border-width: 29px 17px;
      right: 129px;
    }
  }

  & + .postingEnd {
    align-items: center;
    border: 1px 0 solid rgb(239, 239, 239);
    display: flex;
    flex-direction: row-reverse;
    height: 45px;
    margin: 0 auto;
    width: 950px;

    .postingEnd__text {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      font-size: 14px;
      height: 24px;
      line-height: 21px;
      width: 147px;
    }
  }
}
