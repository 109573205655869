@charset "UTF-8";

.headline {
  background-color: #e1e1e1;
  border-bottom: 1px solid #ddd;
  padding: 12px 0;
  min-width: $top-hero-width;

  &__content {
    margin: 0 auto;
    width: 950px;
    text-align: center;
  }

  &__link {
    @include clearfix;
    font-size: 1.2rem;
    padding: 6px 0;
    border-bottom: 1px solid $color_subBorder;
  }

  &__link .linkArrow {
    float: left;
    font-size: 1.2rem;
  }

  &__close {
    float: right;
  }

  &__close .iconFont--close::before {
    font-size: 1.1rem;
    line-height: 1.25;
  }
}
