@charset "UTF-8";

.topShareButtonList {
  @include clearfix;

  &__item {
    float: left;
    width: ($top-column-side-width - 20px) / 3;
  }

  &__item + &__item {
    margin-left: 10px;
  }
}

.topShareButton {
  @include onHover;

  img {
    width: 100%;
  }
}
