@charset "UTF-8";

.scoutSection {
  @include scoutBlock;

  &-tp {
    background: transparent;
    box-shadow: none;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 30px;
  }

  &__inner-bottom {
    padding: 25.8px 30px;
  }

  &__title {
    border-top: 2px solid $scout-lightblue-bordercolor;
    border-bottom: 1px solid $scout-title-boder;
    padding: 0 30px;
    font-size: 2rem;
    font-weight: $scout_bold;
    line-height: 75px;

    & .label {
      vertical-align: middle;
    }
  }

  &__note {
    line-height: 1;
  }

  &__balloon {
    position: relative;
    margin: 18px 15px 18px 0;
    width: 150px;
    font-size: 16px;
    background: $scout-lightblue-color;
    color: $scout-white;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -12px;
      margin-top: -6px;
      border: 6px solid transparent;
      border-left: 9px solid $scout-lightblue-color;
      z-index: 1;
    }
  }

  &__help {
    font-weight: $scout_bold;
    padding-bottom: 24px;
  }

  &__attentionTitle {
    padding-bottom: 5.8px;
    font-weight: $scout_bold;
  }
}
