@charset "UTF-8";

//企業一覧

//企業情報・会社概要から探す

.companySearch {
  .headingPage {
    margin: 0 auto 24px;
  }

  .textLead {
    margin-bottom: 24px;
  }

  .card {
    margin-bottom: 40px;

    &__content {
      padding: 24px;
    }
  }

  .formTable__row {
    display: table;
  }

  .formTable__col {
    display: table-cell;
    vertical-align: middle;
  }

  .checkbox__label-only {
    cursor: text;
    margin: 0;
    font-size: 1.3rem;

    &:not(:last-child)::after {
      content: '/';
      margin-left: 5px;
    }

    &__text {
      margin: 0;
    }
  }

  .float-none {
    float: none;
  }

  .topSection {
    margin: auto;
    margin-bottom: 40px;
    width: 950px;

    &__subtitle {
      width: 674px;
    }
  }

  input[name="isPublish"] + .checkbox__icon {
    top: 1px;
  }
}

.specifyBtn {
  height: 40px;
  line-height: 16px;
  padding: 12px 20px;
  white-space: nowrap;
}

.specifyBtn::before {
  content: '＋';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-top: -3.5px;
  text-align: left;
}

.mt-10 {
  margin-top: 10px;
}

.searchCompanies {
  display: flex;
  justify-content: center;
}

.searchCompaniesBtn {
  background: #00aaeb;
  background-image: url(..\img\common\search.svg);
  background-position: left 94px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 #00000029;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  line-height: 21px;
  padding: 12px 16px 12px 32px;
  text-align: center;
  width: 320px;
}

.searchCompaniesBtn:hover {
  opacity: .5;
  text-decoration: none;
}

.industryList {
  &__title {
    background: #f9f9f9;
    color: #00aaeb;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 8px;
    padding: 8px 16px;
    text-align: left;
    width: 100%;
  }
}

.tag--icon {
  background-color: #fff;
  border-radius: 3px;
  display: inline-block;
  height: 6px;
  width: 6px;
}

.tag--text {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  padding: 6px 8px;
  text-align: center;
}

.place__icon {
  background: url(..\img\common\place.svg);
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  margin-right: 4px;
  width: 16px;
  min-width: 16px;
}

.setting__icon {
  background: url(..\img\common\setting.svg);
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  margin-right: 4px;
  width: 16px;
  min-width: 16px;
}

.companySearchList {
  background-color: #fafafb;
  box-shadow: none;

  a:hover {
    text-decoration: none;
  }

  &__content {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 32px;

    &--completed {
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 32px;

      .navItem {
        background-color: #f9f9f9;
      }
    }
  }

  &__content:not(:last-child) {
    margin-bottom: 8px;
  }

  &__content:not(:last-child),
  &__content--completed:not(:last-child) {
    margin-bottom: 8px;
  }

  &__company-name {
    color: #00aaeb;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
    margin-right: 30px;
  }

  &__text {
    color: #333;
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__icon-parent {
    display: flex;
    align-items: center;
    color: #333;
  }

  &__icon-parent:first-child {
    margin-bottom: 4px;
  }

  .navItem {
    padding: 0;
  }

  .navItem::after {
    border-color: #555;
  }
}
