@charset "UTF-8";

/* =========================================================
プレビュー画面のレイアウト
========================================================= */
#previewContentPc {
  overflow: hidden;
  padding: 24px;
  width: 1203px;
  font-size: 14px;
  font-size: 1.4rem;
}

#previewContentPc .heading {
  line-height: 1.8;
  margin-bottom: 24px;
}

#previewContentPc .closeButton {
  text-align: right;

  a {
    text-decoration: underline;
  }
}

#previewContentPc .closeButton .iconFont--close::before {
  color: #555;
  font-size: 10px;
  font-size: 1rem;
  margin-right: 0.5em;
  font-weight: bold;
  position: relative;
  top: 8px;
}

#previewContentPc .heading p {
  margin-top: 0;
}

#previewContentPc .headingPreview {
  max-width: 950px;
  background: #737373;
  border-bottom: #ff2700 solid 2px;
  color: #fff;
  border-radius: 3px 3px 0 0;
  padding: 12px;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 auto 30px auto;
}

/* =========================================================
■プレビュー画面用 特殊対応
========================================================= */
#previewContent {
  margin: 0 auto 40px auto;
  width: 1100px;
}
// iPad表示対応
@media only screen and (max-width: 1200px) {
  #previewContent {
    width: 1020px;
  }
}

@media print {

  /* メッセージ画像と文字が重ねるの対応 ※Chrome、Edge向け */
  .messageInfoPictArea {
    contain: strict;
    display: table;
    margin: auto;
    page-break-after: always;
    & img {
      max-width: 100%;
      height: auto;
      page-break-inside: avoid;
      page-break-before: unset;
    }
  }

  /* 設定された地図の画像が崩れの対応 ※Chrome、Edge向け */
  #previewContent .previewUnit {
    .mapWrap {
      contain: strict;
      display: table;
      & img {
        margin-top: 100px;
      }
    }
  }

  /* 応募ボタン(二番目)とインタビューの文字が重ねるの対応 ※FireFox向け */
  .jobPointArea.lightBlue {
    #jobBtn {
      page-break-before: always;
    }
  }

  /* IEの対応 */
  #previewContent iframe {
    display: block;
  }
}

#previewContent #mmap {
  height: 548px;
  width: 548px;
}

#previewContent .ngWord {
  border: 2px solid #dc143c;
  display: inline;
  margin: 0 -2px;
  padding-left: 0;
}

#eventDetail .diff {
  background-color: #ff9 !important;
}

#previewContent .diff,
#eventDetail .diff {
  background-color: #ff9;

  & img {
    border-left: 9px solid #ff9;
    border-right: 9px solid #ff9;
    margin-left: -9px;
    box-sizing: content-box;
  }

  &.btnListL__item {
    border-left: 9px solid #ff9;
    border-right: 9px solid #ff9;
    margin-left: -9px;
    box-sizing: content-box;
  }

  &.btnListL__item.half {
    border-left: 5px solid #ff9;
    border-right: 5px solid #ff9;
    margin-left: -5px;
    margin-right: 0;

    &:last-child {
      border-left: 5px solid #ff9;
      border-right: 5px solid #ff9;
      margin-left: 0;
      margin-right: -5px;
    }
  }

  &.btnListL__item.narrow {
    margin-right: 11px;
  }

  &.labelEmploymentStatus {
    border-left: 9px solid #ff9;
    border-right: 9px solid #ff9;
    margin-left: 1px;
    background-color: $color_condition;
    box-sizing: content-box;
  }

  &.cassetteRecruit__attributeLabel {
    border-left: 9px solid #ff9;
    border-right: 9px solid #ff9;
    margin-left: -9px;
    box-sizing: content-box;
    padding-right: 1px;
  }

  &.cassetteRecruit__attribute-jobinfo {
    border-left: 9px solid #ff9;
    border-right: 9px solid #ff9;
    margin-left: -9px;
    box-sizing: content-box;
    padding-right: 1px;
  }

  .addLinkLogo {
    margin: 0 auto;
  }

  &.interviewPictureWrap {
    border-left: 9px solid #ff9;
    border-right: 9px solid #ff9;
    margin-left: -9px;
    box-sizing: content-box;
    margin-right: 15px;
  }

  &.mapWrap {
    border-left: 9px solid #ff9;
    border-right: 9px solid #ff9;
    margin-left: -9px;
    box-sizing: content-box;
    margin-right: 15px;

    img {
      border: none;
      margin: 0;
    }
  }
}

#previewContent img.diff, #eventDetail img.diff {
  border-left: 9px solid #ff9;
  border-right: 9px solid #ff9;
  margin-left: -9px;
  box-sizing: content-box;
}

#previewContent img.check-right.diff, #eventDetail img.check-right.diff {
  margin-left: 0;
  margin-right: -9px;
}

#previewContent .previewTitle {
  position: relative;
  margin: 20px 0 0;
  padding: 0 10px;
  color: #fff;
  font-size: 16px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  background: #737373;
  margin-top: 0;
  border-bottom: 2px #ff2700 solid;
  line-height: 40px;
  font-weight: bold;
}

#previewContent .previewUnit {
  background-color: $color_white;
  max-width: 1100px;
  padding: 10px 0;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  font-size: 1.4rem;
  line-height: 18px;
  margin-bottom: 30px;
}

#previewContent .previewUnit.gray {
  background: #fafafb;
  line-height: 1.42;
  overflow: visible;
}

#previewContent .previewBtn-close {
  color: #06c;
  font-size: 1.3rem;
  text-align: right;
  margin: 25px auto 10px auto;
  min-height: 13px;
  position: relative;
}

#previewContent .previewBtn-close .caution--top {
  color: #555;
  font-weight: bold;
  left: 0;
  position: absolute;
  top: 0;
}

#previewContent .previewBtn-close a {
  color: #08c;
  text-decoration: underline;
}

#previewContent .previewBtn-close a:hover {
  color: #005580;
  text-decoration: none;
}

#previewContent .previewBtn-close a:hover,
#previewContent .previewBtn-close a:active {
  outline: 0;
}

#previewContent .icon-remove::before {
  font-size: 1rem;
  color: #000;
  padding: 1px 4px 0 0;
}

#previewContent .previewUnit .cassetteRecruit {
  margin: 0;
}

#previewContent h3.boots {
  margin: 0;
  padding: 0 10px;
  font-size: 1.3rem;
  background: #ccc;
  line-height: 40px;
  font-weight: bold;
  color: #222;
}

#previewContent table.table-bordered {
  border-top-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
}

#previewContent table.table-bordered tbody:first-child tr:first-child > th:first-child {
  border-top-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
}

#previewContent table.table-bordered * {
  box-sizing: content-box;
}

#previewContent table.table.table-bordered.table-form-common {
  font-size: 13px;
  color: #222;
  margin: 0;
  border: 1px solid #ddd;
  border-left: none;
}

#previewContent table.table.table-bordered.table-form-common th {
  width: 187px;
  color: #666;
  background: #f5f5f5;
  font-weight: normal;
  border-left: 1px solid #ddd;
  padding: 8px 8px 8px 18px;
}

#previewContent .nords_all {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
}

#previewContent .nords_tl {
  border-top-left-radius: 0 !important;
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
}

#previewContent .nords_tr {
  border-top-right-radius: 0 !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
}

#previewContent .nords_bl {
  border-bottom-left-radius: 0 !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
}

#previewContent .mb30 {
  margin-bottom: 30px !important;
}

#previewContent .label {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 4px;
  line-height: 14px;
  color: #fff;
  white-space: nowrap;
}

#previewContent .table-bordered tr:last-child th {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#previewContent .table-bordered tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#previewContent .table-bordered td {
  border-left: 1px solid #ddd;
}

#previewContent .table-bordered th,
#previewContent .table-bordered td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

#previewContent .table-bordered tr:first-child th,
#previewContent .table-bordered tr:first-child td {
  border-top: none;
}

#previewContent .label-important {
  vertical-align: middle;
  text-align: center;
  font-size: 11px;
  line-height: 15px;
  background: #333;
  text-shadow: none !important;
  background-color: #d90000 !important;
  margin-right: 5px;
}

#previewContent .wper2 {
  width: 2% !important;
}

#previewContent .nobrdr {
  border-right: none !important;
}

#previewContent .nobrdl {
  border-left: none !important;
}

#previewContent .icon-lock {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: -3px;
  line-height: 14px;
  background-repeat: no-repeat;
  background-image: url("../img/glyphicons-halflings.png");
  margin-right: 5px;
  background-position: -287px -24px;
  vertical-align: middle;
}

#previewContent .fs14 {
  font-size: 1.4rem;
}

#previewContent .strong {
  font-weight: bold !important;
}

#previewContent .tabNaviRecruit {
  margin: -5px -10px 40px -10px;
}

#previewContent .tabNaviRecruitBottom {
  margin: 0 -10px;
}

#previewContent .headingBlock,
#previewContent .jobOfferQuestion__title,
#previewContent .cassetteRecruit__name,
#previewContent .headingContent {
  line-height: 1.42;
}

#previewContent .previewUnit iframe {
  width: 970px;
}

#previewContent .previewUnit.iframe {
  padding: 10px 0;
}

#previewIframeContent {
  width: 970px;
  min-width: 970px;
  padding: 20px 10px;
}

#previewIframeContent .headingBlock,
#previewIframeContent .jobOfferQuestion__title,
#previewIframeContent .cassetteRecruit__name,
#previewIframeContent .headingContent,
#previewIframeContent .textEm,
#previewIframeContent .originalInfo__contentTitle,
#previewIframeContent .originalInfo__menuTitle,
#previewIframeContent .cardNotice__contentTitle {
  line-height: 1.42;
}

#previewIframeContent .formTable,
#previewIframeContent .container__inner {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.42;
}

#previewIframeContent .tabNaviRecruit {
  margin: -5px -10px 40px -10px;
}

#previewIframeContent .tabNaviRecruitBottom {
  margin: 0 -10px 30px -10px;
  padding: 0;
}

#previewContent .cassetteOfferRecapitulate {
  margin: 20px auto;
}

#previewContent .cassetteOfferRecapitulate--other__heading {
  line-height: 1.57;
}

/* スカウト */
#previewContent.scout {
  margin: 0 auto 40px auto;
  width: 640px;
}

#previewContent.scout .previewHeader::before,
#previewContent.scout .previewHeader::after {
  content: " ";
  display: table;
}

#previewContent.scout .previewHeader::after {
  clear: both;
}

#previewContent.scout .previewHeader {
  margin: 40px 0 10px 0;
  *zoom: 1;
}

#previewContent.scout .previewHeader .explain {
  font-size: 1rem;
  float: left;
}

#previewContent.scout .previewHeader .previewBtn-close {
  margin: 0;
  float: right;
}

#previewContent.scout .previewTtl {
  padding: 10px;
  border: 1px solid #ddd;
  border-bottom: none;
}

#previewContent.scout .estimated-delivery-date {
  font-size: 1.3rem;
  line-height: 1.4;
  color: #222;
}

#previewContent.scout .contents {
  margin: 20px auto;
  width: 580px;
}

#previewContent.scout .scoutBenefit__item .labelScout {
  cursor: default;
}

#previewContent.scout .description__content.contents--html {
  font-size: 1.2rem;
}

#previewContent .jobPointArea__sideMenu {
  position: relative;
  top: 0;
}

.anchor {
  &__h2 {
    margin-top: -82px;
    padding-top: 82px;
    position: relative;
  }

  &__container {
    margin-top: -40px;
    padding-top: 40px;
    position: relative;
  }

  &__duplicate {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.summaryTag {
  display: block;
  text-indent: 1em;
}

.adminPreview {
  overflow: hidden;
}
