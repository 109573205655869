@charset "UTF-8";

.container {
  &-bookmark {
    border-top: solid 1px #bbb;
    padding: 30px 0 0;

    &:has(.bookmarkTopHeader) {
      z-index: 10;
    }
  }

  &-bookmarkList {
    z-index: 9;
  }

  &__caseZeroPostingEnd {
    .postingEnd {
      align-items: center;
      border: 1px 0 solid rgba(239, 239, 239, 1);
      display: flex;
      flex-direction: row-reverse;
      height: 24px;
      margin: 0 auto;
      width: 950px;

      .postingEnd__text {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        font-size: 14px;
        font-weight: 600;
        height: 24px;
        line-height: 21px;
        width: 147px;

        &.navItem {
          background-color: #fafafb;
        }
      }
    }
  }
}

.other__container {
  border-bottom: 1px solid rgba(239, 239, 239, 1);
  border-top: 1px solid rgba(239, 239, 239, 1);

  .postingEnd {
    align-items: center;
    border: 1px 0 solid rgba(239, 239, 239, 1);
    display: flex;
    flex-direction: row-reverse;
    height: 60px;
    margin: 0 auto;
    width: 950px;

    .postingEnd__text {
      align-items: center;
      display: flex;
      font-size: 14px;
      height: 24px;
      line-height: 21px;
      width: 147px;
    }

    &__header {
      align-items: center;
      display: flex;
      font-size: 22px;
      font-weight: 500;
      height: 75px;
      justify-content: center;
      line-height: 33px;
      text-align: center;
    }
  }

  + .container {
    padding-top: 40px;
  }

  + .container.bkWhite + .container {
    padding: 30px 0 10px;
  }
}
