@charset "UTF-8";

/* =========================================================
会社・仕事の魅力
========================================================= */
.jobAttraction {
  &__imageWrap {
    display: table;
    table-layout: fixed;
    padding-left: 40px;
    margin-bottom: 33px;
    width: 100%;
    @supports (display: flex) or (display: -webkit-box) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
      -ms-flex-align: align;
      align-items: align;
      flex-wrap: nowrap;
    }
  }

  &__image {
    display: table-cell;
    vertical-align: top;
    @supports (display: flex) or (display: -webkit-box) {
      display: block;
    }
  }
}
