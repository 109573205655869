@charset "UTF-8";

// 求人情報ページ共通スタイル

/* 求人情報／求人上部パーツ
----------------------------------------------------------------- */
.cassetteOfferRecapitulate {
  $component: &;
  word-break: break-all;
  word-wrap: break-word;

  &--other {
    &__heading {
      &-jobinfo {
        font-size: 1.8rem;
      }
    }
  }

  &--otherJobinfo {
    margin-top: 25px;
    margin-bottom: 50px;
  }

  &__content {
    &-jobinfo {
      padding-top: 20px;
      padding-bottom: 0;

      & .blockWrapper {
        width: 100%;
      }

      & .leftBlock {
        width: 100px;
      }

      & .rightBlock:last-child {
        margin-bottom: 20.5px;
        float: left;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
      }

      .readinessBlock {
        padding-left: 20px;
        min-width: 112px;

        & .labelReadiness {
          text-align: center;
          width: 92px;
        }

        // iPad縦向き表示対応
        @media only screen and (max-width: 1024px) {
          & .labelReadiness {
            font-size: 1rem;
          }
        }
      }

      .occName {
        margin-top: -5px;
        margin-bottom: 5px;
      }

      & .statusIcon {
        position: absolute;
        width: 80px;
        height: 40px;
        top: 0;
        right: 28px;
        text-align: center;
        color: #fff;
        font-size: 2rem;
        font-weight: bold;

        & .new {
          display: block;
          line-height: 40px;
          background-color: #f00;
          border-radius: 0 0 4px 4px;
        }

        & .update {
          display: block;
          line-height: 40px;
          background-color: #00aaeb;
          border-radius: 0 0 4px 4px;
        }

        & + .blockWrapper {
          width: 790px;

          & h1 {
            min-width: 510px;
          }
        }
      }

      & .deadlineIcon {
        position: absolute;
        min-width: 80px;
        top: 0;
        right: 28px;
        text-align: center;

        &__top {
          width: 100%;
          line-height: 30px;
          background: #f00;
          color: #fff;
          font-weight: bold;
        }

        &__bottom {
          width: 100%;
          line-height: 27px;
          border-radius: 0 0 5px 5px;
          background: #fff;
          border: 1px solid #f00;
          color: #333;
          font-weight: bold;
          padding: 0 10px;
        }

        &__date {
          font-size: 1.6rem;
          color: #f00;
        }

        & + .blockWrapper {
          width: 790px;

          & h1 {
            min-width: 510px;
          }
        }
      }
    }

    &--2colLargeJobinfo {
      padding-bottom: 20px;

      .cassetteOfferRecapitulate__date.cassetteOfferRecapitulate__date-jobinfo {
        width: 265px;
      }
    }

    .leftBlock {
      float: left;
      clear: both;
    }

    .rightBlock {
      float: right;
      width: calc(100% - 108px);

      .occName {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 28px;
        display: block;
        color: #333;
      }

      .companyName {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 28px;
        display: block;
      }

      .companyNameAdd {
        font-size: 1.2rem;
        font-weight: 100;
        line-height: 19px;
        display: block;
      }
    }

    &--2colLarge {
      @extend .cassetteOfferRecapitulate__content;
      display: table;
      table-layout: fixed;
      width: 100%;
      font-size: 1.2rem;

      ul li {
        margin-bottom: 10px;
      }

      .cassetteOfferRecapitulate__date {
        width: 300px;
        vertical-align: top;
        text-align: left;

        .webSeminarBtn {
          position: relative;
          cursor: pointer;
          float: right;
          margin: -60px -60px 0 0;
          display: block;
          width: 120px;
          text-align: center;
          font-size: 1.2rem;
          line-height: 18px;
          font-weight: bold;
          border: solid 2px $color_keyColor;
          border-radius: 60px;
          height: 120px;
          padding: 45px 10px;
          background-color: $color_white;
          filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));

          .addLinkLogo {
            position: absolute;
            top: 20px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 70px;
            height: auto;
          }

          .addLinkText {
            width: 100px;
            display: table;
            vertical-align: middle;
            height: 116px;
            position: absolute;
            top: 0;
            color: #555;

            & span {
              vertical-align: middle;
              display: table-cell;
            }
          }

          .addLinkLogo + .addLinkText {
            display: block;
            position: relative;
            height: auto;

            & span {
              vertical-align: middle;
              display: inline;
            }
          }

          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 10px;
            right: 0;
            left: 0;
            margin: auto;
            z-index: 1;
            width: 30px;
            height: 20px;
            background: url(../img/jobInfo/mynaviTvCareer_bottom.png) left center no-repeat;
            background-size: cover;
          }

          & + .dateInfo {
            text-align: left;
          }
        }

        .dateInfo {
          text-align: right;
        }
      }
    }
  }

  // 追加リンク複数表示対応用の専用デザイン
  &__content--topLink.noBorder {
    @extend .cassetteOfferRecapitulate__content;
    border-top: none !important;
    padding-bottom: 20px;

    a {
      text-decoration: none;
    }

    div {
      border: 1px #ddd solid;
      border-right: none;
      cursor: pointer;
      display: table-cell;
      height: 65px;
      text-align: center;
      vertical-align: middle;
      width: 179px;

      img {
        display: block;
        margin: -13px 0 6px 35px;
        height: 32px;
      }

      span {
        color: $color-gray;
        display: block;
        font-size: 1rem;
        margin: 0 0 0 0;
      }
    }

    div:last-child {
      border: 1px #ddd solid;
    }

    div:hover {
      opacity: .5;
    }

    div::before {
      color: #00aaeb;
      content: "\e603";
      font-family: mynavi; /* stylelint-disable-line */
      font-size: 1.5rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      left: 75px;
      line-height: 1;
      position: relative;
      speak: none;
      text-decoration: none;
      text-rendering: auto;
      text-transform: none;
      top: 22px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  // 追加リンク複数表示対応用の専用デザイン (パディング無し)
  &__content--topLink.noBorder.noPadding {
    padding: 0;
  }

  // 追加リンク複数表示対応用の専用デザイン (メッセージ画面用)
  &__content--topLink.noBorder.forMessage {
    padding: 0 0 20px;

    div {
      background-color: $color_white;
      width: 116px;

      span {
        font-size: .75rem;
      }

      a {
        img {
          height: 20px;
          margin: -19px 0 4px 25px;
          width: 64px;
        }
      }

      &::before {
        font-size: 1rem;
        left: 50px;
        top: 10px;
      }
    }
  }

  &.noBorder {
    border-top: none;
  }

  &__text-jobinfo {
    h4 {
      line-height: 20px;
      font-weight: 700;
      font-size: 1.4rem;
      color: #333;
      padding-bottom: 10px;
    }

    ul {
      list-style-type: disc;
      margin: 0 0 0 15px;
      line-height: 23px;
    }

    h4 + h4 {
      padding-top: 0;
    }
  }
}
