@charset "UTF-8";

// グリッドスタイルレコメンド用
.gridRecommend {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 12px;
  }

  &__item {
    background: $color_white;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);
    padding: 12px;
    position: relative;
    width: calc(100% / 3 - 8px);

    //hrefが空のときは非表示
    &:has(a[href=""]) {
      display: none;
    }

    > .jobLink {
      &:hover {
        opacity: .5;
        text-decoration: none;
      }
    }
  }

  .btnInterst {
    box-shadow: none;
    width: 284px;
  }

  //ページ毎の余白調整
  &--jobList {
    padding-bottom: 60px;
  }
}

.gridRecommendCard {
  &__title {
    align-items: center;
    -webkit-box-orient: vertical;
    color: $color_keyColor;
    display: -webkit-box;
    font-size: $top-card-title-size;
    font-weight: bold;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    line-height: $top-card-title-line-height;
    min-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
  }

  &__col2 {
    display: flex;
    height: 78px;
    margin: 8px auto;
  }

  &__text {
    font-size: 1.3rem;
    line-height: 1.5;
    width: 179px;
    word-break: break-all;

    > .topSearchCondition {
      color: $color_text;
      font-weight: normal;

      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 8px;
      }
    }
  }

  &__eyeCatch {
    margin-right: 10px;

    > img {
      height: auto;
      width: 96px;
      word-break: break-all;
    }
  }
}
