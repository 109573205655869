@charset "UTF-8";

/* pc */

.wrapper--sj {
  min-width: 1200px;
}

.sjcontainer {
  margin: 0 auto;
  width: 1140px;
}

.sjContent {
  margin: 0;
}

.sjContent::after {
  content: " ";
  display: block;
  clear: both;
}

.sjContent__side {
  float: left;
  width: 240px;
}

.sjContent__main {
  float: right;
  width: 850px;
}

.sjcard {
  max-width: 240px;
  margin: 0 auto 20px;
  background-color: #fff;
}

.sjcard .btnBox {
  padding: 15px;
  margin: 0;
  background-color: #e6f8fc;
  text-align: center;
  border: 1px solid #ddd;
  border-top: 0;
}

.sjcard .btnBox .btnPrimary {
  font-size: 1.6rem;
}

.sjcard__content {
  padding: 0;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.sjcard__content + .card__content {
  border-top: 0 solid #ddd;
}

.sjcard__content .textEm:first-child {
  padding-bottom: 20px;
}

.sjcard__content > .imgCenter:first-child {
  margin-top: 10px;
}

.sjcard__content > form:first-child {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.sjjobChoice--1col {
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  width: 240px;
}

.sjjobChoice--1col:last-child {
  border-bottom: 1px solid #ddd;
}

.sjjobChoice--1col__item {
  border: 1px solid #ddd;
  border-top: 0;
  border-bottom: 0;
  display: table-cell;
  width: 100%;
}

.sjjobChoice__title {
  padding: 20px 0 20px 12px;
  border: 1px solid #ddd;
  border-top: 0;
}

.sjjobChoice__title .checkbox > input,
.sjjobChoice__title .checkbox--disabled > input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.sjjobChoice__title .checkbox__text {
  font-size: 1.3rem;
  line-height: 1.3em;
  font-weight: bold;
  position: relative;
  top: 1px;
  display: inline-block;
  margin: 0;
  padding: 0 10px 0 26px;
}

.sjjobChoice__title a:hover,
.sjjobChoice__title a:hover .checkbox__text {
  text-decoration: underline;
}

.sjjobChoice__title .labelNumber,
.sjjobChoice__sectionTitle .labelNumber {
  vertical-align: middle;
}

.sjjobChoice__sectionTitle {
  background-color: #f0f0f3;
  padding: 16px 12px;
  font-weight: bold;
}

.sjjobChoice__sectionTitle .checkbox__area {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 26px;
}

.sjjobChoice__sectionTitle .checkbox > input,
.sjjobChoice__sectionTitle .checkbox--disabled > input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.sjjobChoice__sectionTitle .checkbox__text {
  font-size: 1.2rem;
  line-height: 1.3em;
  margin-left: 0;
  margin-right: 13px;
  position: relative;
  top: 1px;
}

.sjjobChoice__sectionTitle .labelNumber {
  position: relative;
  top: 2px;
}

.sjjobChoice__sectionTitle .textCaution {
  position: relative;
  top: 2px;
  font-size: 1.2rem;
  margin-left: 0;
  margin-right: 4px;
}

.sjjobChoice__list {
  padding-bottom: 18px;
  background-color: #fff;
}

.sjjobChoice__item {
  display: block;
  padding: 20px 5px 0 12px;
  letter-spacing: 0;
}

.sjjobChoice > .jobChoice__item:first-child {
  padding: 0 5px 0 15px;
}

.sjjobChoice__item .checkbox > input,
.sjjobChoice__item .checkbox--disabled > input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.sjjobChoice__item .checkbox__text {
  font-size: 1.2rem;
  line-height: 1.2em;
  display: inline-block;
  margin: 0;
  padding: 0 15px 0 26px;
}

.sjjobChoice__item a:hover,
.sjjobChoice__item a:hover .checkbox__text {
  text-decoration: underline;
}

.sjjobChoice__item [class*="disabled"] {
  color: #555;
}

.sjContent__sideItem {
  display: block;
  margin-bottom: 30px;
}

.sjNaviGroup__header {
  border-bottom: 1px solid #cfecfa;
  font-weight: bold;
  padding-bottom: 15px;
}

.sjNaviGroup__header--search {
  background-color: #cfecfa;
  font-size: 1.6rem;
  line-height: 1.3em;
  padding: 40px 1rem 40px 1rem;
}

.sjNaviGroup__header--search .sjNaviGroup__title {
  color: #333;
}

.sjNaviGroup__header--other {
  background-color: #cfecfa;
  font-size: 1.6rem;
  padding: 40px 0 40px 1.5rem;
}

.sjNaviGroup__header--other .sjNaviGroup__title {
  color: #333;
}

.sjNaviGroup .sjNavItemList__item {
  border-bottom: 1px solid #dfeff5;
}

.sjNavItem {
  border: 0;
  background-color: #fff;
  display: block;
  cursor: pointer;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 1.5rem 3rem 1.5rem .5rem;
  position: relative;
  text-align: left;
  width: 100%;
}

.sjNavItem:hover {
  opacity: 0.5;
  text-decoration: none;
}

.sjNavItem::after {
  border-right: 2px solid #00aaeb;
  border-top: 2px solid #00aaeb;
  content: "";
  display: block;
  height: 8.5px;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: rotate(45deg) translateY(-80%);
  width: 8.5px;
}

.sjNavItem__text {
  font-weight: bold;
  color: #00aaeb;
}
