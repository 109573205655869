@charset "UTF-8";

.requestList {
  &__item {
    @include scoutFlex;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__box {
    -webkit-flex: 1;
    flex: 1;
  }

  &__title {
    color: $scout-lightblue-color;
    font-size: 2rem;
    font-weight: $scout_bold;
    padding: 0 0 0 30px;
    line-height: 1;
    background: url(#{$scout_path}titleicon_check.png) no-repeat center left;
  }

  &__text {
    padding: 18px 0 38px 0;
    line-height: 1.4;

    &-last {
      padding: 20px 0 0 0;
    }
  }

  &__capture {
    width: 590px;
    margin-left: 26px;

    > img {
      vertical-align: top;
    }
  }

  &__point {
    @include scoutFlex($align: center);
    background: $scout-white;
    border: solid 2px $scout-lightblue-color;
    box-sizing: border-box;
    position: relative;
    padding: 15px 10px 15px 0;
    margin-right: 12px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: -25px;
      margin-top: -13px;
      border: 13px solid transparent;
      border-left: 13px solid $scout-white;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -29px;
      margin-top: -14px;
      border: 14px solid transparent;
      border-left: 14px solid $scout-lightblue-color;
      z-index: 1;
    }
  }

  &__pointIcon {
    background-color: $scout-lightblue-color;
    border: 1px solid $scout-lightblue-color;
    color: $scout-white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    margin: 0 10px;
    font-size: 1.3rem;
    font-family: "Futura", sans-serif;
  }

  &__pointText {
    -webkit-flex: 1;
    flex: 1;
  }
}
