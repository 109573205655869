@charset "UTF-8";

.requestMessage {
  &__heading {
    position: relative;
  }

  &__deliveryDate {
    bottom: 0;
    color: #fff;
    font-size: 1rem;
    height: 1rem;
    line-height: 1rem;
    margin: auto;
    position: absolute;
    right: 18px;
    text-align: right;
    top: 0;
    width: 14em;
  }
}
