@charset "UTF-8";

.topToggleSwitch {
  left: 232px;
  position: absolute;
  top: 8px;

  &__buttonGroup {
    border-radius: .5rem;
    box-shadow: $btnShadow;
    display: inline-block;
  }

  &__button {
    @include clearfix;
    background-color: $color_white;
    border: 1px solid $color_keyColor;
    color: $color_keyColor;
    cursor: pointer;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    outline: 0;
    padding: 1rem;
    text-align: center;
    width: 12.5rem;

    &--left {
      border-bottom-left-radius: .5rem;
      border-top-left-radius: .5rem;
      float: left;
    }

    &--right {
      border-bottom-right-radius: .5rem;
      border-top-right-radius: .5rem;
      float: right;
    }

    &--active {
      background-color: $color_keyColor;
      color: $color_white;
    }
  }
}
