@charset "UTF-8";

.topReApproach {
  background-color: $color_white;
  border-radius: 2px;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 1rem;

  &__title {
    color: $color_heading;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__text {
    margin-bottom: 2rem;
  }

  &__link {
    font-size: 1.1rem;

    a {
      @include underlined;
    }

    [class^="iconFont"] {
      &::before {
        font-size: inherit;
        vertical-align: baseline;
      }
    }
  }
}
