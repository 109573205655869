@charset "UTF-8";

.topSearchPanelList {
  @include clearfix;

  &__item {
    float: left;
  }

  &__item + &__item {
    margin-left: 1rem;
  }
}

.topSearchPanel {
  @include onHover;
  align-items: center;
  border: 1px solid $color_keyColor;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
  display: block;
  height: 162px;
  padding: 1.8rem 0 2rem 0;
  position: relative;
  width: 162px;

  &::before {
    border-top: 12px solid $color_keyColor;
    border-right: 12px solid $color_keyColor;
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    border-top-right-radius: 2px;
    content: "";
    position: absolute;
    right: 4px;
    top: 4px;
  }

  &__title {
    align-items: center;
    color: $color_keyColor;
    font-size: 1.6rem;
    font-weight: bold;
    height: 40px;
    line-height: 1.5;
    position: relative;
    text-align: center;
  }

  &__titleText {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__icon {
    height: 72px;
    margin: 0 auto 10px auto;
    position: relative;
    width: 72px;

    &::before {
      content: "";
      display: inline-block;
      height: 72px;
      left: 0;
      position: absolute;
      top: 0;
      width: 72px;
    }

    &--income {
      &::before {
        background: url('../img/top/sprite.png') -10px -276px;
      }
    }

    &--keyword {
      &::before {
        background: url('../img/top/sprite.png') -10px -368px;
      }
    }

    &--skill {
      &::before {
        background: url('../img/top/sprite.png') -10px -460px;
      }
    }

    &--aptitudeTest {
      &::before {
        background: url('../img/top/sprite.png') -10px -552px;
      }
    }

    &--history {
      &::before {
        background: url('../img/top/sprite.png') -10px -644px;
      }
    }

    &--station {
      &::before {
        background: url('../img/top/sprite.png') -10px -799px;
      }
    }
  }
}
