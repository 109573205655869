@charset "UTF-8";

.topBannerList {
  @include clearfix;
  $component: &;

  &__item {
    float: left;

    a {
      @include onHover;

      .js__lazy {
        height: 70px;
        width: 162px;
      }
    }
  }

  &--subsite {
    #{$component}__item + #{$component}__item {
      margin-left: 1rem;
    }

    #{$component}__item {
      &:nth-child(n+6) {
        margin-top: 10px;
      }

      &:nth-child(5n+1) {
        margin-left: 0;
      }
    }
  }

  &--relatedSite {
    #{$component}__item + #{$component}__item {
      margin-left: .8rem;
    }

    #{$component}__item {
      &:nth-child(n+7) {
        margin-top: 10px;
      }

      &:nth-child(6n+1) {
        margin-left: 0;
      }
    }
  }
}
