@charset "UTF-8";

.topMenuList {
  @include clearfix;
  $component: &;

  &--fullWidth {
    width: 100%;

    #{$component}__item {
      text-align: center;
      width: 16.66666%;

      .topMenu {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__item {
    border-left: 1px solid $color_keyColor;
    float: left;
    height: 24px;

    &:last-child {
      border-right: 1px solid $color_keyColor;
    }
  }
}

.topMenu {
  @include onHover;
  color: $color_heading;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 3rem;
  transform: translateY(4px);

  &__badge {
    background-color: $color_red;
    border-radius: 99999px;
    color: $color_white;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
    padding: .4rem .8rem;
    transform: translateY(-2px);
  }

  &__logeedinBadge {
    display: none;
  }
}
