
@charset "UTF-8";
$eventImg_path: '../img/event/';

/* イベントプレビュー
----------------------------------------------------------------- */
.previewEvent {
  #event-manage-preview-header {
    #globalHeader {
      width: 100%;
      border-top: none;
      border-bottom: 1px solid #ccc;

      .title-unit {
        text-align: center;
      }

      .hero-unit {
        padding: 0;
        width: auto;
        min-width: auto;
        margin: 5px auto 20px auto;
        font-size: 18px;
        font-weight: 200;
        line-height: 30px;
        color: inherit;
        background-color: $color_white;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: none;

        h1 {
          color: $color_black;
          margin-right: 10px;
          line-height: 31px;
          padding-top: 11px;
          margin-top: 0;
          font-size: 31px;
          font-weight: bold;
          letter-spacing: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  #contents {
    width: 100%;
    margin: 40px auto;
    border-bottom: 1px solid #ccc;

    .error-message {
      font-size: 16px;
      color: #333;
      margin: 100px auto;
      line-height: 200%;
      text-align: center;
    }

    .title-date {
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: #00345c;
      margin-bottom: 30px;

      .large {
        font-size: 72px;
      }
    }

    .contentsArea {
      width: 1300px;
      text-align: center;
      margin: 0 auto 0 auto;
      min-height: 500px;
      vertical-align: top;

      #parts__top--page.fair {
        margin: 50px -160px 0 0;
        width: 850px;
      }

      #parts__top--page {
        margin-top: 50px;
        width: 690px;
        padding: 0 0 10px 0;
        -webkit-transform: scale(0.8, 0.8);
        -ms-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
        display: inline-block;
        min-height: 500px;
        vertical-align: top;

        .topSection {
          width: 850px;

          &__main {
            .topEvents {
              text-align: left;

              &__title {
                margin-bottom: 30px;

                img {
                  float: left;
                  height: 92px;
                  margin-right: 2rem;
                }
              }
            }
          }
        }
      }

      #parts__event--page {
        width: 600px;
        -webkit-transform: scale(0.6, 0.6);
        -ms-transform: scale(0.6, 0.6);
        transform: scale(0.6, 0.6);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
        display: inline-block;
        min-height: 500px;
        vertical-align: top;
      }

      .Stage {
        text-align: left;
        margin: 0 auto 0 auto;
        padding: 0;
      }
    }

    .schedule {
      padding: 70px 0 125px;

      .card.durRegistration {
        box-sizing: border-box;
        border: 3px solid #dc0800;
        position: relative;
      }

      .durRegistration__icon {
        position: absolute;
        top: -36px;
        font-size: 16px;
        left: -8px;
        width: 231px;
        padding: 10px 0;
        background: #dc0800;
        color: $color_white;
        text-align: center;
      }

      .prefecture {
        text-align: center;
      }

      .eventName,
      .notice {
        font-size: 16px;
        overflow: hidden;
        text-align: center;
      }

      &.bgBlue {
        background-color: #f0fcff;
        padding-bottom: 65px;
      }

      h3 {
        color: $color_black;
        font-size: 50px;
        font-weight: bold;
        margin: 0 -335px 40px 0;
        text-align: center;

        &::before {
          content: url(#{$eventImg_path}icon_calender.png);
          position: relative;
          bottom: -0.16em;
          margin-right: 0.3em;
        }
      }

      .scheduleTab {
        display: flex;
        font-weight: bold;
        flex-wrap: nowrap;
        list-style: none;
        margin: 0 auto;
        overflow: hidden;
        padding: 0 0 32px 0;
        position: relative;
        width: 950px;

        &__item {
          background-color: #f1f1f1;
          border-left: 1px solid #ddd;
          color: $color_text;
          cursor: pointer;
          font-size: 14px;
          padding: 12px 0;
          position: relative;
          width: 100%;

          &:first-of-type {
            border: none;
          }

          span {
            display: block;
            font-size: 10px;
          }

          &--active {
            @extend .scheduleTab__item;
            background-color: $color_keyColor;
            border: none;
            color: $color_white;
            cursor: default;
            pointer-events: none;

            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 0;
              height: 0;
              border-color: transparent;
              border-top-color: $color_keyColor;
              border-style: solid;
              border-width: 10px 8px;
              -webkit-transform: translate(-50%, 100%);
              -ms-transform: translate(-50%, 100%);
              transform: translate(-50%, 100%);
            }
          }
        }
      }

      .scheduleSelector {
        background-color: $color_white;
        margin: -32px auto 30px;
        padding: 32px 0 28px 28px;
        text-align: left;
        width: 950px;

        .checkbox {
          &__text {
            display: block;
            font-size: 12px;
            margin: -33px 6px 0 25px;
          }
        }
      }

      .mainContentIn {
        width: 960px;

        p {
          margin: 0 5px 0;
        }

        a.card {
          display: block;
          float: left;
          background-color: #fff581;
          background-image: url(#{$eventImg_path}arrow_calender.png);
          background-repeat: no-repeat;
          background-position: right bottom;
          background-size: 28px;
          box-shadow: none;
          color: #000;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          width: 230px;
          margin: 32px 5px 10px;
          position: relative;

          .prefecture {
            display: block;
            margin: 15px;
            font-size: 34px;
            font-weight: bold;
            padding: 10px;
            background-color: $color_white;
          }

          .eventName {
            display: block;
            min-height: 50px;
          }

          .eventName01 {
            display: block;
            font-weight: bold;
            line-height: 19.152px;
          }

          .eventName02 {
            color: #ff8000;
            display: block;
            font-weight: bold;
            line-height: 19.152px;
          }

          .days {
            color: #000;
            font-size: 30px;
            font-weight: bold;
            text-align: left;
            display: block;
            margin: 0 0 0 15px;
            padding-bottom: 15px;
            line-height: 1.3em;
          }
        }

        a:hover.card {
          background-color: #fffac0;
          text-decoration: none;
        }

        .card {
          background-image: url(#{$eventImg_path}bg_card.png);
          float: left;
          width: 230px;
          color: #999;
          margin: 32px 5px 10px;
          position: relative;
          word-wrap: break-word;

          .prefecture {
            display: block;
            margin: 15px;
            font-size: 34px;
            font-weight: bold;
            padding: 10px;
            background-color: $color_white;
          }

          .eventName {
            display: block;
            min-height: 50px;
          }

          .days {
            font-size: 30px;
            font-weight: bold;
            text-align: left;
            display: block;
            margin: 0 0 0 15px;
            padding-bottom: 60px;
            color: #777;
            line-height: 1.3em;
          }

          .notice {
            background-color: $color_subBorder;
            display: block;
            padding: 5px 0;
            color: #777;
            position: absolute;
            bottom: 0;
            width: 100%;
          }

          .eventName01 {
            display: block;
            font-weight: bold;
            line-height: 19.152px;
          }

          .eventName02 {
            display: block;
            font-weight: bold;
            line-height: 19.152px;
          }
        }
      }
    }
  }

  div.floatPageTop {
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 10000;
    right: 0;
    bottom: 50px;
  }

  div.floatPageBottom {
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 10000;
    right: 0;
    top: 50px;
  }

  .gmenu {
    position: fixed;
    padding: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    top: 0;
    z-index: 99999;
    height: 50px;

    h2 {
      color: $color_white;
      font-size: 18px;
      line-height: 18px;
      margin-top: 16px;
      text-align: left;
      padding-left: 1em;
    }
  }

  &#eventDetail {
    position: relative;
    margin: 0;
    padding: 50px 0 120px;
  }

  #event-manage-preview-search {
    .data-change-area {
      margin: 20px auto 40px auto;
      border: 1px solid #ddd;
      width: 550px;
      display: flex;

      .fl {
        float: left;
        text-align: center;
        width: 390px;
        border-right: 1px solid #ddd;

        .postingDate {
          font-size: 13px;
          padding: 20px;
        }

        .err {
          color: #dc0800 !important;
          font-size: 13px;
          font-weight: bold;
          margin: -5px 0 10px 0;
          padding: 0;
          display: inline-block;
          text-align: left;
        }
      }

      .fr {
        float: right;
      }

      .fr.button-area {
        padding: 20px;
      }
    }

    [type=text] {
      width: 110px;
      background-color: $color_white;
      border: 1px solid #ccc;
      -webkit-transition: border linear .2s, box-shadow linear .2s;
      -moz-transition: border linear .2s, box-shadow linear .2s;
      -o-transition: border linear .2s, box-shadow linear .2s;
      transition: border linear .2s, box-shadow linear .2s;
      display: inline-block;
      height: 30px;
      padding: 4px 6px;
      font-size: 14px;
      line-height: 30px;
      color: #555;
      vertical-align: baseline;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    .btn {
      display: inline-block;
      *display: inline;
      padding: 4px 12px;
      margin-bottom: 0;
      *margin-left: .3em;
      font-size: 13px;
      line-height: 20px;
      color: #333;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      background-color: #f5f5f5;
      *background-color: #e6e6e6;
      background-image: -moz-linear-gradient(top, $color_white, #e6e6e6);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from($color_white), to(#e6e6e6));
      background-image: -webkit-linear-gradient(top, $color_white, #e6e6e6);
      background-image: -o-linear-gradient(top, $color_white, #e6e6e6);
      background-image: linear-gradient(to bottom, $color_white, #e6e6e6);
      background-repeat: repeat-x;
      border: 1px solid #ccc;
      *border: 0;
      border-color: #e6e6e6 #e6e6e6 #bfbfbf;
      border-bottom-color: #b3b3b3;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
      filter: progid:dximagetransform.microsoft.gradient(enabled=false);
      *zoom: 1;
    }
  }

  .textL {
    min-width: 850px;
  }
}

@media screen and (max-width: 768px) {
  body.responsive {
    min-width: 0;
  }

  .previewEvent {
    #event-manage-preview-header {
      #globalHeader {
        width: 100%;
        border-top: none;
        border-bottom: 1px solid #ccc;

        .title-unit {
          text-align: center;
        }

        .hero-unit {
          padding: 0;
          width: auto;
          min-width: auto;
          margin: 5px auto 20px auto;
          font-size: 18px;
          font-weight: 200;
          line-height: 30px;
          color: inherit;
          background-color: $color_white;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          border: none;

          h1 {
            color: $color_black;
            margin-right: 10px;
            line-height: 31px;
            padding-top: 11px;
            margin-top: 0;
            font-size: 31px;
            font-weight: bold;
            letter-spacing: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    #contents {
      width: 100%;
      margin: 40px auto;
      border-bottom: 1px solid #ccc;

      .contentsArea {
        width: 690px;
        text-align: center;
        margin: 90px auto 0 auto;
        min-height: 500px;
        vertical-align: top;

        #parts__top--page {
          margin-top: 50px;
          width: 850px;
          padding: 0 0 10px 0;
          -webkit-transform: scale(0.8, 0.8);
          -ms-transform: scale(0.8, 0.8);
          transform: scale(0.8, 0.8);
          -webkit-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
          transform-origin: 0 0;

          .topEvents__title {
            margin-bottom: 30px;

            img {
              float: left;
              height: 92px;
              margin-right: 2rem;
            }
          }

          .topEvents {
            text-align: left;
          }
        }
      }
    }

    div.floatPageTop {
      width: 50px;
      height: 50px;
      position: fixed;
      z-index: 10000;
      right: 0;
      bottom: 50px;
    }

    div.floatPageBottom {
      width: 50px;
      height: 50px;
      position: fixed;
      z-index: 10000;
      right: 0;
      top: 50px;
    }

    .gmenu {
      position: fixed;
      padding: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      top: 0;
      z-index: 99999;
      height: 50px;

      h2 {
        color: $color_white;
        font-size: 18px !important;
        line-height: 18px !important;
        margin-top: 16px;
        text-align: left;
        padding-left: 1em;
      }
    }

    &#eventDetail {
      position: relative;
      margin: 0;
      padding: 50px 0 120px;
    }
  }

  #eventDetail {
    margin-top: 10px;
    text-align: center;
    -webkit-text-size-adjust: none;
    color: #000;
    font-size: 3.6vw !important;
    line-height: 1.8em !important;
    font-family: "Lucida Grande", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;

    strong,
    em {
      font-style: normal;
      font-weight: bold;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 100%;
      font-weight: bold;
      line-height: 1.2;
    }

    h3 {
      .arrow {
        height: 16vw;
        width: 16vw;
        position: absolute;
        left: 50%;
        bottom: -8vw;
        margin-left: -8vw;
      }
    }

    dl,
    ol,
    p,
    ul {
      margin: 0;
      padding: 0;
    }

    ul,
    ol {
      list-style: none;
    }

    li {
      font-size: 3.6vw;
      padding: 0;
      margin: 0;
      list-style: none;
      line-height: 1.8em;
    }

    a {
      img {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;

      td {
        line-height: 1.5;
      }
    }

    a:link,
    a:visited {
      color: #06c;
      text-decoration: none;
    }

    .float_r {
      float: right;
    }

    .float_l {
      float: left;
    }

    .card {
      max-width: none;
      margin: 0;
      background-color: transparent;
      box-shadow: none;
    }

    .list {
      margin: 0;
    }

    .emergency_info {
      color: #f00;
      font-weight: bold;
      font-size: 120%;
      margin: 10px auto;
      padding: 10px 0 10px 0;
      background-color: $color_white;
      display: block;
      opacity: 0.9;
    }

    .mainContentIn {
      width: 92vw;
      overflow: hidden;
      margin: 0 auto;
      position: relative;

      .exhibitorCompanyRead {
        font-size: 3.6vw;
        padding-bottom: 20px;
      }

      p {
        text-align: justify;
        text-justify: inter-ideograph;
      }

      ul.link {
        margin: 1em 0 0 1em;

        li {
          list-style-image: url(#{$eventImg_path}arrow_link.png);

          a {
            color: #0d386b;
          }
        }
      }

      .link__map {
        font-size: 5vw;
        margin-top: 10vw;
        margin-bottom: 10vw;
        text-align: center;
      }
    }

    #mainImage {
      background-image: url(#{$eventImg_path}mainimage.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 67vw;
      width: 100%;
      overflow-y: hidden;

      #mainTitle {
        background-image: url(#{$eventImg_path}mainimage_circle.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 0 auto;
        padding: 0;
        height: 67vw;
        width: 98vw;
        position: relative;

        h2 {
          margin: 0 0 0 -37vw;
          padding: 0;
          position: absolute;
          top: 7vw;
          left: 50%;
          z-index: 12;
          width: 74vw;
        }

        .title_event {
          padding: 32vw 0 0 7.5vw;
          vertical-align: text-top;
          overflow: hidden;

          .title_expo {
            width: 46vw;
            margin-right: 5vw;
            float: left;
          }

          img {
            vertical-align: text-top;
          }
        }

        .info {
          h3 {
            font-size: 4vw;
            margin: 0 0 2vw 0;
            text-align: center;
          }

          dl {
            padding-bottom: 1vw;
            display: table;
            border-collapse: collapse;
            border-spacing: 0;
            margin: 0 auto;
            overflow: hidden;
            width: 80vw;

            dt {
              width: 10%;
              max-width: 10%;
              background-color: transparent;
              font-size: 3vw;
              padding: 0;
              text-align: center;
              clear: left;
              margin: 0 0 1vw 0;

              & > span {
                min-width: 10%;
                display: block;
                background-color: #fc0;
              }
            }

            dd {
              width: 70%;
              line-height: 1.5;
              font-size: 3vw;
              padding: 0 0 1vw 2vw;
              padding-bottom: 0;
              margin: 0;
            }

            dt,
            dd {
              float: none;
              display: table-cell;
              vertical-align: middle;
            }

            & + dl {
              margin-top: 1vw;
              padding-bottom: 4vw;
            }
          }
        }

        .schedule {
          dl {
            overflow: hidden;

            dt {
              width: 42vw;
              text-align: left;
              clear: both;
              float: left;
              margin-bottom: 3vw;

              .month {
                font-size: 6vw;
                font-weight: bold;
                line-height: 1.2em;
                vertical-align: top;
              }

              .date {
                white-space: nowrap;
                font-size: 10vw;
                vertical-align: top;
                font-weight: bold;
                line-height: 1em;

                .day {
                  font-size: 4vw;
                  font-weight: bold;
                  line-height: 1em;
                }
              }
            }

            dd {
              width: 42vw;
              margin: 0;
              margin-top: 5px;
              font-size: 3vw;
              float: left;
              padding: 0 0 0 5vw;
              margin-bottom: 3vw;

              .time {
                font-size: 5vw;
                display: block;
                font-weight: bold;
                line-height: 1em;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    #nav {
      color: $color_white;

      ul {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        overflow: hidden;

        li {
          float: left;
          width: 33.3vw !important;
          border-right: 1px solid $color_white;
          border-bottom: 1px solid $color_white;

          a {
            height: 17vw !important;
            padding-top: 5vw !important;
            background: url(#{$eventImg_path}nav_arrow.png) center 13vw no-repeat !important;
            background-size: 3vw !important;
            display: block;
            color: $color_white;
            font-weight: bold;
            font-size: inherit;
          }
        }
      }
    }

    #company {
      .lead {
        font-size: 3.6vw;
        padding: 7vw 5vw 7vw;
        text-align: left;
        width: auto;
      }
      
      .notice {
      font-size: 18px;
      padding: 40px 0 0;
      text-align: left;
      width: 950px;
      margin: 0 auto;
      }

      .mainContentIn {
        width: 100vw;

        .list {
          background: url(#{$eventImg_path}bg_company02.png) no-repeat 75vw 5vw, url(#{$eventImg_path}bg_company01.png);
          background-size: 30vw, auto;
          padding: 12vw 4vw 1px 4vw;
          margin-bottom: 0;

          .note {
            background-color: $color_white;
            color: #777;
            padding: 10vw 0;
            margin: 15vw 0 4vw;
          }

          h3 {
            font-size: 8vw;
            color: #0d386b;
            margin-bottom: 6vw;
          }

          h4 {
            text-align: left;
            font-size: 5vw;
            margin-bottom: 2vw;
            font-weight: normal;
            color: #00a3d9;
          }

          table {
            font-size: 12px;
            text-align: left;
            margin-bottom: 15px;
            clear: both;

            a {
              font-size: 12px;
            }

            th {
              color: $color_white;
              background-color: #00a3d9;
              padding: 1em;
              vertical-align: top;
              font-weight: bold;
              font-size: 3vw;

              .small {
                font-size: 2.4vw;
              }
            }

            tr {
              .name {
                font-weight: bold;
                width: 430px;
              }

              td {
                background-color: $color_white;
                padding: 1em;
                vertical-align: top;
                border-bottom: solid 1px #ddd;
                font-size: 12px;

                a:link,
                a:visited {
                  text-decoration: underline;
                  color: #0d386b;
                }
              }

              td.name {
                width: 50%;
                line-height: 2;
              }

              &:last-child {
                td {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }

    #detail {
      h4 {
        font-size: 4.5vw !important;
        overflow: hidden;
        line-height: 1em;
        margin-bottom: 1em;
        background-color: $color_white;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;

        .no {
          display: block !important;
          text-align: center !important;
          width: 12vw !important;
          background-color: #ff8000 !important;
          color: $color_white !important;
          padding: 4vw 0 !important;
          border-radius: 10px 0 0 10px / 10px 0 0 10px !important;
          -webkit-border-radius: 10px 0 0 10px / 10px 0 0 10px !important;
          -moz-border-radius: 10px 0 0 10px / 10px 0 0 10px !important;
          float: left !important;
          height: auto !important;
        }

        .text {
          font-size: 4.5vw !important;
          display: block !important;
          color: #000 !important;
          text-align: left !important;
          padding: 4vw 0 4vw 4vw !important;
          float: left !important;
          width: 76vw !important;
          height: auto !important;
        }

        .feaTitle {
          height: 19px;
        }
      }

      .mainContentIn {
        .wrapper {
          overflow: hidden;
          margin-bottom: 3em;
          min-width: auto;

          .text {
            width: 100%;

            p {
              font-size: 3.6vw;
              margin-bottom: 1.5em;
            }
          }
        }

        .img {
          width: 100%;
          height: auto !important;
        }
      }
    }

    #present {
      padding: 0 0 7vw 0;
      background: url(#{$eventImg_path}bg_present.png) no-repeat right 2vw;
      background-size: 50vw;

      .mainContentIn {
        h3 {
          font-size: 10vw;
          margin: 15vw auto 13vw;
          width: 69vw;
        }

        p {
          font-size: 3.6vw;
        }

        .point {
          background-color: #4cbee4;
          padding: 10vw 0 7vw;
          position: relative;
          margin-bottom: 10vw;

          h4 {
            font-size: 4vw;
            background: url(#{$eventImg_path}present_h4.png) no-repeat;
            background-size: 50vw;
            width: 50vw;
            height: 20vw;
            position: absolute;
            top: -5vw;
            left: 50%;
            margin-left: -25vw;
            display: block;
            padding-top: 1vw;
          }

          p {
            font-size: 4vw;
            color: $color_white;
            line-height: 1.5em;
            font-family: "Verdana", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
            margin-left: 5vw;
            margin-right: 5vw;
            text-align: left;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        ul.link {
          width: auto;
          float: right;
          font-size: 1.14em;

          li {
            list-style-image: url(#{$eventImg_path}arrow_link.png);

            a {
              font-size: 1.14em;
              color: #0d386b;
              text-decoration: none;
            }
          }
        }
      }
    }

    #schedule {
      .mainContentIn {
        .note {
          background-color: $color_white;
          color: #777;
          padding: 10vw 0;
          margin: 4vw 0 4vw;
        }

        .card {
          background-color: $color_white;
          padding: 6vw;
          position: relative;
          margin: 8vw 0 10vw;

          .date {
            display: block;
            height: 17vw;
            width: 70vw;
            background: url(#{$eventImg_path}shedule_date.png) no-repeat;
            background-size: cover;
            color: $color_white;
            padding: 5vw 0 0 2vw;
            position: absolute;
            top: -2.8vw;
            left: 4vw;
            font-weight: bold;

            .days {
              font-size: 6vw;
            }

            .day {
              font-size: 4vw;
            }

            .time {
              display: inline;
            }
          }

          h4 {
            font-weight: bold;
            color: #f88016;
            font-size: 6vw;
            text-align: left;
            padding: 15vw 0 5vw 0;
            border-bottom: #f88016 dotted 2px;
            margin-bottom: 5vw;
          }

          .text {
            font-size: 3.5vw;

            p {
              font-size: 3.5vw;
            }
          }

          .wrapper {
            overflow: hidden;
            text-align: left;
            margin-top: 7vw;
            min-width: auto;

            .text {
              width: 50vw;

              h5 {
                font-size: 1.03em;
                margin-bottom: .5em;
              }

              p {
                font-size: .9em;
              }
            }

            .img {
              width: 25vw;
              margin: 0;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    #access {
      padding: 7vw 0;

      h3 {
        font-size: 10vw;
        font-weight: bold;
        margin-bottom: 5vw;

        &::before {
          content: url(#{$eventImg_path}icon_access.png);
          position: relative;
          bottom: -0.16em;
          margin-right: 0.3em;
        }
      }

      .mainContentIn {
        .wrapper {
          min-width: auto;

          .map {
            width: 100%;
            margin-bottom: 7vw;

            iframe {
              height: 92vw;
              width: 92vw;
            }
          }

          .text {
            width: 100%;
            text-align: left;

            h4 {
              background-color: #fc0;
              font-size: 3.6vw;
              text-align: center;
              width: 5.5em;
              padding: 1vw;
              margin-bottom: 1em;
              line-height: 100%;
              border-radius: 1vw;
              -webkit-border-radius: 1vw;
              -moz-border-radius: 1vw;
            }

            p {
              margin-bottom: 2em;
              font-size: 1.3rem;
            }

            ul {
              margin-left: 2em;

              li {
                list-style-type: disc;
                margin-bottom: 1em;
              }
            }
          }
        }
      }
    }

    #flow {
      background-color: #00a3d9;

      h3 {
        font-size: 6vw;
        color: $color_white;
        padding: 10vw 0;

        span {
          font-size: 10vw;
          display: block;
        }
      }

      .mainContentIn {
        .card {
          background-color: $color_white;
          padding: 5%;
          margin-bottom: 4vw;
          position: relative;
          height: 100% !important;
          width: 100%;

          h4 {
            font-size: 6vw;
            color: #0d386b;
            margin: 1em 0 0.5em;
            text-align: center;
            height: auto !important;

            span {
              font-size: 6vw;
            }
          }

          p {
            font-size: 3.6vw;
          }

          .icon {
            margin-bottom: 7vw;
          }

          .link {
            text-align: left;

            a {
              text-decoration: none;
            }
          }

          .arrow {
            position: absolute;
            width: 14vw;
            height: 14vw;
            top: -9vw;
            left: 50%;
            margin-left: -7vw;
            margin-top: 0;
            transform: none;
            -webkit-transform: none;
          }
        }
      }
    }

    a#previous {
      font-size: 3.4vw;
      text-align: center;
      color: #00a3d9;
      margin: 10vw auto 10vw;
      padding: 3vw 0 3vw 5vw;
      border: 1vw solid #00a3d9;
      display: block;
      width: 89vw;
      border-radius: 10vw;
      -webkit-border-radius: 10vw;
      -moz-border-radius: 10vw;
      background: url(#{$eventImg_path}arrow_back.png) no-repeat 3vw center, $color_white;
      background-size: 2vw;
      font-weight: bold;
    }

    .bottomLink {
      background: url(#{$eventImg_path}bg_bottomLink.png);
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 2vw 2vw 0 2vw;
      z-index: 14;
    }

    .pagetop {
      position: fixed;
      bottom: 37vw;
      right: 4vw;
      width: 15vw;
      height: 15vw;
      z-index: 13;
    }

    &.sub {
      #mainImage {
        padding: 4vw 0;
        height: inherit;

        #mainTitle {
          background-image: none;
          margin: 0 auto;
          padding: 0;
          width: 92vw;
          position: inherit;
          overflow: hidden;
          text-align: left;
          height: inherit;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .info {
            width: 100%;
            padding-bottom: 4vw;
            background-color: $color_white;
            height: auto;

            .title_event {
              .prefecture {
                width: 46vw;
                font-size: 13vw;
                float: right;
                margin: 0;
                line-height: 1em;
                text-align: center;
                padding-top: 1vw;
              }
            }
          }

          h2 {
            position: inherit;
            margin: 0 auto;
            padding: 6vw 0 2vw;
            width: 70vw;
          }

          .title_event {
            margin: 4vw auto 3vw;
            padding: 0 0 0 0;
            vertical-align: inherit;
            width: 92vw;
            height: auto;

            .title_expo {
              width: 46vw;
              margin: 0;
              padding-left: 6vw;
              float: left;
            }
          }

          .schedule {
            padding: 5vw 0 0 4vw;
            width: 100%;
            background-color: #9cf;
            margin: 0;
            clear: both;
            height: auto;
          }
        }
      }

      #nav {
        background-color: #0d386b;
      }

      #detail {
        padding-bottom: 0;

        h3 {
          margin: 0 0 1.5em 0;
        }
      }

      .orange {
        background: url(#{$eventImg_path}bg_people01.png);

        h3 {
          background: url(#{$eventImg_path}bg_people02.png);
          color: $color_white;
          font-size: 8vw;
          font-weight: bold;
          padding: 1.33em 0 1.5em;
          position: relative;
        }
      }

      #schedule {
        h3 {
          margin: 0 0 1em 0;
        }
      }

      .bottomLink {
        padding: 0;

        a#entry {
          font-size: 6vw;
          color: #000;
          margin: 3vw auto;
          padding: 5vw 0;
          display: block;
          width: 92vw;
          border-radius: 40vw;
          -webkit-border-radius: 40vw;
          -moz-border-radius: 40vw;
          background: url(#{$eventImg_path}arrow_entry.png) no-repeat 13.5em center, $color_white;
          background-size: 7vw;
          font-weight: bold;

          span {
            font-size: 4vw;
          }
        }
      }
    }
  }
}
