@charset "UTF-8";

.agsHistory {
  border-top: none;

  &__itemMain {
    width: 100%;
  }

  &__nothing {
    height: 200px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  &__nothingText {
    margin: 0 !important;
  }

  &__annotation {
    color: #333;
    font-weight: 700;
    font-size: 1.4rem;

    &-side {
      line-height: 1.42;
      vertical-align: middle;
      margin-left: 1em;
    }

    &-bottom {
      line-height: 1.7;
      display: block;
    }
  }
}
