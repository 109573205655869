.container-bookmarkList:has(.bookmarkTab) {
  padding: 40px 0;
}

.container-bookmarkList {
  .pullDown__trigger {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .15);
    height: 38px;
    min-width: 176px;
    padding: 10px 20px 10px 10px;
    width: 176px;

    &::before {
      background-size: contain;
      height: 8px;
      right: 14px;
      width: 10px;
    }
  }
}

.bookmarkTab {
  background: $color-white;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 8px;
  display: flex;
  height: 64px;
  left: 243px;
  margin: 0 auto 30px;
  top: 481px;
  width: 950px;

  &__kinds {
    @include onHover;
    height: 60px;
    position: relative;
    width: 244px;

    &:last-of-type {
      .bookmarkTab__title {
        border-right: none;
      }
    }
  }

  &__title {
    align-items: center;
    border-right: 1px solid rgba(221, 221, 221, 1);
    display: flex;
    height: 32px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 244px;

    &--text {
      color: $color_text;
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      text-align: left;
    }

    &--selectText {
      color: $color_keyColor;
      font-size: 16px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
    }

    .topMenu__badge {
      align-items: center;
      display: none;
      height: 18px;
    }
  }

  &__errorConsideration {
    background: $color-white;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    display: none;
    height: 265px;
    margin: 0 auto;
    max-width: 950px;
    padding: 40px 80px;

    &:has(.bookmarkTab__errorConsideration--img) {
      height: 484px;
    }

    &--title {
      font-size: 24px;
      font-weight: 600;
      height: 38px;
      line-height: 1.6;
      text-align: center;
    }

    &--detail {
      font-size: 16px;
      font-weight: 400;
      height: 52px;
      line-height: 25.6px;
      margin-top: 10px;
    }

    &--img {
      height: 189px;
      margin-top: 27px;
      width: 271px;
    }

    .btnBox {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 20px auto 0;

      .btnDefaultL {
        align-items: center;
        border: 2px solid $color_keyColor;
        border-radius: 4px;
        color: $color_keyColor;
        display: flex;
        font-size: 20px;
        font-weight: 500;
        height: 60px;
        justify-content: center;
        line-height: 30px;
        width: 380px;
      }
    }
  }
}
