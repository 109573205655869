@charset "UTF-8";

.jobContentList {
  @include clearfix;
  $component: &;
  border: 1px solid #ddd;

  &__item {
    padding: 30px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ddd;

    &:last-child() {
      border-bottom: 0;
    }
  }
}

.jobContentItem {
  @include clearfix;

  &__title {
    color: $color_keyColor;
    display: block;
    font-size: 1.8em;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  &__img {
    float: right;
    width: 250px;
  }

  &__leftBox {
    float: left;
    width: 510px;
  }
}
