@charset "UTF-8";

.infoConsentModal {
  background: $color_white;
  bottom: 70px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  display: none;
  left: 50%;
  margin: 0 auto;
  padding: 15px 20px 5px;
  position: fixed;
  transform: translateX(-50%);
  width: 900px;
  z-index: 100;

  &__title {
    margin: 0 0 5px;
    text-align: center;

    &--text {
      color: #03a9f4;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.5;
      margin: 0;
    }
  }

  &__details {
    margin: 10px 0 10px;

    &--text {
      color: #444;
      font-size: 1.2rem;
      line-height: 1.5;
      margin: 0;
    }
  }

  &__btnArea {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 30%;

    .infoConsentModal__btn {
      border-radius: 5px;
      color: $color_white;
      display: inline-block;
      font-size: 14px;
      line-height: 1.5;
      margin: 10px 0;
      padding: 10px;
      text-decoration: none;
      width: 100px;

      &--close {
        background: #666;
        &:hover {
          background: #747474;
        }
      }

      &--confirmation {
        background: #f00;
        margin-left: 2%;
      }
    }
  }
}
