@charset "UTF-8";

/* ページタイトル（主にh1）
----------------------------------------------------- */
.headingPage {
  @include heading() {
    margin: 0 auto 20px;
    padding: 3px 20px;
    font-size: 2rem;
    border-left: 4px solid $color_keyColor;
  }
}

.headingPageGray {
  background-color: #ebebeb;
  border: 0;
  padding: 8px 0;

  .headingPageGrayText {
    @include heading() {
      background-color: #ebebeb;
      border-left: 2px solid #333;
      display: block;
      font-size: 2.8rem;
      font-weight: 400;
      height: fit-content;
      line-height: 1;
      margin: 0 auto;
      max-width: 1230px;
      padding: 4px 0 4px 10px;
    }
  }
}

/* 主にメインコンテンツのh2要素
----------------------------------------------------------------- */
.headingBlock {
  @include heading() {
    margin: 0 auto;
    padding: 30px 28px 20px;
    font-size: 2rem;
    border-bottom: 1px solid $color_subBorder;
  }
}

.headingBlock .textSup {
  float: right;
  padding-top: 0.6em;
  font-weight: normal;
}

.card > .headingBlock:first-child {
  border-top: 2px solid $color_keyColor;
}

.card__content + .headingBlock,
.card__section > .headingBlock {
  padding-top: 10px;
}

.headingBlock + .card__content {
  padding-top: 20px;
}

.headingBlock + .card__settingContent {
  padding-top: 0;
}

.headingBlock + .card__content > .imgCenter:first-child {
  margin-top: 20px;
}

.headingReadBlock {
  @extend .headingBlock;
}

.headingReadBlock .textSup {
  float: none;
}

//MYコーチ
.headingBlock--coach {
  @extend .headingBlock;
  padding: 28px 28px 20px;
}

.headingBlock--coach span {
  display: block;
  color: $color_text;
  font-size: 1.4rem;
  font-weight: normal;
}

.footingBlock {
  margin: 0 auto;
  padding: 20px 28px;
  border-top: 1px solid #ddd;
}

/* 主に各画面のh3要素
----------------------------------------------------------------- */
.headingContent {
  @include heading() {
    margin: 0 auto 20px;
    font-size: 1.6rem;
  }
}

.headingContentS {
  @extend .headingContent;
  margin-bottom: 16px;
  font-size: 1.4rem;
}

.headingContentM {
  @extend .headingContent;
  font-size: 1.8rem;
}

.headingContentL {
  @extend .headingContent;
  font-size: 2rem;
}

/* 背景グレーの要素
----------------------------------------------------------------- */
.headingSubBlock {
  @include heading() {
    margin: 0 auto;
    padding: 20px 28px;
    font-size: 1.6rem;
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f5;
  }
}

/* 主にサイドバーのh2要素
----------------------------------------------------------------- */
.headingSideMenu {
  @include heading() {
    margin: 0 auto;
    padding: 30px 12px 16px;
    font-size: 1.6rem;
    border-bottom: 1px solid #ddd;
  }
}

/* トップ用
----------------------------------------------------------------- */
.headingTop {
  @extend .headingBlock;
  font-size: 1.6rem;
}

.headingTop__day {
  margin-right: 1em;
}

.headingTop__count {
  color: $color_red;
  font-size: 1.9rem;
}

.headingTop__nextDay {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: normal;
  margin-left: 1em;
}

.headingTop .textSup {
  @include bulletLink(1.2rem);
  margin-top: 0.6em;
}

/* 職種検索結果一覧
----------------------------------------------------------------- */
.headingPageArrow {
  color: $color_keyColor;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.headingPageArrow::before {
  content: "";
  margin-right: 8px;
  padding-left: 10px;
  background: url(#{$img_path}arrowRightL.png) no-repeat 0 50%;
}

/* コーチ
----------------------------------------------------------------- */
.headingCoach {
  margin: 0 0 20px 12px;
}

.headingCoach img {
  vertical-align: top;
}

/* コーチ アドバイス
----------------------------------------------------------------- */
.headingCoachAdvice {
  margin: 20px 0;
  color: $color_white;
  font-size: 1.2rem;
  font-weight: bold;
}

.headingCoachAdvice span {
  display: inline-block;
  height: 30px;
  padding: 0 20px;
  line-height: 30px;
}

.headingCoachAdvice__interview {
  background-color: #e14987;
}

/* オートマッチ
----------------------------------------------------------------- */
.headingJobMatch {
  @include iconFont($fontSize: 2.5rem, $icon: "\e917");
  margin: 0 0 25px;
  padding: 15px 0 15px 25px;
  color: $color_white;
  font-size: 1.8rem;
  border-radius: 5px;
  background-color: $color_keyColor;
}

.headingJobMatch::before {
  margin: 0 8px 0 0;
}

.headingJobMatch--woman {
  @extend .headingJobMatch;
  background-color: $color_woman;
}

.headingJobMatch--done {
  @include iconFont($fontSize: 2.1rem, $icon: "\e91c");
  margin: 0 0 25px;
  padding: 15px 0 15px 25px;
  color: $color_white;
  font-size: 1.8rem;
  border-radius: 5px;
  background-color: #ff7f7f;
}

.headingJobMatch--done::before,
.headingJobMatch--done__text,
.headingJobMatch--done__label {
  display: inline-block;
  vertical-align: middle;
}

.headingJobMatch--done__label {
  height: 26px;
  padding: 3px 25px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff7f7f;
  background-color: #fff;
  border-radius: 13px;
}

/* 青線見出し (面接応募日時)
----------------------------------------------------------------- */
.headingBlockBlueLine {
  padding: 13px 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #fff;
  border-color: #00aaeb;

  &__title {
    color: #00aaeb;
    font-weight: 700;
    word-break: break-all;
    white-space: normal;
    word-wrap: break-word;
    margin: 0 auto;
    padding: 5px 0 10px;
    font-size: 2.4rem;
  }

  &__annotation {
    padding: 10px 0 0;
    color: $color_subText;
    font-size: 1.2rem;
  }
}
