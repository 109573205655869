@charset "UTF-8";

.clearfix {
  @include clearfix;
}

/* 帯メッセージ
----------------------------------------------------------------- */
.message {
  padding: 30px 0;
  color: $color_white;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  position: absolute;
  width: 100%;
  z-index: 101;
}

/* モーダル
----------------------------------------------------------------- */
.modal {
  @include modal;
  $component: &;

  &__close {
    text-align: right;
  }

  &--small {
    #{$component}__container {
      margin: 1.5rem 0;
    }
  }

  &__container {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    color: #333;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.77;
    margin: 30px 0;
    text-align: center;
  }

  &__content {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  /* モーダル 気になる マッチング */
  &--bookmarkMatching {
    background: $color_matching;

    .btnClose {
      background-color: $color_matching;
      border-color: $color_white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15);
      color: $color_white;
    }

    #{$component}__content {
      background: $color_white;
      border-radius: .5rem;
      padding: 1.5rem;
    }

    #{$component}__text {
      color: $color_white;
      margin: 0;

      &::before {
        content: "\e91c";
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: mynavi;
        margin-right: .5rem;
      }
    }
  }

  /* モーダル WEB履歴書で未入力がある場合 */
  &--webResumeLack {
    width: 660px;

    #{$component}__text {
      margin: 10px 0 -10px 0;
    }

    #{$component}__body {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      padding: 22px 0;
      text-align: center;
    }

    #{$component}__item {
      font-size: 14px;
      font-weight: 300;
      padding: 0;
      text-align: center;
    }
  }

  /* モーダル イベント事前登録 */
  &--eventRegistration {
    background-color: #fcb653;

    .btnClose {
      background-color: #fcb653;
      border-color: $color_white;
      color: $color_white;
    }

    #{$component}__text {
      color: $color_white;
      font-size: 14px;
      font-weight: 600;
    }

    #{$component}__body {
      background-color: $color_white;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      margin: -16px 0 -11px 0;
      padding: 17px 0;
      text-align: center;
    }

    #{$component}__qr {
      display: block;
      margin: -25px auto 0;
    }
  }
}

.bandLayer,
.modalLayer,
.band__content {
  min-width: 950px;
}

.modalLayer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__keep {
  width: 184px;
  margin: 20px auto 0;
  text-align: center;
}

.modal__keep > a,
.modal__keep > span {
  @include iconFont($fontSize: 2rem, $icon: "\e919") {
    font-size: 1.2rem;
  }
}

.modal__keep > a::before,
.modal__keep > span::before {
  position: relative;
  top: -2px;
  vertical-align: middle;
  margin-right: 4px;
  color: #bbb;
}

.modal__keep--done {
  @extend .modal__keep;
}

.modal__keep--done > span::before {
  color: #fece30;
}

.modal__keep--cancel {
  @extend .modal__keep;
}

.modal__keep--cancel > span::before {
  color: #555;
}

.modal__keep--doneCancel {
  @extend .modal__keep;
  opacity: 0.5;
}

.modal__keep--doneCancel > span::before {
  color: #fece30;
}

.modal__textSup {
  margin-top: 30px;
  font-size: 1.2rem;
  text-align: center;
}

.modal > .btnBox:last-child {
  margin-bottom: 0;
}

.modal .applicationTable {
  width: 520px;
  margin: 0 auto;
  font-size: 1.4rem;
  line-height: 1.42;
}

.modal .formTable {
  width: 773px;
  margin: 20px auto;
}

.modal .formTable__head {
  width: 168px;
}

.modal .formTable__body {
  vertical-align: top;
}

.modal .formTable .text {
  line-height: 1.42;
}

/* モーダル アイコン・スカウト特典について
----------------------------------------------------------------- */
.modalScout {
  @include modal($padding: 20px 0 30px);
  display: none;
}

.modalScout__heading {
  padding: 0 0 16px 20px;
  font-size: 1.9rem;
}

.modalScout__content {
  padding: 30px 0 30px 20px;
  border-top: 1px solid #ddd;
}

.modalScout__headingSub {
  font-size: 1.7em;
  color: #000;
  margin: 0 0 30px;
}

.modalScout__item {
  @include clearfix;
  margin-bottom: 26px;
}

.modalScout__item:last-child {
  margin-bottom: 0;
}

.modalScout__item [class^="label"] {
  float: left;
}

.modalScout__item .labelCrown,
.modalScout__item .labelPremiumBenefit {
  margin-right: 10px;
}

.modalScout__item .labelScoutLimited {
  margin-right: 5px;
}

.modalScout__item .labelScoutBenefit {
  margin-right: 27px;
}

.modalScout__item .text {
  float: left;
  width: 9em;
  margin: 2px 5px 0 0;
}

.modalScout__item .textSup {
  float: left;
  margin: 3px 0 0;
}

.modalScout__keep {
  @include iconFont($fontSize: 2rem, $icon: "\e919") {
    float: left;
    display: block;
    margin-right: 16px;
    color: #ccc;
  }
}

.modalScout__keep::before {
  position: relative;
  z-index: 1;
  top: -3px;
}

.modalScout__keep--done {
  @extend .modalScout__keep;
  color: #fece30;
}

/* モーダル 非公開に設定されている企業
----------------------------------------------------------------- */
.modalPrivateCompany {
  @include modal($padding: 62px 88px 30px);
}

.modalPrivateCompany__heading {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.4rem;
  font-weight: bold;
}

.modalPrivateCompany__content {
  overflow-y: auto;
  max-height: 450px;
  margin-bottom: 20px;
}

.modalPrivateCompany__table {
  width: 100%;
  table-layout: fixed;
  font-size: 1.4rem;
  border-top: 1px solid #{$color_subBorder};
  border-left: 1px solid #{$color_subBorder};
}

.modalPrivateCompany__check,
.modalPrivateCompany__detail {
  vertical-align: top;
  border-right: 1px solid #{$color_subBorder};
  border-bottom: 1px solid #{$color_subBorder};
}

.modalPrivateCompany__check {
  width: 54px;
  padding: 20px 0;
  text-align: center;
}

.modalPrivateCompany__detail {
  padding: 20px;
}

.modalPrivateCompany__name {
  color: #333;
  font-weight: bold;
  line-height: 1.42;
}

.modalPrivateCompany .btnBox {
  margin-bottom: 0;
}

/*  モーダル 選択
----------------------------------------------------------------- */
.modalChoice {
  @include modal($padding: 0) {
    display: none;
  }
}

.modalChoice__heading {
  height: 56px;
  padding: 20px 100px 0 15px;
  color: $color_heading;
  font-size: 1.9rem;
  font-weight: bold;
  border-bottom: 1px solid $color_subBorder;

  &--sub {
    position: absolute;
    top: 2.2rem;
    left: 500px;
    font-size: 1.2rem;
    color: #000;
  }
}

.modalChoice__heading--prefecture {
  display: inline;
}

.modalChoice__heading--office {
  display: none;
}

.modalChoice--noLabelNumber {
  .modalChoice__heading--prefecture {
    display: none;
  }

  .modalChoice__heading--office {
    display: inline;
  }
}

.modalChoice__content {
  overflow: hidden;
  height: 579px;
  font-size: 1.4rem;

  &--freeword {
    @extend .modalChoice__content;
    height: 60vh;
    margin-top: 25px;
    max-height: 579px;
    min-height: 400px;
    overflow: hidden;

    .more {
      display: block;
      margin: 0 0 10px 20px;
    }

    .suggest {
      &::before {
        background: url(#{$img_path}freeword_loupe.png) no-repeat center center / auto 100%;
        content: "";
        display: inline-block;
        height: 25px;
        left: 40px;
        position: absolute;
        top: 12px;
        width: 25px;
        z-index: 2;
      }
    }

    .recommend {
      margin: 0 60px;

      .headingContent {
        margin-bottom: 10px;
      }

      &__content {
        background-color: #eaeaea;
        border-radius: 10px;
        padding: 10px;

        div {
          background-color: $color_white;
          border-radius: 7px;
          cursor: pointer;
          display: inline-block;
          font-size: 1.4rem;
          margin: 5px 3px;
          padding: 10px;
          word-break: normal;
          white-space: nowrap;
          word-wrap: normal;

          span {
            border: 1px solid #eba3a3;
            border-radius: 15px;
            margin: 0 10px;
            padding: 2px 10px;
          }
        }

        div.added {
          display: none;
        }
      }
    }

    .connection {
      margin: 0 60px;

      div {
        margin-bottom: 10px;
      }

      &__content {
        div {
          border: 1px solid $color_border;
          border-radius: 15px;
          cursor: pointer;
          display: inline-block;
          padding: 10px;
          word-break: normal;
          white-space: nowrap;
          word-wrap: normal;
        }

        div.added {
          display: none;
        }
      }
    }
  }
}

.modalChoice__submit {
  height: 80px;
  text-align: center;
  border-top: 1px solid $color_subBorder;

  &--freeword {
    @extend .modalChoice__submit;
    border-top: none;
  }
}

.modalChoice__btn [class*="btn"] {
  margin-top: 15px;
  width: 30%;
}

.modalChoice__btn [class*="btn"] + [class*="btn"] {
  margin-left: 15px;
}

.modalChoice__list,
.modalChoice__result {
  float: left;
  width: 305px;
}

.modalChoice__list + .modalChoice__choiceContent,
.modalChoice__list + .modalChoice__btn {
  float: right;
  width: 645px;
}

.modalChoice__choiceContent {
  overflow-y: auto;
  overflow-x: hidden;
  height: 579px;

  &.suggestFreeword {
    height: 60vh;
    max-height: 579px;
    min-height: 400px;
    overflow: hidden;
  }
}

.modalChoice__list {
  position: absolute;
  height: inherit;
}

.modalChoice__list::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-right: 1px solid $color_subBorder;
}

.modalChoice__item {
  position: relative;
  z-index: 2;
  display: block;
  height: 36.2px;
  padding: 10px 14px;
  color: $color_keyColor;
  font-weight: bold;
  border-right: 1px solid $color_subBorder;
  border-bottom: 1px solid $color_subBorder;
  background: url(#{$img_path}arrowRight.png) no-repeat right 5px top 50%;
  background-size: 5px auto;

  &--side {
    padding: 10px 14px;

    li {
      font-size: 90%;
      padding: 5px 7px;

      .iconQuestion {
        position: absolute;
        margin-top: -3px;
        right: 11px;
        z-index: 500;
      }

      .tooltip {
        position: absolute;
        display: none;
      }

      .iconQuestion:hover + .tooltip {
        display: block;
        margin: 20px 0 0 -30px;
        z-index: 103;
      }
    }
  }
}

.modalChoice__item:hover {
  opacity: 0.5;
  text-decoration: none;
}

.modalChoice__item:nth-child(16) {
  border-bottom: none;
}

//選択中表示
.js__modal--menuSelected {
  background-image: none;
}

.modalChoice__item .labelSelected {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 5px;
  margin: 0;
  transform: translateY(-50%);
}

.js__modal--menuSelected .labelSelected {
  display: block;
}

//アクティブ表示
.js__modal--menuActive {
  color: $color_heading;
  cursor: default;
  border-right-color: $color_white;
}

.js__modal--menuActive::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: $color_keyColor;
}

.js__modal--menuActive:hover {
  opacity: 1;
}

.modalChoice__itemText {
  letter-spacing: -0.05em;
}

.modalChoice__result {
  white-space: normal;
  overflow-y: auto;
  overflow-x: hidden;
  height: 80px;
  padding: 20px 15px;
  font-size: 1.1rem;
  text-align: left;
  border-right: 1px solid $color_subBorder;
}

//言語選択
.modalChoice__heading--language {
  @extend .modalChoice__heading;
  padding: 20px 100px 0 20px;
  font-size: 2rem;
}

.modalChoice__content--language {
  overflow-y: auto;
  overflow-x: hidden;
  height: 548px;
}

.modalChoice__submit--language {
  @extend .modalChoice__submit;
  height: 111px;
}

.modalChoice__submit--language [class*="btn"] {
  margin-top: 30px;
}

/* モーダル キャリアシート
----------------------------------------------------------------- */
.modalCarrier {
  display: none;
  position: absolute;
  z-index: 101;
  width: 950px;
  padding: 0;
  background: #fff;
}

.modalCarrier .btnClose {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
}

.modalCarrier__heading {
  height: 56px;
  padding: 20px 100px 0 15px;
  color: $color_heading;
  font-size: 1.9rem;
  font-weight: bold;
  border-bottom: 1px solid $color_subBorder;
}

.modalCarrier__content {
  overflow: auto;
  height: 548px;
  font-size: 1.4rem;
}

.modalCarrier__subHeading {
  padding: 20px 0 0 30px;
  font-size: 2rem;
  font-weight: bold;
}

.modalCarrier__sectionTitle {
  margin-bottom: 20px;
  padding: 9px 15px;
  color: #333;
  font-weight: bold;
  background-color: #f0f0f3;
}

.modalCarrier__submit {
  height: 111px;
  padding-top: 30px;
  text-align: center;
  border-top: 1px solid $color_subBorder;
}

.modalCarrier__section {
  padding: 20px;
}

.modalCarrier__section + .modalCarrier__section {
  padding: 0 20px 20px;
}

.modalCarrier__sectionContent {
  padding-left: 20px;
  padding-right: 20px;
}

.modalCarrier__section .formTable {
  margin: 0;
}

.modalCarrier__section .formTable .formTable__head {
  padding: 10px 0 10px 16px;
  width: auto;
}

.modalCarrier__section .formTable .formTable__head span {
  font-size: 1.1rem;
  margin-left: 16px;
  margin-right: 16px;
}

.modalCarrier__section .formTable .formTable__body {
  padding-left: 20px;
}

.modalCarrier__section .formTable .formTable__head:first-child,
.modalCarrier__section .formTable .formTable__body:first-child {
  border-right: 1px solid #ddd;
}

.modalCarrier__section .formTable .formTable__head:first-child {
  width: 355px;
}

.modalMySeacrch {
  text-align: center;
}

.modalMySeacrch .selectS {
  font-size: 1.2rem;
  text-align: left;
  width: 300px;
}

.modalMySeacrch .select__text--placeholder {
  color: $color_text;
}

/* モーダル プレミアムスカウト
----------------------------------------------------------------- */
.modalPremium {
  @include modal($padding: 20px 0 30px);
}

.modalPremium__text {
  margin-top: 20px;
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.77;
  text-align: center;
}

.modalPremium .btnBox [class*="btn"] {
  width: 30%;
}

.modalPremium .linkList {
  text-align: center;
  padding-top: 4px;
  margin-bottom: 16px;
}

.modalPremium .linkArrow {
  font-size: 1.2rem;
}

/* モーダル 面接予定日が近い場合
----------------------------------------------------------------- */
.modalInterview {
  @include modal($padding: 20px 0 30px);

  &__text {
    margin-top: 20px;
    color: #333;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.77;
    text-align: center;
  }

  & .btnBox [class*="btn"] {
    width: 30%;
  }

  & .linkList {
    text-align: center;
    padding-top: 4px;
    margin-bottom: 16px;
  }

  & .linkArrow {
    font-size: 1.2rem;
  }

  &__heading {
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0 15px 15px;
    color: #00aaeb;
    text-align: left;
  }

  &__border {
    border: 1px solid $color_keyColor;
  }

  &__caption {
    text-align: center;
    font-size: 1.6rem;
    padding: 15px;
    font-weight: bold;
  }

  &__datetime {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10px;
  }

  &__annotation {
    text-align: center;
    font-size: 1.2rem;
    color: #acacac;
  }
}

/* モーダル 検索条件
----------------------------------------------------------------- */
.modalSearch {
  @include modal($padding: 30px 0 10px);
}

.modalSearch__text {
  color: $color_red;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.77;
  text-align: center;
}

.modalSearch__icon {
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  height: 49px;
  width: 49px;
}

.modalSearch__icon::before {
  background: url(#{$img_path}iconAttentionInfo.png) 0 0 no-repeat;
  content: "";
  display: inline-block;
  line-height: 1;
  height: 49px;
  width: 49px;
}

/* モーダル 面接応募日時確認
----------------------------------------------------------------- */
.modalInterviewApplicationDatetime {
  @include modal($padding: 30px 0 10px);

  &__text {
    color: $color_keyColor;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.77;
    text-align: center;
  }
}

/* トグル アコーディオン
----------------------------------------------------------------- */
.toggleAccordion__content {
  display: none;
  line-height: 1.42;
}

.toggleAccordion__trigger {
  padding-left: 10px;
  font-size: 1.2rem;
  background: url(#{$img_path}arrowDown.png) 0 center no-repeat;
}

//開いた状態
.toggleAccordion--open__content {
  display: block;
  height: auto;
  line-height: 1.42;
}

.toggleAccordion--open__trigger {
  display: none;
  padding-left: 10px;
  font-size: 1.2rem;
  background: url(#{$img_path}arrowUp.png) 0 center no-repeat;
}

/* 履歴書完成度グラフ
----------------------------------------------------------------- */
$graphs: 00, 05, 15, 25, 35, 45, 55, 65, 75, 85, 95, 100;

@each $graph in $graphs {
  .resumeGraph--#{$graph} {
    position: relative;
    z-index: 1;
    width: 184px;
    height: 184px;
    margin: 0 auto 30px;
    background: url(#{$img_path}graph#{$graph}.gif) 0 0 no-repeat;
  }
}

.resumetxt {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 80px;
  text-align: center;
  transform: translate(-50%, -50%);
}

.resumetxt span {
  font-size: 1.3rem;
  line-height: 1.5384;
}

.resumetxt strong {
  display: block;
  margin-top: 8px;
  font-size: 3rem;
  color: $color_red;
}

/* ツールチップ
----------------------------------------------------------------- */
.tooltip {
  position: relative;
  z-index: 1;
  width: 326px;
  line-height: 1.7;
  box-shadow: $boxShadow_defult;
}

.tooltip::before {
  content: "";
  position: absolute;
  z-index: 2;
  right: 30px;
  top: -39px;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 20px 7px;
  border-bottom-color: #e0eff5;
}

.tooltip::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 29px;
  top: -40px;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 20px 8px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.tooltip__content {
  position: relative;
  z-index: 2;
  padding: 20px;
  background-color: #e0eff5;
}

.tooltip__title {
  margin-bottom: 15px;
  color: #000;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.15;
}

.tooltip__text {
  font-size: 1.2rem;

  &.prPoint {
    b {
      font-weight: bold;
    }
  }
}

/* 前の要素集約する場合のアイコン
----------------------------------------------------------------- */
.gather {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}

.gather::after {
  content: "";
  display: block;
  width: 72px;
  height: 29px;
  margin: 0 auto 24px;
  background: url(#{$img_path}/arrowDownGray.png) left top no-repeat;
}

/* ローディング 画像
----------------------------------------------------------------- */
.loading {
  display: none;
  padding: 276px 0;
  text-align: center;
}

.nowLoading {
  @include loading($height: 15px, $top: -2px);
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 0 0 0 5px;
  padding: 0 30px;
  top: -5px;
  background-color: transparent;
}

.nowLoading span {
  display: block;
}

/* アイコンホバー ツールチップ
----------------------------------------------------------------- */
.iconTooltip {
  @include card($margin: 0, $bgColor: #e0eff5) {
    position: absolute;
    z-index: 1;
    display: none;
    padding: 0 4px;
    color: #333;
    font-size: 1.2rem;
    line-height: 1.6666;
  }
}

.iconTooltip::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 32px;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #e0eff5;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/* ツールチップ グレー
----------------------------------------------------------------- */
.tooltip--type2 {
  position: absolute;
  z-index: 1;
  top: -18px;
  right: 0;
  display: none;
  padding: 15px 20px;
  color: $color_white;
  font-size: 1.1rem;
  background-color: rgba(85, 85, 85, 0.5);
  transform: translateY(-100%);
}

.tooltip--type2::before {
  content: "";
  position: absolute;
  z-index: 2;
  right: 39px;
  bottom: -36px;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 18px 8px;
  border-top-color: rgba(85, 85, 85, 0.5);
}

/* サジェスト
----------------------------------------------------------------- */
.suggest {
  position: relative;
  display: inline-block;

  &__content {
    display: none;
    position: absolute;
    top: 80%;
    left: 22px;
    z-index: 10;
    padding: 10px 0;
    background-color: $color_white;
    border: 1px solid #{$color_border};

    a {
      display: block;
      padding: 10px;
      word-break: normal;
      white-space: nowrap;
      word-wrap: normal;
    }

    &--access {
      @extend .suggest__content;
      left: 0;
      max-height: 520px;
      overflow-y: auto;
      top: 33px;

      a.added {
        display: none;
      }
    }

    &--freeword {
      @extend .suggest__content;
      border-radius: 10px;
      color: inherit;
      font-size: 1.3rem;
      height: 45vh;
      left: 30px;
      max-height: 465px;
      min-height: 288px;
      overflow-y: auto;
      top: 68px;
      width: 890px;

      div {
        cursor: pointer;
        font-size: 1.4rem;
        padding: 12px 10px;
        word-break: normal;
        white-space: nowrap;
        word-wrap: normal;

        span {
          background-color: #eaeaea;
          border-radius: 5px;
          font-size: 1.1rem;
          margin: 0 10px;
          padding: 2px 10px;
        }
      }

      div.added {
        display: none;
      }
    }

    &.noContent {
      font-size: 1.2rem;
      padding: 10px;
    }

    &--companyList {
      @extend .suggest__content;
      top: 39px;
      left: 0;
    }
  }
}

.inputQualification + .suggest__content {
  left: 0;
}

.inputSchool + .suggest__content {
  left: 0;
}

.suggestCompanyList {
  width: 100%;
}

/* アイコン ？
----------------------------------------------------------------- */
.iconQuestion {
  @include iconFont($fontSize: 1.1rem, $icon: "\e916") {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    color: $color_white;
    background-color: $color_keyColor;
    border-radius: 10px;
    cursor: pointer;
  }

  &.t2 {
    top: -2px;
  }
}

.iconQuestion::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.iconQuestion:hover {
  opacity: 0.5;
}

.checkType {
  .iconQuestion:hover + .tooltip {
    display: block;
  }

  .tooltip {
    display: none;
    position: absolute;
    z-index: 1;
    width: 326px;
    line-height: 1.7;
    box-shadow: $boxShadow_defult;
    top: 40px;
  }

  .tooltip::before {
    content: "";
    position: absolute;
    z-index: 2;
    right: 50px;
    top: -39px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 20px 7px;
    border-bottom-color: #e0eff5;
  }

  .tooltip::after {
    content: "";
    position: absolute;
    z-index: 1;
    right: 49px;
    top: -40px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 20px 8px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
  }

  .tooltip__content {
    position: relative;
    z-index: 2;
    padding: 20px;
    background-color: #e0eff5;

    .tooltip__title {
      margin-bottom: 15px;
      color: #000;
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1.15;
    }

    .tooltip__text {
      font-size: 1.2rem;

      &.prPoint {
        b {
          font-weight: bold;
        }
      }
    }
  }
}

/* アイコン 送信
----------------------------------------------------------------- */
.iconSend {
  @include iconFont($fontSize: 1.4rem, $icon: "\e91f") {
    position: relative;
    display: inline-block;
    color: $color_disable02;
    font-size: 1rem;
  }
}

.iconSend::before {
  display: block;
  text-align: center;
}

/* アイコン 送信
----------------------------------------------------------------- */
.iconReceive {
  @include iconFont($fontSize: 1.4rem, $icon: "\e920") {
    position: relative;
    display: inline-block;
    color: $color_disable02;
    font-size: 1rem;
  }
}

.iconReceive::before {
  display: block;
  text-align: center;
}

/* アイコン バツ
----------------------------------------------------------------- */
.iconDelete {
  @include iconFont($fontSize: 1.3rem, $icon: "\e626") {
    position: relative;
    display: inline-block;
    color: $color_subText;
    font-size: 0;
  }
}

/* アイコン 返信
----------------------------------------------------------------- */
.iconReply {
  @include iconFont($fontSize: 1.2rem, $icon: "\e921") {
    position: relative;
    display: inline-block;
    color: $color_disable02;
    font-size: 0;
  }
}

/* アイコン 鍵
----------------------------------------------------------------- */
.iconKey {
  @include iconKey;
}

.iconKey::before {
  margin-right: 10px;
}

.iconShiori {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: url(#{$img_path}sprite.png) 0 -91px no-repeat;
}

.iconMan {
  display: inline-block;
  width: 42px;
  height: 47px;
  background: url(#{$img_path}sprite.png) -108px -91px no-repeat;
}

.iconNote {
  display: inline-block;
  width: 32px;
  height: 36px;
  background: url(#{$img_path}sprite.png) -36px -91px no-repeat;
}

.iconWrench {
  display: inline-block;
  width: 40px;
  height: 34px;
  background: url(#{$img_path}sprite.png) -68px -91px no-repeat;
}

.iconOther {
  display: inline-block;
  width: 34px;
  height: 6px;
  background: url(#{$img_path}sprite.png) -150px -91px no-repeat;
}

.iconSearchWhite {
  @include icon(0, 0, 0, 0 auto, 20px, 20px, -30px, -138px);
}

/* マッチングツールチップ
----------------------------------------------------------------- */
.tooltipMatching {
  position: relative;
  display: inline-block;
  height: 25px;
  color: #f00;
  font-size: 1.1rem;
  font-weight: normal;
  line-height: 1;
}

.tooltipMatching::before {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  z-index: 1;
  border: 6px solid transparent;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #f00;
}

.tooltipMatching::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  z-index: 1;
  border: 6px solid transparent;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #ffe5e5;
}

.tooltipMatching .iconFont--heart {
  display: block;
  height: 20px;
  padding: 3px 8px 0;
  white-space: nowrap;
  background-color: #ffe5e5;
  border: 1px solid #f00;
  border-radius: 4px;
}

.tooltipMatching .iconFont--heart::before {
  vertical-align: middle;
  font-size: 1rem;
}

/* マッチングツールチップ
----------------------------------------------------------------- */
.tooltipPickUp {
  margin: 20px auto 30px;
  text-align: center;
}

.tooltipPickUp__content {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 400px;
  padding: 20px;
  background-color: $color_keyColor;
}

.tooltipPickUp__content::after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: -12px;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: $color_keyColor;
  transform: rotate(45deg) translate(-50%, -50%);
}

.tooltipPickUp__title {
  color: $color_white;
  font-size: 1.8rem;
}

/* メッセージ送信確認
----------------------------------------------------------------- */
.js__modal--reply .text,
.js__modal--sendDate .text,
.js__modal--cancelDate .text,
.js__modal--confirmedCancelDate .text {
  word-wrap: break-word;
}

/* 人気求人
----------------------------------------------------------------- */
.popularContent {
  background-color: #f0f0f3;
}

.popularLableLine {
  margin: 20px 3px 5px 0;
  padding: 20px 0;
  width: 70%;
  border-bottom: 1px solid #aaa;
}

.popularMainBox {
  width: 950px;
  margin: 0 auto;
  padding: 20px 0;

  > .cassetteRecommend {
    margin-bottom: 40px;
  }

  .cassetteRecommend__item img {
    width: 194px;
    height: 145.5px;
    display: inline-block;
  }
}

/* 途中保存機能　履歴書内容確認BOX
----------------------------------------------------------------- */

.useNewResumeDesign {
  width: 670px;
  color: #333;

  .modal__container {
    margin-top: 20px;
    text-align: center;
  }
}

.useNewResumeDesignQuestion {
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
}

.selectBoxNewResume {
  float: left;

  .pullDown--bookmark {
    float: none;

    .pullDown__trigger::before {
      content: none;
    }
  }

  .pullDown__item {
    height: 50px;
    font-weight: 300;
    font-size: 1.4rem;
    text-align: center;

    div {
      height: 50px;

      p:nth-child(1) {
        padding: 5px 0 5px 0;
      }

      p:nth-child(2) {
        font-size: 1rem;
      }
    }
  }

  .selectBoxNewResumeWidth {
    width: 250px !important;
    height: 44px !important;
    min-width: 100px;
    line-height: 44px;
    padding: 0;
    text-align: center;
  }
}

/* フリーワード検索結果一覧　指定したキーワード表示箇所
----------------------------------------------------------------- */
.specifiedResult {
  float: left;

  .pullDown--bookmark {
    float: none;

    .pullDown__trigger::before {
      content: none;
    }
  }

  .pullDown__item {
    font-size: 1.4rem;
    font-weight: 300;
    height: 30px;
    text-align: center;

    div {
      height: 30px;

      p:nth-child(1) {
        padding: 5px 0 5px 0;
      }

      p:nth-child(2) {
        font-size: 1rem;
      }
    }
  }

  .specifiedResultWidth {
    height: 30px !important;
    line-height: 30px;
    min-width: 100px;
    padding: 0;
    text-align: center;
    width: 172px !important;
  }
}

.checkFormQuestion1,
.checkFormQuestion2 {
  width: 400px;
  text-align: center;
  padding: 3px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
}

.js__resumePageStatus {
  display: none;
}

/* 提携先転職エージェント一覧
----------------------------------------------------------------- */

.allCompany ul {
  display: flex;
  flex-wrap: wrap;
}

.allCompany ul li {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  -ms-flex-preferred-size: 45%;
  padding: 2px 0;
}

.allCompany ul li:nth-child(odd) {
  padding-right: 15px;
}

.noCompanyData {
  font-size: 16px;
  font-weight: 700;
  height: 40px;
}

.allCompanyTitle {
  display: flex;
  max-width: 950px;
  margin: 0 auto;

  :nth-child(1) {
    flex: 10;
  }

  :nth-child(2) {
    flex: 2;
    padding-top: 10px;
  }
}

/* 404ページ
----------------------------------------------------------------- */
.page404Body {
  .page404MiddleBox {
    :first-child {
      font-weight: 700;
    }

    p {
      text-align: center;
      margin-bottom: 5px;
      word-wrap: break-word;
    }
    margin-bottom: 25px;
  }

  .supplement {
    text-align: center;
  }

  .topHeader {
    padding-top: 0;
  }
  @media only screen and (min-width: 950px) {
    .wrapper {
      min-width: 1200px;
    }

    .breadcrumb__list {
      max-width: 1140px;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 950px) {
    .breadcrumb__list {
      margin: 0;
    }

    .topSubHeader {
      margin: 0 3%;
    }

    .topContainer {
      width: 100%;
    }

    .topHeader {
      margin: 0 3%;
    }

    .wrapper {
      min-width: 0;
    }

    .container {
      min-width: 0;
    }

    .container__inner {
      width: auto;
      margin: 0 3%;
    }

    .footerLower__corporate {
      width: 100%;
      margin: 0 3%;
    }
    min-width: 0;
  }
  @media only screen and (max-width: 820px) {
    .areaPullDownList__item {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .page404Alert {
      font-size: 1.6rem;
      height: 80px;
      line-height: 80px;
    }

    .supplement {
      text-align: left;
      padding: 0;
      background: none;
    }

    .page404MiddleBox p {
      text-align: left;
    }

    .topSubHeader {
      display: none;
    }

    .breadcrumb__list {
      display: none;
    }

    .container {
      background-color: #ebebeb;
      padding-top: 5px;
      box-shadow: inset 0 2px 2px rgba(0, 0, 0, .08);
    }

    .container__inner {
      margin: 0 4%;
    }

    .wrapper > .breadcrumb {
      border-bottom: none;
      border-top: 1px solid #00aaeb;
      height: 10px;
      box-shadow: 0 4px 0 0 rgba(0, 0, 0, .3);
    }

    .topHeader__main {
      padding-top: 13px;
    }

    .topHeader__logo {
      width: 142px;
      height: 25px;
      margin: 0 auto;
      float: none;
    }

    .page404BtnBlock {
      margin: 12px 0;
      background: #fff;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, .08);
      padding: 12px 0;

      .btnBox {
        margin: 0;
      }
    }
  }
}

/* パスワードの再設定のお知らせ
----------------------------------------------------------------- */
.resetPwdInfo {
  background-color: rgb(255, 248, 240);
  border: 2px solid rgb(255, 140, 141);
  border-radius: 4px;
  margin: 30px auto;
  padding: 10px;
  width: 100%;

  &__title {
    font-weight: 700;
  }

  &__link {
    &--red {
      color: #f00;
    }
  }
}

/* HeaderとContainerのセットは既存と違うので、合ってない所を修正する
----------------------------------------------------------------- */
.containerBorder {
  border-top: solid 1px #bbb;
}

/* 流動レイアウト 1140pxの親クラスの幅に合わせる用途
----------------------------------------------------------------- */
.fluid {
  max-width: 100% !important;
  width: 100% !important;
}

/* 「あなたの検索条件に近い求人」検索してみるボタンスタイル
----------------------------------------------------------------- */
.cassetteRecruit__content {
  .cassetteRecruit__heading {
    .btnSecondaryL {
      display: block;
      margin: auto;
      margin-top: 2rem;
      margin-bottom: 20px;
      width: fit-content;
    }
  }
}

/* フリーワード検索結果一覧画面　マージン独自調整
----------------------------------------------------------------- */
.specifiedTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.specifedResult-mb40 {
  margin-bottom: 40px;
}

.specifedResult-dlt-mb75 {
  margin-bottom: -75px;
}

.specifedResult-border {
  border: 1px solid #ddd;
}

/* 面接希望日時登録 */
.interviewSchedule {
  &Area {
    & .interviewApplicationDatetimeTable__head {
      font-size: 1rem;
      padding: 20px 0;
    }

    & .interviewApplicationDatetimeTable__date {
      font-size: 1rem;
    }
  }

  &__heading--title {
    font-size: 1.8rem;
  }

  &__header {
    color: $color_heading;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 18px;
  }

  &__timeTxt {
    margin-left: 5px;

    &--space {
      margin-left: 5px;
    }
  }

  &__otherDateTimeHead {
    margin-top: -3px;
  }

  &__otherDateTime {
    border-top: 0.5px solid #dde0e0;
    border-left: 0.5px solid #dde0e0;
    display: inline-flex;
    margin-top: 15px;
    margin-bottom: 3px;

    td {
      background-color: $color_keyColor_light;
      border-right: 0.5px solid #dde0e0;
      border-bottom: 0.5px solid #dde0e0;
      font-size: 12px;
      font-weight: 400;
      height: 36px;
      text-align: center;
      vertical-align: middle;
      width: 108px;
    }
  }

  &__dateTime {
    background: #f5f5f5;
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 4px;
    padding: 12px 0;
    padding-left: 12px;
  }

  &__entryJob {
    margin-bottom: 7px;
    margin-top: 12px;

    > .linkArrowS {
      color: #2db9f4;
      font-weight: 600;
    }
  }

  &__entryForm > .linkArrowS {
    color: #2db9f4;
    font-weight: 600;
  }

  &__selectionInfo {
    background: #e8f9ff;
    font-weight: 600;
    margin-top: 13px;
    padding-bottom: 12px;
    padding-top: 10px;
  }

  &__selectionInfo--txt {
    font-size: 1.2rem;
    margin: 0 auto;
    width: 76%;
  }

  &__messageBtn {
    background-color: $color_white;
    border: solid 1px;
    border-radius: 4px;
    color: $color_heading;
    display: block;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 auto;
    margin-top: 10px;
    padding: 8px 45px;
    width: fit-content;

    &:hover {
      opacity: .5;
      text-decoration: none;
    }
  }

  &__entryList {
    border-radius: 4px;
    color: #2db9f4;
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 auto;
    margin-top: 17px;
    text-decoration: none;
    width: fit-content;
  }

  &__entry--link {
    margin-top: -26px;
  }

  &__otherBtn {
    margin-top: 20px;
  }

  &__messageDetail {
    font-weight: 500;
    line-height: 19px;
    text-align: center;
  }

  &__btnBox {
    display: flex;
    justify-content: space-around;

    & button {
      font-size: 1.2rem;
      min-width: 230px;
    }
  }

  &__btnAnnotationBox {
    display: flex;
    justify-content: end;

    & .btnAnnotationBox__text {
      font-size: 1rem;
      padding: 0;
      min-width: 230px;
    }
  }
}

.btnBox.interviewSchedule__btnBox {
  > [class*=btn] + [class*=btn] {
    margin: 0;
  }
}

/* レコメンドエリア メッセージ管理/WEB履歴書　独自調整
----------------------------------------------------------------- */
.recommendArea {
  &--mailBox {
    background: #fafafb;
    line-height: 1.42;
    padding: 60px 0 20px;

    &__inner {
      margin: 0 auto;
      width: 950px;
    }
  }

  &--resume {
    padding: 80px 0 5px;
  }
}
