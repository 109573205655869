@charset "UTF-8";

.topNotice {
  $component: &;
  margin: 2rem 0;

  &--serious {
    border: 2px solid $color_red;
    padding: 16px 20px;

    a {
      @include onHover;
      color: inherit;
    }
  }

  &--alert {
    border: 1px solid $color_red;
    display: table;
    table-layout: fixed;
    width: 100%;

    #{$component}__header {
      background-color: #ffe5e5;
      color: $color_red;
      display: table-cell;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      width: 200px;
    }

    #{$component}__main {
      padding: 10px 20px;
    }

    a {
      @include onHover;
      @include ellipsis;
      color: inherit;
    }

    #{$component}__date {
      display: inline-block;
      font-weight: bold;
      width: 128px;
    }
  }

  &__main {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  &__item {
    @include ellipsis;

    a {
      @include dotArrowSmall;
      @include underlined;
    }
  }

  &__item + &__item {
    margin-top: 12px;
  }
}
