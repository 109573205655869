@charset "UTF-8";

.jobBreadcrumb {
  line-height: 1.42;
  background-color: #fff;

  &__list {
    width: 1140px;
    margin: 0 auto;
    padding: 20px 0;
    letter-spacing: -.4em;
    text-align: left;
  }
}
