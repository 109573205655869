@charset "UTF-8";

.topNowhaw {
  background-color: $top-cream-bg-color;
  display: flex;
  height: 17rem;

  &__header {
    background-image: url(#{$img-path-top}nowhaw.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin: 30px 20px;
    width: 240px;
  }

  &__title {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0 0 99.9% 99.9%);
    clip-path: inset(0 0 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
  }

  &__main {
    margin: 20px 20px 20px 0;
  }

  &__list {
    @include clearfix;
  }

  &__item {
    background-color: $color_white;
    box-shadow: 0 0 5px -2px #bc6363;
    border-radius: 8px;
    float: left;
    margin-right: 10px;
    height: 13rem;
    width: 13rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      @include onHover;
      display: block;
      height: 100%;
    }
  }

  &__stage {
    padding: 22px 34px 10px;
    width: 100%;
  }

  &__text {
    color: $color_heading;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 0 24px;
    position: relative;

    &::before {
      background: #fd6b6b;
      border-radius: 99999px;
      bottom: 2px;
      content: '';
      height: 20px;
      margin-top: -10px;
      position: absolute;
      right: 14px;
      width: 20px;
    }

    &::after {
      border-right: solid 2px #fff;
      border-top: solid 2px #fff;
      bottom: 9px;
      content: '';
      height: 6px;
      margin-top: -3px;
      position: absolute;
      right: 22px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 6px;
    }
  }
}
