@charset "UTF-8";

.jobNoList {
  width: 100%;
  display: inline-block;

  &__item {
    display: inline-block;
    padding: 20px 0;
    width: 100%;
  }

  &__title {
    color: #000;
    display: block;
    font-size: 1.6em;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
