@charset "UTF-8";

.partsArea {
  @include scoutBlock;
  margin: 0 auto 50px auto;

  &__title {
    font-size: 2rem;
    font-weight: $scout_bold;
    margin-bottom: 3rem;
    line-height: 1;
    letter-spacing: 0.05em;

    &-err {
      margin-bottom: 0;
    }
  }

  &__note {
    font-size: 1.4rem;
    font-weight: $scout_bold;
    color: $scout-note;
    line-height: 1.25;
  }

  &.agsError {
    @extend .includeError;
  }

  &__innerBox {
    text-align: center;
    padding: 30px;
  }

  &__resumeBtn {
    @include scoutBtn($width: auto, $background: $scout-lightblue-color, $padding: 14px 55px);
    margin-top: 30px;
    font-size: 1.7rem;
  }
}
