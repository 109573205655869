@charset "UTF-8";

.jobRegisterArea {
  background-color: #fffddc;
  padding: 20px;
  text-align: center;

  &__text {
    @include jobText;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
