@charset "UTF-8";

.topContent {
  @include clearfix;
  margin: 30px 0 47px 0;

  &__side {
    float: left;
    width: $top-column-side-width;
  }

  &__sideItem {
    display: block;
    margin-bottom: 30px;
  }

  &__sideHeader {
    background-color: #cfecfa;
    font-size: 1.6rem;
    line-height: 1.3em;
    padding: 40px 1rem 40px 1rem;
  }

  &__sideTitle {
    color: #333;
    font-weight: bold;
  }

  &__main {
    float: right;
    width: $top-column-main-width;
  }
}
