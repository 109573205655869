@charset "UTF-8";

/* card
----------------------------------------------------- */
.card {
  @include card;

  &__content {
    @include contentMargin;
    padding: 30px 28px 40px;

    &__bottomPad {
      padding-bottom: 20px;
    }
  }

  &__content + &__content {
    border-top: 1px solid #ddd;
  }

  &__content .textEm:first-child {
    padding-bottom: 20px;
  }

  &__content .btnBox:first-child {
    margin-top: 10px;
  }

  &__content:first-child .btnBox:first-child {
    margin-top: 0;
  }

  &__content > .imgCenter:first-child {
    margin-top: 10px;
  }

  &__content > form:first-child {
    @include contentMargin;
  }

  &__content form > .btnBox:last-child {
    margin-bottom: 0;
  }

  &__section {
    @include contentMargin;
    margin: 0;
    padding: 0;
  }

  &__section + &__section .headingCoachAdvice {
    margin: 24px 0 20px;
  }

  &.blueLine {
    border-top: 2px solid #00aaeb;
  }

  &__title {
    color: $color_keyColor;
    font-size: 20px;
    font-weight: 600;
    padding: 24px 0 14px 28px;
  }

  &__body {
    font-size: 14px;
    font-weight: 300;
    padding: 0 0 28px 28px;
  }

  &--noBackground {
    background: none;
    box-shadow: none;
  }
}

/* 設定一覧 コンテンツ
----------------------------------------------------- */
.card__settingContent {
  @extend .card__content;
  padding: 0 38px 40px;

  .banner {
    text-align: center;

    img {
      padding: 5px 0;
      width: 350px;
    }
  }
}

.card__settingContent .settingTable {
  margin-top: 20px;
}

.card__settingContent > .headingContent:first-child,
.card__settingContent > [class^=text]:first-child,
.card__settingContent > form:first-child,
.card__settingContent > .supplement:first-child {
  margin-top: 20px;
}

/* card 上ボーダー付き
----------------------------------------------------- */
.cardBorder {
  @extend .card;
  border-top: 2px solid $color_keyColor;
}

.cardBorder__content {
  @extend .card__content;
}

/* card 2カラム
----------------------------------------------------- */
.card2col {
  @extend .card;
  float: left;
  width: 465px;
}

.card2col:first-child {
  margin-right: 20px;
}

.card2col__content {
  @extend .card__content;
}

/* card内で縦に区切る場合で使用
----------------------------------------------------- */
.cardList {
  margin: 0;
}

.cardList__item {
  margin-bottom: 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #{$color_subBorder};
}

.cardList__item:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

/* card内で横に区切る場合で使用
----------------------------------------------------- */
.cardList--2col {
  @include clearfix;
}

.cardList--2col__item {
  float: left;
  width: 50%;
  border-right: 1px solid #{$color_subBorder};
}

.cardList--2col__item:nth-child(odd) {
  padding: 0 28px 0 0;
}

.cardList--2col__item:nth-child(even) {
  padding: 0 0 0 28px;
  border-right: none;
}

.cardList--2col__item .login {
  margin-bottom: 0;
}

/* SNS
------------------------------------------------------ */
.snsHeading {
  @include clearfix;
}

.snsHeading .headingPage {
  float: left;
}

.snsHeading .socialBtn {
  padding-top: 6px;
}

/* 補足
------------------------------------------------------ */
.supplement {
  margin: 20px 0;
  padding: 20px;
  line-height: 1.5;
  background-color: #f5f5f5;
}

.supplement__title {
  color: $color_red;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.44;
}

.supplement__text {
  font-size: 1.4rem;
  line-height: 1.28;
  word-wrap: break-word;
}

.supplement__textL {
  font-size: 2rem;
  word-wrap: break-word;
}

.supplement em {
  color: $color_red;
  font-weight: bold;
}

/* コーチ補足
------------------------------------------------------ */
.coachSupplement {
  @extend .supplement;
  line-height: 1.75;
}

.coachSupplement__title {
  @extend .supplement__title;
}

.coachSupplement__subTitle {
  margin-top: 30px;
  font-weight: bold;
}

/* カード全体をカードにする場合に使用
----------------------------------------------------- */
.cardImage {
  @include card() {
    display: inline-block;
    width: auto;
  }
}

.cardImage img {
  vertical-align: top;
}

/* 青背景の全幅モジュール。主にナビゲーションに使用する
----------------------------------------------------- */
.cardNavi {
  @include card($bgColor: #e0eff5);

  &__content {
    @extend .card__content;
  }

  &--blue {
    border-top: 4px solid #00aaeb;
  }
}

/* 広告的な見せ方の場合に使用する
----------------------------------------------------- */
.cardNotice {
  @include card() {
    border: 2px solid $color_keyColor;
  }
}

.cardNotice__content {
  @extend .card__content;
}

.cardNotice__row {
  @include clearfix;
}

.cardNotice__img {
  float: right;
  width: 280px;
  margin: 0 0 20px 20px;
}

.cardNotice__img img {
  vertical-align: top;
}

.cardNotice__contentTitle {
  margin: 0 auto 20px;
  padding: 0 15px;
  font-weight: bold;
  border-left: 4px solid #00aaeb;
}

.cardNotice__img--interview {
  float: left;
  width: 280px;
  margin: 0 20px 20px 0;
}

.cardNotice__img--interview + .cardNotice__profile {
  margin: 0 0 20px 300px;
}

.cardNotice__profile {
  margin: 0 0 20px;
}

.cardNotice__profile .text {
  padding: 20px 0;
  border-top: 1px solid $color_subBorder;
  border-bottom: 1px solid $color_subBorder;
}

/* 主に注記表示に使用する
----------------------------------------------------- */
.attention {
  @include card($bgColor: #ffe5e5);
}

.attention__content {
  @extend .card__content;
  overflow: hidden;
  padding-top: 20px;
}

.attention__icon {
  position: relative;
  z-index: 1;
  left: 50%;
  display: inline-block;
  padding-left: 70px;
  color: $color_red;
  font-weight: bold;
  transform: translateX(-50%);
}

.attention__icon::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 0;
  width: 53px;
  height: 41px;
  background: url(#{$img_path}iconAttentionMail.png) left top no-repeat;
}

.attention__title {
  padding-top: 20px;
  font-size: 1.8rem;
}

.attention__text {
  margin-bottom: 20px;
}

.attention .btnBox {
  margin-top: 0;
}

/* アラート
----------------------------------------------------- */
.alert {
  @include card($bgColor: #ffe5e5) {
    position: relative;
    z-index: 1;
    padding: 30px 28px;
    color: $color_red;
  }
}

.alert__title {
  position: relative;
  z-index: 1;
  padding-left: 80px;
  color: #f00;
  font-size: 2rem;
  font-weight: bold;
}

.alert__title::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 49px;
  height: 49px;
  background: url(#{$img_path}iconAttentionInfo.png) 0 0 no-repeat;
  transform: translateY(-50%);
}

.alert > .btnBox:last-child {
  margin-bottom: 0;
}

.alert--multiline {
  @extend .alert;
}

.alert--multiline__special {
  @extend .alert--multiline ;
  margin: 0 auto 30px;
}

.alert--multiline__title {
  @extend .alert__title;
}

.alert--multiline__title::before {
  top: 0;
  transform: translateY(0);
}

.alert--multiline__content {
  padding: 10px 0 0 80px;
}

.alert--multiline__text {
  font-weight: bold;
  line-height: 1.7;
}

/* アラート 変更アイコン
----------------------------------------------------- */
.alertChange {
  @extend .alert;
}

.alertChange__title {
  @extend .alert--multiline__title;
}

.alertChange__title::before {
  background: url(#{$img_path}iconRefresh.png) 0 0 no-repeat;
}

.alertChange__content {
  @extend .alert--multiline__content;
}

.alertChange__text {
  @extend .alert--multiline__text;
}

/* アラート 青
----------------------------------------------------- */
.caution {
  @extend .alert;
  color: $color_heading;
  background-color: #dfeff5;
}

.caution__title {
  @extend .alert__title;
  color: $color_heading;
}

.caution__title::before {
  background: url(#{$img_path}iconCaution.png) 0 0 no-repeat;
}

.caution--multiline {
  @extend .caution;
}

.caution--multiline__title {
  @extend .caution__title;
}

.caution--multiline__title::before {
  top: 0;
  transform: translateY(0);
}

.caution--multiline__content {
  @extend .alert--multiline__content;
}

.caution--multiline__text {
  @extend .alert--multiline__text;
  color: $color_heading;
}

//テキストのみ
.caution--textMultiline {
  @include card($bgColor: #dfeff5) {
    position: relative;
    padding: 30px 30px 30px 110px;
  }
}

.caution--textMultiline::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 30px;
  width: 49px;
  height: 49px;
  background: url(#{$img_path}iconCaution.png) 0 0 no-repeat;
}

.caution--textMultiline__text {
  color: $color_heading;
  font-weight: bold;
  line-height: 1.7;
}

//アラート 青 アイコンなし
.caution__title--noIcon {
  @extend .caution__title;
  padding: 0;
}

.caution__title--noIcon::before {
  content: none;
}

/* 注意 認証メール
----------------------------------------------------- */
.certificationMail {
  @include card() {
    padding: 40px 28px;
    text-align: center;
    border-top: 2px solid $color_keyColor;
  }
}

.certificationMail__icon {
  margin-bottom: 20px;
}

.certificationMail__icon img {
  vertical-align: top;
}

.certificationMail__text {
  margin-bottom: 20px;
}

/* カード内エラー
----------------------------------------------------- */
.errorList {
  display: none;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffe5e6;
}

.errorList__item {
  position: relative;
  z-index: 1;
  padding-left: 10px;
  color: #f00;
  font-size: 1.1rem;
  line-height: 1.5;
}

.errorList__item::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0.45em;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #f00;
  border-radius: 50%;
}

/* ページ下部追従要素
----------------------------------------------------- */
.fixedArea {
  $component: &;
  background-color: #dfeff5;
  bottom: 0;
  display: none;
  height: 84px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 95;

  &__content {
    @include clearfix;
    margin: 0 auto;
    position: relative;
    width: 850px;
  }

  &__chackJob,
  &__readiness {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    top: 50%;
    transform: translateY(50%);
    letter-spacing: -0.4em;
    position: absolute;
  }

  &__chackJob {
    left: 0;
  }

  &__readiness {
    right: 0;

    & .btnDefaultS {
      background-color: red;
      border-color: red;
      color: $color-white;
    }
  }
}

.fixedArea__chackJob .textSup,
.fixedArea__readiness .textSup {
  margin-bottom: 9px;
  letter-spacing: 0;
}

.fixedArea__chackJob .btnChackJob:first-of-type {
  margin-right: 4px;
}

.fixedArea__chackJob .btnChackJob:last-of-type {
  margin-left: 4px;
}

.fixedArea__readiness .btnPrimaryS {
  min-width: 280px;
  height: 33px;
  padding: 6.5px 10px;
  font-size: 1.4rem;
}

/* ページ下部追従要素 応募
----------------------------------------------------- */
.fixedArea--application {
  @extend .fixedArea;
}

.fixedArea--application__content {
  display: table;
  table-layout: fixed;
  width: 950px;
  margin: 0 auto;
}

.fixedArea--application__text,
.fixedArea--application__btn {
  display: table-cell;
  vertical-align: middle;
  height: 84px;
}

.fixedArea--application__text {
  width: 520px;
}

.fixedArea--application__btn {
  position: relative;
  letter-spacing: -0.4em;
}

.fixedArea--application__btn [class*="btn"] {
  vertical-align: middle;
}

.fixedArea--application__btn .btnDefaultS {
  position: relative;
  z-index: 2;
  min-width: 144px;
  padding: 15px 20px;
}

.fixedArea--application__btn .btnPrimaryS {
  min-width: 220px;
  margin-left: 20px;
  padding: 13px 20px;
  font-size: 1.7rem;
}

.fixedArea--application__btn .tooltip--type2 {
  top: 0;
  right: auto;
  left: -56px;
  letter-spacing: 0;
}

.fixedArea--application__btn .tooltip--type2::before {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

.fixedArea--application__btn .btnDefaultS:hover + .tooltip--type2 {
  display: block;
}

/* ページ下部追従要素 求人情報
----------------------------------------------------- */
.fixedArea--jobOfferInfo {
  @extend .fixedArea;
  height: auto;
}

.fixedArea--jobOfferInfo__content {
  display: table;
  table-layout: fixed;
  width: 950px;
  margin: 0 auto;
}

.fixedArea--jobOfferInfo__text,
.fixedArea--jobOfferInfo__btn {
  display: table-cell;
  vertical-align: middle;
}

.fixedArea--jobOfferInfo__text {
  width: 450px;
  padding: 14px 0;
}

.fixedArea--jobOfferInfo__text .textSS {
  margin: 0 0 5px;
  line-height: 1.45;
}

.fixedArea--jobOfferInfo__text .textBoldM {
  line-height: 1.56;
}

.fixedArea--jobOfferInfo__btn {
  padding: 0 0 0 40px;
  letter-spacing: -0.4em;
}

.fixedArea--jobOfferInfo__btn [class*="btn"] {
  max-width: 220px;
  min-width: 220px;
  letter-spacing: 0;
}

.fixedArea--jobOfferInfo__btn .btnInterst {
  margin: 0 0 0 20px;
}

/* ページ下部追従要素 MYコーチ
----------------------------------------------------- */
.fixedArea--myCoach {
  @extend .fixedArea;
}

.fixedArea--myCoach__content {
  width: 950px;
  margin: 0 auto;
  padding: 15px 0 0;
  text-align: center;
  letter-spacing: -0.4em;
}

.fixedArea--myCoach__content .btnPrimaryL {
  min-width: 220px;
}

.fixedArea--myCoach__content .btnDefaultM {
  min-width: 243px;
  height: 50px;
  margin-right: 20px;
  padding-top: 16px;
}

/* ページ下部追従要素 検索結果
----------------------------------------------------- */
.fixedArea--searchResult {
  @extend .fixedArea;
}

.fixedArea--searchResult__content {
  display: table;
  table-layout: fixed;
  width: 950px;
  margin: 0 auto;
}

.fixedArea--searchResult .result,
.fixedArea--searchResult__btn {
  display: table-cell;
  vertical-align: middle;
  height: 84px;
}

.fixedArea--searchResult .result {
  width: 400px;
}

.fixedArea--searchResult .result__info {
  margin: 0;
  padding: 0;
}

.fixedArea--searchResult__btn {
  position: relative;
  letter-spacing: -0.4em;
  text-align: right;
}

.fixedArea--searchResult__btn [class*="btn"] {
  vertical-align: middle;
  margin-left: 20px;
}

.fixedArea--searchResult__btn .btnDefaultS {
  position: relative;
  z-index: 1;
  min-width: 220px;
  padding: 15px 20px;
  font-size: 1.7rem;
}

.fixedArea--searchResult__btn .btnSearchCondition {
  min-width: 220px;
  padding: 15px 20px;
  font-size: 1.7rem;
}

.fixedArea--searchResult__btn .tooltip--type2 {
  z-index: 2;
  top: 10px;
  right: 23px;
  letter-spacing: 0;
}

.fixedArea--searchResult__btn .tooltip--type2::before {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

.fixedArea--searchResult .result .loading::after {
  top: -5px;
}

/* ページ下部追従要素 一括応募選択
----------------------------------------------------------------- */
.fixedBulkEntry {
  align-items: center;
  background: #ffffffba;
  bottom: 0;
  box-shadow: 0 4px 10px #00000026;
  display: none;
  height: 118px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 11;

  &__selectJobOffer {
    width: 100%;

    &--count {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 8px;
      margin-top: calc((118px - 79px) / 2);
      text-align: center;
    }

    &:has([style*="display: none"]),
    &:not(:has(.fixedBulkEntry__selectJobOffer--count)) {
      margin-top: calc((118px - 52px) / 2);
    }

    &Btn {
      background: $color_subText;
      border: 1px solid transparent;
      border-radius: 4px;
      box-shadow: 0 2px 0 0 rgba(111, 111, 111, 1);
      color: $color_white;
      display: block;
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.3;
      margin: 0 auto;
      margin-top: 46px;
      min-height: 48px;
      min-width: 351px;
      padding: 15px 20px;
      pointer-events: none;
      text-align: center;
      text-decoration: none;
      width: 351px;

      &.noMargin{
        margin-top: 0;
      }
    }
  }
}

// 一括応募ボタン活性状態
.selectJobOffer__btnActive {
  background: $color_red;
  box-shadow: 0 2px 0 0 rgba(149, 27, 27, 1);
  cursor: pointer;
  margin-top: 0;
  pointer-events: auto;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }
}

//気になる求人
.fixedBulkEntryBookmark {
  .fixedBulkEntry__selectJobOfferBtn {
    margin-top: 40px;
  }

  .selectJobOffer__btnActive {
    margin-top: 0;
  }

  .fixedBulkEntry__selectJobOffer--count {
    margin-top: calc((118px - 80px)/ 2);
  }
}

/* 追従ボタン 登録
----------------------------------------------------------------- */
.fixedEntryBtn {
  overflow: hidden;
  position: fixed;
  top: 235px;
  right: 0;
  z-index: 99;
  width: 37px;
  height: 228px;
  border-radius: 4px;
  box-shadow: $boxShadow_defult;
}

.fixedEntryBtn a {
  display: block;
}

/* スカウト対応状況に使用する
----------------------------------------------------- */
.situation {
  padding: 22px;
  color: $color_white;
  font-size: 1.7rem;
  font-weight: bold;
  background-color: rgba(51, 51, 51, 0.7);
}

/* btn 調整用枠
----------------------------------------------------- */
.btnBox {
  margin: 20px 0;
  text-align: center;
}

.btnBox .text--mb,
.btnBox .textCaution--mb {
  margin-bottom: 20px;
}

.btnBox .text--small {
  font-size: 1.1rem;
  margin-top: 20px;
}

.btnBox .text--left {
  text-align: left;
}

.btnBox > [class*="btn"] + [class*="btn"] {
  margin-left: 15px;
}

.btnBox > [class*="btn"] + .textNote {
  margin-top: 20px;
}

/* SNSボタン 調整用枠
----------------------------------------------------- */
.snsBtnBox {
  @extend .btnBox;

  & form {
    margin-bottom: 10px;

    &:last-child() {
      margin-bottom: 0;
    }
  }

  &--flex {
    display: flex;
    justify-content: center;

    & form {
      margin-bottom: 0;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* btn 調整用枠 2カラム
----------------------------------------------------- */
.btnBox--2col {
  margin: 20px auto;
  letter-spacing: -0.4em;
}

.btnBox--2col__item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-right: 7px;
  letter-spacing: 0;
}

.btnBox--2col__item:last-child {
  padding-left: 7px;
  padding-right: 0;
}

/* btn 調整用枠 並行
----------------------------------------------------- */
.btnBox--horizon {
  margin: 20px auto;
}

.btnBox--horizon [class*="btn"] {
  margin-bottom: 20px;
}

.btnBox--horizon [class*="btn"]:last-child {
  margin-bottom: 0;
}

/* ソート
----------------------------------------------------- */
.sort {
  letter-spacing: -0.4em;
}

.sort__heading {
  display: inline-block;
  vertical-align: top;
  font-size: 1.4rem;
  letter-spacing: 0;
  padding: 10px 10px 10px 0;
}

/* ページの先頭へ戻る
----------------------------------------------------- */
.link__top {
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 20px;
  width: 52px;
  height: 52px;
}

.link__top.mb-90 {
  bottom: 90px;
}

.link__top a:hover {
  opacity: 0.5;
}

/* js用クラス
----------------------------------------------------- */
.js--hidden {
  overflow: hidden;
}

/* 職種から探す
----------------------------------------------------- */
.jobSearch {
  line-height: 1.5;
  max-height: 350px;
  margin: 0 auto;
  width: 900px;
}

.jobSearch__title {
  clear: both;
  float: left;
  font-size: 1.2rem;
  font-weight: bold;
  height: 22px;
}

.jobSearch__item {
  font-size: 1.1rem;
  height: 22px;
  padding-left: 230px;
}

/* 都道府県リスト
----------------------------------------------------- */
.areaLinkList__item {
  margin-bottom: 14px;
  font-size: 1.2rem;
}

.areaLinkList__item span {
  display: inline-block;
  width: 61px;
}

.areaLinkList__item ul {
  display: inline-block;
  letter-spacing: -0.4em;
}

.areaLinkList__item li {
  display: inline-block;
  letter-spacing: 0;
  border-left: 1px solid #b5b5b5;
}

.areaLinkList__item li:first-child {
  border-left: none;
}

.areaLinkList__item a {
  padding: 0 9px;
}

.areaLinkList__item li:first-child a {
  padding-left: 0;
}

.areaLinkList__item li:last-child a {
  padding-right: 0;
}

.areaLinkList:last-child .areaLinkList__item:last-child {
  margin-bottom: 0;
}

/* 都道府県プルダウン
----------------------------------------------------- */
.areaPullDownList {
  position: relative;
  font-size: 0;
  width: 663px;
  letter-spacing: 0;

  &__header {
    width: auto;
  }

  & .areaNavi__link {
    padding: 2px 4px 3px 4px;

    &__sub {
      padding: 3px 0;
    }
  }

  & .areaNavi__list__sub {
    top: 15px;
    z-index: 999;
    color: $color_keyColor;
    background-color: #eaf6ff;
  }

  & .areaNavi__item {
    height: 15px;
  }
}

.areaPullDownList__item {
  display: inline-block;
  font-size: 1.5rem;
}

.areaPullDownList__item span {
  border-left: 1px solid #d0d0d0;
  padding: 0 10px;
}

.areaPullDownList__item a {
  display: inline-block;
  padding: 10px 0;
  text-decoration: none;
}

.areaPullDownList__item:hover > a,
.areaPullDownList__detail a:hover {
  background-color: $color_keyColor;
  color: $color_white;
}

.areaPullDownList__item__sub:hover > a,
.areaNavi__list__sub a:hover {
  opacity: 1;
  background-color: $color_keyColor;
  color: $color_white;
}

.areaPullDownList__item:hover .areaPullDownList__detail {
  display: block;
}

.areaPullDownList__item:nth-child(2):hover .areaPullDownList__detail {
  left: 57px;
}

.areaPullDownList__item:nth-child(3):hover .areaPullDownList__detail {
  left: 108px;
}

.areaPullDownList__item:nth-child(4):hover .areaPullDownList__detail {
  left: 174px;
}

.areaPullDownList__item:nth-child(5):hover .areaPullDownList__detail {
  left: 240px;
}

.areaPullDownList__item:nth-child(6):hover .areaPullDownList__detail {
  left: 306px;
}

.areaPullDownList__item:nth-child(7):hover .areaPullDownList__detail {
  left: 357px;
}

.areaPullDownList__item:nth-child(8):hover .areaPullDownList__detail {
  left: 369px;
}

.areaPullDownList__item:nth-child(9):hover .areaPullDownList__detail {
  left: 429px;
}

.areaPullDownList__item:nth-child(10):hover .areaPullDownList__detail {
  left: 476px;
}

.areaPullDownList__item:nth-child(11):hover .areaPullDownList__detail {
  left: 275px;
}

.areaPullDownList__item:nth-child(12):hover .areaPullDownList__detail {
  left: 200px;
}

.areaPullDownList__item:first-child span {
  padding: 0 6px;
  border-left: none;
}

.areaPullDownList__detail {
  position: absolute;
  top: 35px;
  left: 0;
  display: none;
  font-size: 0;
  background-color: $color_white;
}

.areaPullDownList__detail li {
  display: inline-block;
  font-size: 1.3rem;
}

.areaPullDownList__detail li:first-child span {
  border-left: none;
}

.areaPullDownList__detail a {
  display: block;
  padding: 8px 0;
  text-decoration: none;
}

.topAreaList {
  @extend .areaPullDownList;
}

.topAreaList__item {
  @extend .areaPullDownList__item;
  font-size: 1.2rem;
}

.topAreaList__detail {
  @extend .areaPullDownList__detail;
  background: #e0eff6;
}

.topAreaList__detail li {
  font-size: 1.2rem;
}

/* 検索
----------------------------------------------------- */
.search {
  @include boxCenter();
  position: relative;
  overflow: hidden;
  padding: 54px 0 74px;
}

.search__content {
  @include clearfix();
  border-bottom: 1px solid #99ddf7;
}

.search__content:last-child {
  border-bottom: none;
}

.search__title {
  float: left;
  width: 243px;
  margin: 39px 0 0;
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

.search__detail {
  float: left;
  width: 707px;
  padding-top: 25px;
}

.search .searchSelect {
  float: left;
}

.search__detail .linkArrow {
  font-size: 1.5rem;
}

.search__detail .columnList--2col li:nth-child(3) {
  letter-spacing: -1px;
}

/* 職種・勤務地から探す
----------------------------------------------------- */
.conditionSelect {
  @include clearfix();
}

.conditionSelect__text {
  clear: both;
  margin: 0 0 30px;
  background-position: 0 49%;
}

.conditionSelect .btnPrimaryM {
  @include iconFont($fontSize: 2rem, $icon: "\e623");
  float: right;
}

.conditionSelect .btnPrimaryM::before {
  margin-top: -2px;
  font-weight: normal;
}

.conditionSelect .btnPrimaryM img {
  vertical-align: middle;
  margin: 0 8px 0 0;
}

.conditionSelect__text .linkArrow {
  font-size: 1.2rem;
}

.conditionSelect .loading {
  @include loading($height: 15px, $top: 0);
  top: -8px;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 0;
  padding: 0 26px;
  background-color: transparent;
}

.conditionSelect .loading span {
  display: block;
}

.conditionSelect .loading > ::after {
  background-color: $color_white;
}

/* 職種・勤務地から探す ボタン
----------------------------------------------------- */
.searchSelect {
  width: 467px;
  margin-bottom: 17px;
  letter-spacing: 0;
}

.searchSelect__select {
  position: relative;
  vertical-align: top;
  display: inline-block;
  width: 213px;
  padding: 0 24px 0 14px;
  color: #666;
  font-size: 1.6rem;
  line-height: 3.1875;
  line-height: 2.6875;
  border: 1px solid $color_keyColor;
  border-radius: $borderRadius_defult;
  background: url(#{$img_path}arrowRight.png) right 14px top 50% no-repeat;
  background-color: $color_white;
  box-shadow: $boxShadow_defult;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.searchSelect__select:hover {
  opacity: 0.5;
  text-decoration: none;
}

.searchSelect__cross {
  content: '';
  display: inline-block;
  margin: 13px 6px;
  background: url(#{$img_path}sprite.png) -11px -138px no-repeat;
  width: 19px;
  height: 19px;
  text-align: center;
}

/* キーワードから探す
----------------------------------------------------- */
.searchKeyword {
  @include clearfix();
  position: relative;
  z-index: 1;
  margin: 0 0 30px;
  letter-spacing: 0;
}

.searchKeyword .inputText,
.searchKeyword .inputText--error {
  position: relative;
  z-index: 2;
  float: left;
  width: 464px;
  padding: 8px 5px;
}

.searchKeyword .btnSecondaryM {
  @include iconFont($fontSize: 1.7rem, $icon: "\e623") {
    float: left;
    min-height: 37px;
    margin: 0 0 0 21px;
    padding: 0;
  }
}

.searchKeyword .btnSecondaryM::before {
  margin-right: 8px;
  font-weight: normal;
}

/* 転職ノウハウ グロナビ
----------------------------------------------------- */
.knowhow {
  @include boxCenter();
  position: relative;
  padding-top: 30px;
}

.knowhow .columnList--3col {
  width: 726px;
}

.knowhow .linkArrow {
  font-size: 1.5rem;
}

/* 転職イベント グロナビ
----------------------------------------------------- */
.event {
  @include boxCenter();
  position: relative;
  padding: 30px 0 40px;
}

.event__content {
  border-bottom: 1px solid #99ddf7;
}

.event__content:last-child {
  border-bottom: none;
}

.event .columnList--3col {
  width: 726px;
}

.event .columnList--3col .linkArrow {
  font-size: 1.5rem;
}

.event__title {
  padding: 31px 0 19px;
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

.event__more {
  text-align: right;
}

.event__more .linkArrow {
  font-size: 1.5rem;
}

.event .btnList .btnDefaultS {
  width: 100%;
}

/* マイページ グロナビ
----------------------------------------------------- */
.myPage {
  @include boxCenter();
  position: relative;
  padding: 30px 0;
}

.myPage__content {
  @include clearfix();
  border-bottom: 1px solid #99ddf7;
}

.myPage__content:last-child {
  border-bottom: none;
}

.myPage__title {
  float: left;
  color: #000;
  width: 243px;
  padding: 0;
  margin: 16px 0 0;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
}

.myPage__detail {
  position: relative;
  float: left;
  width: 707px;
}

.myPage .btnList__item {
  width: 227px;
  min-height: 0;
  margin-bottom: 28px;
}

.myPage .btnList__item .btnDefaultS {
  width: 100%;
  min-height: 0;
  padding: 12px 10px;
  text-align: center;
}

.myPage .columnList--2col__item {
  width: 247px;
  margin-bottom: 34px;
}

.myPage .columnList {
  padding-top: 30px;
}

.myPage .columnList__item {
  margin: 0 36px 0 0;
}

.myPage .linkArrow {
  font-size: 1.5rem;
}

.myPage .btnDefaultS__numText {
  background-color: #f00;
  border-radius: 9px;
  color: #fff;
  font-size: 1.1rem;
  padding: 4px 6px;
  margin-left: 8px;
}

.myPage__text {
  margin: 24px 0 17px;
  color: #333;
  font-size: 1.5rem;
  line-height: 2;
}

.myPage__notes {
  margin: 0 0 27px;
  font-size: 1.1rem;
  line-height: 1.875;
}

.myPage [class^="resumeGraph"] {
  position: absolute;
  top: 22px;
  right: 0;
}

/* MY検索条件
----------------------------------------------------- */
.tableLayout {
  padding: 0 12px;
  position: relative;
}

.tableLayout [class*="iconFont"]::before {
  font-size: 1.3rem;
  margin-top: -2px;
  margin-right: 6px;
  vertical-align: middle;
}

.tableLayout__more--close {
  background-image: url(#{$img_path}shadeBottomWhite.png);
  background-repeat: repeat-x;
  padding-top: 50px;
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.tableLayout__more--open {
  text-align: center;
}

.card__bottom {
  border-top: 1px solid $color_subBorder;
  position: relative;
  padding: 30px 28px 40px;
  text-align: center;
}

.card__bottom .btnDelete {
  position: absolute;
  right: 20px;
}

.card__bottom .btnDefaultL {
  margin-bottom: 12px;
}

/* お知らせ
----------------------------------------------------- */
.notice {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 254px;
  font-size: 1.2rem;
  line-height: 1.6666;
}

.notice__item {
  padding: 15px 0;
  border-top: 1px solid #99ddf7;
}

.notice__item:first-child {
  border-top: none;
}

.notice__icon {
  display: inline-block;
  width: 80px;
  margin: 0 12px 0 0;
  padding: 4px 0;
  color: $color_white;
  text-align: center;
  background-color: $color_keyColor;
  vertical-align: middle;
}

.notice__text {
  display: inline-block;
  width: 650px;
  vertical-align: middle;
}

.notice__time {
  display: inline-block;
  vertical-align: middle;
}

/* ログイン
----------------------------------------------------- */
.login {
  width: 385px;
  margin: 20px auto;
}

.login__input {
  @include clearfix;
  margin-bottom: 6px;
}

.login__input label {
  float: left;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
}

.login__input:nth-child(1) label {
  padding-top: 2px;
  line-height: 1.3;
}

.login__input:nth-child(2) label {
  padding-top: 10px;
}

.login__input .inputText {
  float: right;
  width: 280px;
}

.login__input .linkArrowS {
  float: left;
}

.login__input .checkbox {
  float: right;
}

.login__input .checkbox__text {
  display: inline-block;
  padding-top: 2px;
}

.login .btnBox {
  @include clearfix;
  width: 290px;
  margin: 20px auto 0;
}

.login .btnBox > .linkArrowS {
  float: right;
  margin-top: 10px;
}

/* トップページ
----------------------------------------------------- */
.offerSearch {
  background-color: $color_white;
  border: 1px solid $color_subBorder;
  box-shadow: 0 1px 2px 0 #bbb6a8;
  margin: 40px auto 20px;
  padding: 30px 0 20px;
  width: 950px;
}

.offerSearch__title {
  border-bottom: 1px solid $color_subBorder;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 17px;
  margin-bottom: 22px;
}

.offerSearch__recruit {
  color: #000;
  display: inline-block;
  font-weight: bold;
  font-size: 2rem;
  margin-right: 15px;
}

.offerSearch__recruitNumber {
  color: $color_red;
  font-size: 2.8rem;
}

.offerSearch__lead {
  display: inline-block;
  position: relative;
}

.offerSearch__lead .tooltip {
  display: none;
  position: absolute;
  right: -30px;
  top: 34px;
}

.offerSearch__more {
  @extend .textSup;
  @include bulletLink(1.2rem);
  margin-top: 1.6em;
  float: right;
}

.offerSearch__content {
  padding-left: 28px;
  padding-right: 28px;
}

.offerSearch__searchSelect {
  display: inline-block;
}

.offerSearch__searchBtn {
  display: inline-block;
  float: right;
}

.offerSearch__searchBtn button {
  @include btn(inline-block, auto, 10px 0, 1.7rem);
  background-color: $color_red;
  color: $color_white;
  font-size: 1.4rem;
  width: 278px;
}

.offerSearch__searchBtn button .iconSearchWhite {
  left: -36px;
}

.offerSearch__searchPrevBtn button {
  background: none;
  border: 1px solid $color_keyColor;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 #bbb6a8;
  cursor: pointer;
  text-align: left;
  padding-top: 10px;
  height: 42px;
  width: 100%;
}

.offerSearch__searchPrevBtn button:hover {
  opacity: 0.5;
}

.offerSearch__searchPrevBtn button span {
  background: $color_keyColor;
  box-sizing: border-box;
  color: $color_white;
  display: inline-block;
  font-weight: bold;
  margin-top: -10px;
  margin-right: 14px;
  padding-top: 10px;
  text-align: center;
  height: 42px;
  width: 34%;
  float: left;
}

/* 求人情報
----------------------------------------------------- */
.jobQuestion {
  @include card;
  border: 2px solid $color_keyColor;
}

.jobQuestion__content {
  padding: 16px 88px;
}

.jobQuestion__title {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.jobQuestion__content .columnList__item {
  line-height: 3;
  margin-bottom: 0;
  min-width: 50%;
}

.jobQuestion__content [class*="label"] {
  margin-right: 16px;
}

/* スカウト WEB履歴書完成度
----------------------------------------------------- */
.resumeGraphScout {
  padding-top: 40px;
}

.resumeGraphScout .btnBox {
  margin-bottom: 20px;
}

.resumeGraphScout__link {
  padding: 30px 20px 40px;
  text-align: right;
  border-top: 1px solid #ddd;
}

/* 0件表示
----------------------------------------------------- */
.none {
  padding-top: 150px;
  display: none;
}

.none__title {
  color: #000;
  font-size: 1.8rem;
  line-height: 1.8;
  text-align: center;
}

/* エラー表示
----------------------------------------------------- */
.error {
  padding: 102px 0 50px;
  display: none;
}

.error__title {
  margin: 0 0 30px;
  font-size: 1.7rem;
  line-height: 1.7;
  text-align: center;
}

/* エラー表示
----------------------------------------------------- */
.errorConsideration {
  @extend .card;
  display: none;
}

.errorConsideration__content {
  @extend .card__content;
}

/* 画像センター寄せ
----------------------------------------------------- */
.imgCenter {
  margin: 20px 0;
  text-align: center;
}

.imgCenter img {
  vertical-align: top;
}

/* web履歴書
----------------------------------------------------- */
.webResume {
  @include contentMargin;
  position: relative;
  z-index: 1;
  padding: 20px 38px 40px;
}

.webResume__detail {
  position: relative;
  z-index: 1;
  width: 406px;
}

.webResume__detail::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 47px;
  background: url(#{$img_path}shadeTable.png) left bottom no-repeat;
}

.webResume__detail .table__head {
  width: 119px;
}

.webResume [class^="resumeGraph--"] {
  position: absolute;
  top: 20px;
  right: 38px;
}

/* 非公開企業設定
----------------------------------------------------- */
.privateCompany {
  @include contentMargin;
  padding: 30px 38px 40px;
}

.privateCompany__btnBox {
  margin-bottom: 30px;
  text-align: center;
}

.privateCompany .textEm {
  margin-bottom: 10px;
}

.privateCompany__add {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 20px 0 10px;
}

.privateCompany__addInput,
.privateCompany__addBtn {
  display: table-cell;
  vertical-align: middle;
}

.privateCompany__addInput {
  padding-right: 20px;
}

.privateCompany__textSup {
  margin-bottom: 40px;
  font-size: 1.2rem;
}

.privateCompany .list {
  border-top: 1px solid #{$color_subBorder};
}

.privateCompany .list__title {
  color: $color_text;
  font-size: 1.4rem;
  font-weight: normal;
}

.privateCompany__addBtn,
.privateCompany__addBtn [class*="btn"],
.privateCompany .btnDelete {
  width: 98px;
}

.privateCompany__addBtn [class*="btn"],
.privateCompany .btnDelete {
  padding: 6px 10px;
}

/* 追加削除要素 希望職種選択
----------------------------------------------------- */
.addCondition {
  @include clearfix;
}

.addCondition .btnAdd {
  float: right;
}

.addCondition__row {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}

.addCondition__col {
  display: table-cell;
  vertical-align: middle;
}

.addCondition__col:first-child {
  width: 50px;
  text-align: center;
}

.addCondition .iconFont--close {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.addCondition .iconFont--close::before {
  color: $color_text;
  font-size: 1.6rem;
}

.addCondition .iconFont--close:hover {
  text-decoration: none;
}

/* 追加削除要素 希望条件選択
----------------------------------------------------- */
.settingTable__addCondition .btnAdd {
  float: none;
  margin-left: 50px;
  padding: 7px 15px;
  min-width: 160px;
  min-height: 34px;
}

/* 追加削除要素
----------------------------------------------------- */
.addConditionList {
  @include clearfix;
}

.addConditionList__item {
  display: table;
  float: left;
  margin: 0 0 10px 20px;
}

.addConditionList__btn,
.addConditionList__text {
  display: table-cell;
  vertical-align: middle;
}

.addConditionList__text {
  padding: 10px;
  word-break: break-all;
  white-space: normal;
  word-wrap: break-word;
  background-color: #f5f5f5;
}

.addConditionList__btn {
  width: 25px;
}

.addConditionList__btn .iconFont--close {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.addConditionList__btn .iconFont--close::before {
  vertical-align: middle;
  color: $color_text;
  font-size: 1.5rem;
}

.addConditionList__btn .iconFont--close:hover {
  text-decoration: none;
}

/* 追加ファイル
----------------------------------------------------- */
.addFileList {
  @extend .addConditionList;
}

.addFileList__item {
  @extend .addConditionList__item;
}

.addFileList__text {
  @extend .addConditionList__text;
}

.addFileList__btn {
  @extend .addConditionList__btn;
}

/* 応募 条件追加 枠
----------------------------------------------------- */
.addCondition--application {
  @extend .addCondition;
}

.addCondition--application__row {
  @extend .addCondition__row;
}

.addCondition--application__col {
  @extend .addCondition__col;
}

.addCondition--application .btnAddS {
  margin-left: 50px;
}

.addCondition--application + .textSup {
  margin-top: 10px;
}

.addCondition--application + .formTable__row {
  margin-top: 10px;
}

.addCondition--application .formTable__error {
  margin-left: 50px;
}

/* 応募 条件追加 内容
----------------------------------------------------- */
.applicationSelect {
  padding: 10px 20px;
  background-color: #f5f5f5;
}

.applicationSelect__row {
  @include clearfix;
  clear: both;
  display: block;
  margin: 10px 0;
}

.applicationSelect__col {
  float: left;
  margin-left: 10px;
  letter-spacing: -0.4em;
}

.applicationSelect__col > * {
  letter-spacing: 0;
}

.applicationSelect__col .selectS {
  display: inline-block;
  vertical-align: middle;
}

.applicationSelect__col:first-child {
  margin-left: 0;
}

.applicationSelect__label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 1.2rem;
}

.applicationSelect__text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.applicationSelect__col > [class*='--disabled'] {
  opacity: 0.5;
}

/* 応募 経験企業
----------------------------------------------------- */
.experienceCompany .experienceCompany__item:first-child .headingBlock {
  padding-top: 10px;
}

.experienceCompany__content {
  @extend .card__content;
  padding-bottom: 20px;
}

.experienceCompany__add {
  padding: 0 28px 40px;
}

.experienceCompany__add [class*='btnAdd'],
.experienceCompany__add .textSup {
  vertical-align: middle;
}

.experienceCompany__add .textSup {
  margin-left: 20px;
}

.experienceCompany .coachResumeDetail__content {
  padding: 20px 28px;
}

.experienceCompany__myCoachAdvice {
  padding: 0 28px 40px;
}

.experienceCompany__myCoachAdvice .myCoachAdvice {
  margin: -20px 0 0;
}

.experienceCompany {
  .btnDelete--floatRight {
    margin-top: 25px;
    position: absolute;
    right: 30px;
  }

  &__item:first-child .btnDelete--floatRight {
    margin-top: 5px;
  }
}

/* 応募 希望勤務地
----------------------------------------------------- */
.workLocationCheckbox {
  margin: 0 0 -10px -10px;
}

.workLocationCheckbox .checkbox {
  margin: 0 0 10px 10px;
}

/* 応募 スキル
----------------------------------------------------- */
.skillList {
  @include clearfix;
  margin-left: -20px;
}

.skillList__item {
  float: left;
  width: 180px;
  margin: 0 0 10px 20px;
}

.skillList + .btnAddS {
  position: relative;
  z-index: 3;
}

/* 応募 スキル
----------------------------------------------------- */
.skill {
  @include clearfix;
  margin: 0 0 20px;
  text-align: left;
  border: 1px solid #{$color_subBorder};
  border-bottom: none;
}

.formTable__body > .skill:last-child {
  margin-bottom: 0;
}

.skill__heading,
.skill__content {
  border-bottom: 1px solid #{$color_subBorder};
}

.skill__heading {
  padding: 10px 20px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  line-height: 1;
  vertical-align: middle;
}

.skill__heading .btnAddS {
  margin-left: 20px;
  vertical-align: middle;
}

.skill__headingText {
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 126px);
}

.skill__content {
  padding: 20px;
  font-size: 1.3rem;
  line-height: 1.5;
  background-color: $color_white;
}

.skill__text {
  font-size: 1.2rem;
}

.skill__heading + .skill__content {
  padding: 10px 20px;
}

.skill__content--3col {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-bottom: 1px solid #{$color_subBorder};
}

.skill__content--3col__item {
  display: table-cell;
  vertical-align: top;
  padding: 10px;
  font-size: 1.2rem;
  line-height: 1.5;
}

.skill__content--3col__item:nth-child(1) {
  width: 175px;
  padding: 10px 10px 10px 20px;
  border-right: 1px solid #{$color_subBorder};
}

.skill__content--3col__item:nth-child(3) {
  width: 82px;
  border-left: 1px solid #{$color_subBorder};
}

.skill__textBold {
  font-weight: bold;
}

/* 応募 英語資格 追加
----------------------------------------------------- */
.addSkill {
  position: relative;
  z-index: 1;
  display: none;
  margin: 20px 0 0;
  border: 1px solid #{$color_subBorder};
}

.addSkill::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: -28px;
  left: 44px;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 14px 7px;
  border-bottom-color: $color_white;
}

.addSkill::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -29px;
  left: 43px;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 14px 8px;
  border-bottom-color: $color_subBorder;
}

.addSkill__content {
  position: relative;
  z-index: 2;
  padding: 20px;
  background-color: $color_white;
}

.addSkill__item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
}

.addSkill__item:last-child {
  margin-bottom: 0;
}

.addSkill__item .selectSS {
  margin-left: 20px;
}

/* 応募 内容確認
----------------------------------------------------- */
.applicationSubmit {
  @include contentMargin;
  margin: 20px 0;
  text-align: center;
}

.applicationSubmit__title {
  margin-bottom: 10px;
  color: $color_text;
  font-size: 2rem;
  font-weight: bold;
}

.applicationSubmit__title em {
  color: $color_keyColor;
  cursor: pointer;
}

.applicationSubmit__title em:hover {
  text-decoration: underline;
}

.applicationSubmit .textSup {
  margin-bottom: 20px;
}

.applicationSubmit .textCaution {
  margin: 20px 0;
}

.applicationSubmit__btn {
  position: relative;
  display: inline-block;
}

.applicationSubmit__loading {
  @include loading($height: 15px, $top: 9px);
}

.applicationSubmit label {
  cursor: pointer;
}

/* 応募 データ保存
----------------------------------------------------- */
.applicationSave {
  @include clearfix;
  margin: 50px 0 20px;
}

.applicationSave__call {
  float: left;
}

.applicationSave__save {
  position: relative;
  float: right;
  text-align: right;
}

.applicationSave .btnDefaultS {
  margin-bottom: 10px;
}

.applicationSave__save .btnDefaultS {
  position: relative;
  z-index: 2;
}

.applicationSave__save .btnDefaultS:hover + .tooltip--type2 {
  display: block;
}

.applicationSave__save .iconQuestion {
  position: relative;
  z-index: 2;
}

.applicationSave__save .iconQuestion:hover + .tooltip {
  display: block;
}

.applicationSave__save .tooltip {
  position: absolute;
  right: -27px;
  bottom: -19px;
  display: none;
  width: 505px;
  transform: translateY(100%);
}

.applicationSave__save .tooltip__content {
  padding: 10px;
  text-align: left;
}

.applicationSave__save .tooltip__text {
  font-size: 1.1rem;
}

/* 規約同意
----------------------------------------------------- */
.agreement {
  display: block;
  width: 460px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffe5e5;
}

.agreement .labelRequired {
  margin-right: 20px;
}

.agreement .checkbox {
  margin-right: 8px;
}

.agreement label:hover {
  cursor: pointer;
}

/* スカウト特典
----------------------------------------------------- */
.scoutBenefit {
  @include contentMargin;
  padding: 20px 20px 40px;
}

.scoutBenefit .labelPremiumBenefit {
  display: block;
  margin: 0 8px;
}

.scoutBenefit .labelPremiumBenefit:first-child:last-child {
  margin-bottom: 0;
}

.scoutBenefit .labelPremiumBenefit__title {
  width: 102px;
}

.scoutBenefit .labelPremiumBenefit__title,
.scoutBenefit .labelPremiumBenefit__text {
  display: table-cell;
  vertical-align: middle;
}

.scoutBenefitList {
  letter-spacing: -0.4em;
}

.scoutBenefit__item {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  width: 33.3%;
  padding: 16px 8px 0;
  letter-spacing: 0;
}

$scoutBenefit: 13;

@for $i from 1 through $scoutBenefit {
  .scoutBenefit__item:nth-child(#{$i}) {
    z-index: $scoutBenefit - $i;
  }
}

.scoutBenefit__item .labelScout {
  position: relative;
  z-index: 2;
  display: block;
  cursor: pointer;
}

.scoutBenefit__item .labelScout:hover + .tooltip {
  display: block;
}

.scoutBenefit__item .tooltip {
  position: absolute;
  z-index: 1;
  top: 58px;
  left: 50%;
  display: none;
  transform: translateX(-50%);
}

.scoutBenefit__item .tooltip::before,
.scoutBenefit__item .tooltip::after {
  right: 50%;
  transform: translateX(50%);
}

/* スカウト 詳細 説明
----------------------------------------------------- */
.description {
  @extend .card;
}

.description__content {
  @extend .card__content;
  @include clearfix;
  word-break: break-all;
}

.description__photo {
  float: right;
  margin: 0 0 30px 30px;
}

.description__link {
  text-align: right;
}

.description .text {
  margin-bottom: 30px;
}

.description .text:last-child {
  margin-bottom: 0;
}

/* スカウト 詳細 注意
----------------------------------------------------- */
.scoutAttention .textEm {
  margin-bottom: 5px;
}

.scoutAttention .unorderedList__item {
  font-size: 1.2rem;
}

/* スカウト 詳細 掲載終了
----------------------------------------------------- */
.scoutExpiration__text {
  margin-bottom: 4px;
  padding-left: 10px;
  font-size: 1.2rem;
  background: url(#{$img_path}arrowDownDisable.png) left center no-repeat;
}

/* 応募準備完了
----------------------------------------------------- */
.readyJob {
  background-color: #f0f0f3;
}

.readyJob__contnet {
  width: 950px;
  margin: 0 auto;
  padding: 30px 0 40px;
}

.readyJob__contnet > .text,
.readyJob__title {
  margin-bottom: 20px;
}

.readyJob__title,
.readyJob__title .text {
  color: $color_heading;
  font-size: 1.6rem;
}

.readyJob__title .labelReadiness,
.readyJob__title .textBoldM,
.readyJob__title .text {
  vertical-align: middle;
}

.readyJob__list {
  @include clearfix;
}

.readyJob__item {
  float: left;
  display: block;
  width: 174px;
  height: 135px;
  margin-left: 20px;
  padding: 12px;
  background-color: $color_white;
  box-shadow: $boxShadow_defult;
}

.readyJob__item:hover {
  text-decoration: none;
}

.readyJob__item:hover .readyJob__itemJob,
.readyJob__item:hover .readyJob__itemDetail {
  opacity: 0.5;
}

.readyJob__item:first-child {
  margin-left: 0;
}

.readyJob__itemJob {
  color: $color_keyColor;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.53;
}

.readyJob__itemDetail {
  overflow: hidden;
  color: $color_text;
  font-size: 1.2rem;
  line-height: 1.5;
}

/* 内容確認 ローディング
----------------------------------------------------- */
.itemConf {
  margin: 20px 0;
  text-align: center;
}

.itemConf .textCaution {
  margin-bottom: 20px;
}

.itemConf__btn {
  position: relative;
  display: inline-block;
}

.itemConf__loading {
  @include loading($height: 15px, $top: 9px);
}

/* 途中保存 件数
----------------------------------------------------- */
.result {
  margin: 20px 0 30px;
  border-bottom: 1px solid #{$color_subBorder};
}

.result.mt50 {
  margin: 50px 0 0;
}

.container__inner .result {
  border-bottom: none;
}

.result__info {
  @include clearfix;
  width: 950px;
  margin: 0 auto;
  padding: 0 0 20px;
}

.result__num,
.result__noApplicationNum,
.result .sort {
  display: inline-block;
  vertical-align: middle;
}

.result__num {
  color: $color_heading;
  font-size: 2rem;
  font-weight: bold;
  margin-right: 20px;
}

.result__num em {
  color: $color_red;
  font-size: 2.8rem;
}

.result__num span {
  margin-left: 8px;
}

.result__num em span {
  margin: 0;
}

.result__noApplicationNum {
  margin-right: 4px;
  color: $color_heading;
  font-size: 1.6rem;
  font-weight: bold;
}

.result__noApplicationNum .textSup {
  color: $color_text;
  font-weight: normal;
}

.result__noApplicationNum em {
  margin: 0 8px;
  color: $color_red;
}

.result .sort {
  float: right;
}

.result .pullDown__list {
  min-width: 200px;
}

.result [class*="pullDown__item"] a {
  width: 100%;
}

.result .textRightBox {
  float: right;
}

.result .loading {
  @include loading($height: 15px, $top: -2px);
  position: relative;
  top: 3px;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 0 0 0 5px;
  padding: 0 30px;
  background-color: transparent;
}

.result .loading span {
  display: block;
}

/* 途中保存一覧
----------------------------------------------------- */
.storedData__item {
  margin: 20px 0;
}

.storedData__date {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

/* 設定 完了
----------------------------------------------------- */
.settingDone {
  @extend .card;
  text-align: center;
}

.settingDone__content {
  @extend .card__content;
  margin: 0 28px;
  padding: 20px;
}

.settingDone__content:last-child {
  padding: 20px 28px 40px;
}

.settingDone .headingBlock {
  color: $color_keyColor;
}

.settingDone__check {
  width: 210px;
  margin: 20px auto;
  text-align: left;
}

/* コーチ ステップ
----------------------------------------------------- */
.stepCoach {
  overflow: hidden;
}

.stepCoach__item {
  float: left;
  margin-bottom: 20px;
}

.stepCoach__item img {
  vertical-align: top;
}

/* WEB履歴書コーチ アドバイス
----------------------------------------------------- */
.coachResume {
  @extend .card;
}

.coachResume__head {
  @include heading() {
    margin: 0 auto;
    padding: 30px 28px 20px;
    font-size: 2rem;
    color: #33a801;
    border-top: 2px solid #33a801;
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f5;
  }
}

.coachResume__content {
  @extend .card__content;
  padding-top: 20px;
}

.coachResume__content > .text {
  margin-bottom: 20px;
}

.coachResume__offerDate,
.coachResume__receiveDate {
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: right;
}

.coachResume__advice {
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 1px solid #{$color_subBorder};
}

.coachResume__person,
.coachResume__review {
  display: table-cell;
  vertical-align: top;
}

.coachResume__person {
  width: 147px;
  padding: 20px;
  background-color: #f5f5f5;
}

.coachResume__personIcon {
  width: 108px;
  height: 108px;
  margin-bottom: 10px;
}

.coachResume__personIcon img {
  vertical-align: top;
}

.coachResume__personText {
  color: $color_heading;
  font-size: 1.2rem;
  font-weight: bold;
}

.coachResume__personName {
  color: $color_heading;
  font-size: 1.4rem;
  font-weight: bold;
}

.coachResume__review {
  padding: 20px;
}

.coachResume__reviewTitle {
  margin-bottom: 10px;
  color: $color_red;
  font-size: 1.2rem;
}

.coachResume__review .text + .text {
  margin-top: 20px;
}

/* WEB履歴書コーチ タグ規定
----------------------------------------------------- */
.inputArea {
  line-height: 1.8;
}

.inputArea h4 {
  @include heading() {
    margin: 0 auto 20px;
    padding: 2px 15px 0;
    color: $color_text;
    font-size: 1.4rem;
    border-left: 4px solid $color_keyColor;
  }
}

.inputArea em {
  font-size: 1.4rem;
  font-weight: bold;
}

.inputArea u {
  color: $color_red;
}

.inputArea strong {
  background-color: #f9ec83;
}

.inputArea blockquote {
  background-color: $color_white;
  border: 1px solid #{$color_border};
}

/* ○○から探す
----------------------------------------------------- */
.jobChoice--2col {
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 20px 0;
  margin: 20px -20px 0;
  width: 930px;
}

.jobChoice--2col__item {
  border: 1px solid $color_subBorder;
  display: table-cell;
  width: 50%;
}

.jobChoice__title {
  color: $color_keyColor;
  font-size: 1.6rem;
  font-weight: bold;
}

.jobChoice__title .labelNumber,
.jobChoice__sectionTitle .labelNumber {
  vertical-align: middle;
}

.jobChoice__sectionTitle {
  background-color: #f0f0f3;
  color: $color_keyColor;
  padding: 16px 12px;
  font-weight: bold;
}

.jobChoice__sectionTitle .checkbox__text,
.jobChoice__sectionTitle .radio__text {
  position: relative;
  top: 0;
}

.jobChoice__sectionTitle .labelNumber {
  position: relative;
  top: 0;
}

.jobChoice__sectionTitle .textCaution {
  font-size: 1.2rem;
  margin-left: 4px;
  margin-right: 4px;
}

.jobChoice__list {
  padding-bottom: 18px;
}

.jobChoice__item {
  color: $color_keyColor;
  display: block;
  padding: 20px 5px 0 12px;
  letter-spacing: 0;
}

.jobChoice > .jobChoice__item:first-child {
  padding: 0 5px 0 15px;
}

.jobChoice__item .checkbox > input,
.jobChoice__item .checkbox--disabled > input,
.jobChoice__item .radio > input,
.jobChoice__item .radio--disabled > input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.jobChoice__item .checkbox__text,
.jobChoice__item .radio__text {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 26px;
}

.jobChoice__item a:hover,
.jobChoice__item a:hover .checkbox__text {
  text-decoration: underline;
}

.jobChoice__item [class*="disabled"] {
  color: $color_text;
}

/* WEB履歴書コーチ 履歴書
----------------------------------------------------- */
.coachResumeDetail__content {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.coachResumeDetail__table {
  width: 514px;
  padding-right: 20px;

  &.coachResumeDetail__table--selfIntroduction {
    padding-right: 0;

    & .formTable {
      border: 0;
      height: 1px;
      width: 100%;

      & .formTable__head {
        border-bottom: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid transparent;
        width: 194px;

        &:first-of-type {
          border-top: 1px solid #ddd;
        }
      }

      & .formTable__body {
        border-bottom: 1px solid #ddd;
        border-left: 1px solid transparent;
        border-right: 1px solid #ddd;
        vertical-align: top;
        width: 291px;

        &:first-of-type {
          border-top: 1px solid #ddd;
        }
      }

      & .formTable__body--advice {
        padding-left: 25px;
        width: 402px;

        & .myCoachAdvice {
          display: block;
          height: 100%;
        }
      }
    }
  }
}

.coachResumeDetail__table,
.coachResumeDetail__content .myCoachAdvice {
  display: table-cell;
  vertical-align: top;
}

.coachResumeDetail__table .formTable {
  width: 490px;
  margin: 0;
}

.coachResumeDetail .formTable__head,
.coachResumeDetail .formTable__head--low {
  width: 196px;
}

.coachResumeDetail .formTable__body {
  padding: 20px;
}

.coachResumeDetail__content .myCoachAdvice {
  width: 380px;
  margin: 0;
}

.coachResumeDetail__table .skill__content--3col__item:nth-child(1) {
  padding: 10px;
  width: 75px;
}

.coachResumeDetail__table .skill__content--3col__item:nth-child(2) {
  width: 82px;
}

.coachResumeDetail__content .experienceCompany__content {
  padding: 0;
}

.coachResumeDetail__myCoachAdvice {
  padding: 0 28px 40px;
}

.coachResumeDetail__myCoachAdvice .myCoachAdvice {
  margin: -10px 0 0;
}

.coachResumeDetail [class*="inputAddress"] {
  width: 186px;
}

.coachResumeDetail [class*="inputAddress"] + .formTable__label,
.coachResumeDetail [class*="inputTel"] + .formTable__label {
  margin: 0 -20px 0 0;
}

.coachResumeDetail [class*="inputTextM"],
.coachResumeDetail [class*="inputSchool"],
.coachResumeDetail [class*="inputCompany"],
.coachResumeDetail [class*="selectExperienceJob"] {
  width: 100%;
}

.coachResumeDetail [class*="inputSchool"] {
  width: 212px;
}

.coachResumeDetail__applicationSelect .applicationSelect__row:nth-child(3),
.coachResumeDetail__applicationSelect .applicationSelect__row:nth-child(5) {
  margin-bottom: 20px;
}

.coachResumeDetail__applicationSelect .applicationSelect {
  margin-bottom: 10px;
  padding: 10px 15px;
}

.coachResumeDetail .formTable .addCondition--application {
  margin-left: -15px;
}

.coachResumeDetail .formTable .addCondition--application + .textSup {
  margin-left: 35px;
}

.coachResumeDetail .addSkill__item .selectSS {
  display: block;
  margin: 10px 0 0;
}

.coachResumeDetail__applicationSelect {
  display: table;
  table-layout: fixed;
  width: calc(100% + 10px);
  margin: 0 0 10px -10px;
}

.coachResumeDetail__applicationSelect .applicationSelect,
.coachResumeDetail__close {
  display: table-cell;
  vertical-align: middle;
}

.coachResumeDetail__close {
  width: 25px;
}

.coachResumeDetail__close .iconFont--close {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.coachResumeDetail__close .iconFont--close:hover {
  text-decoration: none;
}

.coachResumeDetail__close .iconFont--close::before {
  color: #555;
  font-size: 1.6rem;
}

.coachResumeDetail__applicationSelect + .btnAddS {
  margin: 0 0 0 15px;
}

/* WEB履歴書コーチ MYコーチアドバイス
----------------------------------------------------- */
.myCoachAdvice {
  margin: 20px 0;
  padding: 20px;
  background-color: rgba(51, 168, 1, 0.1);
}

.myCoachAdvice__title {
  margin-bottom: 20px;
}

.myCoachAdvice__content {
  line-height: 1.8;
}

.myCoachAdvice__content + .myCoachAdvice__content {
  padding-top: 10px;
}

.myCoachAdvice__title span {
  padding: 10px 20px;
  color: $color_white;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #33a801;
}

.myCoachAdvice .text {
  margin-bottom: 20px;
}

/* 転職MYコーチ 求人紹介
----------------------------------------------------- */
.coachRecruit {
  @extend .coachResume;
}

.coachRecruit__head {
  @include heading() {
    margin: 0 auto;
    padding: 30px 28px 20px;
    font-size: 2rem;
    color: #0068b8;
    border-top: 2px solid #0068b8;
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f5;
  }
}

.coachRecruit__content {
  @extend .card__content;
  padding-top: 20px;
}

.coachRecruit__content > .text {
  margin-bottom: 20px;
}

.coachRecruit__content .textCaution--one:nth-of-type(3) {
  margin-bottom: 20px;
}

.coachRecruit__tag {
  background-color: #0068b8;
  color: $color_white;
  padding: 8px 20px;
  float: left;
}

.coachRecruit__offerDate,
.coachRecruit__receiveDate {
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: right;
}

.coachRecruit__advice {
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 1px solid #{$color_subBorder};
}

.coachRecruit__person,
.coachRecruit__review {
  display: table-cell;
  vertical-align: top;
}

.coachRecruit__person {
  width: 147px;
  padding: 20px;
  background-color: #f5f5f5;
}

.coachRecruit__personIcon {
  width: 108px;
  height: 108px;
  margin-bottom: 10px;
}

.coachRecruit__personIcon img {
  vertical-align: top;
}

.coachRecruit__personText {
  color: $color_heading;
  font-size: 1.2rem;
  font-weight: bold;
}

.coachRecruit__personName {
  color: $color_heading;
  font-size: 1.4rem;
  font-weight: bold;
}

.coachRecruit__review {
  padding: 20px;
}

.coachRecruit__reviewTitle {
  margin-bottom: 10px;
  color: $color_red;
  font-size: 1.2rem;
}

.coachRecruit__reviewDetailTitle {
  @include heading() {
    margin: 0 auto 20px;
    padding: 2px 15px 0;
    color: $color_text;
    font-size: 1.4rem;
    border-left: 4px solid $color_keyColor;
  }
}

.coachRecruit__review .text,
.coachRecruit__review .textBorder {
  line-height: 1.8;
}

.coachRecruit__review .text + .text {
  margin-top: 20px;
}

/* 転職コラム リンク
----------------------------------------------------- */
.columnArticle {
  padding: 30px;
  background-color: #f5f5f5;
}

.columnArticle__title {
  margin-bottom: 30px;
  color: $color_heading;
  font-weight: bold;
}

.columnArticle .orderedList {
  font-size: 1.4rem;
  line-height: 1.7;
}

.columnArticle .orderedList__item {
  margin-bottom: 30px;
}

.columnArticle .orderedList__item:last-child {
  margin-bottom: 0;
}

/* アドバイス　アンケート
----------------------------------------------------- */
.adviceQuestionnaire {
  @extend .card;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 30px 28px 40px;
}

.adviceQuestionnaire__title {
  margin-bottom: 20px;
  color: $color_heading;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
}

.adviceQuestionnaire__content {
  display: table-cell;
  vertical-align: top;
  width: 50%;
  border-right: 1px solid #{$color_subBorder};
}

.adviceQuestionnaire__subTitle {
  clear: both;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: bold;
}

.adviceQuestionnaire__section > [class*="select"] {
  margin-bottom: 20px;
}

.adviceQuestionnaire__section:last-child > [class*="select"] {
  margin-bottom: 10px;
}

.adviceQuestionnaire__content:nth-child(1) {
  padding: 0 28px 0 0;
}

.adviceQuestionnaire__content:nth-child(2) {
  padding: 40px 0 0 28px;
  border-right: none;
}

.adviceQuestionnaire__checkbox {
  float: left;
  margin: 10px 30px 24px 0;
}

.adviceQuestionnaire__content [class*="textArea"] {
  margin-bottom: 25px;
}

.adviceQuestionnaire__content .textNote {
  line-height: 1.7;
}

/* 転職希望条件
----------------------------------------------------- */
.requirementConf {
  @extend .card__section;
}

.requirementConf__date {
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: right;
}

.requirementConf__content {
  @extend .card__content;
}

.requirementConf__content .formTable {
  margin: 0;
}

/* 職種選択
----------------------------------------------------- */
.choiceContent {
  padding: 20px 20px 20px 15px;
}

.choiceContent__content {
  &.is-none {
    display: none;
  }

  .choiceContent__title {
    color: $color_heading;
    font-size: 1.7rem;
    font-weight: bold;
    display: flex;
    align-items: center;

    label {
      display: flex;
      align-items: center;

      .checkbox__icon {
        top: auto;
      }
    }
  }

  .choiceContent__sectionTitle {
    display: flex;
    align-items: center;
    padding: 9px 15px;
    color: $color_heading;
    font-weight: bold;
    background-color: #f0f0f3;

    label {
      display: flex;
      align-items: center;

      .checkbox__icon {
        top: auto;
      }
    }
  }

  .choiceContent__section {
    .choiceContent__list {
      display: flex;
      flex-wrap: wrap;
      white-space: normal;
    }
  }
}

.choiceContent__title {
  color: $color_heading;
  font-size: 1.7rem;
  font-weight: bold;
}

.choiceContent__title--skill {
  @extend .choiceContent__title;
  padding: 0 0 0 20px;
}

.choiceContent__title .checkbox__icon,
.choiceContent__title .radio__icon {
  top: 1px;
}

.choiceContent__title .labelNumber,
.choiceContent__sectionTitle .labelNumber {
  vertical-align: top;
}

.choiceContent__section {
  padding: 20px 0 0;
}

.choiceContent > .choiceContent__section:first-child {
  padding: 0;
}

.modalChoice {
  &--noLabelNumber {
    .labelNumber {
      opacity: 0;
    }
  }
}

.modalChoice__list + .modalChoice__choiceContent .choiceContent__section {
  padding-left: 15px;
}

.choiceContent__sectionTitle {
  padding: 9px 15px;
  color: $color_heading;
  font-weight: bold;
  background-color: #f0f0f3;
}

.choiceContent__sectionTitle .checkbox__text,
.choiceContent__sectionTitle .radio__text {
  position: relative;
  top: 1px;
}

.choiceContent__sectionTitle .labelNumber {
  position: relative;
  top: -1px;
}

.choiceContent__list {
  overflow: hidden;
  letter-spacing: -0.4em;
}

.choiceContent__item {
  display: inline-block;
  vertical-align: top;
  padding: 20px 5px 0 15px;
  width: 33%;
  letter-spacing: 0;

  label {
    display: flex;
    align-items: center;
  }
}

.choiceContent > .choiceContent__item:first-child {
  padding: 0 5px 0 15px;
}

.modalChoice__list + .modalChoice__choiceContent .choiceContent__item {
  width: 50%;
}

.choiceContent__item .checkbox > input,
.choiceContent__item .checkbox--disabled > input,
.choiceContent__item .radio > input,
.choiceContent__item .radio--disabled > input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.choiceContent__item .checkbox__text,
.choiceContent__item .radio__text {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 26px;
  line-height: 1.4;
}

.choiceContent__more {
  display: inline-block;
  margin: 20px 5px 0 12px;
  padding-left: 15px;
  font-size: 1.2rem;
  background: url(#{$img_path}arrowRight.png) no-repeat left 5px top 50%;
}

.choiceContent__more + .choiceContent__list {
  display: none;
  margin: 0 0 20px;
}

.choiceContent__section + .choiceContent__title {
  margin: 20px 0 0;
}

.choiceContent__title + .choiceContent__section .choiceContent__list,
.choiceContent__section + .choiceContent__section .choiceContent__list {
  margin: 0;
}

/* 言語選択
----------------------------------------------------- */
.languageChoice {
  font-size: 14px;
}

.languageChoice__title {
  padding: 20px;
  color: $color_heading;
  font-size: 2rem;
  font-weight: bold;
}

.languageChoice__section {
  padding: 0 20px 20px;
}

.languageChoice__section .formTable {
  margin: 0;
}

.languageChoice__content {
  padding: 0 20px;
}

.languageChoice__subTitle {
  margin-bottom: 20px;
  padding: 10px 20px;
  color: $color_heading;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #f0f0f3;
}

/* 検討リスト 件数 絞り込み
----------------------------------------------------- */
.considerationSort {
  background-color: #fafafb;
  border-bottom: 1px solid #{$color_subBorder};
}

.considerationSort__content {
  display: table;
  table-layout: fixed;
  width: 950px;
  margin: 0 auto;
  padding: 40px 0 20px;
}

.considerationSort__content .considerationNumber,
.considerationSort__content .pipeLink,
.considerationSort__content .sort {
  display: table-cell;
  vertical-align: middle;
}

.considerationSort__content .considerationNumber {
  padding-left: 20px;
}

.considerationSort__content .pipeLink {
  width: 270px;
  text-align: right;
}

.considerationSort__content .sort {
  width: 294px;
  text-align: right;
}

.considerationSort .pullDown__list {
  min-width: 200px;
}

.considerationSort [class*="pullDown__item"] a {
  width: 100%;
}

/* 検討リスト 件数
----------------------------------------------------- */
.considerationNumber .textAttentionL {
  margin-right: 10px;
}

.considerationNumber .textSub {
  position: relative;
  top: -5px;
  font-size: 1.2rem;
}

/* 検討リスト
----------------------------------------------------- */
.considerationResult__item {
  margin: 20px 0;

  &:first-of-type {
    margin-top: 30px;

    &.smallMargin {
      margin-top: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  .bookmarkNoCheckBox {
    padding-right: 70px;
  }

  .cassetteJobOffer:has(label.checkbox) {
    .bookmarkNoCheckBox {
      padding-right: 33px;
    }
  }
}

.considerationResult__item .textSup {
  display: block;
  margin-bottom: 10px;
}

.considerationResult__ellipsisIcon,
.considerationResult__ellipsisIcon::before,
.considerationResult__ellipsisIcon::after {
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #00b946;
  border-radius: 50%;
}

.considerationResult__ellipsisIcon::before,
.considerationResult__ellipsisIcon::after {
  content: "";
}

.considerationResult__ellipsisIcon::before {
  top: -38px;
}

.considerationResult__ellipsisIcon::after {
  top: 38px;
}

/* 検討リスト マッチング済み
----------------------------------------------------- */
.matchingJob {
  margin: 20px 0;
  padding: 0 4px 4px;
  background-color: #ff7f7f;
  border-radius: 4px 4px 0 0;
  box-shadow: $boxShadow_defult;
}

.matchingJob .cassetteJobOffer {
  margin-bottom: 0;
  box-shadow: none;
}

.matchingJob__heading {
  height: 45px;
  padding: 9px 0 0 20px;
  background: url(#{$img_path}bgMatchingJob.png) center bottom no-repeat #ff7f7f;
}

.matchingJob__text,
.matchingJob__label {
  display: inline-block;
  vertical-align: middle;
}

.matchingJob__text {
  @include iconFont($fontSize:2rem, $icon: "\e91c") {
    position: relative;
    margin-right: 12px;
    padding-left: 35px;
    color: $color_white;
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.matchingJob__text::before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.matchingJob__label {
  height: 26px;
  padding: 3px 25px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff7f7f;
  background-color: $color_white;
  border-radius: 13px;
}

.matchingJob .cassetteJobOffer .checkbox {
  width: 32px;
}

.matchingJob .cassetteJobOffer .cassetteJobOffer__content {
  padding-right: 16px;

  &.paddingLarge {
    padding-right: 22px;
    padding-left: 22px;
  }
}

.matchingJob .cassetteJobOffer .cassetteJobOffer__heading {
  padding: 20px 16px 20px 20px;
  background-color: #ffe5e5;
}

.considerationResult__ellipsis {
  position: relative;
  height: 150px;
}

.matchingJob .cassetteRecruit__heading {
  border-top: none;
  background-color: #ffe5e5;
}

.matchingJob .cassetteRecruit__content {
  margin: 0;
  box-shadow: none;
}

.matchingJob .cassetteRecruit {
  margin: 0;
}

/* 検討リスト 面接依頼
----------------------------------------------------- */
.requestMessage {
  margin: 20px 0;
  padding: 0 4px 4px;
  border-radius: 4px 4px 0 0;
  box-shadow: $boxShadow_defult;

  &--interview {
    background-color: $color_requestInterview;
  }

  &--apply {
    background-color: $color_requestApply;
  }

  & .cassetteJobOffer {
    margin-bottom: 0;
    box-shadow: none;
  }

  &__heading {
    height: 45px;
    padding: 9px 0 0 20px;

    &--interview {
      background: url(#{$img_path}bginterviewRequest.png) center bottom no-repeat $color_requestInterview;
    }

    &--apply {
      background: url(#{$img_path}bgMatchingJob.png) center bottom no-repeat $color_requestApply;
    }
  }

  &__heading2 {
    height: 35px;
    padding: 0px 0 0 20px;
    border-bottom: 1px solid #fff;
  }

  &__text,
  &__label {
    display: inline-block;
    vertical-align: middle;
  }

  &__text {
    @include iconFont($fontSize:2rem, $icon: "\e91c") {
      position: relative;
      margin-right: 12px;
      padding-left: 35px;
      color: $color_white;
      font-size: 1.8rem;
      font-weight: bold;
    }
  }

  &__text::before {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__title {
      position: relative;
      margin-right: 12px;
      color: $color_white;
      font-size: 1.5rem;
      font-weight: bold;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 35px;
      padding-right: 10px;
  }

  &__label {
    height: 26px;
    padding: 3px 25px;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: $color_white;
    border-radius: 13px;

    &--interview {
      color: $color_requestInterview;
    }

    &--apply {
      color: $color_requestApply;
    }
  }

  & .cassetteJobOffer .checkbox {
    width: 32px;
  }

  & .cassetteJobOffer .cassetteJobOffer__content {
    padding-right: 16px;

    &.paddingLarge {
      padding-right: 22px;
      padding-left: 22px;
    }
  }

  & .cassetteJobOffer .cassetteJobOffer__heading {
    padding: 20px 16px 20px 20px;

    &--interview {
      background-color: $color_requestInterviewLight;
    }

    &--apply {
      background-color: $color_requestApplyLight;
    }
  }

  .considerationResult__ellipsis {
    position: relative;
    height: 150px;
  }

  & .cassetteRecruit__heading {
    border-top: none;

    &--interview {
      background-color: $color_requestInterview;
    }

    &--apply {
      background-color: $color_requestApply;
    }
  }

  & .cassetteRecruit__content {
    margin: 0;
    box-shadow: none;
  }

  & .cassetteRecruit {
    margin: 0;
  }
}

/* 検討リスト お知らせメール
----------------------------------------------------- */
.noticeMail {
  @extend .cardNotice;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 50px auto 30px;
  padding: 30px 30px 20px;

  //気になるリストページ
  &--bookmark {
    border: none;
    border-top: 4px solid #00aaeb;
    margin: 0 auto 30px;

    & .btnSecondaryL {
      background-color: red;
      border-color: red;
    }

    & .btnSecondaryL.allOff {
      background-color: #fff;
      border-color: #bbb;
      color: #666;
    }
  }

  &__title {
    position: relative;
    margin-bottom: 30px;
    padding-left: 80px;
    color: $color_keyColor;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0.05em;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 0;
      width: 54px;
      height: 38px;
      background: url(../img/bookmark/icon_mail01.png) left center no-repeat;
      transform: translateY(-50%);
    }
  }
}

.noticeMail__text,
.noticeMail__btn {
  display: table-cell;
  vertical-align: middle;
}

.noticeMail__btn {
  text-align: right;
}

.noticeMail__text {
  width: 580px;
}

.noticeMail__note {
  color: #555;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  width: 450px;
}

.noticeMail__heading {
  position: relative;
  margin-bottom: 30px;
  padding-left: 90px;
  color: $color_keyColor;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1;
}

.noticeMail__heading::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 0;
  width: 72px;
  height: 52px;
  background: url(#{$img_path}iconCautionMail.png) left center no-repeat;
  transform: translateY(-50%);
}

.noticeMail .text em {
  color: $color_red;
}

.noticeMail .btnPrimaryL {
  min-width: 230px;
}

/* 求人特集
----------------------------------------------------------------- */
.feature__section {
  @include clearfix;
}

.feature__section--content {
  width: 690px;
  float: left;
}

.feature__section--side {
  width: 240px;
  float: right;
}

.feature__section--side .card__content {
  padding: 20px;
}

.feature__section--side .textBold {
  color: #000;
}

/* 探すブロック
----------------------------------------------------------------- */
.variousSearch {
  background-color: #f5f5f5;
  padding: 28px 30px 0;
}

.variousSearch .headingContentS {
  display: inline-block;
  margin-bottom: 12px;
  color: $color_text;
}

.variousSearch .columnList--3col__item {
  margin-bottom: 0;
}

.variousSearch__list {
  padding-bottom: 28px;
}

.variousSearch__item {
  margin-bottom: 8px;
}

.variousSearch__item .linkArrow {
  font-size: 1.2rem;
}

.variousSearch__title {
  float: left;
}

.variousSearch__subList {
  padding-left: 68px;
}

.variousSearch__subItem {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 4px;
}

.variousSearch__subItem:nth-last-of-type(-n + 3) {
  margin-bottom: 0;
}

.variousSearchInline__list {
  padding-bottom: 22px;
}

.variousSearchInline__item {
  display: inline-block;
  margin-right: 24px;
  margin-bottom: 8px;
  font-size: 1.2rem;

  span {
    color:#999
  }
}

.variousSearchInline__item .linkArrow {
  font-size: 1.2rem;
}

.jobSearch__list {
  display: flex;
  justify-content: center;
}

/* WEB履歴書
----------------------------------------------------------------- */
.scoutResume {
  padding-bottom: 20px;
  line-height: 1.75;
}

.scoutResume .markdescrption {
  padding-left: 1rem;
}

.scoutResume .markdescrption::before {
  content: "※";
  display: inline-block;
  text-indent: -1.5rem;
}

.scoutResume .textScout {
  display: inline-block;
  height: 18px;
  width: 42px;
  vertical-align: middle;
}

.scoutResume .textRightBox {
  margin-bottom: 0;
}

.webResumeForm .formTable__head--low,
.webResumeForm .formTable__head {
  background-color: #e8f7fd;
}

.webResumeForm [class^=label] {
  margin-left: 8px;
}

.webResumeForm .formTable__body {
  background-color: #f3fbfe;
}

/* 業種 リスト
----------------------------------------------------------------- */
.industryList .columnList--3col__item {
  margin: 20px 0 0;
  padding-right: 10px;
}

.industryList .columnList--3col__item:nth-child(1),
.industryList .columnList--3col__item:nth-child(2),
.industryList .columnList--3col__item:nth-child(3) {
  margin: 0;
}

.industryList__disabled {
  color: $color_subText;
}

/* 職種 リスト
----------------------------------------------------------------- */
.occList {
  @extend .industryList;

  &__subtitle {
    margin-top: 20px;
    font-size: 15px;

    &:first-child {
      margin: 0;
    }

    a {
      color: $color_text;
      padding-left: 30px;
      position: relative;

      &:hover {
        opacity: 0.5;
        text-decoration: none;
      }

      &::before {
        position: absolute;
        content: '';
        display: inline-block;
        z-index: 2;
        bottom: calc((100% - 6px) / 2 );
        left: 6px;
        width: 6px;
        height: 6px;
        border-right: 2px solid $color_white;
        border-bottom: 2px solid $color_white;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-top: -3px;
      }

      &::after {
        position: absolute;
        content: '';
        display: inline-block;
        bottom: calc((100% - 19px) / 2 );
        left: 0;
        width: 20px;
        height: 20px;
        background-color: $color_keyColor;
        margin-top: -10px;
        border-radius: 100%;
      }
    }

    &--disabled {
      @extend .occList__subtitle;
      color: $color_border;
      padding-left: 30px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: inline-block;
        z-index: 2;
        bottom: calc((100% - 4px) / 2 );
        left: 6px;
        width: 6px;
        height: 6px;
        border-right: 2px solid $color_white;
        border-bottom: 2px solid $color_white;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-top: -3px;
      }

      &::after {
        position: absolute;
        content: '';
        display: inline-block;
        bottom: calc((100% - 18px) / 2 );
        left: 0;
        width: 20px;
        height: 20px;
        background-color: $color_keyColor;
        margin-top: -10px;
        border-radius: 100%;
      }
    }
  }

  .columnList {
    &--2col {
      margin-left: 30px;
      padding-bottom: 10px;

      &__item {
        margin: 20px 0 0;
        padding-right: 20px;

        .occList__disabled {
          color: $color_border;
        }
      }
    }
  }
}

/* 希望条件に合った新着メールブロック
----------------------------------------------------------------- */
.matchingMail {
  @extend .cardNotice;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 26px 64px 26px 44px;
}

.matchingMail__icon,
.matchingMail__content {
  display: table-cell;
  vertical-align: middle;
}

.matchingMail__icon {
  width: 140px;
  color: $color_keyColor;
  font-size: 1.6rem;
  font-weight: bold;
}

.matchingMail__icon::before {
  content: '';
  display: block;
  width: 84px;
  height: 68px;
  margin: 0 0 15px 8px;
  background: url(#{$img_path}iconMail.png) left top no-repeat;
}

.matchingMail__title {
  margin: 0 0 10px;
  color: $color_red;
  font-size: 2.4rem;
  font-weight: bold;
}

.matchingMail__title span {
  color: $color_text;
  font-size: 2.2rem;
}

.matchingMail__txt {
  margin-bottom: 18px;
  font-weight: bold;
}

.matchingMail__form {
  @include clearfix;
}

.matchingMail__form .inputText {
  float: left;
  width: 494px;
}

.matchingMail__form .btnSignUp {
  float: right;
  min-width: 183px;
  min-height: 34px;
  margin: 0;
  padding: 6px 20px;
  background-color: $color_keyColor;
  border: none;
}

.matchingMail__form .btnSignUp__attention {
  font-size: 1.3rem;
}

.matchingMail__form .btnSignUp__label {
  font-size: 1.6rem;
}

/* 特徴 リスト
----------------------------------------------------------------- */
.featureList {
  overflow: hidden;
}

.featureList__title {
  clear: both;
  float: left;
  width: 200px;
  margin: 20px 0 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.featureList__txt {
  float: left;
  width: calc(100% - 200px);
  margin: 20px 0 0;
}

.featureList__title:nth-of-type(1),
.featureList__txt:nth-of-type(1) {
  margin: 0;
}

/* 調整ブロック 右寄せ
----------------------------------------------------------------- */
.textRightBox {
  @include contentMargin;
  margin: 20px 0;
  text-align: right;
}

.textRightBox .textS + .textS {
  margin-top: 10px;
}

/* 調整ブロック 中央寄せ
----------------------------------------------------------------- */
.centerBox {
  @include contentMargin;
  margin: 20px 0;
  text-align: center;
}

.centerBox > * {
  display: inline-block;
}

/* 注目求人
----------------------------------------------------------------- */
.attentionJobOffer {
  padding: 30px 0;
  background-color: #f0f0f3;
}

.attentionJobOffer .card {
  margin: 0 auto;
}

.attentionJobOffer .text {
  margin: 0 0 12px;
}

/* メインコンテンツ全体枠
----------------------------------------------------- */
.arrivalsBox {
  position: relative;
  width: 100%;
  padding: 30px 0;
  font-size: 1.4rem;
  line-height: 1.42;
  background: #f0f0f3;
  border-bottom: 1px solid $color_subBorder;
  z-index: 1;
}

.arrivalsBox:last-child {
  border-bottom: none;
}

.arrivalsBox__inner {
  @include clearfix;
  @include boxCenter;
  position: relative;
}

.arrivalsAnchorBottom .arrivalsList__item a::before {
  transform: rotateX(180deg);
}

/* 検索詳細
----------------------------------------------------------------- */
.searchJobDetail {
  margin: 20px auto;
}

.searchJobDetail__submit {
  position: relative;
  margin: 20px auto;
  text-align: center;
}

.searchJobDetail__submit [class*="btnPrimary"] {
  @include iconFont();
}

.searchJobDetail__submit [class*="btnPrimary"]::before {
  content: "\e623";
  margin: 0 8px 0 0;
  font-weight: normal;
}

.searchJobDetail__number {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
}

.searchJobDetail__number .textSup {
  color: #666;
  vertical-align: middle;
}

.searchJobDetail__number .textAttentionM {
  position: relative;
  vertical-align: middle;
}

.searchJobDetail__number .loading {
  @include loading($height: 15px, $top: -2px);
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 0 0 0 5px;
  padding: 0 30px;
  background-color: transparent;
}

.searchJobDetail__number .loading span {
  display: block;
}

.searchJobDetail__numberText {
  margin-left: 5px;
  color: #000;
  font-size: 2rem;
}

.searchJobDetail .formTable__row {
  display: table;
}

.searchJobDetail .formTable__row .formTable__col [class*="btn"] {
  white-space: nowrap;
}

.searchJobDetail .formTable__row .formTable__col {
  position: relative;
  float: none;
  display: table-cell;
  vertical-align: middle;
}

.searchJobDetail .formTable__row .formTable__col + .formTable__col {
  padding-left: 20px;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.searchJobDetail__checkbox {
  margin: -10px 0 0 -30px;
}

.searchJobDetail__checkbox .formTable__col--checkbox:first-of-type,
.searchJobDetail__checkbox .formTable__col--checkbox {
  margin: 10px 0 0 30px;
}

.searchJobDetail .formTable__col .iconQuestion {
  z-index: 3;
}

.searchJobDetail .formTable__col .iconQuestion:hover + .tooltip {
  display: block;
}

.searchJobDetail .formTable__col {
  letter-spacing: -0.4em;
}

.searchJobDetail .formTable__col .tooltip {
  position: absolute;
  z-index: 2;
  top: 47px;
  right: -27px;
  display: none;
}

.searchJobDetail .formTable__col .tooltip__text + .tooltip__text {
  margin: 10px 0 0;
}

.searchJobDetail .inputText {
  width: 270px;
  margin: 0 0 0 10px;
}

.searchJobDetail .textSup {
  margin: 0 0 0 5px;
}

.searchJobDetail .textSup + [class*="selectM"] {
  margin: 0 0 0 10px;
}

.searchJobDetail .selectM__text--placeholder {
  color: $color_text;
}

.searchJobDetail .select--disabled .selectM__text--placeholder {
  color: $color_disable02;
}

.searchJobDetail .formTable__body .formTable {
  margin: 0;
}

.searchJobDetail .formTable__body .formTable__head {
  width: 105px;
  padding: 20px 15px;
}

.searchJobDetail .formTable__body .formTable__body {
  padding: 20px;
}

.searchJobDetail .card:nth-child(1) tr:nth-child(1) .formTable__body .inputText {
  margin: 0;
}

/*  気になる
----------------------------------------------------------------- */
.concernCard {
  margin: 0 auto;
  width: 290px;
}

.concernCard .textSup {
  position: relative;
  margin-top: 10px;
}

.concernCard > [class*="btn"] + .textSup [class*="icon"] {
  position: relative;
  margin-right: 4px;
  z-index: 12;
}

.concernCard .textSup [class*="icon"]:hover + .tooltip {
  display: block;
}

.concernCard .tooltip {
  display: none;
  text-align: left;
  position: absolute;
  z-index: 10;
  top: 40px;
  right: 152px;
}

.jobBreadcrumb .breadcrumb {
  background-color: inherit;
  border-bottom: none;
}

.jobCarousel {
  background-color: #f0f0f3;
  padding: 30px 0;
  margin-bottom: 20px;
}

.jobCarousel__inner {
  @include clearfix;
  margin: 0 auto;
  width: 950px;
}

.jobCarousel__heading {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.jobCarousel__item {
  background-color: $color_white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  box-sizing: inherit;
  display: inline-block;
  font-size: 1.2rem;
  padding: 16px;
  width: 220px;
  margin-right: 19px;
}

.jobCarousel__item:hover {
  opacity: 0.5;
  text-decoration: none;
}

.jobCarousel__item:last-child {
  margin-right: -10px;
}

.jobCarousel__title {
  color: $color_keyColor;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 2px;
}

.jobCarousel__text {
  color: $color_text;
}

.jobCarousel .linkArrowS {
  margin-top: 20px;
  float: right;
}

/* 求人情報 ログイン
----------------------------------------------------------------- */
.JobInfoLogin {
  position: relative;
  width: 950px;
  margin: 0 auto 40px;
  box-sizing: border-box;
}

.JobInfoLogin__row {
  display: table;
  border-collapse: separate;
  border-spacing: 20px 0;
  table-layout: fixed;
  width: 100%;
  margin: 0 0 20px -20px;
}

.JobInfoLogin__row .card {
  display: table-cell;
  width: 464px;
  margin: 0 0 0 11px;
  box-sizing: border-box;
}

/* お気に入りエラー
----------------------------------------------------------------- */
.bookmarkContent {
  border-bottom: 1px solid $color_subBorder;
  margin: 0 12px 12px;
  padding-bottom: 12px;
}

.bookmarkContent:last-of-type {
  border-bottom: none;
  margin-bottom: -20px;
}

.bookmarkContent__title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 8px;
}

/* ソーシャルボタン
----------------------------------------------------------------- */
.socialBtn {
  overflow: hidden;
  margin: 0 0 20px;
}

.socialBtn__content {
  float: right;
}

.socialBtn__facebook,
.socialBtn__twitter {
  float: left;
  margin: 0 7px 0 0;
}

/* 求人情報
----------------------------------------------------------------- */
.jobOfferInfo {
  margin: 0 0 40px;
}

.jobOfferInfo__labelFeature {
  margin: 0 0 12px -8px;
  letter-spacing: -0.4em;
}

.jobOfferInfo__labelFeature [class*="label"] {
  margin: 0 0 8px 8px;
}

.jobOfferInfo__link {
  text-align: right;
}

.jobOfferInfo .btnListL {
  margin: 40px 0;
}

.jobOfferInfo__contentTitle .headingContent,
.jobOfferInfo__contentTitle .linkArrowS {
  display: inline-block;
  vertical-align: middle;
}

.jobOfferInfo__contentTitle .headingContent {
  margin: 0;
  max-width: calc(100% - 140px);
}

.jobOfferInfo__contentTitle .linkArrowS {
  margin: 0 0 0 20px;
}

.jobOfferInfo__recommendContent {
  @include clearfix;
  padding: 20px 27px;
  border: 1px solid $color_subBorder;
}

.jobOfferInfo__recommendContent + .jobOfferInfo__recommendContent {
  border-top: none;
}

.jobOfferInfo__recommendContent [class*="btn"] {
  float: left;
}

.jobOfferInfo__recommendContent .text {
  margin: 0 0 0 320px;
}

.jobOfferInfo .cassetteQuestionnaire {
  position: static;
  display: block;
  margin: 0 0 20px;
  box-shadow: $boxShadow_defult;
}

.jobOfferInfo .cassetteQuestionnaire__content {
  width: 100%;
  padding: 0 0 15px;
  border: 2px solid $color_keyColor;
  border-top: none;
}

.jobOfferInfo .cassetteQuestionnaire__text {
  margin: 0;
  padding: 30px 0;
}

.jobOfferInfo .cassetteQuestionnaire .js__question:first-child {
  display: block;
}

.jobOfferInfo .cassetteQuestionnaire .btnBox {
  margin: 0;
}

/* 求人情報 ポイント
----------------------------------------------------------------- */
.jobOfferPoint {
  display: table;
  width: 100%;
}

.jobOfferPoint__text,
.jobOfferPoint__img {
  display: table-cell;
  vertical-align: top;
}

.jobOfferPoint__img {
  padding: 0 0 0 25px;
  width: 280px;
}

.jobOfferPoint__img img {
  vertical-align: top;
}

.jobOfferPoint__img .textS {
  display: block;
  margin: 18px 0 0;
}

.jobOfferPoint__content {
  width: 100%;
}

.jobOfferPoint__title {
  width: 138px;
  padding: 9px 0;
  color: $color_white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  background-color: #ff5254;
}

.jobOfferPoint__list {
  width: 100%;
  background-color: #fff2f2;
  border: 2px solid #ff5254;
}

.jobOfferPoint__item {
  padding: 18px 25px;
  font-weight: bold;
  border-top: 1px solid $color_subBorder;
}

.jobOfferPoint__item:first-child {
  border: none;
}

/* 求人情報 会社・仕事の魅力
----------------------------------------------------------------- */
.cardCharm {
  @include card() {
    border: 2px solid $color_keyColor;
  }
}

.cardCharm__content {
  @extend .card__content;
  @include clearfix;
}

.cardCharm__img {
  float: right;
}

.cardCharm__imgItem {
  width: 280px;
  margin: 0 0 20px 40px;
}

.cardCharm__img img {
  vertical-align: top;
}

.cardCharm__img .textS {
  display: block;
  margin: 10px 0 0;
}

/* 求人情報 取材後記
----------------------------------------------------------------- */
.cardCoverage {
  @include card() {
    border: 2px solid #cee879;
  }
}

.cardCoverage__content {
  @extend .card__content;
  @include clearfix;
}

.cardCoverage__img {
  @extend .cardCharm__imgItem;
  float: right;
}

/* ミミヨリ情報
----------------------------------------------------------------- */
.originalInfo {
  margin: 0 0 40px;
}

.originalInfo .textEm:first-child {
  padding: 0 0 10px;
}

.originalInfo .row {
  margin: 20px 0;
}

.originalInfo__content {
  float: left;
  width: 690px;
}

.originalInfo__content .card:last-child {
  margin: 0;
}

.originalInfo__content .card__content {
  display: table;
  width: 100%;
  padding: 20px 28px 40px;
}

.originalInfo__content .card__content:first-child {
  padding: 30px 28px 20px;
}

.originalInfo__contentTitle {
  margin: 0 auto 20px;
  padding: 0 0 0 15px;
  font-weight: bold;
  border-left: 4px solid $color_keyColor;
}

.originalInfo__contentDate,
.originalInfo__contentCategory {
  display: table-cell;
  vertical-align: top;
  font-size: 1.2rem;
}

.originalInfo__contentDate {
  padding: 0 10px 0 0;
}

.originalInfo__contentCategory {
  text-align: right;
  white-space: nowrap;
}

.originalInfo__menu {
  @include card;
  float: right;
  width: 240px;
  padding: 20px;
}

.originalInfo__menuTitle {
  margin: 0 0 15px;
  color: $color_heading;
  font-weight: bold;
}

.originalInfo__menuItem {
  margin: 15px 0 0;
  font-size: 1.2rem;
}

/* フォトレポート
----------------------------------------------------------------- */
.photoReport {
  @extend .card;
  margin: 0 0 40px;
}

.photoReport__content {
  @extend .card__content;
}

.photoReport__row {
  margin: 0 0 40px;
  letter-spacing: -0.4em;
}

.photoReport__row:last-child {
  margin: 0;
}

.photoReport__col {
  display: inline-block;
  vertical-align: top;
  width: 280px;
  margin: 0 0 0 27px;
  letter-spacing: 0;
}

.photoReport__col:first-child {
  margin: 0;
}

.photoReport__text {
  margin: 5px 0 0;
  font-size: 1.2rem;
}

/* PCプレビュー
----------------------------------------------------------------- */
#previewPhoto {
  .photoReport {
    margin: 0 auto 40px;
  }
}

.previewDisplay {
  display: none;
}

/* 応募プロセス
----------------------------------------------------------------- */
.adoptionProcess__content {
  position: relative;
  margin: 0 0 60px;
}

.adoptionProcess__content::before {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 30px;
  z-index: 1;
  border: 10px solid transparent;
  border-left-width: 6px;
  border-right-width: 6px;
  border-top-color: $color_text;
}

.adoptionProcess__content:last-child::before {
  content: none;
}

/* 応募に関するよくある質問
----------------------------------------------------------------- */
.jobOfferQuestion {
  @extend .card;
  padding: 20px 90px;
}

.jobOfferQuestion__title {
  margin: 0 0 20px;
  padding: 0 0 20px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid $color_subBorder;
}

.jobOfferQuestion__content {
  font-weight: bold;
}

.jobOfferQuestion__q,
.jobOfferQuestion__a {
  position: relative;
  margin: 0 0 15px;
  padding: 5px 0 0 35px;
}

.jobOfferQuestion__a:last-child {
  margin: 0;
}

.jobOfferQuestion [class*="label"] {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

/* スペシャル情報
----------------------------------------------------------------- */
.specialInfo {
  margin: 0 0 40px;
}

.specialInfo script {
  display: none;
}

/* 特集検索結果一覧差込
----------------------------------------------------------------- */
div.special_banner {
  background: url(../img/common/background_special_banner_01.gif) 0 0 no-repeat;
  margin-bottom: 15px;
}

div.special_banner div.content {
  background: url(../img/common/background_special_banner_01.gif) -950px 100% no-repeat;
  padding: 9px 10px;
}

div.special_banner div.content div.title {
  background: url(../img/common/background_special_banner_02.gif) 0 0 no-repeat;
}

div.special_banner div.content div.title p {
  background: url(../img/common/background_special_banner_02.gif) -6px 100% no-repeat;
  color: #3579ca;
  font-size: 1.833em /* = 22px */;
  font-weight: bold;
  line-height: 1.636em /* = 36px */;
  padding-bottom: 1px;
  padding-left: 18px;
  padding-top: 7px;
  text-shadow: 0 1px 0 #fff;
  *zoom: 1;
}

div.special_banner div.content div.title p span {
  display: block;
  font-size: 0.636em /* = 14px */;
  line-height: 1em /* = 14px */;
}

div.special_banner div.content div.text {
  background: url(../img/common/background_special_banner_03.gif) 0 0 no-repeat;
  margin-top: -2px;
}

div.special_banner div.content div.text p {
  background: url(../img/common/background_special_banner_03.gif) -6px 100% no-repeat;
  color: #777;
  font-size: 0.833em /* = 10px */;
  line-height: 1.4em /* = 14px */;
  padding-bottom: 3px;
  padding-left: 18px;
  padding-top: 3px;
  text-shadow: 0 1px 0 #fff;
  *zoom: 1;
}

/* 転職支援サービスのブロック
----------------------------------------------------------------- */
.recruitment {
  height: 230px;
  margin-top: 50px;
  font-size: 1.6rem;
  font-weight: bold;

  & img {
    margin-top: -60px;
    float: right;
  }

  & .btnBox {
    margin-top: 105px;
  }

  &__imgBox {
    padding-top: 40px;
  }
}

/* 404ページ
----------------------------------------------------------------- */
.page404Alert {
  @include card($width:none) {
    height: 121px;
    width: 100%;
    font-weight: 700;
    font-size: 1.8rem;
    text-align: center;
    line-height: 121px;
  }

  div {
    display: inline-block;
    line-height: 20px;
  }
}

/* キャリQエラーページ
----------------------------------------------------------------- */
.careqAlert {
  @include card($width:none) {
    height: 121px;
    width: 100%;
    font-weight: 700;
    font-size: 1.8rem;
    text-align: center;
    line-height: 121px;
  }

  div {
    padding-top: 40px;
    display: inline-block;
    line-height: 20px;
  }
}

/* メッセージ詳細
----------------------------------------------------------------- */
.applicationDetail__changeDateCaution {
  p {
    font-size: 1.2rem;
  }

  p:first-child {
    font-weight: 700;
    font-size: 1.4rem;
    color: red;
  }
}

/* 応募フォームガイドラインテキスト
----------------------------------------------------------------- */
.card__content__text {
  @extend .card__content;
  padding: 30px 30px 30px;
}

/* テキスト折り返し用ブロック
----------------------------------------------------------------- */
.textBreak {
  word-break: break-all;
}
