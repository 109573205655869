@charset "UTF-8";

$job-red-color: #ff080e;
$job-blue-color: #00aaeb;

@mixin tagBase {
  float: left;
  font-size: 1.2rem;
  padding: 0 10px;
  color: #fff;
  line-height: 2em;
  margin-right: 10px;
}

@mixin jobTitle {
  color: $color_keyColor;
  display: block;
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 20px;
}

@mixin jobText {
  font-size: 1.2rem;
  line-height: 1.3em;
  color: $color_text;
}
