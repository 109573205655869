@charset "UTF-8";

.jobSearchKnowHowLink {
  width: 240px;
  margin-right: 0;
  float: right;
  background-color: #fff3f3;
  position: relative;

  &::before {
    content: '';
    border: 12px solid transparent;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    border-top-color: #fff3f3;
    bottom: 3px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 16px;
    display: block;
    border-right: 3px solid #ff6451;
    border-bottom: 3px solid #ff6451;
    width: 14px;
    height: 14px;
    transform: translateX(-50%) rotate(45deg);
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    padding: 24px 0 42px 0;
    text-align: center;
    letter-spacing: 1px;
  }

  a {
    @include onHover;
    display: block;
  }

  &__title {
    color: #ff6451;
    display: block;
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 1.3rem;
    font-weight: 700;
    color: #000;
  }
}
