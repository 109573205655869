@charset "UTF-8";

.js__keep,
.js__interest {
  color: $color_disable02;
}

.js__keep--done,
.js__interest--done {
  color: $color_keep;
}

.js__keep--done:hover + .iconTooltip,
.js__interest--done:hover + .iconTooltip {
  display: none;
}

.js__counter--over {
  display: none;
}

.js__industry--item {
  margin-bottom: 0;
}

.js__fixed {
  display: none;
}

.js__skillSelect--sentence {
  display: none;
}

.js__skillSelect--possibleText {
  display: none;
}

.js__skillSelect--qualificationText {
  display: none;
}

.js__favorite {
  color: #fece30;
}

.js__loading {
  padding: 0;
  position: relative;
}

.js__loading img {
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.js__hide {
  display: none;
}

.js__headerNotice--icon {
  background-color: $color_red;
  border-radius: 3px;
  display: none;
  height: 6px;
  position: absolute;
  right: 7px;
  top: 7px;
  width: 6px;
}

.js__headerNotice--hover,
.js__headerMenu--hover {
  background-color: #e0eff5;
}

.js__question {
  display: none;
}

.js__question--answer {
  display: none;
}

.js__check--profile,
.js__check--career,
.js__check--skill,
.js__check--PR,
.js__check--condition,
.js__check--question,
.js__check--specialReport,
.js__check--personalInformation {
  display: none;
}

.js__tab--content {
  display: none;
}

.js__conditionMatchedNumber {
  display: none;
}

.js__searchRecruit--loading,
.loading.js__searchRecruit--loading {
  display: none;
}

.js__accordion {
  display: none;
}

.js__readMore--content {
  overflow: hidden;
}

.js__readMore--trigger {
  cursor: pointer;
}

.js--hidden {
  overflow: hidden;
}

/* animation
----------------------------------------------------- */
.js__lazy {
  animation: colorfade 1s ease-in-out 0s infinite alternate;
  background: #bbb;
  display: block;
  height: 100%;
  width: 100%;
}

@keyframes colorfade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.4;
  }
}

//IE9~11 未適用
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .js__lazy {
    animation: none;
  }
}
