@charset "UTF-8";

.list {
  &__text {
    padding: 5px 0;
    line-height: 1.57;

    &--emphasis {
      background: $line-emphasis;
    }
  }
}
