@charset "UTF-8";

.topBalloon {
  $component: &;
  background-color: $color_keyColor_light;
  padding: 1rem;
  position: relative;

  &::before {
    border: 14px solid transparent;
    border-bottom-color: $color_keyColor_light;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: -27px;
    transform: translateX(-50%);
  }

  &--rounded {
    border-radius: .5rem;
    padding: 2rem;
  }

  &--newArrival {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);

    &::before {
      left: auto;
      right: 2rem;
      transform: none;
    }
  }

  &__button {
    @include topDashboardButton;

    &--recentView {
      height: 6.6rem;
      margin-top: 1rem;
      padding-left: 2rem;

      #{$component}__buttonIcon {
        left: 2rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 3rem;
      }

      #{$component}__buttonLabel {
        left: 6rem;
        width: auto;
      }
    }
  }

  &__table {
    background: $color_white;
    height: 66px;
    margin-top: 1rem;
    table-layout: fixed;
    width: 100%;
  }

  &__tableHeader {
    border-right: 1px solid $color_keyColor_light;
    font-size: 1.3rem;
    padding: .5rem;
    width: 20%;
    vertical-align: middle;
  }

  &__tableCell {
    font-size: 1.2rem;
    padding: 1rem;
    width: 80%;
  }

  &__tableText {
    @include ellipsis;
    color: $color_subText;
    margin-bottom: .5rem;
  }

  &__conditionKey {
    &::after {
      content: "：";
    }
  }

  &__tableLink {
    text-align: right;

    a {
      @include underlined;
    }

    [class^="iconFont"] {
      &::before {
        font-size: inherit;
      }
    }
  }
}
