@charset "UTF-8";

$eventImg_path: '../img/event/';

/* イベントTOP
----------------------------------------------------------------- */
.eventTop {
  text-align: center;
  color: #000;
  font-size: 15.96px !important;
  line-height: 28.728px;

  p {
    font-size: 15.96px;
  }

  strong,
  em {
    font-style: normal;
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: bold;
    line-height: 1.2;
  }

  ul {
    padding: 0;
    margin: 5px 0 0;
  }

  li {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 1.5;
  }

  a {
    img {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }

  a:link,
  a:visited {
    color: #06c;
    text-decoration: none;
  }

  a:hover {
    img {
      filter: alpha(opacity=50);
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }

  .float_r {
    float: right;
  }

  .float_l {
    float: left;
  }

  .card {
    max-width: none;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .mainContentIn {
    width: 950px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    p {
      text-align: justify;
      text-justify: inter-ideograph;
    }

    ul.link {
      font-size: 0.875em;
      margin: 1em 0 0 1em;

      li {
        list-style-image: url(#{$eventImg_path}arrow_link.png);

        a {
          color: #0d386b;
          text-decoration: underline;
        }
      }
    }
  }

  .text {
    font-size: 16px;
  }

  .notice {
    overflow: visible;
    max-height: none;
    font-size: inherit;
    line-height: 28.728px;
  }

  #socialHeader {
    overflow: hidden;
    width: 100%;
    margin-bottom: -5px;

    div.inner {
      float: right;
    }

    div.btn {
      float: left;
      margin-right: 7px;

      &.facebook {
        width: 75px;
      }

      &.twitter {
        width: 80px;
      }
    }
  }

  .mainImage {
    background-image: url(#{$eventImg_path}mainimage.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 480px;
    width: 100%;
    overflow-y: hidden;
  }

  .mainTitle {
    background-image: url(#{$eventImg_path}mainimage_circle.png);
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    padding: 0;
    height: 480px;
    width: 950px;
    position: relative;

    h2 {
      margin: 0 0 0 -267px;
      padding: 0;
      position: absolute;
      top: 50px;
      left: 50%;
      z-index: 12;
      width: 535px;
    }

    .point01 {
      width: 160px;
      position: absolute;
      top: -10px;
      left: 145px;
      z-index: 10;
    }

    .point02 {
      width: 129px;
      position: absolute;
      top: 80px;
      left: 55px;
      z-index: 11;
    }

    .titleEvent {
      padding: 240px 0 0 350px;
      overflow: hidden;

      .titleExpo {
        float: none;
        margin: 25px 0 10px;
        width: 247px;
      }
    }

    p {
      font-size: 1.6rem;
      padding: 10px 0 0 0;
    }
  }

  .schedule {
    padding: 70px 0 125px;

    &.bgBlue {
      background-color: #f0fcff;
      padding-bottom: 65px;
    }

    h3 {
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 70px;

      &::before {
        content: url(#{$eventImg_path}icon_calender.png);
        position: relative;
        bottom: -0.16em;
        margin-right: 0.3em;
      }
    }

    .scheduleTab {
      display: flex;
      font-weight: bold;
      flex-wrap: nowrap;
      list-style: none;
      margin: 0 auto;
      overflow: hidden;
      padding: 0 0 32px 0;
      position: relative;
      width: 950px;

      &__item {
        background-color: #f1f1f1;
        border-left: 1px solid #ddd;
        color: $color_text;
        cursor: pointer;
        font-size: 14px;
        padding: 12px 0;
        position: relative;
        width: 100%;

        &:first-of-type {
          border: none;
        }

        span {
          display: block;
          font-size: 10px;
        }

        &--active {
          @extend .scheduleTab__item;
          background-color: $color_keyColor;
          border: none;
          color: $color_white;
          cursor: default;
          pointer-events: none;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            border-color: transparent;
            border-top-color: $color_keyColor;
            border-style: solid;
            border-width: 10px 8px;
            -webkit-transform: translate(-50%, 100%);
            -ms-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
          }
        }
      }
    }

    .scheduleSelector {
      background-color: $color_white;
      margin: -32px auto 30px;
      padding: 32px 0 28px 28px;
      text-align: left;
      width: 950px;

      .checkbox {
        &__text {
          display: block;
          font-size: 12px;
          margin: -33px 6px 0 25px;
        }
      }
    }

    .mainContentIn {
      width: 960px;

      p {
        margin: 0 5px 0;
      }

      a.card {
        display: block;
        float: left;
        background-color: #fff581;
        background-image: url(#{$eventImg_path}arrow_calender.png);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 28px;
        box-shadow: none;
        color: #000;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        width: 230px;
        margin: 0 5px 10px;
        position: relative;

        .prefecture {
          display: block;
          margin: 15px;
          font-size: 34px;
          font-weight: bold;
          padding: 10px;
          background-color: $color_white;
        }

        .eventName {
          display: block;
          min-height: 50px;
        }

        .eventName01 {
          display: block;
          font-weight: bold;
          line-height: 19.152px;
        }

        .eventName02 {
          color: #ff8000;
          display: block;
          font-weight: bold;
          line-height: 19.152px;
        }

        .days {
          color: #000;
          font-size: 30px;
          font-weight: bold;
          text-align: left;
          display: block;
          margin: 0 0 0 15px;
          padding-bottom: 15px;
          line-height: 1.3em;
        }
      }

      a:hover.card {
        background-color: #fffac0;
        text-decoration: none;
      }

      .card {
        background-image: url(#{$eventImg_path}bg_card.png);
        float: left;
        width: 230px;
        color: #999;
        margin: 0 5px 10px;
        position: relative;
        word-wrap: break-word;

        .prefecture {
          display: block;
          margin: 15px;
          font-size: 34px;
          font-weight: bold;
          padding: 10px;
          background-color: $color_white;
        }

        .eventName {
          display: block;
          min-height: 50px;
        }

        .days {
          font-size: 30px;
          font-weight: bold;
          text-align: left;
          display: block;
          margin: 0 0 0 15px;
          padding-bottom: 60px;
          color: #777;
          line-height: 1.3em;
        }

        .notice {
          background-color: $color_subBorder;
          display: block;
          padding: 5px 0;
          color: #777;
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        .eventName01 {
          display: block;
          font-weight: bold;
          line-height: 19.152px;
        }

        .eventName02 {
          display: block;
          font-weight: bold;
          line-height: 19.152px;
        }
      }
    }
  }

  #nav {
    color: $color_white;
    background-color: #00a3d9;

    ul {
      margin: 0 auto;
      padding: 0;
      width: 950px;
      overflow: hidden;

      li:first-child {
        width: 188px;
        border-left: 1px solid $color_white;
      }

      li {
        float: left;
        width: 189px;
        border-right: 1px solid $color_white;
        list-style: none;
        line-height: 1.5;

        a {
          display: block;
          height: 120px;
          padding-top: 35px;
          color: $color_white;
          font-size: 16px;
          font-weight: bold;
          background: url(#{$eventImg_path}nav_arrow.png) center 100px no-repeat;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }

        a:hover {
          background-color: #00c1e9;
          text-decoration: none;
        }

        a.lines {
          padding-top: 45px;
        }
      }
    }
  }

  #info {
    .notice {
      font-size: 18px;
      padding: 20px 0 20px;
      text-align: left;
      width: 950px;
      margin: 0 auto;
    }
  }

  #about {
    background: url(#{$eventImg_path}bg_about02.png) bottom repeat-x, url(#{$eventImg_path}bg_about01.png);
    padding-bottom: 230px;

    h3 {
      background-color: #00a3d9;
      border-top: 1px solid $color_white;
      color: $color_white;
      font-size: 42px;
      font-weight: bold;
      margin: 0;
      padding: 50px 0 70px;
      position: relative;
    }

    .arrow {
      height: 90px;
      width: 90px;
      position: absolute;
      left: 50%;
      bottom: -45px;
      margin-left: -45px;
    }

    .mainContentIn {
      p {
        color: $color_white;
        text-align: left;
        margin-bottom: 2em;
      }

      p:last-of-type {
        margin-bottom: 4em;
      }
    }

    h4 {
      font-size: 30px;
      font-weight: bold;
      color: #fd0;
      margin: 120px 0 70px 0;

      span {
        font-size: 55px;
        display: block;
      }
    }
  }

  #merit {
    padding: 80px 0 60px;

    .mainContentIn {
      h3 {
        font-size: 30px;
        color: #00a3d9;
        font-weight: bold;
        margin-bottom: 80px;

        span {
          font-size: 60px;
          display: block;
        }
      }

      .point {
        background-color: #d9f1f9;
        border: 20px solid #ebfaff;
        margin: 70px 0 0 0;
        padding: 40px 40px 30px 150px;
        text-align: left;
        position: relative;
        background-repeat: no-repeat;
        background-position: right bottom;

        .ribon {
          position: absolute;
          left: 15px;
          top: -44px;
          width: 105px;
          height: 170px;
        }

        h4 {
          font-size: 34px;
          font-weight: bold;
          color: #0d386b;
          margin-bottom: 0.5em;
        }
      }

      .point01 {
        background-image: url(#{$eventImg_path}bg_point01.png);
      }

      .point02 {
        background-image: url(#{$eventImg_path}bg_point02.png);
      }

      .point03 {
        background-image: url(#{$eventImg_path}bg_point03.png);
      }
    }
  }

  #people {
    background: url(#{$eventImg_path}bg_people01.png);
    padding-bottom: 50px;

    h3 {
      background: url(#{$eventImg_path}bg_people02.png);
      color: $color_white;
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      padding: 50px 0 70px;
      position: relative;

      span {
        font-size: 60px;
        display: block;
      }

      .arrow {
        height: 90px;
        width: 90px;
        position: absolute;
        left: 50%;
        bottom: -45px;
        margin-left: -45px;
      }
    }

    .mainContentIn {
      .card {
        background-color: $color_white;
        margin: 0 0 30px 0;
        padding: 40px;
        float: left;
        text-align: left;
        overflow: hidden;

        &.type01 {
          width: 950px;

          .text {
            width: 400px;

            h4 {
              font-size: 30px;
              color: #ff8000;
              margin-bottom: 1em;
            }
          }

          .img {
            width: 450px;

            img {
              width: 100%;
            }
          }
        }

        &:first-of-type {
          margin-top: 100px;
        }

        .text {
          h4 {
            font-size: 30px;
            color: #ff8000;
            margin-bottom: 1em;
          }
        }

        &.type02 {
          width: 460px;

          .img {
            width: 380px;
            margin-top: 1em;

            img {
              width: 100%;
            }
          }
        }

        &.type02:last-of-type {
          float: right;
        }
      }

      .note {
        clear: both;
        font-size: 13.965px;
        text-align: right;
        line-height: 1.5;
      }
    }
  }

  #voice {
    padding: 70px 0;

    .mainContentIn {
      .wrapper {
        background: url(#{$eventImg_path}bg_voice_fukidashi.png) no-repeat top right, url(#{$eventImg_path}bg_voice.png);
        padding: 80px 20px 20px 50px;
        background-color: #d9f1f9;
        overflow: hidden;

        h3 {
          font-size: 30px;
          color: #0d386b;
          font-weight: bold;
          margin-bottom: 70px;

          span.large {
            font-size: 60px;
            display: block;
          }

          span.small {
            font-size: 20px;
            display: block;
          }
        }

        .card {
          font-size: 13.965px;
          margin: 0 30px 30px 0;
          padding: 30px 35px 25px 40px;
          width: 405px;
          float: left;
          background-color: $color_white;
          text-align: left;
          position: relative;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;

          p {
            font-size: 13.965px;
          }

          .icon {
            width: 60px;
            height: 60px;
            position: absolute;
            top: -15px;
            left: -15px;
            background-size: 60px;
          }

          strong {
            font-size: 1.285em;
          }

          &.men {
            .icon {
              background-image: url(#{$eventImg_path}icon_voice_men.png);
            }

            strong {
              color: #0f3a6d;
            }
          }

          &.card.women {
            .icon {
              background-image: url(#{$eventImg_path}icon_voice_women.png);
            }

            strong {
              color: #ff8000;
            }
          }
        }
      }
    }
  }

  #qa {
    background: url(#{$eventImg_path}bg_qa01.png);
    padding-bottom: 80px;

    h3 {
      padding: 65px 0 50px;
      font-size: 30px;
      background: url(#{$eventImg_path}bg_qa03.png) no-repeat 85% center, url(#{$eventImg_path}bg_qa02.png);
      background-size: 260px, auto;
      margin-bottom: 60px;

      span {
        font-size: 60px;
        display: block;
      }
    }

    .mainContentIn {
      width: 950px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;

      .card {
        text-align: left;
        width: 450px;
        float: left;
        margin: 0 50px 50px 0;
        padding-bottom: 1em;
        border-bottom: solid 1px #cecebf;

        h4 {
          font-size: 18px;
          background: url(#{$eventImg_path}icon_qa_q.png) no-repeat;
          background-size: 22px;
          padding-left: 30px;
          padding-top: 0.1em;
          margin-bottom: 1em;
        }

        p {
          font-size: 0.875em;
          background: url(#{$eventImg_path}icon_qa_a.png) no-repeat 0 5px;
          background-size: 22px;
          padding-left: 30px;

          & + p {
            margin-top: 1em;
            background: none;
          }

          &.note {
            font-size: 12px;
            background: none;
          }
        }

        &:nth-child(2n) {
          margin: 0 0 50px 0;
        }
      }
    }
  }

  // 画面の上部に表示するバナー
  &--banner {
    margin-top: 70px;
  }

  // 画面の下部に表示するバナー
  #banner {
    padding: 98px 0 0 0;

    .mainContentIn {
      .img {
        width: 450px;
      }
    }
  }

  .pagetop {
    position: fixed;
    bottom: 150px;
    right: 20px;
    width: 90px;
    height: 90px;
    z-index: 13;
  }

  .bottomLink {
    background: url(#{$eventImg_path}bg_bottomLink.png);
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0 0;
    transition: all 0.5s;
    z-index: 14;

    a.card {
      display: block;
      float: left;
      background-color: #fff;
      background-image: url(#{$eventImg_path}arrow_calender.png);
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 28px;
      color: #000;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      min-height: 95px;
      width: 230px;
      margin: 0 10px 10px 0;
      position: relative;

      &:nth-child(4n) {
        margin: 0 0 10px 0;
      }

      .prefecture {
        display: block;
        margin: 15px;
        font-size: 32px;
        font-weight: bold;
        padding: 0;
        color: #0d386b;
      }

      .days {
        font-weight: bold;
        text-align: left;
        display: block;
        margin: 0 10px 0 15px;
        padding-bottom: 0;
        color: $color_black;
      }
    }

    &.isHidden {
      bottom: -250px;
      opacity: 0.01;
      pointer-events: none;
    }

    &.dummy {
      display: block;
      position: relative;
      visibility: hidden;
    }
  }

  #knowhowContents {
    text-align: left;
    margin-top: 65px !important;

    .KowsearchContainer {
      padding: 15px 25px 10px 25px;
      width: 865px;
      margin: 0 auto 20px;

      #jobSearch {
        margin-left: 10px;
        margin-right: 50px;
        padding-right: 50px;
        border-right: none;

        .job {
          margin-bottom: 30px;
        }
      }

      #areaSearch {
        width: 320px;
      }

      .searchBlock {
        float: left;
        display: inline;
        width: 370px;

        h4 {
          font-weight: normal;
          color: #443925;
          font-size: 14px;
          margin-bottom: 10px;
          padding-bottom: 8px;
          border-bottom: 1px solid #d8d8d8;

          span {
            font-size: 16px;
            padding-right: 2px;
          }
        }

        ul {
          li {
            font-size: 14px;
            margin: 4px;
            padding-left: 12px;
            background: url(#{$eventImg_path}icon_link_a_01.png) 0 8px no-repeat;
          }
        }

        ul.search-list-v {
          & > li {
            clear: both;
          }

          li {
            ul {
              overflow: hidden;
              width: 210px;
              float: right;

              li {
                float: left;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .title {
      position: relative;
      margin-bottom: 15px;
      padding: 0 0 12px 0;
      border-bottom: 1px solid #d8d8d8;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
    }

    .title::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      display: inline-block;
      width: 800px;
      height: 1px;
    }
  }
}

// iPad
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .eventTop {
    div:nth-child(3) {
      .mainContentIn {
        a.card {
          .days {
            font-size: 26px;
          }
        }
      }
    }
  }
}
