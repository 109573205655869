@charset "UTF-8";

$job-banner-hero-height: 200px;

.jobBannerHero {
  $component: &;
  color: #000;
  height: $job-banner-hero-height;
  overflow: hidden;
  padding: 0 1.5rem;
  position: relative;

  &::before {
    background: #ff8401;
    background: linear-gradient(90deg, #ff8401, #ffd24d);
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transform: skewX(20deg) translateX(-360px);
  }

  &__title {
    @include clearfix;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.2;
    margin: 3rem 0 1.5rem 1.5rem;
  }

  &__titleIcon {
    float: left;
    width: 5rem;
    height: 5rem;
  }

  &__titleText {
    float: left;
    left: 6.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__text {
    font-size: 1.3rem;
    line-height: 2;
    min-height: 1rem * 1.6 * 3;
    margin-left: 1.5rem;
    width: 72rem;
  }

  &__title,
  &__text {
    position: relative;
    z-index: 3;
  }

  &__image {
    bottom: 0;
    display: block;
    height: $job-banner-hero-height;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
