@charset "UTF-8";

.pullDown {
  &__list-scout {
    z-index: 22;

    & .children {
      padding-left: 43px !important;
    }

    & .grandChild {
      padding-left: 56px !important;
    }
  }

  &__list-scoutBottom {
    z-index: 1;
  }

  &__trigger {
    &-scout {
      min-width: inherit;
      padding: 8px 24px 8px 14px;
    }
  }
}
