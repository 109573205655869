@charset "UTF-8";

//企業一覧

//企業情報・会社概要から探す

.companiesInfo {
  padding-bottom: 0;

  .topSection__subtitle {
    width: 674px;
  }
}

.jobInformationList {
  .tabNaviRecruit {
    width: 100%;
  }

  .topSection__subtitle {
    width: 674px;
  }
}

.content__white {
  background-color: #fff;
  padding: 30px 28px 40px;

  .leftLogoBlock {
    padding-right: 40px;
  }

  .headingPage {
    margin-bottom: 8px;
  }
}

.container__margin {
  margin-top: 40px;

  .headingBlock {
    padding: 30px 28px 20px;
  }

  .card__content {
    padding: 30px 28px 40px;
  }
}

.pd-0 {
  padding: 0;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-24 {
  margin-top: 24px;
}

.income-example {
  &__content {
    border: 1px solid #ddd;
    margin: 0;
    padding: 16px;
    width: 290px;
  }

  &__content:not(:last-child) {
    margin-right: 12px;
  }

  &__industry {
    background: #cae9ff;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    height: 15px;
    margin-bottom: 8px;
    text-align: center;
    width: 142px;
  }

  &__text {
    line-height: 21px;
    margin-bottom: 8px;
  }

  &__date {
    color: #333;
    font-size: 10px;
    line-height: 15px;
    text-align: right;
  }
}

.companyBlog {
  &__card {
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 290px;
  }

  &__img {
    position: relative;

    img {
      border-radius: 6px 6px 0 0;
      width: 100%;
    }
  }

  &__date {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    left: 16px;
    position: absolute;
    bottom: 14px;
  }

  &__content {
    padding: 16px;
    height: 175px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    line-height: 24px;
    max-height: 68px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__read-text {
    color: #333;
    line-height: 21px;
    margin-bottom: 12px;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 42px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__company-name {
    color: #333;
    font-size: 12px;
    line-height: 18px;
    margin: auto 0 0;
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
}

.arrow {
  &__content {
    align-items: center;
    display: flex;
    line-height: 21px;
  }

  &__icon {
    background: url(../img/common/blueArrow.svg);
    background-repeat: no-repeat;
    display: inline-block;
    height: 22px;
    margin-right: 8px;
    width: 20px;
  }
}
