@charset "UTF-8";

.navItemList {
  $component: &;

  &--separated {
    #{$component}__item + #{$component}__item {
      margin-top: 1rem;
    }
  }
}

.navItem {
  @include onHover;
  $component: &;
  border: 0;
  background-color: $color_white;
  display: block;
  cursor: pointer;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 1.5rem 3rem 1.5rem .5rem;
  position: relative;
  text-align: left;
  width: 100%;

  &::after {
    border-right: 2px solid $color_keyColor;
    border-top: 2px solid $color_keyColor;
    content: "";
    display: block;
    height: 8.5px;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: rotate(45deg) translateY(-80%);
    width: 8.5px;
  }

  &__title {
    color: $color_keyColor;
    font-weight: bold;
    margin: 0;
  }

  &__title + &__text {
    font-size: 1.2rem;
    // タイトルの下にある時だけタイトルとの距離を設定する
    margin: 10px 0 0 0;
  }

  &__text {
    color: $color_text;
  }

  &--blur {
    &::before {
      background-image:
        linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 75%,
          rgba(255, 255, 255, 1) 85%
        );
      content: "";
      height: 100%;
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  &--strong {
    background-color: $color_keyColor_light;
    border-radius: 2px;
    padding: 2rem 3rem 2rem 1.5rem;

    #{$component}__title {
      color: $color_text;
    }

    #{$component}__title + #{$component}__text {
      margin: 15px 0 0 0;
    }
  }

  &--outline {
    border: 1px solid $color_keyColor;
    border-radius: 2px;
    padding: 1.2rem 3rem 1.2rem 1.5rem;

    &::after {
      right: 2.6rem;
    }

    #{$component}__title {
      line-height: 1;
    }

    #{$component}__text {
      height: 18px;
    }

    #{$component}__title + #{$component}__text {
      margin: .5rem 0 0 0;
    }
  }

  &--useScout {
    padding-bottom: 2.4rem;
    padding-top: 2.4rem;

    &::after {
      display: none;
    }
  }

  &__alert {
    color: $color_red;
  }

  &__badge {
    background-color: $color_red;
    border-radius: 99999px;
    color: $color_white;
    font-size: 1rem;
    display: inline-block;
    line-height: 1;
    margin: 0 .5rem 0 .2rem;
    padding: .4rem .8rem;
    transform: translateY(-1px);
  }

  &--icon {
    padding-left: 6rem;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      left: 2rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--iconMenuScout {
    &::before {
      background: url('../img/top/sprite.png') -10px -10px;
      height: 15px;
      width: 20px;
    }
  }

  &--iconMenuMessage {
    &::before {
      background: url('../img/top/sprite.png') -10px -45px;
      height: 22px;
      width: 20px;
    }
  }

  &--iconMenuWebResume {
    &::before {
      background: url('../img/top/sprite.png') -10px -87px;
      height: 18px;
      width: 20px;
    }
  }

  &--iconMenuMySearch {
    &::before {
      background: url('../img/top/sprite.png') -10px -125px;
      height: 20px;
      width: 20px;
    }
  }

  &__jobNum {
    display: inline-block;

    &::before {
      content: "（";
    }

    &::after {
      content: " 件）";
    }
  }
}
