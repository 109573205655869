@charset "UTF-8";

.includeError {
  $component: &;
  border: 1px solid #d9d9d9;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 6rem 6rem 6rem 12rem;
  position: relative;

  &::before {
    background: url('../img/top/sprite.png') -10px -736px;
    content: "";
    display: block;
    height: 51px;
    left: 5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 39px;
  }

  &--hero {
    float: left;
    width: 590px;
    height: 340px;
    margin-top: 2rem;
    position: relative;

    #{$component}__inner {
      padding-right: 2rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
