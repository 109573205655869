@charset "UTF-8";

.noMySearch {
  border: 1px solid $color_keyColor;
  height: 347px + 136px + 13px;
  position: relative;

  &__inner {
    padding: 0 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__text {
    color: $color_keyColor;
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 2rem;
    text-align: center;
  }

  &__button {
    @include topDashboardButton;
  }
}
