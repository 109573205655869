@charset "UTF-8";

.colorBox {
  padding: 0 30px;
  background-color: #fdf3f3;

  &__header {
    border-bottom: 1px dashed #ee8835;
    font-weight: 700;
    padding-top: 30px;
  }

  &__tag {
    font-size: 1.4rem;
    color: #ed644c;
    line-height: 2em;
    border-radius: 15px;

    &--yellow {
      color: #ee8835;
    }
  }

  &__title {
    color: #ed644c;
    font-size: 2em;
    font-weight: bold;
    margin-left: -10px;
    padding-bottom: 10px;

    &--yellow {
      color: #ee8835;
      margin-left: 0;
    }
  }

  &--yellow {
    background-color: #fefce9;
    padding-bottom: 15px;

    header {
      margin-bottom: 20px;
    }
  }
}
