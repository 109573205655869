@charset "UTF-8";

.topAreaLinkList {
  $component: &;
  margin-top: -17px;

  &__item {
    height: 50px;
  }

  &__area {
    text-align: left;
    vertical-align: middle;
    width: 108px;

    a {
      @include dotArrow;
      color: $color_keyColor;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  &__prefecture {
    vertical-align: middle;
  }
}

.topPrefectureList {
  @include clearfix;

  &__item {
    float: left;
    margin-right: 10px;

    a {
      @include onHover;
      border: 1px solid $color_keyColor_light;
      color: $color_keyColor;
      display: inline-block;
      font-size: 1.3rem;
      font-weight: bold;
      padding: 8px 12px;
    }
  }
}
