.interviewDateInfo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;

  &__none {
    display: none;
  }

  &Area {
    background: #E8F9FF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 950px;
  }

  &__text {
    color: $color_heading;
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__btn {
    background: #00AAEB;
    border-radius: 4px;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
    min-height: auto;
    min-width: auto;
    padding: 8px 30px;
  }
}
