@charset "UTF-8";

.agentDetail {
  &__agentImg {
    text-align: center;
    padding: 5.8px 10px;

    > img {
      max-width: 100%;
    }
  }
}
