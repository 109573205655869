@charset "UTF-8";

$top-hero-width: 1200px;
$top-hero-slider-width: 590px;
$top-hero-newArrival-width: 610px;

$top-width: 1140px;
$top-column-side-width: 240px;
$top-column-main-width: 850px;

$top-search-bg-color: #f1f1f1;
$top-cream-bg-color: #fffbed;

$top-card-width: 250px;
$top-card-title-size: 1.6rem;
$top-card-title-line-height: 1.5;

$top-short-width: 950px;

$top-subsite-width: 980px;

$img-path-top: '../img/top/';

@mixin onHover {
  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }
}

@mixin underlined {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@mixin ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin dotArrow($color: $color_keyColor, $left: 0) {
  padding-left: 30px;
  position: relative;

  &::before {
    background: $color;
    border-radius: 99999px;
    content: '';
    height: 20px;
    left: $left;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 20px;
  }

  &::after {
    border-right: solid 2px $color_white;
    border-top: solid 2px $color_white;
    content: '';
    height: 6px;
    left: $left + 6px;
    margin-top: -3px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 6px;
  }
}

@mixin dotArrowSmall($color: $color_keyColor, $left: 0) {
  @include dotArrow($color, $left);
  padding-left: 20px;

  &::before {
    height: 14px;
    margin-top: -7px;
    width: 14px;
  }

  &::after {
    border-width: 1px;
    height: 4px;
    left: $left + 4px;
    margin-top: -2px;
    width: 4px;
  }
}

@mixin topDashboardButton {
  @include onHover;
  background-color: $color_white;
  border: 1px solid $color_red;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
  color: $color_red;
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  height: 4rem;
  position: relative;
  width: 100%;

  &::after {
    border-right: solid 2px $color_red;
    border-top: solid 2px $color_red;
    content: '';
    height: .6rem;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
    width: .6rem;
  }

  span {
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}
