@charset "UTF-8";

.saveMidway {
  margin: 0 auto;
  margin-top: 18px;
  width: 950px;

  a:hover {
    text-decoration: none;
  }

  &__content {
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 2px;
    display: -ms-flexbox;
    display: flex;
    height: 44px;
    position: relative;
    text-decoration: none;
    -ms-flex-align: center;
  }

  &__tooltip {
    color: $color_red;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 400;
    height: 25px;
    line-height: 1;
    margin: -15px 0 0 200px;
    position: absolute;
    transform: translateX(-50%);
    z-index: 10;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);

    &--top {
      z-index: 2;
    }

    &::before {
      border: 6px solid transparent;
      border-left-width: 4px;
      border-right-width: 4px;
      border-top-color: $color_red;
      bottom: -6px;
      content: '';
      left: 50%;
      position: absolute;
      z-index: 1;
    }

    &::after {
      border: 6px solid transparent;
      border-left-width: 4px;
      border-right-width: 4px;
      border-top-color: $color_matchingLight;
      bottom: -5px;
      content: '';
      left: 50%;
      position: absolute;
      z-index: 1;
    }

    span {
      background-color: $color_matchingLight;
      border: 1px solid $color_red;
      border-radius: 4px;
      display: block;
      height: 20px;
      padding: 3px 8px 0;
      white-space: nowrap;
    }
  }

  .requestInfo__text {
    color: $color_heading;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    padding: 15px 0;
    padding-left: 15px;

    &::before {
      content: "\e603";
      color: #000;
      font-family: mynavi; /* stylelint-disable-line */
      font-size: 1.4rem;
      line-height: 11px;
      position: absolute;
      right: 15px;
      speak: none;
      text-decoration: none;
      text-rendering: auto;
      text-transform: none;
      top: 50%;
      transform: translateY(-50%);
      vertical-align: top;
      z-index: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
    }
  }
  
  .interview__number {
    color: $color_keyColor;
  }
}
