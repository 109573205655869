@charset "UTF-8";

.privateList {
  @include scoutFlex(space-between, center, nowrap);
  border: 1px solid $scout-red;
  padding: 0 55px 0 50px;
  height: 174px;
  margin-top: 30px;

  &__limited {
    font-size: 1.3rem;
    font-weight: $scout_bold;
    text-align: center;
    background: $scout-red;
    color: $scout-white;
    border-radius: 20px;
    padding: .8rem 0;
    line-height: 1;
    width: 150px;
    margin: 10px auto;
  }

  &__about {
    font-size: 1.6rem;
    font-weight: $scout_bold;
    text-align: center;
    color: $scout-red;
    margin-top: 27px;
  }

  &__box {
    > img {
      vertical-align: middle;
      width: 140px;
    }
  }

  &__item {
    width: 664.5px;
    margin: 15px 0;
    padding: 5px 0 0 25px;
    font-size: 1.4rem;
    font-weight: $scout_bold;
    line-height: 1.4;
    letter-spacing: 0.04em;
    background: url(#{$scout_path}listicon_balloon.png) no-repeat 0 5px;
  }
}
