@charset "UTF-8";

.jobSearchForm {
  background-color: #f8f8f8;
  padding: 10px;

  &__text {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
  }

  input {
    font-size: 1.3rem;
  }
}
