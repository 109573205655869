@charset "UTF-8";

.topHeader {
  background-color: $color_white;
  padding-bottom: 1rem;
  padding-top: 1.7rem;
  position: sticky;
  top: -.7rem;
  z-index: 20;

  &__noSticky {
    position: static;

    &__border {
      position: relative;
      box-shadow: 0 4px 5px -2px rgba(107, 107, 107, .3);
      margin-top: 7px;
    }
  }

  &__main {
    @include clearfix;
    height: 50px;
  }

  &__logo {
    float: left;
    transform: translateY(6px);
    width: 220px;

    img {
      width: 100%;
    }
  }

  &__controls {
    @include clearfix;
    float: right;
    height: 50px;

    .topSearch {
      float: left;
    }
  }

  &__nav {
    &--login {
      float: right;
      width: 235px;

      .topSubMenuList {
        float: right;
      }
    }
  }

  &__logout {
    margin-bottom: 1rem;
    margin-top: .2rem;
    text-align: right;
  }

  &__logoutLink {
    font-size: 1.3rem;
  }

  &__loginCTA {
    @include onHover;
    border: 1px solid $color_subBorder;
    border-radius: 2px;
    color: $color_keyColor;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
    height: 50px;
    margin-left: 1rem;
    padding: 18px 8px 18px 36px;
    position: relative;
    transform: translateY(-9px);

    &::before {
      background: url('../img/top/sprite.png') -10px -165px;
      content: "";
      display: inline-block;
      height: 20px;
      left: 7px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
    }
  }

  &__registerCTA {
    @include onHover;
    background-color: $color_red;
    border: 1px solid $color_red;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    color: $color_white;
    display: inline-block;
    font-size: 1.4rem;
    height: 50px;
    margin-left: 1rem;
    padding: 8px 18px;
    text-align: center;
    width: 110px;

    &--badge {
      background-color: $color_white;
      border-radius: 7px;
      color: $color_red;
      display: block;
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 5px;
      padding: 1px 0;
    }
  }
}

.areaNavi {
  &__list {
    @include clearfix;
    align-items: center;
    display: flex;
  }

  &__item {
    float: left;

    &::before,
    &:last-child::after {
      color: #ccc;
      content: "|";
      display: inline-block;
    }
  }

  &__link {
    @include onHover;
    color: inherit;
    display: inline-block;
    font-size: 1.2rem;
    padding: 0 8px 0 6px;
  }
}
