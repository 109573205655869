@charset "UTF-8";

.topEvents {
  &__title {
    @include clearfix;
    margin-bottom: 1.5rem;

    img {
      float: left;
      margin-right: 2rem;
    }

    .js__lazy {
      height: 65px;
      width: 180px;
    }
  }

  &__list {
    @include clearfix;
  }

  &__item {
    width: 415px;

    // 奇数
    &:nth-child(odd) {
      float: left;
    }

    // 偶数
    &:nth-child(even) {
      float: right;
    }

    // 3つめ以降
    &:nth-child(n+3) {
      margin-top: 20px;
    }

    a {
      @include onHover;
      color: inherit;
      display: block;
    }
  }
}

.topEvent {
  background-color: $color_premium3;
  border-bottom: 1px solid #f48502;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
  padding: 1rem;

  &__title {
    @include dotArrow(#f48502, 10px);
    background-color: $color_white;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    margin-right: 2rem;
    padding: .6rem 1rem .6rem 4rem;
  }

  &__schedule {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: -.4rem;
    margin-top: .6rem;
  }
}
