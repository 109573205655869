@charset "UTF-8";

.scoutBenefits {
  letter-spacing: -.4em;

  &-scoutLimited {
    display: inline-block;
  }

  &__item {
    position: relative;
    display: inline-block;
    width: auto;
    vertical-align: middle;
    padding: 5px 5px 0 0;
    letter-spacing: 0;

    &-top {
      padding: 9px 10px 0 0;
    }

    & .tooltip {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 48px;
      display: none;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);

      &-scoutLimited {
        top: 42px;
      }

      &::before {
        left: 15px;
        transform: translateX(50%);
      }

      &::after {
        left: 15px;
        transform: translateX(50%);
      }
    }

    .labelProInterview {
      border: 1px solid;
      // 追加コンテンツラベル
      .icon-movie {
        background-image: url("../img/glyphicons-halflings.png");
        background-position: -192px 0;
        background-repeat: no-repeat;
        display: inline-block;
        filter: invert(54%) sepia(82%) saturate(1590%) hue-rotate(6deg) brightness(0) invert(1) contrast(101%);
        height: 14px;
        line-height: 14px;
        margin-right: 5px;
        margin-top: -3px;
        vertical-align: middle;
        width: 14px;
      }
    }
  }

  $scoutBenefits: 13;
  @for $i from 1 through $scoutBenefits {
    &__item:nth-child(#{$i}) {
      z-index: $scoutBenefits - $i;
    }
  }

  &__item-scoutLimited:nth-child(1) {
    z-index: $scoutBenefits;
    padding: 0;
  }

  &__icon {
    color: $scout-lightblue-color;
    display: inline-block;
    margin: 0;
    padding: 0 9px;
    font-size: 1.1rem;
    font-weight: $scout_bold;
    letter-spacing: normal;
    border: 1px solid $scout-lightblue-color;
    border-radius: 5px;
    background-color: $scout-white;
    position: relative;
    z-index: 2;

    &::before {
      content: url(#{$scout_path}listicon_privilege.png);
      vertical-align: middle;
      padding-right: 4px;
      position: relative;
      top: 1px;
    }

    &:hover + .tooltip {
      display: block;
    }
  }
}
