@charset "UTF-8";

/*! =======================================================================
 * File        : header.css
 * DESCRIPTION : ヘッダデザイン用CSS
 * AUTHOR      : i-studio Co.,Ltd.
 * ------------------------------------------------------------------------
 * (C)Mynavi Corporation
========================================================================== */

/* header
----------------------------------------------------------------- */
.header {
  position: relative;
  z-index: 4;
}

.header__content {
  @include boxCenter;
  @include clearfix;
  padding-bottom: 13px;
}

.header__left {
  float: left;
}

.header__logo {
  margin-right: 20px;
  float: left;
}

.menu_flex {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .area_menu {
    display: table;
    border: solid 1px #d7f0fa;
    border-collapse: collapse;
    height: fit-content;
    margin: 0 40px 0 20px;
    text-align: center;

    .area_menu_type {
      background-color: #d7f0fa;
      display: table-cell;
      font-size: 17px;
      vertical-align: middle;
      width: 80px;
    }

    .area_menu_list {
      border-bottom: solid 1px #d7f0fa;
      display: table;
      margin-right: -1px;
    }
  }
}

.header__txt {
  margin: 7px 0 17px;
  width: 519px;
  color: #666;
  font-size: 1.1rem;
  font-weight: normal !important;
  line-height: 1.5;
}

.header__right {
  float: right;
  width: 341px;
  font-size: 0;
  text-align: right;
}

.header__right .btnSignUp {
  display: inline-block;
  min-width: 180px;
  margin: 7px 7px 7px 0;
  padding: 13px 0;
}

.area_menu {
  text-align: center;
  border: solid 1px #d7f0fa;
  display: table;
  border-collapse: collapse;
}

.area_menu .area_menu_list {
  margin-right: -1px;
  border-bottom: solid 1px #d7f0fa;
  display: table;
}

.area_menu .area_menu_list:last-child {
  border-bottom: none;
}

.area_menu .area_menu_list li {
  width: 51px;
  vertical-align: middle;
  border-right: solid 1px #d7f0fa;
  display: table-cell;
  border-collapse: collapse;
}

.area_menu .area_menu_list li a {
  padding: 4px 0;
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: #666;
  display: block;
}

.area_menu dd {
  width: 51px;
  border: solid #d7f0fa;
  border-width: 1px 0 0 1px;
  float: left;
}

.area_menu dd a {
  font-size: 1.1rem;
  color: #666;
  display: block;
}

.header__nav {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.header__nav li {
  display: inline-block;
  border-left: 1px solid #ddd;
}

.header__nav a {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 64px;
  margin: 2px 2px 0;
  padding-top: 45px;
  font-size: 10px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
}

.header__info.js--headerNoticeHover a,
.header__menu.js--headerMenuHover a,
.header__menu:hover a,
.header__info:hover a {
  background-color: #e0eff5;
}

.header__menu a {
  @include icon;
}

.header__login a {
  @include icon(8px, 0, 0, 0 auto, 26px, 26px, 0, 0);
  color: $color_red;
}

.header__logout a {
  @include icon(8px, 0, 0, 0 auto, 26px, 26px, -26px, 0);
}

.header__login a:hover,
.header__logout a:hover {
  opacity: 0.5;
}

.header__info a {
  @include icon(7px, 0, 0, 0 auto, 26px, 31px, -52px, 0);
}

.header__lineTxt {
  padding-right: 14px;
  margin-top: 16px;
  font-size: 1.4rem;
  text-align: right;
}

/* 下層ヘッダー
----------------------------------------------------- */
.headerLower {
  @extend .header;
  border-bottom: 1px solid #ddd;
}

.headerLower__content {
  @extend .header__content;
  padding: 16px 0;
}

.headerLower__left {
  @extend .header__left;
}

.headerLower__logo {
  @extend .header__logo;
}

/* 別ウィンドウヘッダー
----------------------------------------------------- */
.headerOtherWindow {
  @extend .header;
  border-bottom: 1px solid #ddd;
}

.headerOtherWindow__content {
  @extend .header__content;
  padding-bottom: 16px;
}

.headerOtherWindow__left {
  @extend .header__left;
}

.headerOtherWindow__logo {
  margin: 10px 0 0 10px;
}

.headerOtherWindow__right {
  @extend .header__right;
}

.headerOtherWindow__right .btnClose {
  margin: 10px 10px 0 0;
}

/* slideDownMenu
----------------------------------------------------- */
.slideDownMenu {
  @include slideDown;
}

.slideDownMenu__content {
  @include boxCenter;
  position: relative;
  min-height: 54px;
}

.slideDownMenu .btnClose {
  position: absolute;
  top: 10px;
  right: 20px;
}

.slideDownMenu__item {
  @include clearfix;
  border-bottom: 1px solid #99ddf7;
}

.slideDownMenu__item:last-child {
  border-bottom: none;
}

.slideDownMenu .columnList--3col {
  width: 726px;
  padding: 41px 0 0;
}

.slideDownMenu .headingContent {
  margin: 0 0 32px;
}

.slideDownMenu__areaBlock {
  float: left;
  width: 488px;
  padding: 33px 0 36px;
}

.slideDownMenu__jobBlock {
  float: left;
  width: 462px;
  padding: 33px 0 36px;
}

.slideDownMenu__jobBlock .columnList--2col li {
  margin-bottom: 17px;
  font-size: 1.3rem;
}

.slideDownMenu__loading {
  padding: 20px 0;
  text-align: center;
}

.slideDownMenu__noticeText {
  padding: 20px 0;
  text-align: center;
  font-size: 1.2rem;
}

.slideDownMenu .noticeBlock {
  overflow-y: scroll;
  max-height: 254px;
}

.slideDownMenu .linkArrow {
  font-size: 1.5rem;
}

/* gnav
----------------------------------------------------- */
.gnav {
  position: relative;
  z-index: 3;
  background-color: $color_keyColor;
  box-shadow: $boxShadow_defult;
}

.gnav__logout,
.gnav__login {
  @include boxCenter;
  @include clearfix;
  position: relative;
}

.gnav__list {
  float: left;
  font-size: 0;
  border-left: 1px solid #4dc4f1;
}

.gnav__item--search {
  @include navlink(234px);
}

.gnav__item--search a {
  @include icon(16px, 0, 47px, 0, 24px, 24px, 0, -31px);
}

.gnav__item--search:hover a::before,
.gnav__item--search.js--gnaviHover a::before {
  background: url(#{$img_path}sprite.png) -24px -31px no-repeat;
}

.gnav__item--knowHow {
  @include navlink(187px);
}

.gnav__item--knowHow a {
  @include icon(17px, 0, 28px, 0, 26px, 24px, -48px, -31px);
}

.gnav__item--knowHow:hover a::before,
.gnav__item--knowHow.js--gnaviHover a::before {
  background: url(#{$img_path}sprite.png) -74px -31px no-repeat;
}

.gnav__item--event {
  @include navlink(187px);
}

.gnav__item--event a {
  @include icon(14px, 0, 29px, 0, 25px, 24px, -100px, -31px);
}

.gnav__item--event:hover a::before,
.gnav__item--event.js--gnaviHover a::before {
  background: url(#{$img_path}sprite.png) -125px -31px no-repeat;
}

.gnav__item--myPage {
  @include navlink(148px);
}

.gnav__item--myPage a {
  @include icon(16px, 0, 21px, 0, 17px, 24px, -150px, -31px);
}

.gnav__item--myPage:hover a::before,
.gnav__item--myPage.js--gnaviHover a::before {
  background: url(#{$img_path}sprite.png) -167px -31px no-repeat;
}

.gnav__info {
  position: relative;
  float: right;
  width: 335px;
  height: 56px;
  font-size: 0;
  text-align: center;
  border-right: 1px solid #4dc4f1;
}

.gnav__infoItem {
  display: inline-block;
  margin: 13px 10px 0 0;
}

.gnav__infoItem:last-child {
  margin: 13px 0 0;
}

.gnav__infoItem > a,
.gnav__baloonHoverArea > a {
  position: relative;
  z-index: 61;
  display: inline-block;
  min-width: 82px;
  padding: 7.5px 21px;
  color: $color_white;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
  border-radius: 20px;
  background-color: #0088bc;
  box-sizing: border-box;
}

.gnav__infoItem > a:hover,
.gnav__baloonHoverArea:hover > a {
  opacity: 0.5;
  text-decoration: none;
}

.gnav__baloonHoverArea {
  position: relative;
}

.gnav__baloonHoverArea:hover .gnav__baloon {
  display: block;
}

.gnav__baloonHoverArea:hover {
  height: 80px;
}

.gnav__baloon {
  position: absolute;
  z-index: 60;
  top: 48px;
  left: 50%;
  display: none;
  width: 326px;
  margin: 0 0 0 -163px;
  padding: 23px;
  font-size: 0;
  background-color: #e0eff5;
  box-shadow: $boxShadow_defult;
  box-sizing: border-box;
}

.gnav__baloon::before {
  content: "";
  position: absolute;
  z-index: 100;
  top: -40px;
  left: 153px;
  display: block;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #e0eff5;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.gnav__baloon [class^="btn"] {
  min-width: 135px;
  padding: 12px 10px;
}

.gnav__baloon .btnSecondaryS {
  margin-left: 10px;
}

.gnav__baloonText {
  margin: 0 0 15px;
  color: #333;
  font-size: 1.2rem;
  line-height: 1.6666;
  text-align: left;
}

/* gnav__login
----------------------------------------------------- */
.gnav__login .gnav__item--search {
  @include navlink(199px);
}

.gnav__login .gnav__item--search a::before {
  left: 30px;
}

.gnav__login .gnav__item--knowHow {
  @include navlink(149px);
}

.gnav__login .gnav__item--knowHow a::before {
  left: 11px;
}

.gnav__login .gnav__item--event {
  @include navlink(148px);
}

.gnav__login .gnav__item--event a::before {
  left: 13px;
}

.gnav__login .gnav__item--myPage {
  @include navlink(148px);
}

.gnav__login .gnav__info {
  width: 298px;
}

.gnav__login .gnav__infoItem {
  margin: 8px 2px 0;
}

.gnav__login .gnav__infoItem > a {
  min-width: auto;
  padding: 4px 11px;
  font-size: 1.2rem;
  line-height: 1.3;
}

/* gnaviSlideDown
----------------------------------------------------- */
.gnaviSlideDown {
  @include slideDown($display: none, $top_position: 56px);
}

.gnaviSlideDown .btnClose {
  position: absolute;
  z-index: 2;
  top: 17px;
  right: 3px;
}

.gnaviSlideDown .areaPullDownList {
  margin: 4px 0 0;
}

/* キャリQ ヘッダー
----------------------------------------------------- */
.headerCareq {
  @extend .header;

  &__content {
    @extend .header__content;
    padding: 16px 0;
  }

  &__center {
    margin-top: 100px;
    text-align: center;
  }

  &__logo {
    img {
      width: 300px;
    }
  }
}
