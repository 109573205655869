@charset "UTF-8";

.scoutCard {
  max-width: 950px;
  margin: 0 auto 20px;
  background-color: $scout-white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);

  &__title {
    border-top: 2px solid $scout-lightblue-color;
    color: $scout-light-black;
    font-size: 2rem;
    font-weight: $scout_bold;
    line-height: 1;
    word-break: break-all;
    white-space: normal;
    word-wrap: break-word;
    margin: 0 auto;
    padding: 30px 20px 28px;
    border-bottom: 1px solid $scout-title-boder;
    text-align: center;
  }

  &__content {
    padding: 16.8px 20px 20px 20px;
  }

  &__btnBox {
    text-align: center;
  }

  &__btn {
    @include btn(inline-block, 100%, 10px, 1.4rem);
    border-radius: 4px;
    background: $scout-white;
    border: 1px solid $scout-lightblue-bordercolor;
    box-shadow: none;
    line-height: 1;
  }

  &__txtLink {
    text-decoration: underline;
  }

  &__text {
    margin: 0 0 15.8px 0;
  }

  &__scoutCondition {
    width: 280px;
    margin: 0 auto;
    border: 1px solid $scout-title-boder;

    & + .scoutCard__btnBox {
      margin-top: 15.8px;
    }
  }

  &__term {
    width: 100%;
    padding: 11px 15px;
    background: $scout-skyblue-color;
    font-weight: $scout_bold;

    & + .scoutCard__description {
      border-top: 1px solid $scout-title-boder;
    }
  }

  &__description {
    padding: 11px 15px;

    & + .scoutCard__term {
      border-top: 1px solid $scout-title-boder;
    }
  }

  .resumeGraph-scout {
    margin: 0 auto 20px;
  }

  .iconwraper {
    margin: 128px;
    position: absolute;
  }

  &:has(.iconwraper) {
    opacity: .5;

    dd {
      height: 45px;
    }
  }
}
