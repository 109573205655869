@charset "UTF-8";

/* パンクズ
----------------------------------------------------- */
.breadcrumb {
  line-height: 1.42;
  background-color: $color_white;
  border-bottom: 1px solid #{$color_border};

  &__renew {
    line-height: 1.42;
    background-color: $color_white;
    border-bottom: 0;

    &--border {
      border-bottom: 1px solid #bbb;
    }
  }

  &__list {
    max-width: 950px;
    margin: 0 auto;
    padding: 11px 0;
    letter-spacing: -0.4em;
    text-align: left;

    &--subsite {
      max-width: 1230px;
    }
  }

  &__list__renew {
    max-width: 1140px;
    margin: 0 auto;
    padding: 20px 0;
    letter-spacing: -0.4em;
    text-align: left;
  }

  &__item {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    font-size: 1.1rem;
    letter-spacing: 0;

    &--newLine {
      display: inline;
    }

  }

  &__item::after {
    content: '>';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 5px;
    color: $color_text;
    font-size: 1.1rem;
  }

  &__item--newLine::after {
    position: relative;
    padding: 0 5px;
    right: 0;
  }

  &__item:first-child a {
    padding-left: 0;
  }

  &__item:last-child::after {
    content: none;
  }

  &__item:last-child span {
    display: block;
    padding: 0;
    color: $color_text;
    letter-spacing: 0;
  }

  &__item--newLine:last-child span {
    display: inline;
  }

  &__link {
    display: block;
    padding: 0 16px 0 0;
    letter-spacing: 0;

    &--newLine {
      display: inline;
      padding: 0;
    }

    &--subsite {
      color: #000;
    }
  }

  .container__inner &,
  .container__inner &__renew {
    background-color: #fafafb;
    border-bottom: none;
  }

  &:has(+ .saveMidway) {
    border: none;
  }
}

.breadcrumb.no-border {
  border-bottom: none;
}

/* パンクズ 下層用
----------------------------------------------------- */
.breadcrumbLower {
  background-color: #fafafb;
}

.breadcrumbLower__list {
  @extend .breadcrumb__list;
}

.breadcrumbLower__item {
  @extend .breadcrumb__item;
}

.breadcrumbLower__link {
  @extend .breadcrumb__link;
}

/* 順序なしリスト
----------------------------------------------------------------- */
.unorderedList__item {
  list-style: none;
  position: relative;
  padding-left: 1em;
  color: #333;
  font-size: 1.4rem;
  text-indent: -1em;
  line-height: 1.44;
}

/* 番号付与リスト
----------------------------------------------------------------- */
.orderedList {
  list-style-type: decimal;
  list-style-position: inside;
  padding: 0 0 0 1em;
  text-indent: -1em;
  font-size: 1.2rem;
  line-height: 2.5;
}

/* リンクリスト
----------------------------------------------------------------- */
.linkList__item {
  margin-bottom: 12px;
}

.linkList__item:last-child {
  margin-bottom: 0;
}

.linkList__lead {
  display: inline-block;
  font-size: 1.2rem;
  padding-left: 10px;
}

/* リンクリスト横並び
----------------------------------------------------------------- */
.linkListSide {
  @include clearfix;
}

.linkListSide__item {
  display: inline-block;
}

/* リンクリスト横並び 右揃え
----------------------------------------------------------------- */
.linkListSideRight {
  @extend .linkListSide;
  text-align: right;
}

.linkListSideRight__item {
  @extend .linkListSide__item;
  margin-left: 27px;
}

.linkListSideRight__item .linkArrow {
  font-size: 1.2rem;
}

/* プルダウン
----------------------------------------------------------------- */
.pullDown {
  position: relative;
  display: inline-block;
  vertical-align: top;

  //気になるリストページ
  &--bookmark {
    float: right;

    & .pullDown__trigger {
      width: 250px;
    }

    & .pullDown__list {
      width: 100%;
    }
  }

  & &__trigger--checked,
  & &__trigger--checkedOpen {
    padding-left: 30px;
  }

  &.orderType {
    &:hover {
      text-decoration: underline;
    }
  }

  .pullDown__orderType {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    .pullDown__orderType--text {}
  }
}
.pullDown:has(input[type="checkbox"]:checked) {
  .pullDown__trigger {
    min-width: inherit;
    padding-left: 25px;
    background-color: $color_keyColor_light;
  }
  .pullDown__trigger::after {
    content: "\e917";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    display: inline-block;
    font-family: mynavi;
    font-size: 1.2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    speak: none;
    text-decoration: none;
    text-rendering: auto;
    text-transform: none;
    vertical-align: top;
  }
}
.pullDown:has(input[type="radio"]:checked) {
  .pullDown__trigger {
    min-width: inherit;
    padding-left: 25px;
    background-color: $color_keyColor_light;

    &.sub {
      background-color: $color_border
    }
  }
  .pullDown__trigger.sub {
    min-width: inherit;
    padding-left: 25px;
    background-color: $color_subBorder;
  }
  .pullDown__trigger::after {
    content: "\e917";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    display: inline-block;
    font-family: mynavi;
    font-size: 1.2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    speak: none;
    text-decoration: none;
    text-rendering: auto;
    text-transform: none;
    vertical-align: top;
  }
}

.pullDown__trigger {
  @include btn($display: inline-block, $width: auto, $fontSize: 1.4rem, $padding: 8px 20px 8px 10px) {
    position: relative;
    z-index: 1;
    min-width: 200px;
    color: $color_keyColor;
    background-color: $color_white;
    border-color: $color_keyColor;
  }

  &.minWidth0 {
    min-width: 0;
  }
}
.pullDown__trigger.sub {
  @include btnSubSS;
}

.pullDown__trigger::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 7px;
  height: 6px;
  background: url(#{$img_path}arrowDown.png) right top no-repeat;
  transform: translateY(-50%);
}
.pullDown__trigger.sub::before {
  position: absolute;
  top: 50%;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 7px solid $color_text;
}

.pullDown__trigger--disabled {
  @extend .pullDown__trigger;
  color: #bbb;
  background-color: $color_white;
  border-color: #bbb;
  box-shadow: none;
  cursor: default;
}

.pullDown__trigger--disabled::before {
  background: url(#{$img_path}arrowDownDisable.png) right top no-repeat;
}

.pullDown__trigger--disabled:hover {
  opacity: 1;
}

.pullDown__trigger--checked {
  @extend .pullDown__trigger;
  background-color: $color_keyColor_light;
}

.pullDown__trigger--checked::after {
  content: "\e917";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: inline-block;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: mynavi;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  speak: none;
  text-decoration: none;
  text-rendering: auto;
  text-transform: none;
  vertical-align: top;
}

.pullDown__trigger--checkedOpen {
  @extend .pullDown__trigger;
}

.pullDown__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 30;
  display: none;
  letter-spacing: 0;
  border: 1px solid #a0ddf5;
  border-bottom: none;
  max-height: 300px;
  overflow-y: auto;

  &.sub {
    border: 1px solid $color_text;
    border-bottom: none;
  }
}

.pullDown__item {
  color: $color_keyColor;
  font-size: 1.3rem;
  font-weight: normal;
  cursor: pointer;
  border-bottom: 1px solid #a0ddf5;
  background-color: $color_white;

  &.flex {
    display: flex;
  }

  &.sub {
    color: $color_text;
    border-bottom: 1px solid $color_text;

    & input[type="radio"]:checked + label {
      text-decoration: none;
      background-color: $color_subBorder;
    }
  }

  & input {
    appearance: none;
  }
  & input[type="checkbox"]:checked + label {
    text-decoration: none;
    background-color: #dfeff5;
  }
  & input[type="radio"]:checked + label {
    text-decoration: none;
    background-color: #dfeff5;
  }
  & input[type="radio"]:checked.sub + label {
    text-decoration: none;
    background-color: $color_text;
  }

  & label {
    display: block;
    width: 280px;
    padding: 9px 9px 9px 30px;
  }

  & .children {
    padding-left: 43px !important;
  }

  & .grandChild {
    padding-left: 56px !important;
  }

  .scoutPullDownButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 0px;
    color: $color_white;
    background-color: $color_keyColor;
    border-color: $color_keyColor;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }
  }
}
.pullDown__item:has(input[type="checkbox"]:checked) {
  @extend .pullDown__item;
  @include scoutFilterIconFont($fontSize: 1.2rem, $icon: "\e917");
  background-color: #dfeff5;
  position: relative;
  z-index: 1;
  cursor: default;
}
.pullDown__item:has(input[type="radio"]:checked) {
  @extend .pullDown__item;
  @include scoutFilterIconFont($fontSize: 1.2rem, $icon: "\e917");
  background-color: #dfeff5;
  position: relative;
  z-index: 1;
  cursor: default;
}

.pullDown__item a {
  @include clearfix;
  display: block;
  width: 280px;
  padding: 9px 9px 9px 30px;

  //気になるリストページ
  .pullDown--bookmark & {
    width: 100%;
  }
}

.pullDown__item a:hover {
  text-decoration: none;
}

.pullDown__item a > [class^="label"],
.pullDown__item a > .iconFont--keep {
  float: right;
}

.pullDown__item a > .iconFont--keep {
  margin-right: 95px;
  color: $color_keep;
}

//選択中
.pullDown__item--active {
  @extend .pullDown__item;
  @include iconFont($fontSize: 1.2rem, $icon: "\e917");
  position: relative;
  z-index: 1;
  cursor: default;
}

.pullDown__item--active::before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.pullDown__item--active,
.pullDown__item:hover {
  text-decoration: none;
  background-color: #dfeff5;
}
.pullDown__item.sub:hover {
  text-decoration: none;
  background-color: $color_subBorder;
}

//選択不可
.pullDown__item--disabled {
  @extend .pullDown__item;
}

.pullDown__item--disabled a {
  color: $color_subText;
  cursor: default;
}

.pullDown__item--disabled:hover {
  background-color: $color_white;
}

/* ページャー
----------------------------------------------------------------- */
.pager {
  list-style: none;
  position: relative;
  z-index: 1;
  letter-spacing: -0.4em;
  text-align: center;
}

.pager + .card {
  margin-top: 20px;
}

.pager__text {
  margin-top: 8px;
  font-size: 1rem;
  letter-spacing: 0;
}

.pager__textUpper {
  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 8px;

  span {
    font-size: 10px;
    font-weight: 300;
    padding: 0 12px 0 8px;
  }
}

.companyInfoPager {
  li {
    a::before {
      line-height: 23px;
    }
  }
}

.pager__textBottom {
  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  padding-bottom: 20px;

  span {
    font-size: 10px;
    font-weight: 300;
    padding: 0 12px 0 8px;
  }
}

.pager__item {
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px 0 0;
  color: $color_keyColor;
  font-size: 1.4rem;
  letter-spacing: 0;
}

.pager__item a,
.pager__item button {
  display: block;
  min-width: 25px;
  height: 25px;
  color: $color_keyColor;
  font-weight: normal;
  line-height: 24px;
  text-decoration: none;
  background-color: $color_white;
  border: 1px solid $color_keyColor;
  cursor: pointer;
}

.pager__item a:hover,
.pager__item a:hover::before,
.pager__item button:hover,
.pager__item button:hover::before {
  color: $color_white;
  background-color: $color_keyColor;
}

.pager__item--active {
  @extend .pager__item;
}

.pager__item--active a,
.pager__item--active button {
  color: $color_white;
  background-color: $color_keyColor;
  border: 1px solid $color_keyColor;
  cursor: default;
}

.pager__prev,
.pager__next {
  @extend .pager__item;
}

.pager__prev a,
.pager__next a,
.pager__prev button,
.pager__next button {
  overflow: hidden;
  font-size: 0;
}

.pager__prev a::before,
.pager__next a::before,
.pager__prev button::before,
.pager__next button::before {
  color: $color_keyColor;
  font-size: 1.6rem;
  line-height: 24px;
}

.pager__prev a::before {
  padding-right: 2px;
}

.pager__next a::before {
  padding-left: 2px;
}

.container__inner > .pager:last-child {
  margin-bottom: 20px;
}

.pager__btn {
  position: absolute;
  top: 0;
  right: 0;
}

/* タブナビ
----------------------------------------------------------------- */
.tabNavi {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.tabNavi::before,
.tabNavi::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tabNavi::before {
  box-shadow: 0 0 3px 2px #bbb6a8;
}

.tabNavi::after {
  z-index: 2;
  border-bottom: 1px solid #{$color_red};
}

.tabNavi__list {
  position: relative;
  z-index: 3;
  display: table;
  table-layout: fixed;
  width: 920px;
  margin: 0 auto;
}

.tabNavi__item {
  position: relative;
  z-index: 1;
  display: table-cell;
  vertical-align: bottom;
  padding: 0 10px;
}

.tabNavi__item a,
.tabNavi__item button,
.tabNavi__item > span {
  display: block;
  height: 60px;
  padding: 20px 4px 6px;
  color: $color_red;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  background-color: $color_white;
  border: 1px solid #faa;
  border-bottom: none;
  cursor: pointer;
  width: 100%;
}

.tabNavi__item button {
  padding: 0;
}

.tabNavi__item a:hover,
.tabNavi__item button:hover,
.tabNavi__item > span:hover {
  opacity: 0.5;
  text-decoration: none;
}

.tabNavi__item a[href],
.tabNavi__item button,
.tabNavi__item > span {
  position: relative;
  z-index: 1;
}

.tabNavi__item a[href]::before,
.tabNavi__item a[href]::after,
.tabNavi__item button::before,
.tabNavi__item button::after,
.tabNavi__item > span::before,
.tabNavi__item > span::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
}

.tabNavi__item a[href]::before,
.tabNavi__item button::before,
.tabNavi__item > span::before {
  width: 100%;
  box-shadow: 0 0 3px 2px #bbb6a8;
}

.tabNavi__item a[href]::after,
.tabNavi__item button::after,
.tabNavi__item > span::after {
  left: -2%;
  z-index: 2;
  width: 104%;
  border-bottom: 1px solid #{$color_red};
}

.tabNavi__item--active {
  @extend .tabNavi__item;
  z-index: 3;
}

.tabNavi__item--active a,
.tabNavi__item--active button,
.tabNavi__item--active > span {
  position: relative;
  z-index: 1;
  padding-top: 17px;
  background-color: #f0f0f3;
  border-color: $color_red;
  border-top-width: 4px;
}

.tabNavi__item--active button {
  background-color: #fafafb;
  padding: 0;
}

.tabNavi__item--active a:hover,
.tabNavi__item--active button:hover,
.tabNavi__item--active > span:hover {
  opacity: 1;
}

.tabNavi__item--active a::before,
.tabNavi__item--active button::before,
.tabNavi__item--active > span::before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #f0f0f3;
  box-shadow: none;
}

.tabNavi__item--active a::after,
.tabNavi__item--active button::after,
.tabNavi__item--active > span::after {
  content: none;
}

.tabNaviRecruit__item--active.preTab > a::before,
.tabNaviRecruit__item--active.preTab > a::after,
.tabNaviRecruitBottom__item--active.preTab > a::after {
  height: 0;
  bottom: 0;
  box-shadow: none;
  border-bottom: none;
}

/* タブナビ 検索結果
----------------------------------------------------------------- */

.tabNaviSearch {
  @extend .tabNavi;
  background-color: #fafafb;
}

.tabNaviSearch__item {
  @extend .tabNavi__item;
}

.tabNaviSearch__list {
  @extend .tabNavi__list;
}

.tabNaviSearch__item--active {
  @extend .tabNavi__item--active;
  z-index: 3;
}

.tabNaviSearch__item--active a {
  background-color: #fafafb;
}

/* タブナビ 企業ページ
----------------------------------------------------------------- */

.tabNaviCompany {
  @extend .tabNavi;
  background-color: #fafafb;

  &__item {
    @extend .tabNavi__item;
  }

  &__list {
    @extend .tabNavi__list;
  }
}

.tabNaviCompany__item--active {
  @extend .tabNavi__item--active;
  z-index: 3;
}

.tabNaviCompany__item--active a {
  background-color: #fafafb;
}

.tabNaviCompany__item--active a[href]::before {
  background-color: #fafafb;
  bottom: -10px;
  box-shadow: none;
}

.tabNaviCompany__item--active a[href]::after {
  border-bottom: none;
}

.tabNaviCompany__item--disable {
  @extend .tabNavi__item;

  > a {
    background-color: #bdbcbc;
    pointer-events: none;
  }

  > a:hover {
    opacity: 1;
    cursor: default;
  }
}

/* タブナビ スカウト用
----------------------------------------------------------------- */
.tabNaviScout {
  @extend .tabNavi;
  padding-top: 18px;
}

.tabNaviScout__list {
  position: relative;
  z-index: 3;
  display: table;
  table-layout: fixed;
  width: 950px;
  margin: 0 auto;
}

.tabNaviScout__item {
  @extend .tabNavi__item;
}

.tabNaviScout__item--active {
  @extend .tabNavi__item--active;
}

.tabNaviScout__item--active a {
  background-color: #fafafb;
}

.tabNaviScout__item--active a::before {
  background-color: #fafafb;
}

.tabNaviScout__item:first-child,
.tabNaviScout__item--active:first-child {
  padding: 0 19px 0 0;
}

.tabNaviScout__item:last-child,
.tabNaviScout__item--active:last-child {
  padding: 0 0 0 19px;
}

.tabNaviScout__item .labelNumber {
  position: relative;
  top: -2px;
}

/* タブナビ 求人情報用
----------------------------------------------------------------- */
.tabNaviRecruit {
  @extend .tabNavi;
  margin: -5px 0 40px;
  padding: 25px 0 0;
}

.tabNaviRecruit__list {
  position: relative;
  z-index: 3;
  width: 950px;
  margin: 0 auto;
}

.tabNaviRecruit__item {
  @extend .tabNavi__item;
  width: 120px;
  padding: 0 0 0 10px;
}

.tabNaviRecruit__item--active {
  @extend .tabNavi__item--active;
  width: 120px;
  padding: 0 0 0 10px;
}

.tabNaviRecruit__item--active:first-child,
.tabNaviRecruit__item:first-child {
  width: 110px;
  padding: 0;
}

.tabNaviRecruit__item > span,
.tabNaviRecruit__item--active > span,
.tabNaviRecruit__item > a,
.tabNaviRecruit__item--active > a {
  display: table-cell;
  vertical-align: middle;
  width: 110px;
  padding: 0 5px;
  font-size: 1.3rem;
  line-height: 1.15;
  background-color: #fafafb;
  cursor: pointer;
}

.tabNaviRecruit__item--active > span {
  border-top-width: 1px;
  cursor: default;
}

.tabNaviRecruit__item--active > span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: $color_red;
}

.tabNaviRecruit__item a {
  padding: 0 5px;
  background-color: $color_white;
}

.tabNaviRecruitBottom {
  @extend .tabNaviRecruit;
  margin: 40px 0 0;
  padding: 0 0 40px;
}

.tabNaviRecruitBottom::before,
.tabNaviRecruitBottom::after {
  top: 0;
  bottom: auto;
}

.tabNaviRecruitBottom__list {
  @extend .tabNaviRecruit__list;
}

.tabNaviRecruitBottom__item {
  @extend .tabNaviRecruit__item;
}

.tabNaviRecruitBottom__item--active {
  @extend .tabNaviRecruit__item--active;
}

.tabNaviRecruitBottom__item > a {
  border: 1px solid #faa;
  border-top: none;
}

.tabNaviRecruitBottom__item > a[href]::before {
  top: 0;
  bottom: auto;
}

.tabNaviRecruitBottom__item > a[href]::after {
  top: 0;
  bottom: auto;
}

.tabNaviRecruitBottom__item--active > span {
  border-top: none;
}

.tabNaviRecruitBottom__item--active > span::before {
  top: 0;
  bottom: auto;
  display: none;
}

.tabNaviRecruitBottom__item--active > span::after {
  top: auto;
  bottom: 0;
}

.tabNaviRecruit__tooltip {
  position: absolute;
  top: -25px;
  left: 50%;
  z-index: 3;
  display: inline-block;
  height: 25px;
  color: $color_red;
  font-size: 1.1rem;
  font-weight: normal;
  line-height: 1;
  transform: translateX(-50%);
}

.tabNaviRecruit__tooltip::before {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  z-index: 1;
  border: 6px solid transparent;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #f00;
}

.tabNaviRecruit__tooltip::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  z-index: 1;
  border: 6px solid transparent;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #ffe5e5;
}

.tabNaviRecruit__tooltip span {
  display: block;
  height: 20px;
  padding: 3px 8px 0;
  white-space: nowrap;
  background-color: #ffe5e5;
  border: 1px solid $color_red;
  border-radius: 4px;
}

.tabNaviRecruitBottom__tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  z-index: 3;
  display: inline-block;
  height: 25px;
  color: $color_red;
  font-size: 1.1rem;
  font-weight: normal;
  line-height: 1;
  transform: translateX(-50%);
}

.tabNaviRecruitBottom__tooltip::before {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  z-index: 1;
  border: 6px solid transparent;
  border-left-width: 4px;
  border-right-width: 4px;
  border-bottom-color: #f00;
}

.tabNaviRecruitBottom__tooltip::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  z-index: 1;
  border: 6px solid transparent;
  border-left-width: 4px;
  border-right-width: 4px;
  border-bottom-color: #ffe5e5;
}

.tabNaviRecruitBottom__tooltip span {
  display: block;
  height: 20px;
  padding: 3px 8px 0;
  white-space: nowrap;
  background-color: #ffe5e5;
  border: 1px solid $color_red;
  border-radius: 4px;
}

/* タブナビ 企業情報用
----------------------------------------------------------------- */
.tabNaviCompanyInfo {
  border-bottom: 1px solid $color_subBorder;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  width: 950px;
}

.tabNaviCompanyInfo__list {
  display: flex;
  margin-top: 40px;
}

.tabNaviCompanyInfo__item {
  padding: 17px 0 8px 0;
  text-align: center;
  width: 465px;

  a {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-decoration: none;
  }
}

.tabNaviCompanyInfo__item--active {
  border-bottom: 2px solid #00aaeb;
  padding: 17px 0 8px 0;
  text-align: center;
  width: 465px;

  a {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-decoration: none;
  }
}

.tabNumber {
  background-color: #f00;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  height: 26px;
  line-height: 26px;
  margin-left: 9px;
  vertical-align: baseline;
  width: 26px;
}

.companyInfo__icon-text {
  color: #333;
  display: flex;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 8px;
}

/* サイドメニュー
----------------------------------------------------- */
.sideMenu {
  @include card() {
    float: left;
    width: 215px;
    padding-bottom: 30px;
  }
}

.sideMenu__title {
  @include heading() {
    margin: 0 auto;
    padding: 30px 30px 20px;
    font-size: 1.6rem;
    text-align: center;
    border-bottom: 1px solid $color_subBorder;
  }
}

.sideMenu__item {
  @include listItem;
}

.sideMenu__item:last-child {
  border-bottom: none;
}

.sideMenu__link {
  display: block;
  padding: 15px 10px 15px 20px;
  font-size: 1.4rem;
}

.sideMenu__link--current {
  background-color: rgba(223, 239, 245, 0.5);
}

/* トップサイドメニュー
----------------------------------------------------- */
.sideNavi {
  @extend .sideMenu;
}

.sideNavi__title {
  @extend .sideMenu__title;
  @include heading() {
    padding-left: 0;
    padding-right: 0;
  }
  font-size: 1.5rem;
}

.sideNavi .event__title {
  @extend .sideMenu__title;
  @include heading() {
    padding-left: 0;
    padding-right: 0;
  }
  font-size: 1.5rem;
}

.sideNavi__item {
  @extend .sideMenu__item;
}

.sideNavi .btnList__item {
  border-bottom: 1px solid $color_subBorder;
  margin: 0;
  width: 100%;
}

.sideNavi .event__content .btnList__item [class^="btn"] {
  background-color: inherit;
  border: none;
  display: block;
  padding: 15px 10px 15px 20px;
  font-size: 1.2rem;
}

.sideNavi__link {
  @extend .sideMenu__link;
  font-size: 1.2rem;
}

.sideNavi .event__more {
  padding: 15px 10px 0 20px;
}

.sideNavi .event__more .linkArrow {
  font-size: 1.2rem;
}

.sideNavi__more .linkArrow {
  font-size: 1.2rem;
}

.sideCollaboImage {
  box-shadow: 0 1px 2px 0 #bbb6a8;
  margin-bottom: 20px;
}

.sideCollaboImage__item a {
  background-color: $color_white;
  border: 1px solid $color_subBorder;
  border-bottom: none;
  display: block;
  text-align: center;
  padding: 20px 0;
}

.keywordCloud {
  padding: 15px 10px 0 20px;
}

.keywordCloud__item {
  display: inline;
  border-right: 1px solid #b5b5b5;
  font-size: 1.2rem;
  padding-right: 7px;
  margin-right: 7px;
}

.keywordCloud__item:last-of-type {
  border-right: none;
}

.sideSns__item {
  @include clearfix;
  box-shadow: 0 1px 2px 0 #bbb6a8;
  margin-right: 15px;
  float: left;
}

.sideSns__item img {
  vertical-align: bottom;
}

/* 状態の切り替え
----------------------------------------------------- */
.pipeLink {
  color: $color_text;
  font-size: 1.4rem;
  letter-spacing: -0.4em;
}

.pipeLink__item {
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
  padding: 2px 15px 2px 0;
  font-weight: bold;
  letter-spacing: 0;
  border-right: 1px solid #{$color_text};
}

.pipeLink__item:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

/* ボタンリスト
----------------------------------------------------- */
.btnList {
  letter-spacing: -0.4em;
}

.btnList__item {
  display: inline-block;
  vertical-align: top;
  width: 220px;
  margin: 0 20px 20px 0;
  letter-spacing: 0;
  box-sizing: border-box;
}

.btnList__item:nth-child(4n) {
  margin-right: 0;
}

.btnList [class^="btn"] {
  font-weight: normal;
  text-align: left;
  box-shadow: none;
}

.btnList__item > [class^="btn"] {
  padding: 12px 17px;
}

/* ボタンリスト 求人情報
----------------------------------------------------- */
.btnListL {
  margin: 20px 0 40px;
  letter-spacing: -0.4em;
}

.btnListL__item {
  display: inline-block;
  vertical-align: top;
  width: 230px;
  margin: 0 20px 0 0;
  letter-spacing: 0;
  box-sizing: border-box;
}

.btnListL__item--application {
  @extend .btnListL__item;
  width: 170px;
  margin: 0;
  padding: 8px 0 0;
  text-align: right;
}

.btnListL__item .btnInterst {
  min-width: 100%;
}

.btnListL__item .textSup {
  position: relative;
  display: block;
  margin-top: 20px;
  text-align: center;
}

.btnListL__item .iconQuestion {
  position: relative;
  z-index: 11;
  margin: 0 4px 0 0;
}

.btnListL__item .tooltip {
  position: absolute;
  top: 40px;
  right: 123px;
  z-index: 10;
  display: none;
  text-align: left;
}

.btnListL__item .iconQuestion:hover + .tooltip {
  display: block;
}

/* レイアウト 横並び 高さ合わせなし
----------------------------------------------------- */
.columnList {
  @include columnList;
}

.columnList--2col {
  @include columnList($width: 50%);
}

.columnList--3col {
  @include columnList($width: 33.3%);
}

.columnList--4col {
  @include columnList($width: 25%);
}

.columnList--12col {
  @include columnList($width: auto);

  &__item {
    border-right: 1px solid $color_subBorder;
    margin-bottom: 16px;
    margin-right: 14px;
    padding-right: 14px;

    &:first-child {
      border-right: none;
      margin-right: 0;
      width: 72px;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.columnList--13col {
  @include columnList($width: auto);
  padding: 0 16px 8px 0;

  &__item {
    border-right: 1px solid $color_subBorder;
    margin-bottom: 0;
    margin-right: 15px;
    padding-right: 15px;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      border-right: none;
    }
  }
}

//タグ用col
.columnList--14col {
  @include columnList($width: auto);

  &__item {
    background: #00aaeb;
    border-radius: 16px 0 0 16px;
    height: 32px;
    margin-bottom: 16px;
    margin-right: 12px;
    padding: 6px 8px 6px 8px;
    width: auto;
  }

  &__item::before {
    content: '';
    background: #fff;
    height: 6px;
    width: 6px;
  }
}

/* 新着求人エリア ページアンカー
----------------------------------------------------- */
.arrivalsAnchor {
  @include clearfix;
}

.arrivalsList {
  font-size: 1.1rem;
}

.arrivalsList__item {
  margin-bottom: 16px;
  width: 25%;
  float: left;
}

.arrivalsList__item a {
  color: $color_text;
  font-weight: bold;
  padding-left: 12px;
}

.arrivalsList__item a::before {
  content: "";
  background-image: url(#{$img_path}linkArrow.png);
  background-repeat: no-repeat;
  background-position-y: 50%;
  display: inline-block;
  margin-right: 8px;
  height: 8px;
  width: 12px;
}

/* 新着求人エリア 求人部分
----------------------------------------------------- */
.card__content .newRecordList:first-child {
  padding-top: 0;
  margin-top: -10px;
}

.newRecordList {
  border-bottom: 1px solid $color_subBorder;
  display: table;
  padding: 16px 0;
  line-height: 2;
}

.newRecordList:last-child {
  border-bottom: none;
}

.newRecordList__title {
  display: table-cell;
  font-weight: bold;
  width: 416px;
}

.newRecordList__detail {
  display: table-cell;
  padding-left: 60px;
}

.newRecordList__detail .linkArrow {
  margin-left: -1rem;
}

.newRecordList [class^="label"] {
  margin-right: 8px;
}

.newRecordList__detailItem {
  margin-bottom: 12px;
}

.newRecordList__detailItem:last-child {
  margin-bottom: 0;
}

/* スカウト受信一覧サイドメニュー
----------------------------------------------------- */
.sideMenuScout {
  @include card($width: 350px, $margin: 0, $bgColor: #f5f5f5) {
    position: absolute;
    float: left;
    width: 100%;
  }
}

.sideMenuScout__head {
  padding: 10px;
  background-color: $color_white;
  border-bottom: 1px solid $color_subBorder;
}

.sideMenuScout__operation {
  position: relative;
  height: 36px;
  margin-bottom: 12px;
}

.sideMenuScout__operation [class*="btnDelete"] {
  float: right;
}

.sideMenuScout__operation .pullDown__trigger {
  min-width: 0;
}

.sideMenuScout__content {
  overflow-y: scroll;
}

.sideMenuScout__item {
  position: relative;
  display: block;
  background-color: $color_white;
  border-bottom: 1px solid $color_subBorder;
}

.sideMenuScout__item:hover > * {
  text-decoration: none;
}

.sideMenuScout__title,
.sideMenuScout__text {
  overflow: hidden;
  font-size: 1.4rem;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sideMenuScout__title {
  margin-bottom: 5px;
  color: $color_heading;
}

.sideMenuScout__text {
  color: $color_subText;
}

.sideMenuScout__item .checkbox {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
}

.sideMenuScout__detail {
  display: block;
  padding: 10px 36px 10px 10px;
}

.sideMenuScout__detail:hover > * {
  opacity: 0.5;
}

.sideMenuScout__date {
  color: $color_subText;
  font-size: 1.2rem;
}

.sideMenuScout__date + [class^="label"] {
  float: right;
}

.sideMenuScout__detail .sideMenuScout__title {
  margin: 15px 0 5px 26px;
  letter-spacing: 0;
}

.sideMenuScout__detail .sideMenuScout__text {
  margin-left: 26px;
  letter-spacing: 0;
}

.sideMenuScout__info {
  overflow: hidden;
  margin-top: 10px;
  padding-left: 24px;
}

.sideMenuScout__status {
  float: right;
  margin-top: 4px;
  color: #999;
  font-size: 1.3rem;
  letter-spacing: 0;
}

.sideMenuScout__status em {
  color: $color_red;
}

//キープアイコン 未キープ
.sideMenuScout__keep {
  @include iconFont($fontSize: 2rem, $icon: "\e919") {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
  }
}

.sideMenuScout__keep:hover {
  cursor: pointer;
}

//キープアイコン キープ済み
.sideMenuScout__keep--done {
  @extend .sideMenuScout__keep;
}

//キープアイコン 有効期限切れ・辞退済み
.sideMenuScout__keep--cancel {
  @extend .sideMenuScout__keep;
  color: $color_disable;
}

//キープアイコン キープ済み 有効期限切れ・辞退済み
.sideMenuScout__keep--doneCancel {
  @extend .sideMenuScout__keep;
  color: #ffebac;
}

.sideMenuScout__keep--done:hover,
.sideMenuScout__keep--cancel:hover,
.sideMenuScout__keep--doneCancel:hover {
  cursor: default;
}

//既読済み
.sideMenuScout__detail--read {
  @extend .sideMenuScout__detail;
  background-color: #f5f5f5;
}

//選択中
.sideMenuScout__detail--select {
  @extend .sideMenuScout__detail;
}

.sideMenuScout__detail--select::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: $color_keyColor;
}

.sideMenuScout__detail--select:hover::before {
  opacity: 0.5;
}

.sideMenuScout__detail--read .sideMenuScout__title,
.sideMenuScout__detail--read .sideMenuScout__text {
  font-weight: normal;
}

//既読済みかつ選択中
.sideMenuScout__detail--readSelect {
  @extend .sideMenuScout__detail--select;
  background-color: #f5f5f5;

  &:not(:has(.sideMenuApplication__setInterviewDate--batch)) {
    padding-top: 25px;
    padding-bottom: 22px;
  } 
}

.sideMenuScout__detail--readSelect .sideMenuScout__title,
.sideMenuScout__detail--readSelect .sideMenuScout__text {
  font-weight: normal;
}

.sideMenuScout__footer {
  padding: 15px 20px;
  background-color: $color_white;
}

/* スカウト受信一覧サイドメニュー 0件表示
----------------------------------------------------- */

.sideMenuScout__conf {
  @extend .sideMenuScout__item;
  padding: 15px 0 24px 28px;
}

.sideMenuScout__conf:hover {
  text-decoration: none;
}

.sideMenuScout__conf:hover > * {
  opacity: 0.5;
}

//選択中
.sideMenuScout__conf--select {
  @extend .sideMenuScout__conf;
}

.sideMenuScout__conf--select::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: $color_keyColor;
}

.sideMenuScout__label {
  @include label($fontSize:1.1rem) {
    margin-bottom: 5px;
    padding: 3px 9px 2px;
    color: $color_red;
    background-color: #ffe6e6;
    border: 1px solid #{$color_red};
  }
}

/* トップロゴリスト
----------------------------------------------------- */
.logoList img {
  display: inline-block;
  width: 80px;
  height: auto;
  margin: 0 20px;
}

.logoList img:first-child {
  margin-left: 0;
}

.logoList img:last-child {
  margin-right: 0;
}

/* 応募メッセージ管理サイドメニュー
----------------------------------------------------- */
.sideMenuApplication {
  @extend .sideMenuScout;
}

.sideMenuApplication__head {
  @extend .sideMenuScout__head;
}

.sideMenuApplication__operation {
  @extend .sideMenuScout__operation;
}

.sideMenuApplication__operation .pullDown__trigger .labelNumber {
  vertical-align: top;
  margin-left: 10px;
}

.sideMenuApplication__operation [class*="pullDown__list"] a {
  width: 184px;
}

.sideMenuApplication__head .select {
  margin-top: 10px;
}

.sideMenuApplication__content {
  @extend .sideMenuScout__content;

  // iPad NOTE:メッセージ管理画面特殊対応
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    max-height: calc(100vh - 330px);
  }
}

.sideMenuApplication__item {
  @extend .sideMenuScout__item;
}

.sideMenuApplication__detail {
  @extend .sideMenuScout__detail;
  position: relative;
  z-index: 1;
  min-height: 110px;
  padding-right: 60px;
}

.sideMenuApplication__title {
  @extend .sideMenuScout__title;
}

.sideMenuApplication__detail .sideMenuApplication__title {
  margin-bottom: 0;
}

.sideMenuApplication__text {
  @extend .sideMenuScout__text;
}

//既読済み
.sideMenuApplication__detail--read {
  @extend .sideMenuApplication__detail;
  background-color: #f5f5f5;
}

//選択中
.sideMenuApplication__detail--select {
  @extend .sideMenuApplication__detail;
}

.sideMenuApplication__detail--select::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: $color_keyColor;
}

.sideMenuApplication__detail--select:hover::before {
  opacity: 0.5;
}

.sideMenuApplication__detail--read .sideMenuApplication__title,
.sideMenuApplication__detail--read .sideMenuApplication__text {
  font-weight: normal;
}

//既読済みかつ選択中
.sideMenuApplication__detail--readSelect {
  @extend .sideMenuApplication__detail--select;
  background-color: #f5f5f5;
}

.sideMenuApplication__detail--readSelect .sideMenuApplication__title,
.sideMenuApplication__detail--readSelect .sideMenuApplication__text {
  font-weight: normal;
}

.sideMenuApplication__footer {
  padding: 15px 20px;
  background-color: $color_white;
}

//0件表示
.sideMenuApplication__noMessage {
  padding: 30px 28px;
  font-size: 1.4rem;
  color: $color_subText;
}

//応募一覧
.sideMenuApplication__item--application {
  @extend .sideMenuApplication__item;
}

.sideMenuApplication__item--application .sideMenuApplication__detail {
  padding-right: 12px;
}

.sideMenuApplication__item--application:hover {
  text-decoration: none;
}

.sideMenuApplication__title--application {
  @extend .sideMenuApplication__title;
  display: block;
  color: $color_keyColor;
}

.sideMenuApplication__item .linkArrowS {
  margin-left: 26px;
}

.sideMenuApplication__item--application .sideMenuApplication__detail:hover > * {
  opacity: 1;
}

.sideMenuApplication__date {
  @extend .sideMenuScout__date;
  font-size: 1rem;
}

//ゴミ箱
.sideMenuApplication__item .iconReceive,
.sideMenuApplication__item .iconSend {
  position: absolute;
  z-index: 1;
  top: 48px;
  left: 10px;
}

.sideMenuApplication__back {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 20px;
  font-size: 1.2rem;
  transform: translateY(-50%);
}

.sideMenuApplication__back:hover {
  text-decoration: underline;
}

//面接一覧
.sideMenuApplication__item .iconDelete {
  position: absolute;
  z-index: 2;
  padding: 5px;
  top: 50%;
  right: 18px;
  font-size: 0;
  transform: translateY(-50%);
}

.sideMenuApplication__item .iconReply {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 11px;
  font-size: 0;
  transform: translateY(-50%);
}

.sideMenuApplication__interviewDate {
  & .sideMenuApplication__detail {
    padding-top: 10px;
    padding-left: 14px;

    &:not(:has(.sideMenuApplication__setInterviewDate--batch)) {
      padding-top: 25px;
      padding-bottom: 22px;
    } 

    &:hover {
      opacity: 0.6;
    }
  }

  & .sideMenuApplication__setInterviewDate--batch {
    color: $color_red;
    font-size: 1.2rem;
    font-weight: 400;
    background: #fdd;
    display: table;
    border: solid 1px $color_red;
    border-radius: 2px;
    line-height: 1;
    margin-bottom: 8px;
    padding: 4px 6px;
  }

  & .sideMenuApplication__date {
    font-weight: 900;
  }

  & .sideMenuApplication__title {
    margin: 0;
    margin-top: 6px;
    font-weight: 900;
    margin-bottom: 10px;
    width: 86%;

    &--application {
      margin: 0;
      margin-top: 6px;
      font-weight: 900;
      margin-bottom: 10px;
      width: 86%;
    }
  }

  & .sideMenuApplication__text {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 900;
    margin-bottom: 6px;
    color: $color_disable;
  }

  & .sideMenuApplication__detail--readSelect {
    background-color: unset;
  }
}

/* リスト表示
----------------------------------------------------- */
.list {
  margin: 20px 0;
}

.list__item {
  @include listItem;
  display: table;
  width: 100%;
  height: 72px;

  &__noBorder {
    border-bottom: none;
  }
}

.list__title,
.list__detail {
  display: table-cell;
  vertical-align: middle;
}

.list__title {
  width: 100%;
  padding: 20px 20px 20px 0;
  color: $color_heading;
  font-size: 1.6rem;
  font-weight: bold;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.list__detail {
  padding: 20px 0;
  white-space: nowrap;
}

.list__detail--btn {
  @extend .list__detail;
}

.list__title [class^="label"] {
  margin-right: 20px;
}

.list + [class^="btn"] {
  margin-top: 30px;
}

/* リスト表示 検索条件
----------------------------------------------------- */
.searchCondition {
  @extend .list;
}

.searchCondition__item {
  @extend .list__item;
  table-layout: fixed;
}

.searchCondition__label {
  @extend .list__title;
  width: 76px;
  padding-right: 0;
}

.searchCondition__title [class^="label"] {
  vertical-align: top;
}

.searchCondition__detail {
  @extend .list__detail;
  font-weight: bold;
}

/* リスト表示 sns連携
----------------------------------------------------- */
.snsCooperation {
  @extend .searchCondition;
}

.snsCooperation__item {
  @extend .searchCondition__item;
}

.snsCooperation__label {
  @extend .searchCondition__label;
}

.snsCooperation__text {
  @extend .searchCondition__detail;
  font-weight: normal;
}

.snsCooperation__btn {
  @extend .searchCondition__detail;
  width: 100px;
}

.snsCooperation__title {
  color: $color_heading;
  font-weight: bold;
}

/* リスト表示 メール受信設定
----------------------------------------------------- */
.mailSetting {
  @extend .snsCooperation;
}

.mailSetting__item {
  @extend .snsCooperation__item;
  height: auto;
}

.mailSetting__item:last-child {
  border-bottom: none;
}

.mailSetting__item:last-child .mailSetting__text,
.mailSetting__item:last-child .mailSetting__btn {
  padding-bottom: 0;
}

.mailSetting__text {
  @extend .snsCooperation__text;
}

.mailSetting__btn {
  @extend .snsCooperation__btn;
  width: 120px;
  text-align: right;
}

.mailSetting__btn .btnSetting {
  width: 100%;
}

.mailSetting__btn .toggleSwitch {
  vertical-align: middle;
}

.mailSetting__title {
  @extend .snsCooperation__title;
  margin-bottom: 5px;
  font-size: 1.6rem;
}

.mailSetting__title [class*="label"] {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.mailSetting__title .linkArrow {
  display: inline-block;
  margin-left: 20px;
  font-weight: normal;
}

.mailSetting .toggleSwitch__loading {
  position: absolute;
}

/* フローリスト
----------------------------------------------------------------- */
.flowList {
  @include listItem;
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.flowList__item {
  position: relative;
  z-index: 1;
  float: left;
  width: 33.3%;
  height: 50px;
  padding: 0 25px 0 20px;
  color: $color_white;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 50px;
  background-color: $color_disable02;
}

.flowList__item::before,
.flowList__item::after {
  content: '';
  position: absolute;
  top: 0;
  display: block;
}

.flowList__item::before {
  z-index: 1;
  right: 0;
  width: 25px;
  height: 100%;
  background-color: $color_white;
}

.flowList__item::after {
  z-index: 2;
  right: -25px;
  width: 0;
  height: 0;
  border: 25px solid transparent;
  border-left-color: $color_disable02;
}

.flowList__item--active {
  @extend .flowList__item;
  background-color: $color_keyColor;
}

.flowList__item--active::after {
  border-left-color: $color_keyColor;
}

/* セミナーへの申し込み セミナーリスト
----------------------------------------------------------------- */
.seminarList {
  border-bottom: none;
}

.seminarList__item {
  border-bottom: 1px solid $color_subBorder;
  display: table;
  padding: 18px 0;
  table-layout: fixed;
  width: 100%;
}

.seminarList__item:first-child {
  padding-top: 0;
}

.seminarList__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.seminarList__title {
  display: table-cell;
  font-size: 1.6rem;
  font-weight: bold;
  width: 90px;
}

.seminarList__detail {
  display: table-cell;
}

/* ページナビ
----------------------------------------------------- */
.pageNavi {
  @include clearfix;
  position: relative;
  z-index: 1;
  height: 60px;
  margin: 30px 0 20px;
  padding: 20px 0;
  font-size: 1.3rem;
  text-align: center;
  border-top: 1px solid $color_subBorder;
  border-bottom: 1px solid $color_subBorder;
}

.pageNavi__top {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pageNavi__prev {
  float: left;
}

.pageNavi__next {
  float: right;
}

/* 添付ファイル
----------------------------------------------------- */
.attachedFileList {
  @include clearfix;
}

.attachedFileList__title {
  float: left;
}

.attachedFileList__item {
  margin: 0 0 0 7.2em;
  word-break: break-all;
  white-space: normal;
  word-wrap: break-word;
}

.attachedFileList__item + .attachedFileList__item {
  margin: 20px 0 0 7.2em;
}

/* 企業一覧
----------------------------------------------------- */
.companies__list {
  margin-bottom: 16px;
}

.companies__item {
  margin-bottom: 16px;
}

.companies__btn {
  text-align: right;
}

/* 企業特集
----------------------------------------------------- */
.featureDefinition {
  padding: 20px 28px;
  border-bottom: 1px solid $color_subBorder;
}

.featureDefinition:first-child {
  padding-top: 30px;
}

.featureDefinition:last-child {
  padding-bottom: 40px;
}

.featureDefinition__title {
  font-size: 1.6rem;
  font-weight: bold;
  float: left;
}

.featureDefinition__content {
  padding-left: 112px;
}

.featureDefinition__content .linkList__item {
  padding-left: 12px;
  text-indent: -12px;
}

.featureSideList {
  margin-top: 16px;
}

.featureSideList__item {
  margin-bottom: 24px;
}

.featureSideList__item:last-child {
  margin-bottom: 0;
}

.featureSideList__title,
.featureSideList__content {
  display: inline-block;
  font-size: 1.2rem;
}

.featureSideList__title {
  font-weight: bold;
}
