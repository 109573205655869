@charset "UTF-8";

.jobCard {
  &__title {
    color: $color_keyColor;
    font-size: $top-card-title-size;
    font-weight: bold;
    line-height: $top-card-title-line-height;
    margin-top: 1rem;
  }

  &__text {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-top: 1rem;
  }
}
