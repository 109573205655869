@charset "UTF-8";

.scoutTop {
  position: relative;
  margin: 0 auto;
  height: 450px;
  width: $main_width;
  background: url(#{$scout_path}scout_head.png) no-repeat right 60%;
  background-size: 57.5%;

  &-list {
    height: 326px;
    background: url(#{$scout_path}scoutlist_head.png) no-repeat right center;
    background-size: 25%;
  }

  &-agslist {
    height: 326px;
    background: url(#{$scout_path}agsScoutlist_head.png) no-repeat right center;
    background-size: 30%;
  }

  &__title {
    padding-top: 40px;
    font-size: 6rem;
    font-weight: $scout_bold;
    color: $scout-light-black;
    letter-spacing: 0.08em;
    line-height: 1;

    &-top {
      margin-left: 42px;
    }

    &-multiline {
      padding-top: 31px;
      line-height: 1.35;

      & + .scoutTop__summary {
        margin-top: 16px;
      }
    }

    &-lb {
      color: $scout-lightblue-color;
    }

    &-sm {
      font-size: 3.8rem;
    }

    .ls0 {
      letter-spacing: 0;
    }

    .ls13 {
      letter-spacing: 0.13em;
    }
  }

  &__summary {
    margin-top: 25px;

    &-top {
      margin-left: 42px;
      margin-top: 40px;
    }
  }

  &__text {
    font-weight: $scout_bold;
    letter-spacing: 0.06em;

    &-scout {
      display: inline-block;
      width: 360px;
      vertical-align: middle;
    }

    &-agsscout {
      display: inline-block;
      width: 435px;
      margin-right: 35px;
      line-height: 1.45;
      vertical-align: middle;
    }
  }

  &__scoutLink {
    @include linkBtn($padding: 15px 20px, $font-size: 1.7rem);
    height: 50px;
    line-height: 1;
    letter-spacing: 0.06em;
    vertical-align: middle;

    .labelNumber {
      margin: 0 0 0 2px;
      border-radius: 9px;
    }
  }

  &__textPoint {
    background-color: $scout-lightblue-color;
    border-radius: 99999px;
    color: $scout-white;
    display: inline-block;
    text-align: center;
    padding: 0.5rem 1rem;
    margin-bottom: 5px;
    line-height: 1;
  }

  &__feature {
    margin-top: 35px;
    width: 570px;
    padding: 20px 15.5px 15.5px 15.5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    border-radius: 6px;
    background-color: $scout_white;

    &-wide {
      margin-top: 20px;
      width: 850px;
      padding: 20px 0 15.5px 15.5px;
    }
  }

  &__featureTitle {
    font-size: 1.6rem;
    font-weight: $scout_bold;
    line-height: 1;
    margin-bottom: 15.5px;
    margin-left: 9px;
  }

  &__featureList {
    @include scoutFlex(space-between, center, wrap);
    width: 100%;
  }

  &__featureItem {
    @include scoutFlex(flex-start, center, nowrap);
    width: 255px;
    box-sizing: border-box;

    &-3col {
      width: 33.3%;
    }
  }

  &__featureIcon {
    @include scoutFlex(flex-start, center, nowrap);
    text-align: center;
    width: 63px;
    vertical-align: middle;
  }

  &__featureText {
    vertical-align: middle;
    font-size: 1.3rem;
    width: 190px;
  }

  &__companyBatch {
    display: none;
    padding-top: 2px;
  }

  &__agentBatch {
    display: none;
    padding-top: 2px;
  }
}
