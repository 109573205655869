@charset "UTF-8";

// 面接予定ラベル
.sideMenuApplication__label {
  @extend .sideMenuScout__text;

  .interviewLabel {
    @include label() {
      padding: 2px 9px;
      color: #{$color_keyColor};
      border: 1px solid #{$color_keyColor};
    }
  }
}