@charset "UTF-8";

.jobCheckBox {
  $component: &;

  &--noInput {
    #{$component}__label {
      &::before {
        display: none;
      }
    }
  }

  &__input {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 8px;
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    opacity: 0;

    &:checked + #{$component}__label {
      &::before {
        background: url(../img/common/sprite.png) -110px -160px no-repeat;
      }
    }
  }

  &__label {
    display: inline-block;
    position: relative;
    padding-left: 26px;

    &--disabled {
      position: relative;
      z-index: 1;
      padding-left: 26px;
      display: inline-block;
      opacity: .5;
      cursor: default;
      font-size: 1.3rem;
      line-height: 1.3em;

      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 16px;
        height: 17px;
        left: 0;
        background: url(../img/common/sprite.png) -93px -160px no-repeat;
      }
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 16px;
      height: 17px;
      left: 0;
      background: url(../img/common/sprite.png) -93px -160px no-repeat;
    }
  }
}

.checkNow {
  &::after {
    background-color: $color_red;
    border-radius: 99999px;
    color: #fff;
    content: "選択中";
    display: inline-block;
    font-size: 1.1rem;
    line-height: 1;
    margin-left: 4px;
    padding: 2px 6px;
  }
}

.checkbox__icon--large {
  transform: scale(1.5);
}

.checkboxBlue__icon--large {
  background-color: $color_white;
  transform: scale(0.75);
}
