@charset "UTF-8";

/* ページ全体枠
----------------------------------------------------- */
.wrapper {
  min-width: 950px;
}

.wrapper--wide {
  min-width: 1200px;
}

/* メインコンテンツ全体枠
----------------------------------------------------- */
.container {
  position: relative;
  min-width: 950px;
  width: 100%;
  padding: 30px 0 40px;
  font-size: 1.4rem;
  line-height: 1.42;
  background: #fafafb;
  z-index: 1;

  &.bkWhite {
    background: $color-white;
  }

  &__inner {
    @include clearfix;
    @include boxCenter;
    position: relative;
  }

  &__inner--wide {
    width: 1140px;
  }

  &--transparent {
    background-color: transparent;
  }
}

/* カラムレイアウト枠用
----------------------------------------------------- */
.row {
  @include clearfix;
}

/* ２カラムレイアウト時メインコンテンツ
----------------------------------------------------- */
.mainContent {
  position: relative;
  z-index: 2;
  float: right;
  width: 715px;
}

/* ２カラムレイアウト時メインコンテンツ スカウト受信一覧
----------------------------------------------------- */
.mainContentScout {
  float: right;
  width: 580px;
}

.mainContentScout__link {
  margin-top: 30px;
  text-align: right;
}

/* ２カラムレイアウト時メインコンテンツ 応募メッセージ管理
----------------------------------------------------- */
.mainContentApplication {
  @extend .mainContentScout;
}

/* メインコンテンツ 気になる保存
----------------------------------------------------- */
.interest {
  padding: 0 20px;
}

.mainContentInterest {
  display: table;
  border-collapse: separate;
  border-spacing: 20px 0;
  table-layout: fixed;
  width: 100%;
  margin: 0 0 20px -20px;
}

.mainContentInterest .card {
  display: table-cell;
  width: 445px;
  margin: 0;
}
