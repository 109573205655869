@charset "UTF-8";

// AGSメッセージ：選択中メッセージリスト
.sideMenuAgsMessage__detail--readSelect {
  @extend .sideMenuApplication__detail--select;
  background-color: #f5f5f5;
  min-height: 130px;
}

.sideMenuAgsMessage__detail--readSelect::before {
  background-color: $color_agsMessage_keyColor;
}

// AGSメッセージ：未読や送信
.sideMenuAgsMessage__detail {
  @extend .sideMenuApplication__detail;
}

// AGSメッセージ：既読
.sideMenuAgsMessage__detail--read {
  @extend .sideMenuApplication__detail--read;
}

// AGSメッセージ：件名
.sideMenuAgsMessage__title {
  @extend .sideMenuApplication__title;
  text-overflow: unset;
  white-space: normal;
  word-break: break-all;
}

// AGSメッセージ：テキスト
.sideMenuAgsMessage__text {
  @extend .sideMenuScout__text;
  text-overflow: unset;
  white-space: normal;
  word-break: break-all;
}

.sideMenuAgsMessage__detail--readSelect .sideMenuAgsMessage__title,
.sideMenuAgsMessage__detail--readSelect .sideMenuAgsMessage__text,
.sideMenuAgsMessage__detail--read .sideMenuAgsMessage__title,
.sideMenuAgsMessage__detail--read .sideMenuAgsMessage__text {
  font-weight: normal;
  text-overflow: unset;
  word-break: break-all;
}

// AGSメッセージ（応募一覧のみ）件名
.sideMenuAgsMessage__applicationTitle {
  @extend .sideMenuApplication__title;
  font-weight: normal;
  text-overflow: unset;
  white-space: normal;
  word-break: break-all;
}

// AGSメッセージ（応募一覧のみ）本文
.sideMenuAgsMessage__applicationText {
  @extend .sideMenuScout__text;
  font-weight: normal;
  text-overflow: unset;
  white-space: normal;
  word-break: break-all;
}

// ゴミ箱のメッセージ区分保持span
.msgKbnFlg {
  display: none;
}
