@charset "UTF-8";

.topSubHeader {
  padding-top: .5rem;

  &__main {
    @include clearfix;

    h1 {
      color: $color_text_light;
      float: left;
      font-size: 1rem;
      margin-top: 2px;
    }

    .areaNavi {
      float: right;
    }
  }
}
