
@charset "UTF-8";
$eventImg_path: '../img/event/';

/* イベント詳細
----------------------------------------------------------------- */
#eventDetail {
  text-align: center;
  color: #000;
  font-size: 15.96px !important;
  line-height: 28.728px;

  p {
    font-size: 15.96px;
  }

  strong,
  em {
    font-style: normal;
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: bold;
    line-height: 1.2;
  }

  ul {
    padding: 0;
    margin: 5px 0 0;
  }

  ul,
  ol {
    list-style: none;
  }

  li {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 1.5;
  }

  a {
    img {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;

    td {
      line-height: 1.5;
    }
  }

  a:link,
  a:visited {
    color: #06c;
    text-decoration: none;
  }

  a:hover {
    img {
      filter: alpha(opacity=50);
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }

  .float_r {
    float: right;
  }

  .float_l {
    float: left;
  }

  .card {
    max-width: none;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .list {
    margin: 0;
  }

  .text {
    font-size: 16px;
  }

  .notice {
    overflow: visible;
    max-height: none;
    font-size: inherit;
    line-height: 28.728px;
  }

  .emergency_info {
    color: #f00;
    font-weight: bold;
    font-size: 150%;
    margin: 10px auto;
    padding: 10px 0 10px 0;
    background-color: $color_white;
    display: block;
    opacity: 0.9;
  }

  .mainContentIn {
    width: 950px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    p {
      text-align: justify;
      text-justify: inter-ideograph;
    }

    ul.link {
      font-size: 0.875em;
      margin: 1em 0 0 1em;

      li {
        list-style-image: url(#{$eventImg_path}arrow_link.png);

        a {
          color: #0d386b;
          text-decoration: underline;
        }
      }
    }

    .exhibitorCompanyRead {
      padding-bottom: 20px;
    }

    .link__map {
      font-size: 26px;
      text-align: center;
      margin-bottom: 45px;
    }
  }

  div.pickupCompany {
    text-align: center;
    margin: 10px auto 50px;
    width: 100%;

    .logo {
      width: 159px;
      margin: 0 5px;
      display: inline-block;
      vertical-align: top;

      &:first-child {
        margin-left: 0;
      }

      img {
        width: 159px;
        height: auto;
        display: block;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        line-height: 1.2em;
        text-align: center;
        margin: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    a:link,
    a:visited {
      text-decoration: underline;
      color: #0d386b;
    }
  }

  h3 {
    .arrow {
      height: 90px;
      width: 90px;
      position: absolute;
      left: 50%;
      bottom: -45px;
      margin-left: -45px;
    }
  }

  #socialHeader {
    overflow: hidden;
    width: 100%;
    margin-bottom: -5px;

    div.inner {
      float: right;
    }

    div.btn {
      float: left;
      margin-right: 7px;

      &.facebook {
        width: 75px;
      }

      &.twitter {
        width: 80px;
      }
    }
  }

  #mainImage {
    background-image: url(#{$eventImg_path}mainimage.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 480px;
    width: 100%;
    overflow-y: hidden;

    #mainTitle {
      background-image: url(#{$eventImg_path}mainimage_circle.png);
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto;
      padding: 0;
      height: 480px;
      width: 950px;
      position: relative;

      .info {
        float: left;
        width: 500px;
        min-height: 280px;
        background-color: $color_white;

        h3 {
          font-size: 18px;
          margin: 0 0 5px 25px;
        }

        dl {
          display: table;
          border-collapse: collapse;
          border-spacing: 0;
          margin: 0 0 0 25px;
          overflow: hidden;

          dt {
            width: 4em;
            background-color: transparent;
            float: none;
            font-size: 1.4rem;
            display: table-cell;
            vertical-align: middle;
            text-align: center;

            & > span {
              display: block;
              background-color: #fc0;
            }
          }

          dd {
            width: 390px;
            line-height: 1.3;
            padding: 0 0 5px 10px;
            margin: 0;
            padding-bottom: 0;
            float: none;
            display: table-cell;
            vertical-align: middle;
          }

          & + dl {
            margin-top: 5px;
          }
        }
      }

      h2 {
        margin: 0 0 0 -267px;
        padding: 0;
        position: absolute;
        top: 50px;
        left: 50%;
        z-index: 12;
        width: 535px;
        line-height: 1.2rem;
      }

      .title_event {
        margin: 0 0 10px 25px;
        padding: 0;
        vertical-align: inherit;
        overflow: hidden;

        .title_expo {
          width: 190px;
          margin: 0;
          padding: 0;
          float: left;
        }

        .prefecture {
          font-size: 80px;
          float: left;
          margin-left: 20px;
          line-height: 1em;
        }
      }

      .schedule {
        width: 450px;
        padding: 35px 15px 0 15px;
        background-color: #9cf;
        margin: 0;
        float: right;

        dl {
          overflow: hidden;
        }

        dt {
          width: 240px;
          text-align: left;
          clear: both;
          float: left;
          margin-bottom: 25px;

          .month {
            font-size: 30px;
            font-weight: bold;
            line-height: 1.3em;
            vertical-align: top;
          }

          .date {
            white-space: nowrap;
            font-size: 54px;
            vertical-align: top;
            font-weight: bold;
            line-height: 1em;

            .day {
              font-size: 26px;
              font-weight: bold;
              line-height: 1em;
            }
          }
        }

        dd {
          width: 160px;
          font-size: 15px;
          float: right;
          margin: 0;
          margin-top: 5px;
          padding: 0;
          margin-bottom: 20px;

          .time {
            font-size: 22px;
            display: block;
            font-weight: bold;
            line-height: 1em;
          }
        }
      }
    }
  }

  #nav {
    background-color: #00a3d9;
    color: $color_white;
    margin-top: -5px;

    ul {
      margin: 0 auto;
      padding: 0;
      width: 950px;
      overflow: hidden;

      li {
        float: left;
        width: 189px;
        border-right: 1px solid $color_white;

        &:first-child {
          width: 188px;
          border-left: 1px solid $color_white;
        }

        a {
          display: block;
          height: 85px;
          padding-top: 35px;
          color: $color_white;
          font-size: 16px;
          font-weight: bold;
          background: url(#{$eventImg_path}nav_arrow.png) center 100px no-repeat;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }
    }
  }

  #company {
    .lead {
      font-size: 18px;
      padding: 20px 0 60px;
      text-align: left;
      width: 950px;
      margin: 0 auto;
    }
    
    .notice {
      font-size: 18px;
      padding: 40px 0 0;
      text-align: left;
      width: 950px;
      margin: 0 auto;
      
      p {
          font-size: 18px;
        }
    }

    .mainContentIn {
      .note {
        background-color: $color_white;
        padding: 50px;
        margin: 65px 0 45px;
      }

      .list {
        background: url(#{$eventImg_path}bg_company02.png) no-repeat 770px 20px, url(#{$eventImg_path}bg_company01.png);
        background-size: 154px, auto;
        padding: 65px 45px 1px 45px;
        margin-bottom: 45px;

        h3 {
          font-size: 40px;
          color: #0d386b;
          margin-bottom: 20px;
        }

        h4 {
          text-align: left;
          font-size: 26px;
          margin-bottom: 10px;
          font-weight: normal;
          color: #00a3d9;
          clear: both;
        }

        table {
          font-size: 12px;
          text-align: left;
          margin-bottom: 45px;
          clear: both;

          th {
            color: $color_white;
            background-color: #00a3d9;
            font-size: 16px;
            padding: 1em;
            vertical-align: top;
            font-weight: bold;
          }

          tr {
            .name {
              font-weight: bold;
              width: 300px;
            }

            td {
              background-color: $color_white;
              font-size: 16px;
              padding: 1em;
              vertical-align: top;
              border-bottom: solid 1px #ddd;

              a:link,
              a:visited {
                text-decoration: underline;
                color: #0d386b;
              }
            }

            td.name {
              width: 50%;
              line-height: 2;
            }

            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  #detail {
    .mainContentIn {
      h4 {
        font-size: 30px;
        overflow: hidden;
        line-height: 1em;
        margin-bottom: 1.33em;
        background-color: $color_white;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;

        .no {
          display: block;
          text-align: center;
          width: 70px;
          background-color: #ff8000;
          color: $color_white;
          padding: 22px 0 18px;
          border-radius: 10px 0 0 10px / 10px 0 0 10px;
          -webkit-border-radius: 10px 0 0 10px / 10px 0 0 10px;
          -moz-border-radius: 10px 0 0 10px / 10px 0 0 10px;
          float: left;
        }

        .text {
          display: block;
          color: #000;
          font-size: 30px;
          text-align: left;
          padding: 22px 20px 18px 20px;
          float: left;
          width: 840px;

          p {
            margin-bottom: 1.5em;
          }
        }
      }

      .wrapper {
        overflow: hidden;
        margin-bottom: 80px;
      }

      .text {
        width: 510px;
      }

      .img {
        width: 400px;

        img {
          width: 100%;
        }
      }
    }
  }

  #present {
    padding: 0 0 50px 0;
    background: url(#{$eventImg_path}bg_present.png) no-repeat 85% 10px;

    .mainContentIn {
      h3 {
        font-size: 60px;
        margin: 130px 0 80px;
      }

      .point {
        background-color: #4cbee4;
        padding: 80px 0 60px;
        position: relative;
        margin-bottom: 85px;

        h4 {
          font-size: 28px;
          background-image: url(#{$eventImg_path}present_h4.png);
          width: 312px;
          height: 60px;
          position: absolute;
          top: -25px;
          left: 50%;
          margin-left: -156px;
          display: block;
          padding-top: 8px;
        }

        p {
          font-size: 24px;
          text-align: left/*adjust*/;
          margin-left: 50px;
          margin-right: 50px;
          color: $color_white;
          line-height: 1.5em;
          font-family: "Verdana", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
        }

        &:last-of-type {
          margin-bottom: 15px;
        }
      }
    }

    ul.link {
      width: auto;
      float: right;
      font-size: 1.14em;
    }
  }

  #schedule {
    .mainContentIn {
      .note {
        background-color: $color_white;
        padding: 50px;
        margin: 10px 0 30px;
      }

      .card {
        background-color: $color_white;
        padding: 50px;
        position: relative;
        margin: 20px 0 70px;

        .date {
          display: block;
          height: 130px;
          width: 200px;
          background-image: url(#{$eventImg_path}shedule_date.png);
          background-repeat: no-repeat;
          color: $color_white;
          padding: 35px 0 0 10px;
          position: absolute;
          top: -20px;
          left: 40px;
          font-weight: bold;

          .days {
            font-size: 30px;
          }

          .day {
            font-size: 16px;
          }

          .time {
            display: block;
          }
        }

        h4 {
          font-weight: bold;
          color: #f88016;
          font-size: 26px;
          text-align: left;
          padding: 0 0 20px 230px;
          min-height: 62px;
          border-bottom: #f88016 dotted 2px;
          margin-bottom: 20px;
        }

        .wrapper {
          overflow: hidden;
          text-align: left;
          margin-top: 50px;

          .text {
            width: 650px;

            h5 {
              font-size: 18px;
              margin-bottom: 15px;
            }

            p {
              font-size: 13.965px;
            }
          }

          .img {
            margin-right: 100px;

            img {
              width: 160px;
              height: auto;
            }
          }
        }
      }
    }
  }

  #access {
    padding: 70px 0;

    h3 {
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 55px;

      &::before {
        content: url(#{$eventImg_path}icon_access.png);
        position: relative;
        bottom: -0.16em;
        margin-right: 0.3em;
      }
    }

    .mainContentIn {
      .wrapper {
        .map {
          width: 560px;
        }
      }

      .text {
        width: 340px;
        text-align: left;

        h4 {
          background-color: #fc0;
          text-align: center;
          width: 90px;
          padding: 5px;
          margin-bottom: 1em;
          line-height: 100%;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
        }

        p {
          margin-bottom: 48px;
        }

        ul {
          margin-left: 32px;

          li {
            list-style-type: disc;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  #flow {
    background-color: #00a3d9;

    h3 {
      font-size: 30px;
      color: $color_white;
      padding: 60px 0 70px;

      span {
        font-size: 60px;
        display: block;
      }
    }

    .mainContentIn {
      .card {
        background-color: $color_white;
        float: left;
        width: 300px;
        padding: 30px;
        margin-right: 25px;
        margin-bottom: 70px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        h4 {
          font-size: 30px;
          color: #0d386b;
          margin-bottom: 1em;
          text-align: center;
        }

        span {
          font-size: 26px;
          display: block;
        }

        .icon {
          margin-bottom: 20px;
        }

        .arrow {
          position: absolute;
          width: 60px;
          height: 60px;
          top: 50%;
          left: -43px;
          margin-top: -30px;
          -moz-transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }

        .link {
          text-align: left;
        }
      }
    }
  }

  a#previous {
    font-size: 18px;
    text-align: right;
    color: #00a3d9;
    margin: 50px auto;
    padding: 10px 30px 8px;
    border: 3px solid #00a3d9;
    display: block;
    width: 552px;
    border-radius: 27px;
    -webkit-border-radius: 27px;
    -moz-border-radius: 27px;
    background: url(#{$eventImg_path}arrow_back.png) no-repeat 20px center, $color_white;
    background-size: 10px;
    font-weight: bold;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .bottomLink {
    background: url(#{$eventImg_path}bg_bottomLink.png);
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0 0;
    z-index: 14;

    a#entry {
      font-size: 30px;
      color: $color_black;
      margin: 0 auto 10px;
      padding: 30px 30px 28px;
      display: block;
      width: 480px;
      border-radius: 47px;
      -webkit-border-radius: 47px;
      -moz-border-radius: 47px;
      background: url(#{$eventImg_path}arrow_entry.png) no-repeat 14em center, $color_white;
      background-size: 30px;
      font-weight: bold;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      span {
        font-size: 24px;
      }
    }
  }

  .pagetop {
    position: fixed;
    bottom: 120px;
    right: 20px;
    width: 90px;
    height: 90px;
    z-index: 13;
  }

  &.sub {
    #mainImage {
      padding: 50px 0;
      height: inherit;

      #mainTitle {
        background-image: none;
        margin: 0 auto;
        padding: 0;
        width: 950px;
        position: inherit;
        overflow: hidden;
        text-align: left;
        height: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        h2 {
          position: inherit;
          margin: 35px 0 10px 25px;
          padding: 0;
          width: 375px;
        }

        .title_event {
          margin: 0 0 10px 25px;
          padding: 0 0 0 0;
          vertical-align: inherit;
          height: 80px;

          .title_expo {
            width: 190px;
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    #nav {
      background-color: #0d386b;

      ul {
        li {
          a {
            height: 100px;
            padding-top: 35px;
            background: url(#{$eventImg_path}nav_arrow.png) center 80px no-repeat;
          }

          a:hover {
            background-color: #1964a9;
          }
        }
      }
    }

    #detail {
      padding-bottom: 50px;

      h3 {
        margin: 0 0 1.5em 0;
      }
    }

    #schedule h3 {
      margin: 0 0 1em 0;
    }

    .orange {
      background: url(#{$eventImg_path}bg_people01.png);

      h3 {
        background: url(#{$eventImg_path}bg_people02.png);
        color: $color_white;
        font-size: 60px;
        font-weight: bold;
        padding: 1.33em 0 1.5em;
        position: relative;
      }
    }
  }
}
