@charset "UTF-8";

.topFeatureList {
  @include clearfix;
  $component: &;

  &__item {
    a {
      @include dotArrow;
      color: $color_text;
      font-size: 1.4rem;

      &::before {
        top: 0.65em;
      }

      &::after {
        top: 0.65em;
      }

      #{$component}--job & {
        @include onHover;
        font-weight: bold;
      }
    }
  }

  &--columns {
    #{$component}__item {
      float: left;
      width: calc(50% - 15px);

      &:nth-child(odd) {
        margin-right: 30px;
      }

      &:nth-child(n+3) {
        margin-top: 21px;
      }
    }
  }

  &--stack {
    #{$component}__item {
      width: 100%;

      & + #{$component}__item {
        margin-top: 21px;
      }
    }
  }

  &--relation {
    #{$component}__item {
      &:nth-child(n+2) {
        margin-top: 1.5rem;
      }
    }
  }

  &--agent {
    #{$component}__item {
      a {
        @include onHover;
        font-weight: bold;
      }

      &:nth-child(n+2) {
        margin-top: 2rem;
      }
    }
  }
}
