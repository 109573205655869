@charset "UTF-8";

.topDashboard {
  margin-bottom: 60px;

  &__header {
    color: $color_heading;
  }

  &__title {
    border-bottom: 2px solid $color_keyColor;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  &__savedEntry {
    margin-bottom: 2rem;
  }

  &__username {
    font-size: 2rem;
    margin-right: 10px;
  }

  &__tooltip {
    color: $color_red;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: normal;
    height: 25px;
    left: 50%;
    line-height: 1;
    position: absolute;
    top: -30px;
    transform: translateX(-50%);
    z-index: 3;

    &::before {
      border: 6px solid transparent;
      border-left-width: 4px;
      border-right-width: 4px;
      border-top-color: $color_red;
      bottom: -7px;
      content: '';
      left: 50%;
      position: absolute;
      z-index: 1;
    }

    &::after {
      border: 6px solid transparent;
      border-left-width: 4px;
      border-right-width: 4px;
      border-top-color: #ffe5e5;
      bottom: -5px;
      content: '';
      left: 50%;
      position: absolute;
      z-index: 1;
    }

    span {
      background-color: #ffe5e5;
      border: 1px solid $color_red;
      border-radius: 4px;
      display: block;
      height: 20px;
      padding: 3px 8px 0;
      white-space: nowrap;
    }
  }

  &__main {
    @include clearfix;
  }

  &__recommends {
    @include clearfix;
    float: left;
    width: $top-card-width * 2 + 20px;

    .topRecommend {
      float: left;

      &:first-child {
        margin-right: 20px;
      }
    }

    .topBalloon {
      margin-top: 13px;
    }
  }

  &__menus {
    float: right;
    width: 290px;
  }

  &__menuItem + &__menuItem {
    margin-top: 3rem;
  }

  &__reminder {
    margin-bottom: 3rem;
  }
}

.topRecommend {
  width: $top-card-width;

  &__title {
    background-color: $color_keyColor;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: $color_white;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    padding: .8rem 1rem;
  }

  &__text {
    font-size: 1.3rem;
    height: 72px;
    line-height: 1.4;
    position: relative;

    & > * {
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  &__numLink {
    color: $color_keyColor;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0 .5em;
  }

  &__loading {
    margin-top: 3rem;
    text-align: center;
  }

  &__cardLink {
    @include onHover;
    color: inherit;
    display: block;

    .topCard {
      height: 36.7rem;

      &__title {
        // 3行の高さに合わせる
        height: $top-card-title-size * $top-card-title-line-height * 3;
      }
    }
  }

  &__notFound {
    color: $color_keyColor;
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 2rem;
    text-align: center;
  }
}

.topReminder {
  border: 1px solid $color_keyColor_light;
  display: table;
  width: 100%;

  &__list + &__list {
    border-top: 1px solid $color_keyColor_light;
  }

  &__textArea {
    display: table-cell;
    padding: 20px;
    width: 540px;
  }

  &__linkArea {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: $top-column-main-width - 540px;
  }

  &__link {
    @include onHover;
    background-color: $color_white;
    border: 1px solid $color_red;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    color: $color_red;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    width: 270px;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}
