@charset "UTF-8";

//応募企業へ面接日時を送るリンクが非表示の場合
.container:not(:has(.btnInterviewEntry)) {
  .btnBox_EntryInterview {
    display: none;
  }

  .entryCompleted {
    margin: 40px auto;
  }
}

//応募完了
.entryCompleted {
  align-items: flex-start;
  background: $color_white;
  border-top: 2px solid $color_keyColor;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto 40px;
  padding: 30px 32px 40px;
  width: 950px;

  .max {
    display: none;
  }

  &.other {
    padding: 30px 30px 32px;
  }

  .entryCompleted__headingBlock {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 636px;

    //チェックボックスが非表示の場合
    &:not(:has(.checkbox__selectAll)) {
      .entryCompleted__CardTitle {
        margin-bottom: 0;
      }
    }

    &.otherTitle {
      height: 36px;
    }

    & .checkbox__selectAll {
      align-items: center;
      background: $color_keyColor_light;
      border: 1px solid $color_keyColor;
      border-radius: 4px;
      display: inline-flex;
      padding: 8px 14px;

      &--icon {
        left: 4px;
        top: auto;
        scale: 0.8;
      }

      &--info {
        color: $color_keyColor;
        font-weight: 600;
        line-height: normal;
        margin-left: 10px;

        span {
          font-size: 12px;
        }
      }

      &--disabled {
        background-color: $color_subBorder;
        border: 1px solid transparent;
        border-color: $color_disable02;

        &.checkbox {
          cursor: default;
        }

        & .checkboxBlue__icon {
          &::before {
            background-color: #e9e9e9;
          }
        }

        & .checkbox__selectAll--info {
          color: $color_text;
        }
      }
    }
  }

  .entryCompleted__CardTitle {
    @include heading() {
      font-size: 2rem;
      font-weight: 600;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }

  .entryCompleted__cassette {
    align-items: center;
    align-self: stretch;
    background: var(--Color-white, $color_white);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    margin: 0;

    .checkbox__width {
      width: 40px;
    }

    .entryCompleted__container {
      align-items: flex-start;
      display: flex;
      flex: 1 0 0;
      gap: 8px;
      height: auto;
      padding: 10px 12px 14px 12px;

      &.container__other {
        padding: 14px 12px;
      }

      .entryCompleted__jobInfo {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 602px;

        &.jobInfo__other {
          width: 640px;
        }

        .ReadyEntry {
          align-items: flex-start;
          background: $color_premium3;
          border: 1px solid $color_premium;
          border-radius: 1px;
          display: flex;
          gap: 6px;
          padding: 4px;
          height: 19px;

          &__text {
            color: $color_premium;
            font-size: 9px;
            font-weight: 500;
            line-height: 11px;
          }
        }

        &--content {
          align-self: stretch;
          display: flex;
          gap: 10px;
          height: 72px;

          .entryCompleted__img img {
            height: auto;
            width: 96px;
          }

          .entryCompleted__condition {
            align-items: flex-start;
            display: flex;
            flex: 1 0 0;
            flex-direction: column;
            gap: 8px;
            word-break: break-all;

            &--area,
            &--salary,
            &--company {
              align-items: flex-start;
              align-self: stretch;
              display: flex;
              gap: 6px;

              .icon__area,
              .icon__salary,
              .icon__company {
                color: #01aaeb;
                font-family: mynavi; /* stylelint-disable-line */
                font-size: 1.6rem;
                height: 18px;
                position: relative;
                top: -2px;
              }

              .icon__area::before {
                content: "\e614";
              }

              .icon__salary::before {
                content: "\e905";
              }

              .icon__company::before {
                content: "\e90d";
              }

              .detail {
                display: -webkit-box;
                font-size: 13px;
                line-height: 18px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                flex: 1 0 0;
                overflow: hidden;
              }
            }
          }
        }

        &--overview {
          align-items: center;
          align-self: stretch;
          color: var(--Color-main_primary, $color_keyColor);
          display: flex;
          flex: 1 0 0;
          font-size: 16px;
          font-weight: 600;
          gap: 10px;
          line-height: 150%;
        }
      }

      .entryCompleted__btn {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        width: 200px;

        &--entry {
          @extend .btnEnter;
          align-items: center;
          display: flex;
          gap: 10px;
          height: 44px;
          justify-content: center;
          margin-bottom: 0;
          padding: 8px;
          width: 200px;
        }

        .btnInterst {
          @include iconFont($fontSize: 21px, $icon: "\e91e");
          @include btn($padding: 5px 8px) {
            align-items: center;
            background-color: $color_white;
            border-color: $color_keyColor;
            color: $color_text;
            display: flex;
            gap: 4px;
            height: 44px;
            justify-content: center;
            margin-bottom: 0;
            min-width: 200px;
            min-height: 44px;
          }

          &::before {
            color: #fbb553;
            height: 24px;
          }
        }

        .btnInterst--disabled {
          @include iconFont($fontSize: 21px, $icon: "\e91e");
          @include btn($padding: 5px 8px) {
            align-items: center;
            background-color: #bbb;
            border-color: #bbb;
            box-shadow: none;
            color: #555;
            cursor: default;
            display: flex;
            gap: 4px;
            height: 44px;
            justify-content: center;
            margin-bottom: 0;
            pointer-events: none;
            min-width: 200px;
            min-height: 44px;
          }

          &::before {
            color: #fbb553;
            height: 24px;
          }
        }
      }
    }
  }

  /* ページ下部追従要素 一括応募選択(応募完了画面用)
  ----------------------------------------------------------------- */
  + .fixedBulkEntry {
    background: #4f4d4db2;
    height: auto;

    div {
      margin-bottom: 17px;
      margin-top: 15px;

      a {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.3;
        width: 351px;
      }
    }
  }
}

// 一括応募専用チェックボックス
.checkboxBlue {
  @include checkboxBlue;
}

.cassetteJobOffer {
  .checkboxBlue {
    border-right: 1px solid #ddd;
    cursor: pointer;
    display: table-cell;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    width: 40px;

    &:hover {
      background-color: rgba(221, 221, 221, 0.4);
    }

    &__icon {
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &::before {
        left: 0;
        position: absolute;
        top: 0;
        z-index: 1;
      }

      &--large {
        transform: translate(-50%, -50%) scale(0.6875);
      }
    }
  }

  .cassetteJobOffer__select--disabled.checkboxBlue {
    .checkboxBlue__icon {
      background-color: #e9e9e9;
    }

    &:hover {
      background-color: #ddd;
    }
  }
}
