@charset "UTF-8";

.jobSearchBalloon {
  border: 1px solid $color_heading;
  height: 90px;
  margin-bottom: 24px;
  position: relative;
  text-align: center;
  width: 100%;

  &::before {
    content: '';
    border: 12px solid transparent;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    border-top-color: $color_heading;
    bottom: -24px;
  }

  &::after {
    content: '';
    border: 12px solid transparent;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    border-top-color: #fff;
    bottom: -23px;
  }

  &--right {
    width: 600px;
    margin-right: 10px;
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    padding-top: 24px;
    text-align: center;
    letter-spacing: 1px;

    &::before {
      content: "";
      position: absolute;
      bottom: -1px;
      height: 20px;
      display: block;
      background: #fff;
      left: -1px;
      width: calc(50% - 70px);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      width: calc(50% - 70px);
      height: 20px;
      display: block;
      background: #fff;
      right: -1px;
    }
  }

  &__title {
    color: $color_keyColor;
    display: block;
    font-size: 1.6em;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 1.4rem;
    font-weight: 700;
    color: $color_heading;
  }

  &--main {
    width: 60rem;
  }
}

.jobSearchBalloonWrapper {
  @include clearfix;
  margin-bottom: 2rem;

  .jobSearchBalloon {
    float: left;
  }

  .jobSearchKnowHowLink {
    float: right;
  }
}
