@charset "UTF-8";

.jobNaviGroup {
  &__header {
    background-color: #cfecfa;
    font-size: 1.5rem;
    line-height: 1.3em;
    padding: 20px 1rem 20px 75px;
    position: relative;
  }

  &__titleIcon {
    background-color: $color_white;
    border: 1px solid #00aaeb;
    border-radius: 99999px;
    border-style: dashed;
    height: 50px;
    left: 10px;
    padding: 10px 12px 0 12px;
    position: absolute;
    top: 15px;
    width: 50px;

    &--A {
      padding: 7px 14px 0 14px;
    }

    &--D {
      padding: 8px 12px 0 12px;
    }

    &--E {
      padding: 10px 9px 0 9px;
    }

    &--F,
    &--G {
      padding: 11px 12px 0 12px;
    }

    &--H {
      padding: 14px 12px 0 12px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font-weight: bold;
    color: #333;

    &--keyword {
      line-height: 2.5;
    }
  }
}

.itemText {
  font-size: 1.2rem;
  line-height: 1.3em;
  font-weight: 700;
}
