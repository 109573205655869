@charset "UTF-8";

/*! =======================================================================
 * File        : selectcondition.css
 * DESCRIPTION : 職種×勤務地から探すモーダル用CSS
 * AUTHOR      : i-studio Co.,Ltd.
 * ------------------------------------------------------------------------
 * (C)Mynavi Corporation
========================================================================== */

.js--otherArea {
  display: none;
}

/* selectCondition
----------------------------------------------------- */
.selectCondition--job {
  position: absolute;
  z-index: 110;
  display: none;
  width: 950px;
  height: 718px;
  margin: 0 auto;
  background-color: #fff;
}

.selectCondition--area {
  position: absolute;
  z-index: 110;
  display: none;
  width: 950px;
  height: 574px;
  margin: 0 auto;
  background-color: #fff;
}

.selectCondition__title {
  margin: 0 0 0 14px;
  padding: 0;
  color: #000;
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 2.8947;
  border-bottom: 1px solid #ddd;
}

.selectCondition .btnClose {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
}

.selectCondition .labelNumber {
  vertical-align: top;
  margin: 0 0 0 8px;
  font-size: 1.1rem;
}

.selectCondition__container--job {
  width: 950px;
  height: 575px;
}

.selectCondition__container--area {
  width: 950px;
  height: 431px;
}

.selectCondition__menu {
  overflow: hidden;
  float: left;
  width: 325px;
  height: 100%;
}

.selectCondition__menuItem {
  position: relative;
  display: block;
  padding: 0 0 0 15px;
  color: #00aaeb;
  font-weight: bold;
  line-height: 2.5;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  cursor: pointer;
}

.selectCondition__menuItem:first-child {
  border-top: none;
}

.selectCondition__menuItem:last-child {
  border-bottom: none;
}

.selectCondition__menuItem span {
  vertical-align: middle;
  font-size: 1.4rem;
}

.selectCondition__menuItem--kerning {
  position: relative;
  display: block;
  padding: 0 0 0 15px;
  color: #00aaeb;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 2.5;
  cursor: pointer;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.selectCondition__menuItem--kerning span {
  vertical-align: middle;
  font-size: 1.4rem;
}

.selectCondition__menuItem--kerning.js--menuActive,
.selectCondition__menuItem.js--menuActive {
  padding: 0 0 0 9px;
  color: #000;
  border-left: 6px solid #00aaeb;
  border-right: none;
}

.selectCondition__menuItem--kerning::after,
.selectCondition__menuItem::after {
  content: url(#{$img_path}arrowRight.png);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.selectCondition__menuItem--kerning.js--menuChecked::after,
.selectCondition__menuItem.js--menuChecked::after,
.selectCondition__menuItem--kerning.js--menuSelected::after,
.selectCondition__menuItem.js--menuSelected::after {
  content: '選択中';
  padding: 1px 6px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.2;
  background-color: #00aaeb;
  border-radius: 12px;
}

.selectCondition__content {
  overflow: scroll;
  overflow-x: hidden;
  float: left;
  width: 625px;
  height: 100%;
}

.selectCondition__largeCat {
  margin: 22px 20px 0;
}

.selectCondition__largeCat input {
  margin: 0 7px 0 0;
}

.selectCondition__largeCat label {
  color: #000;
  font-size: 1.7rem;
  font-weight: bold;
}

.selectCondition__midiumCat {
  margin: 22px 20px 0;
  padding: 8px 15px;
  background-color: #f0f0f3;
}

.selectCondition__midiumCat input {
  margin: 0 6px 0 0;
}

.selectCondition__midiumCat label {
  color: #000;
  font-size: 1.4rem;
  font-weight: bold;
}

.selectCondition__list {
  margin: 0 -17px 0 35px;
}

.selectCondition__listItem {
  float: left;
  width: 280px;
  margin: 9px 0 0;
  font-size: 1.2rem;
  line-height: 1.5;
  cursor: pointer;
}

.selectCondition__listItem input {
  vertical-align: top;
  margin: 3px 6px 0 0;
}

.selectCondition__listItem label {
  display: inline-block;
  vertical-align: top;
  width: 255px;
}

.selectCondition__more {
  margin: 17px 0 0 35px;
}

.selectCondition__more a {
  font-size: 1.2rem;
}

.selectCondition__more a::before {
  content: url(#{$img_path}arrowRight.png);
  margin: 0 6px 0 0;
}

.selectCondition__footer {
  overflow: hidden;
  width: 100%;
  height: 86px;
  border-top: 1px solid #ddd;
}

.selectCondition__selected {
  overflow: scroll;
  overflow-x: hidden;
  float: left;
  width: 325px;
  height: 100%;
  padding: 15px;
  font-size: 1.1rem;
  line-height: 1.4545;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}

.selectCondition__submit {
  float: left;
  width: 613px;
  height: 100%;
  padding: 20px 0 0;
  box-sizing: border-box;
}

.selectCondition__fixButton,
.selectCondition__selectButton {
  float: left;
  width: 295px;
}

.selectCondition__fixButton [class^="btn"],
.selectCondition__selectButton [class^="btn"] {
  width: 100%;
}

.selectCondition__fixButton {
  margin: 0 10px;
}
