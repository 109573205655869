@charset "UTF-8";

.scoutContainer {
  border-top: solid 1px $scout-container-border;
  font-size: 1.4rem;
  line-height: 1.6;

  &__2col {
    margin: 0 auto;
    width: 1140px;

    &::after {
      content: "";
      clear: both;
      display: block;
    }
  }

  &__main {
    padding: 40px 0;
    background: $scout-gray-bg;

    & > *:first-child {
      margin-top: 0;
    }

    & > *:first-child:last-child {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__2colLeft {
    width: 800px;
    float: left;
  }

  &__2colRight {
    width: 320px;
    float: right;
  }
}
