@charset "UTF-8";

/*! =======================================================================
 * File        : footer.css
 * DESCRIPTION : フッタデザイン用CSS
 * AUTHOR      : i-studio Co.,Ltd.
 * ------------------------------------------------------------------------
 * (C)Mynavi Corporation
========================================================================== */

body:has(.fixedBulkEntry) {
  .footer {
    padding-bottom: 118px;
  }
}

/* footer
----------------------------------------------------------------- */
.footer {
  width: 100%;
  padding-top: 20px;
  font-size: 12px;
  background-color: $color_white;
}

.footer__content {
  border-top: 1px solid #ddd;
}

.footer__heading {
  @include boxCenter;
  padding-top: 20px;
  margin-bottom: -10px;

  &__noPadding {
    padding-top: 0;
    margin-top: -5px;
  }
}

.footer__note {
  @include boxCenter;
  color: $color_subText;
  font-size: 1.1rem;
  padding-top: 10px;
}

.footer__goToHome {
  @include boxCenter;
  padding-bottom: 20px;
}

.footer__goToHome a {
  display: inline-block;
  padding: 3px 0 3px 22px;
  background: url(#{$img_path}iconHome.png) 0 0 no-repeat;
}

.menuLink {
  @include boxCenter;
  @include clearfix;
  @include arrowLink--small(20px 0 8px);

  &__break {
    margin: -30px auto 0 auto;
  }
}

.menuLink__item:nth-child(8) {
  border-right: none;
}

.menuLink__item:nth-child(9) {
  clear: both;
}

.otherLink {
  @include boxCenter;
  @include clearfix;
  @include arrowLink--small(20px 0 8px);
}

.footer__corporate {
  @include boxCenter;
  @include clearfix;
  padding: 10px 0 25px;
}

.footer__logo {
  float: left;
  margin: 15px 13px 0 0;
  line-height: 0;
}

.footer__copyright {
  float: left;
  margin-top: 17px;
  line-height: 0;

  &__center {
    float: none;
    text-align: center;
  }
}

.footer__site .menuLink,
.footer__site .otherLink {
  padding: 0;
}

/* footer ログイン後トップ
----------------------------------------------------------------- */
.footerBlock {
  padding: 12px;
  text-align: center;
  background-color: #fff;
}

.footerBlock > *:first-child {
  margin: 0 0 8px;
}

.footerBlock--hasButton {
  padding: 12px 12px 120px;
}

.footerBlock--hasButton > *:first-child {
  margin: 0 0 8px;
}

.footerBlock--hasBanner {
  padding: 12px 12px 126px;
}

.footerBlock--hasBanner > *:first-child {
  margin: 0 0 8px;
}

.footerBlock__pcButton {
  display: block;
  width: 100%;
  padding: 6px 12px;
  color: #555;
  font-size: 1.1rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  background-color: #fff;
  border: 2px solid #e0dddd;
  border-radius: 2px;
}

.footerBlock__pcButton [class*="iconFont--"]::before {
  font-size: 1.2em;
}

.footerList {
  position: relative;
  min-height: 30px;
  margin: 8px 0;
  padding: 0;
}

[class*="footerList__item"] {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footerList__item--siteTop {
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.footerList__item--pageTop {
  position: absolute;
  top: 0;
  right: 0;
  width: 90px;
}

.footerList__item--categoryTop {
  margin: 0 96px 0 86px;
}

.footerButton,
.footerButton--category {
  color: #555;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 6px 4px;
  font-size: 1.1rem;
  line-height: 1.3;
  text-align: center;
  text-decoration: none;
  background-color: #fff;
  border: 2px solid #e0dddd;
  border-radius: 2px;
}

.footerButton--category {
  background-color: #ebebeb;
}

.footerBlock__copyright {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 11px;
  line-height: 1.2;
  text-align: center;
}

/* footer
============================================================ */
.footerTop {
  padding: 24px 0 0;
  border-top: 1px solid #e1e1e1;
}

.footer__site {
  width: 950px;
  padding-top: 16px;
  margin-right: auto;
  margin-bottom: 4px;
  margin-left: auto;
  font-size: 1.2em;
  line-height: 1.5;
  border-top: 1px solid #d8d8d8;
}

.footerGroup {
  padding-bottom: 30px;
  padding-top: 21px;
  color: $color_heading;
  font-size: 1.2rem;
  line-height: 1.5;
  background: url(../img/common/background_02.png) repeat-x;
}

.footerGroup__inner {
  @include clearfix;
  width: 950px;
  _height: 1%;
  margin-left: auto;
  margin-right: auto;
  *zoom: 1;
}

.footerGroup__logo {
  float: left;
  margin-top: 22px;
}

.footerGroup__txt {
  float: right;
  width: 845px;
  margin-bottom: 10px;
  font-weight: bold;
}

.footerGroup__content {
  float: right;
  width: 845px;
  padding-bottom: 2px;
  background-position: 0 100%;
  border-bottom: 1px dotted #ddd;
  margin: 0;
}

.relationSiteList {
  border-top: 1px dotted #ddd;
  _height: 1%;
  margin-top: 1px;
  *margin-top: 2px;
  padding-top: 2px;
  *padding-top: 1px;
  *zoom: 1;
}

.relationSiteList::after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

.relationSiteList:first-child,
.relationSiteList.first_child {
  margin-top: 0;
}

.relationSiteList__title,
.relationSiteList__item {
  display: table-cell;
  *display: inline;
  vertical-align: middle;
  *zoom: 1;
}

.relationSiteList__title {
  color: $color_heading;
  width: 100px;
  padding-bottom: 6px;
  padding-top: 6px;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 0;
  text-align: center;
  background-color: #f7f7f7;
}

.relationSiteList__item {
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 10px;
}

.relationSiteList__list {
  overflow: hidden;
}

.relationSiteList__listItem {
  float: left;
  margin-left: -8px;
  margin-right: 15px;
  padding-left: 8px;
  background: url(../img/common/background_01.gif) 0 49% no-repeat;
}

.relationSiteList__listItem:last-child,
.relationSiteList__listItem.last_child {
  margin-right: 0;
}

.relationSiteList a {
  color: #777;
}

.footerCorporate {
  padding-bottom: 45px;
  padding-top: 10px;
  border-top: 1px solid #d8d8d8;
}

.footerCorporate__inner {
  @include clearfix;
  width: 950px;
  _height: 1%;
  margin-left: auto;
  margin-right: auto;
  *zoom: 1;
}

.footerCorporate__copyright {
  font-size: 1rem;
  line-height: 0;
  text-align: center;
  margin-top: 17px;
  margin-bottom: 6px;
}

.footerCorporate__privacy {
  float: right;
}

.footerCorporate .otherLink {
  float: left;
  width: 800px;
  padding-top: 7px;
}

#previewFooterArea {
  clear: both;
  width: 750px;
  margin: 0 auto;
  padding: 15px 0 20px;
  text-align: left;
}

.footer__txt {
  width: 950px;
  margin: 0 auto 5px;
  color: #999;
  font-size: 10px;
  line-height: 1.3;
}

/* 画面遷移セット
------------------------------------------------------------ */
#footer div.return {
  width: 950px;
  margin: 0 auto 30px;
}

#footer ul.return {
  _height: 1%;
  margin: 0 auto 30px;
  font-size: 0;
  line-height: 0;
  *zoom: 1;
}

#footer ul.return::after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

#footer ul.return li.top {
  float: left;
}

#footer ul.return li.close {
  float: right;
  margin-left: 12px;
}

#footer ul.return li.page {
  float: right;
  margin-top: 5px;
  margin-left: 12px;
}

#footer ul.return li {
  float: left;
}

#footer ul.return li:last-child {
  float: right;
}

#footer ul.return li.last_child {
  float: right;
}

.footer__copyright .copyright__conpany {
  float: left;
  width: 114px;
}

.footer__copyright .copyright {
  float: left;
  width: 200px;
  margin-top: 3px;
  color: #aaa;
}

/* 下層フッター
----------------------------------------------------- */
.footerLower {
  @extend .footer;
  padding-top: 0;
  background-color: #fafafb;
}

.footerLower__content {
  @extend .footer__content;
  background-color: $color_white;
}

.footerLower__corporate {
  @extend .footer__corporate;
}

.footerLower__logo {
  @extend .footer__logo;
}

.footerLower__copyright {
  @extend .footer__copyright;
}

/* 別ウィンドウフッター
----------------------------------------------------- */
.footerOtherWindow {
  @extend .footer;
  padding-top: 0;
  background-color: #fafafb;
}

.footerOtherWindow__content {
  @extend .footer__content;
  background-color: $color_white;
}

.footerOtherWindow__corporate {
  @extend .footer__corporate;
}

.footerOtherWindow__logo {
  @extend .footer__logo;
  margin: 15px 13px 0 10px;
}

.footerOtherWindow__copyright {
  @extend .footer__copyright;
}

.footerBreadcrumb {
  background-color: $color_keyColor;
  height: 50px;
  overflow: hidden;

  &__object {
    color: $color_white;
    font-size: 0;
    height: 70px;
    margin-top: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: center;
    white-space: nowrap;

    li:first-child {
      padding-left: 12px;
    }

    li:not(:last-child)::after {
      content: " \003e ";
    }

    li {
      display: inline-block;
      font-size: 1.2rem;
      line-height: 50px;
      padding-right: 4px;
      position: relative;

      a {
        color: $color_white;
        font-size: 14px;
        position: relative;
        text-decoration: none;
      }
    }
  }
}
