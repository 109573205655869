@charset "UTF-8";

/* ＋Stories.
----------------------------------------------------------------- */
.topSection {
  $component: &;

  &--plst {
    background-color: #fffced;
    padding: 20px 10px 0;

    #{$component}__header {
      margin-bottom: 0;
    }

    #{$component}__title {
      color: #706f6f;

      &--sub {
        @extend #{$component}__title;
        font-size: 1.5rem;
        margin-top: 30px;
      }
    }

    &__logo {
      float: right;
      margin: -10px 10px 0 75px;
      width: 300px;
    }

    #{$component}__main--theme {
      max-height: 100px;
    }

    #{$component}__main {
      padding-bottom: 20px;

      .topCarousel {
        &__item--mini {
          width: 169px;

          .topCard {
            &--plstTheme {
              width: 150px;
            }

            &__eyeCatch {
              &--plstTheme {
                height: 100px;
                width: 150px;

                img {
                  border-radius: 10px;
                  height: 100px;
                  width: 150px;
                }
              }

              &--plstTheme::after {
                background: hsla(0, 0, 0, .3);
                border-radius: 10px;
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
              }
            }

            &__title {
              &--plstTheme {
                background: none;
                color: $color-white;
                font-size: 1.5rem;
                font-weight: bold;
                margin-top: -50px;
                transform: translate(0, -50%);
                position: absolute;
                text-align: center;
                width: 100%;
                z-index: 2;
              }

              &--plstRecommend {
                background-color: $color-white;
                overflow: hidden;
                height: 4em;
                line-height: 2;
              }
            }
          }
        }

        &__item {
          .topCard {
            background-color: $color-white;

            &--plstTheme {
              width: 150px;
            }

            &__eyeCatch {
              height: 100%;

              img {
                height: 141px;
                object-fit: cover;
              }
            }

            &__title {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              padding: 0 10px;
              height: 48px;
              margin-top: 1rem;
            }

            &__text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              height: 48px;
              line-height: 2.3rem;
              padding: 0 10px;
              margin-top: 1rem;
            }
          }
        }

        &__controlPlstTheme {
          top: 40px;
        }
      }
    }

    .nowLoading {
      position: absolute;
      top: 60px;
      left: 90px;
    }
  }
}
