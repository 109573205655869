@charset "UTF-8";

/* イベント用 カセット要素
----------------------------------------------------------------- */
.eventFair {
  .eventList {
    padding: 0;
    width: 100%;

    .other_event_announce {
      font-size: 20px;
      font-weight: bold;
      padding-left: 20px;
    }

    &__join {
      background-color: #fcb653;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);
      color: $color_white;
      font-size: 14px;
      font-weight: 300;
      padding: 7px 0 4px 10px;
    }

    &__join + .eventList__item .cassetteEvent {
      border: solid 4px #fcb653;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .3);
    }
  }
}
