@charset "UTF-8";

.tagList {
  margin-top: -15px;

  &__item {
    display: inline-block;
    margin-right: 10px;
    margin-top: 15px;
  }
}

.topTag {
  @include onHover;
  align-items: center;
  background-color: $color_keyColor;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 99999px;
  border-bottom-left-radius: 99999px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
  color: $color_white;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 8px 10px 8px 20px;
  position: relative;

  &::before {
    background-color: $color_white;
    border-radius: 99999px;
    content: "";
    display: inline-block;
    height: 5px;
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
  }

  &__second {
    @include onHover;
    align-items: center;
    border: 2px solid #00aaeb;
    border-radius: 99999px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
    color: #00aaeb;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 8px 20px;
    position: relative;
  }
}
