@charset "UTF-8";

// 求人情報ページ共通スタイル

/* ボタン 求人情報用 (全幅)
----------------------------------------------------------------- */
.container__inner.lightBlue {
  background-color: #f0fcff;
  box-shadow: 0 4px 5px -5px inset;
  width: 100%;
}

.container__inner.lightBlue .jobPointArea.lightBlue {
  width: 950px;
  margin: 0 auto;
  padding: 40px 0 20px;
}

/* ボタン 求人情報用 (全幅)
----------------------------------------------------------------- */
.btnListL__item.full {
  width: 100%;

  .btnPrimary,
  .btnInterst,
  .btnDefaultL {
    min-width: 100%;
  }
}

/* スカウト　FVに応募ボタン
----------------------------------------------------------------- */
.btnListL__item.scout {
  width: 408px;
}

/* ボタン 求人情報用 (横並び、半分の幅)
----------------------------------------------------------------- */
.btnListL.sideBySide {
  margin: 10px 0 0;

  &.pb-3 {
    padding-bottom: 30px;
  }
}

.btnListL__item.half {
  width: calc(50% - 5px);

  &:first-child {
    margin: 0 5px 0 0;
  }

  &:last-child {
    margin: 0 0 0 5px;
  }

  &:first-child:last-child {
    margin: 0 0 0 0;
  }

  .btnPrimary,
  .btnInterst,
  .btnDefaultL {
    min-width: 100%;
  }
}

/* ボタン 求人情報用 (狭幅)
----------------------------------------------------------------- */
.btnListL__item.narrow {
  width: 200px;

  .btnPrimary,
  .btnInterst,
  .btnDefaultL {
    min-width: 200px;
  }

  .bookmark {
    padding: 13px 10px;
  }
}

/* ボタン メッセージ用 (広幅)
----------------------------------------------------------------- */
.btnListL.wide {
  margin: 0;
  padding-bottom: 40px;

  .btnListL__item {
    width: 234px;
    margin: 0 0 0 11px;
  }

  .btnListL__item.wide {
    width: 450px;
    margin: 0 10px 0 0;
  }
}

/* タブナビ 求人情報用 (青)
----------------------------------------------------------------- */
.tabNaviRecruit.blue {
  border-bottom: 2px solid $color_keyColor;
  padding-top: 30px;

  a {
    height: 48px;
  }
}

/* IE11のみ適用させる */
@media all and (-ms-high-contrast: none) {
  .tabNaviRecruit.grayIE11Nav {
    border-bottom: 2px solid #A1A1A1;

    .grayIE11NavTop {
      background-color: #A1A1A1;
      border-color: #A1A1A1;
    }
  }

  .tabNaviRecruitBottom.blue.grayIE11Nav {
    border-top: 2px solid #A1A1A1;

    .grayIE11NavBottom {
      background-color: #A1A1A1;
      border-color: #A1A1A1;
    }
  }
}

.tabNaviRecruit__item.blue,
.tabNaviRecruit__item--active.blue {
  padding: 0 0 0 6px;
  width: 106px;

  &:first-child {
    padding: 0;
    width: 100px;
  }
}

.tabNaviRecruit.blue::before,
.tabNaviRecruit__item.blue a[href]::before {
  box-shadow: none;
}

.tabNaviRecruit__item.blue a[href]::after,
.tabNaviRecruit.blue::after {
  border-bottom: 0;
}

.tabNaviRecruit__item--active a[href]::before {
  box-shadow: none;
  bottom: -10px;
}

.tabNaviRecruit__item--active a[href]::after {
  border: none;
}

.tabNavi__item.blue > a {
  color: $color_subText;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 16px;
  border-color: #dfeff5;
}

.tabNavi__item.blue.threeLine > a {
  font-size: 1.1rem;
  line-height: 13px;
}

.tabNavi__item--active.blue > span {
  color: $color_white;
  background-color: $color_keyColor;
  border-color: $color_keyColor;
  height: 48px;
}

// OK
.tabNavi__item--active.blue > a {
  color: $color_white;
  background-color: $color_keyColor;
  border-color: $color_keyColor;
  height: 48px;
}

.tabNavi__item--active.blue > span::after {
  content: none;
}

.tabNavi__item--active.blue.threeLine > span {
  font-size: 1.1rem;
  line-height: 13px;
}

.tabNaviRecruit__tooltip.yellow {
  top: -30px;
  width: 105px;
  height: 30px;
  font-size: 1rem;

  span {
    color: $color_keyColor;
    border-color: $color_keyColor;
    background-color: #fff7ca;
    height: 25px;
    font-size: 1rem;
    padding: 7px 8px 0;
  }
}

.tabNaviRecruit__tooltip.yellow::before {
  border-top-color: $color_keyColor;
  bottom: -6px;
}

.tabNaviRecruit__tooltip.yellow::after {
  border-top-color: #fff7ca;
  bottom: -4px;
}

.tabNaviRecruitBottom.blue {
  padding: 0 0 40px;
  border: 2px solid $color_keyColor;
  border-width: 2px 0 0 0;
  margin-top: 0;
}

/* 「この求人のポイント」などに使用される領域
----------------------------------------------------------------- */
.jobPointArea {
  &__mainWrap {
    display: flex;
    justify-content: space-between;
    letter-spacing: -.4em;

    .leftBlock {
      letter-spacing: normal;
    }

    .rightBlock {
      letter-spacing: normal;
      position: relative;
    }
  }

  &__sideMenu {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
  }

  .leftBlock {
    float: left;
    width: 50%;
    clear: both;
    padding-right: 25px;
    word-break: break-all;
    word-wrap: break-word;
    margin-bottom: 40px;
  }

  .rightBlock {
    float: right;
    width: 50%;
    padding-left: 25px;
  }

  &__wrap {
    &-jobDescription {
      & h4 {
        font-size: 1.4rem;
      }
    }
  }

  &__title {
    clear: both;
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    line-height: 32px;
    padding-bottom: 50px;
    margin-top: -5px;

    &::after {
      content: '';
      position: absolute;
      width: 100px;
      height: 5px;
      bottom: 31px;
      left: 0;
      border-radius: 3px;
      z-index: 3;
      background-color: $color_keyColor;
    }
  }

  &__head.top {
    margin: -4px 0 30px;
    font-weight: bold;
    font-size: 1.8rem;
  }

  &__head {
    font-weight: normal;
    font-size: 1.4rem;
    color: #333;
    line-height: 28px;
    margin: -4px 0 43px;
  }

  &__large {
    font-weight: bold;
    font-size: 2rem;
    color: #333;
    padding-bottom: 9px;
    margin-left: 40px;
    line-height: 34px;
  }

  &__bold--blue {
    font-weight: bold;
    font-size: 1.6rem;
    color: $color_keyColor;
    word-break: break-all;
    word-wrap: break-word;
  }

  &__bold--blue + &__bold,
  &__bold--blue + &__bold--blue {
    padding-top: 23px;
  }

  &__body + &__body,
  &__bold--blue + &__body {
    padding-top: 20px;
  }

  div &__bold--blue.preview {
    padding-top: 5px;
    margin-top: 38px;
    background-color: rgb(166, 166, 166);
  }

  &__bold {
    font-weight: bold;
    font-size: 1.6rem;
    color: #333;
    padding-bottom: 14px;
  }

  &__body {
    font-weight: normal;
    font-size: 1.4rem;
    color: #333;
    line-height: 24px;
    padding-left: 40px;
    word-break: break-all;
    word-wrap: break-word;

    &-prArea {
      display: table;
      table-layout: fixed;
      width: 100%;
      @supports (display: flex) or (display: -webkit-box) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        -ms-flex-align: align;
        align-items: align;
        flex-wrap: nowrap;
      }
    }

    &-prItem {
      display: table-cell;
      vertical-align: top;
      @supports (display: flex) or (display: -webkit-box) {
        display: block;
      }
    }

    &-prItem + .jobPointArea__body-prItem {
      @supports (display: flex) or (display: -webkit-box) {
        width: 280px;
        margin-right: 10px;
      }
    }

    h4 {
      line-height: 20px;
      font-weight: 700;
      font-size: 1.4rem;
      color: #333;
      padding-bottom: 10px;
    }

    h4:nth-child(n + 2) {
      padding-top: 26px;
    }

    .jobPicture {
      margin-right: 30px;
      margin-top: 5px;
      @supports (display: flex) or (display: -webkit-box) {
        margin-right: 0;
      }
    }

    ul {
      list-style-type: disc;
      margin: 0 0 0 15px;
      line-height: 23px;
    }
  }

  .jobPointArea__title + .jobPointArea__wrap-jobDescription .jobPointArea__title,
  .jobPointArea__body + .jobPointArea__wrap-jobDescription .jobPointArea__title {
    padding-top: 53px;
  }

  .jobPointArea__wrap-jobDescription .jobPointArea__title,
  .jobPointArea__wrap-jobDescription .jobPointArea__title {
    padding-top: 0;
  }

  &__body--large {
    font-weight: normal;
    font-size: 1.4rem;
    color: #333;
    line-height: 24px;
    padding-left: 40px;
    padding-bottom: 43px;

    ul {
      list-style-type: disc;
      margin: 0 0 0 15px;
      line-height: 23px;
    }

    h3 {
      padding-top: 0;
      font-weight: 700;
      font-size: 1.6rem;
      color: $color_keyColor;
    }

    h3:nth-child(n + 2) {
      padding-top: 43px;
    }

    h4 {
      font-weight: 700;
      font-size: 1.4rem;
      color: #333;
      padding-bottom: 14px;
      padding-top: 0;
    }

    h3 + h4 {
      padding-top: 23px;
    }

    h4 + h4 {
      padding-top: 0;
    }

    br + h4 {
      padding-top: 33px;
    }

    ul + h4 {
      padding-top: 33px;
    }
  }

  &__body.preview {
    background-color: rgb(166, 166, 166);
  }

  &__body + &__bold--blue {
    padding-top: 43px;
  }

  &__body + &__bold {
    padding-top: 33px;
  }

  .interviewPostscript {
    background-color: #fef2f4;
    border: 1px solid #f6a3b2;
    border-radius: 10px;
    margin: 30px 0 0 40px;
    padding: 30px 0 30px 30px;
    display: table;
    table-layout: fixed;

    &__title {
      position: relative;
      font-size: 2rem;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
      padding-top: 26px;
      padding-bottom: 30px;
      display: table-cell;
      width: 190px;
      vertical-align: middle;
      border-left: solid #f6a3b2 1px;
    }

    &__title::before {
      content: '';
      display: block;
      position: relative;
      width: 130px;
      height: 91px;
      bottom: 24px;
      left: calc(50% - 65px);
      background: url(../img/jobInfo/interviewPostscript.png);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__body {
      display: table-cell;
      width: 380px;
      vertical-align: top;
      padding-right: 30px;
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 24px;
      word-break: break-all;
      word-wrap: break-word;

      .text {
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 24px;
      }

      ul {
        list-style-type: disc;
        margin: 0 0 0 15px;
        line-height: 23px;
      }

      &__head {
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: -3px;
        margin-bottom: 51px;
        line-height: 28px;
      }

      &__suitable {
        h3 {
          font-size: 1.6rem;
          font-weight: 600;
        }
        background-color: rgba(246, 163, 178, .3);
        margin: 20px 0;
        color: #eb5170;
        height: 40px;
        line-height: 40px;
        border-radius: 30px;
        text-align: center;
        vertical-align: middle;
      }

      &__suitable h3::after {
        content: '';
        background: url(../img/jobInfo/interviewPostscript_suitable.png);
        position: relative;
        width: 42px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        top: -59px;
        left: 20px;
      }

      &__unsuitable {
        h3 {
          font-size: 1.6rem;
          font-weight: 600;
        }
        background-color: rgba(84, 194, 240, .3);
        margin: 52px 0 20px;
        color: #0088bc;
        height: 40px;
        line-height: 40px;
        border-radius: 30px;
        text-align: center;
        vertical-align: middle;
      }

      &__unsuitable h3::after {
        content: '';
        background: url(../img/jobInfo/interviewPostscript_unsuitable.png);
        position: relative;
        margin-top: 1px;
        padding-right: 8px;
        width: 42px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        top: -59px;
        left: 20px;
      }

      h4 {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 22px;
        color: $color_heading;
        padding-bottom: 6px;
      }
    }
  }

  .jobOfferTable {
    width: calc(100% - 40px);
    margin: 0 0 33px 40px;
    line-height: 24px;
    word-break: break-all;
    word-wrap: break-word;

    th:not(.stepTable__step):not(.stepTable__body) {
      width: 150px;
      border-right: 1px solid #ddd;
      padding: 18px 20px 18px 30px;
    }

    td:not(.stepTable__step):not(.stepTable__body) {
      background-color: $color_white;
      padding: 18px 30px;

      ul {
        list-style-type: disc;
        margin: 0 0 0 15px;
      }
    }

    h4 {
      font-size: 1.4rem;
      font-weight: 700;
    }

    &__body {
      .text + .text {
        margin-top:2em;
      }
    }

    &__body {
      .text +.offset_margin{
        margin-top: 0;
      }
    }


    &__zipcode {
      display: block;
    }

    .preview {
      th:not(.stepTable__step),
      td:not(.stepTable__body) {
        color: $color_black;
        background-color: rgb(166, 166, 166);
      }
    }
  }

  .jobOfferTable.thL {
    tr th:not(.stepTable__step) {
      width: 172px;
    }
  }

  .jobOfferTable.thXL {
    tr th:not(.stepTable__step) {
      width: 186px;
    }
  }

  .labelBox {
    padding-left: 40px;
  }

  .labelBox + .jobOfferTable {
    margin-top: 30px;
  }

  .labelBrown {
    background-color: $color_white;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 14px;
    border-radius: 3px;
    padding: 3px 10px 5px;
    color: #9e8b75;
    border: 1px solid #9e8b75;
    height: 20px;
    margin-bottom: 8px;
  }

  .jobOfferInfo__link {
    margin-top: 14px;
    margin-bottom: 4px;
  }

  .jobOfferInfo__link + table {
    margin-top: 24px;
  }

  &__preview--1 {
    color: $color_black;
    background-color: rgb(166, 166, 166);
    padding: 0 5px;
    margin: 30px 0;
  }
}

.jobPointBox {
  background-color: #fff;
  border: 1px solid #00aaeb;
  border-radius: 10px;
  margin-bottom: 40px;

  &__point {
    display: block;
    margin: 16px 20px;
    line-height: 20px;
    font-size: 1.4rem;
    font-weight: normal;
    word-break: break-all;
    word-wrap: break-word;

    &::before {
      content: '';
      background-image: url(../img/jobInfo/border.png);
      background-size: contain;
      height: 2px;
      border: 0 none;
      margin: 16px 0;
      display: block;
    }

    &:first-of-type {
      &::before {
        background-image: none;
        margin: 0 20px;
      }
    }
  }

  hr {
    background-image: url(../img/jobInfo/border.png);
    background-size: contain;
    height: 2px;
    border: 0 none;
    margin: 0 20px;
  }
}

.jobPointIndexBox {
  width: 280px;
  background-color: #fff;

  &__head {
    display: block;
    padding: 10px;
    text-align: center;
    line-height: 15px;
    font-size: 1.4rem;
    color: $color_white;
    background-color: $color_keyColor;
    font-weight: bold;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__index {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);

    a {
      color: $color_black;
      text-decoration: none;
      display: block;
      padding: 13px 15px 13px 35px;
      font-size: 1.3rem;
      font-weight: normal;
      cursor: pointer;
    }

    a:hover {
      opacity: .5;
    }

    a:nth-of-type(n + 2) {
      border-top: solid 1px $color_keyColor_light;
    }

    a::before {
      content: "";
      margin-left: -15px;
      position: absolute;
      z-index: 1;
      width: 10px;
      height: 14px;
      background: url(../img/jobInfo/icon_index.png) left center no-repeat;
      background-size: contain;
    }

    a span::before {
      content: "\e603";
      position: absolute;
      margin: 9px 0 0 214px;
      color: $color_keyColor;
      font-family: mynavi; /* stylelint-disable-line */
      font-size: 1.5rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1;
      speak: none;
      text-decoration: none;
      text-rendering: auto;
      text-transform: none;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

/* 追加リンク（追従）
----------------------------------------------------------------- */
.additionalLink {
  @extend .jobPointIndexBox;
  margin-top: 20px;

  .jobPointIndexBox__index {
    a {
      padding: 13px 15px;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
      }

      span {
        display: inline-block;
        font-size: 1rem;
        max-width: 165px;
        vertical-align: middle;
        word-wrap: break-word;
      }

      span::before {
        margin: 0;
        margin-top: 6px;
        right: 20px;
      }
    }

    a::before {
      content: none;
    }
  }
}

/* 社員インタビューリスト (見出し)
----------------------------------------------------------------- */
.jobInterviewBox {
  width: 280px;
  min-height: 228px;
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);

  &__head {
    text-align: center;
    line-height: 15px;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 20px;
  }
}

/* 求人情報タブ内 (水色背景)
----------------------------------------------------------------- */
.jobPointArea.lightBlue {
  box-shadow: 0 4px 5px -5px inset;
  background-color: #f0fcff;
  margin: 0 -74px;
  padding: 40px 74px 20px;
  height: 100%;
  clear: both;

  .leftBlock {
    display: inline-block;
    float: none;
    padding-right: 30px;
    width: calc(100% - 280px);
    vertical-align: top;
  }

  .rightBlock {
    display: inline-block;
    float: none;
    padding-left: 0;
    padding-bottom: 513px;
    width: 280px;
  }

  h2 {
    margin-top: 10px;
    padding-top: 53px;
  }

  .jobPointArea__head {
    padding-top: 0;
  }

  .jobPointArea__head,
  .jobPointArea__bold,
  .jobPointArea__bold--blue {
    padding-left: 40px;
    height: auto;
  }

  .jobPointArea__jobImage {
    margin: 30px 0 42px 40px;

    .topicPicture {
      margin: 0;
    }
  }

  .topicPicture {
    margin: 30px 0 42px 40px;
  }

  .pictureFile1 {
    display: inline-block;
    margin: -1px 0 0 0;
  }

  .pictureFile2 {
    display: inline-block;
    margin: -1px 0 0 0;
  }

  .btnListL {
    clear: both;
    padding-top: 60px;
    margin-bottom: 58px;
  }

  .unfloat {
    clear: both;
  }
}

/* 採用プロセス STEP表示
----------------------------------------------------------------- */
.stepTable {
  p + & {
    margin-top: 30px;
  }

  .textBold--pink {
    font-size: 1.4rem;
    font-weight: bold;
    color: #f6a3b2;
    margin: 0;
  }

  tr,
  tr td {
    vertical-align: top;
  }

  /* すべて上部に配置する暫定対応
  tr:first-child {
    vertical-align: top;

    td {
      vertical-align: top;
    }
  }
  */

  td:first-child {
    padding-bottom: 30px;
  }

  /* すべて上部に配置する暫定対応
  tr:last-child {
    vertical-align: bottom;

    td {
      vertical-align: bottom;
    }

    td:first-child {
      padding-bottom: 0;
    }
  }
  */

  td {
    font-size: 1.4rem;
    font-weight: normal;
  }

  &__body {
    vertical-align: top;
    padding: 0 20px 10px;
  }

  td:first-child:not(.last) {
    background-image: url(../img/jobInfo/bg_step.png);
    background-size: contain;
  }

  .step {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 0;

    .num {
      font-size: 1.4rem;
      line-height: 30px;
    }
  }

  .step li {
    position: relative;
    width: 47px;
    height: 75px;
    border-radius: 5px;
    background: #f7adbb;
    padding-top: 31px;
    text-align: center;
    color: $color_white;
  }

  .step li.last {
    height: 80px;
  }

  .step li:not(.last) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .step li:not(.last)::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -10px;
    left: 0;
    border-style: solid;
    border-color: #f7adbb transparent transparent transparent;
    border-width: 10px 23px 0 24px;
    z-index: 1;
  }
}

.text + .stepTable {
  margin-top: 30px;
}

/* メッセージ画面
----------------------------------------------------------------- */
.tab_content.center {
  text-align: center;
}

/* 社員インタビューリスト
----------------------------------------------------------------- */
.jobInfoInterviewList {
  $component: &;
  background-color: $color_white;
  border: 2px solid $color_keyColor_light;
  border-radius: 10px;
  margin: 20px 0 40px 40px;
  padding: 30px 27px;

  &__carousel {
    #{$component}__container {
      padding-left: 27px;
      padding-bottom: 20px;

      & ul {
        margin-top: 0;
        overflow-x: hidden;
      }
    }

    #{$component}__item {
      width: 115px;
      min-height: 154px;
      list-style-type: none;

      & a {
        text-decoration: none;
      }

      & div {
        border: 2px solid $color_keyColor_light;
        border-radius: 10px;
        margin-right: 5px;
        background-color: $color_white;
        min-height: 154px;
        text-align: center;

        .interviewPictureWrap {
          width: 70px;
          height: 70px;
          position: relative;
          margin: auto;
          overflow: hidden;
          border-radius: 50%;
          display: block;
          margin-top: 10px;
        }

        .interviewPicture {
          position: absolute;
          width: auto;
          height: auto;
          top: 50%;
          left: 50%;
          min-width: 70px;
          min-height: 70px;
          max-height: 100%;
          -ms-transform: translateX(-50%) translateY(-50%);
          -moz-transform: translateX(-50%) translateY(-50%);
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        }
        @supports ( object-fit: cover ) {
          .interviewPictureWrap {
            border-radius: 0;
          }

          .interviewPicture {
            position: static;
            left: 0;
            -o-object-fit: cover;
            object-fit: cover;
            top: 0;
            -ms-transform: none;
            -moz-transform: none;
            -webkit-transform: none;
            transform: none;
            width: 70px;
            height: 70px;
            display: block;
            margin: auto;
            border-radius: 50%;
          }
        }

        & span.name {
          font-size: 1rem;
          display: block;
          line-height: 14px;
          font-weight: 300;
          margin-top: 10px;
          margin-left: 10px;
          margin-right: 10px;
          min-height: 14px;
          color: $color_heading;
        }

        & span.history {
          font-size: 1rem;
          display: block;
          line-height: 14px;
          min-height: 14px;
          font-weight: 300;
          color: $color_heading;
        }

        & span.position {
          display: block;
          font-size: 1.2rem;
          font-weight: 600;
          background-color: $color_keyColor;
          margin: 7px auto;
          width: 90px;
          color: $color_white;
          border-radius: 10px;
        }
      }
    }

    #{$component}__control {
      background-color: $color_keyColor;
      border-radius: 99999px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
      cursor: pointer;
      height: 30px;
      outline: 0;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
      width: 30px;

      &--prev {
        left: 25px;
        right: auto;
        transform: translateX(-50%);

        &::after {
          left: 17px;
          transform: rotate(-135deg);
        }
      }

      &--next {
        left: auto;
        right: 25px;
        transform: translateX(50%);

        &::after {
          transform: rotate(45deg);
        }
      }

      &::after {
        border-right: 2px solid $color_white;
        border-top: 2px solid $color_white;
        content: "";
        display: block;
        height: 9px;
        left: 11px;
        position: absolute;
        top: 10px;
        width: 9px;
      }
    }
  }

  &__head {
    width: 500px;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: $color_white;
    margin: -50px auto 30px;
    color: $color_heading;
    line-height: 35px;
    border: solid 2px $color_keyColor;
    border-radius: 30px;
    text-align: center;
    vertical-align: middle;
  }

  &__profile {
    margin-bottom: 35px;
    word-break: break-all;
    word-wrap: break-word;

    td:first-child {
      vertical-align: top;
    }

    td:last-child {
      vertical-align: middle;
    }

    .interviewPictureWrap {
      width: 90px;
      height: 90px;
      position: relative;
      margin: auto;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 24px;
    }

    .interviewPicture {
      position: absolute;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      min-width: 90px;
      min-height: 90px;
      max-height: 100%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
    @supports ( object-fit: cover ) {
      .interviewPictureWrap {
        border-radius: 0;
      }

      .interviewPicture {
        position: static;
        left: 0;
        -o-object-fit: cover;
        object-fit: cover;
        top: 0;
        -ms-transform: none;
        -moz-transform: none;
        -webkit-transform: none;
        transform: none;
        width: 90px;
        height: 90px;
        display: block;
        margin: auto;
        border-radius: 50%;
      }
    }

    .profileName {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 19px;
      margin-bottom: 13px;
      margin-top: 6px;
    }

    .nowOccName {
      font-size: 1.4rem;
      font-weight: normal;
      margin-bottom: 10px;
    }

    .beforeOccExperience {
      & td:first-child {
        vertical-align: top;
      }

      & td:last-child {
        vertical-align: top;
      }

      &__label {
        background-color: #fff;
        display: inline-block;
        font-size: 1.2rem;
        font-weight: normal;
        line-height: 1.2;
        padding: 3px 9px 2px;
        margin: 0 0 10px;
        color: $color_text;
        border: 1px solid $color_text;
        width: 80px;
      }

      &__body {
        font-size: 1.4rem;
        font-weight: normal;
        margin: 3px 0 0 10px;
        display: inline-block;
      }
    }
  }

  &__questionTitle {
    margin-top: 0;

    & table {
      & td {
        vertical-align: top;
        padding-right: 5px;
      }
    }

    & &__q {
      font-size: 1.2rem;
      font-weight: 600;
      background-color: $color_keyColor;
      margin: 0 auto 0;
      display: inline-block;
      width: 26px;
      height: 26px;
      color: $color_white;
      border-radius: 20px;
      text-align: center;
      line-height: 24px;
    }

    & &__text {
      font-size: 1.6rem;
      font-weight: bold;
      color: $color_keyColor;
      line-height: 27px;
      margin-left: 4px;
    }
  }

  &__questionBody {
    font-size: 1.4rem;
    font-weight: normal;
    padding: 9px 0 40px;
    line-height: 24px;

    h4 {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 27px;
    }

    ul {
      list-style-type: disc;
      margin: 0 0 0 15px;
      line-height: 23px;
    }
  }

  &__jobInfoInterviewQuestionList {
    padding: 11px 0 0;

    #{$component} &__label {
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 18px;

      &.right {
        float: right;
      }

      table {
        width: 100%;

        td:first-child {
          vertical-align: top;
          min-width: 145px;
          padding-right: 13px;
        }

        td:last-child {
          vertical-align: top;
          min-width: 145px;
          padding-left: 13px;
        }
      }
    }

    &__bar {
      min-width: 250px;
      margin-bottom: 10px;

      & span {
        float: left;
        width: 20%;
        height: 15px;
        background-color: #fffbca;
        border: solid $color_keyColor;
        border-width: 1px 0 1px 1px;
        margin-top: 2px;
        margin-bottom: 18px;

        &.checked {
          background-color: $color_keyColor;
        }

        &.checked::after {
          content: '\e917';
          font-family: mynavi; /* stylelint-disable-line */
          color: $color_white;
          position: relative;
          left: calc(100% * .4);
          top: -3px;
          font-size: 1rem;
          font-weight: normal;
        }
      }

      & &__1 {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      & &__5 {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-width: 1px 1px 1px 1px;
      }
    }

    &__annotation {
      text-align: right;
      font-size: 1rem;
      font-weight: 300;
      margin-right: 6px;
    }
  }

  .preview {
    width: 100%;
    margin-bottom: 35px;
    color: $color_black;
    background-color: rgb(166, 166, 166);

    th,
    td {
      padding: 1rem;
      border: 1px solid $color_white;
    }

    td {
      padding-left: 2rem;
    }
  }
}

.jobInfoInterviewList:last-child {
  margin-bottom: 0;
}

.jobInfoInterviewFree {
  &__body {
    font-weight: 400;
    font-size: 1.4rem;
    color: #333;
    line-height: 24px;
    padding-bottom: 40px;

    h4 {
      font-size: 1.6rem;
      font-weight: 700;
      color: #00aaeb;
      line-height: 27px;
    }

    ul {
      list-style-type: disc;
      margin: 0 0 0 15px;
      line-height: 23px;
    }
  }
}

.textSup-jobinfo {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2.2rem;

  & .iconQuestion {
    margin-right: 8px;
    margin-left: -3px;
  }

  .tooltip-jobinofo {
    right: 117px;
  }

  .tooltip-message {
    right: 134px;
  }

  .tooltip-addPage {
    right: 132px;
  }
}

.headingBlock-jobinfo {
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding-top: 28px;
  padding-bottom: 24px;
}

.fixedArea--jobOfferInfo.white {
  background-color: $color_white;
  border-top: 2px solid #00aaeb;
}

/* メッセージタブ用
----------------------------------------------------------------- */
.messageImgArea {
  @extend .jobPointArea;
  text-align: center;

  img {
    padding-bottom: 60px;
  }

  .btnListL.wide {
    padding-bottom: 20px;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
}

.messageInfoPictArea {
  & img {
    max-width: 100%;
    height: auto;
  }
}

/* セミナー情報を見る
----------------------------------------------------------------- */
.btnListL__item.seminar {
  width: 260px;
}
