@charset "UTF-8";

.topRelatedSite {
  border: 1px solid $color_keyColor_light;
  height: 188px;
  padding: 10px 12px 20px 12px;
  text-align: center;
  width: 135px;

  &__logo {
    margin-bottom: 1.6rem;
  }

  &__title {
    color: $color_keyColor;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
  }
}
