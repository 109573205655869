@charset "UTF-8";

/* 通常テキストサイズ
----------------------------------------------------------------- */
.text {
  font-size: 1.4rem;
}

/* 通常テキストサイズ 下マージン 30px
----------------------------------------------------------------- */
.text--mb {
  @extend .text;
  margin-bottom: 30px;
}

/* 通常テキストサイズ 下マージン 40px
----------------------------------------------------------------- */
.text--mbL {
  @extend .text;
  margin-bottom: 40px;
}

/* 通常テキストサイズ センター寄せ
----------------------------------------------------------------- */
.textCenter {
  @extend .text;
  text-align: center;
}

/* 通常テキストサイズ センター寄せ 下マージン 30px
----------------------------------------------------------------- */
.textCenter--mb {
  @extend .textCenter;
  margin-bottom: 30px;
}

/* テキストサイズ小
----------------------------------------------------------------- */
.textS {
  font-size: 1.2rem;
}

/* テキストサイズ小 下マージン 15px
----------------------------------------------------------------- */
.textS--mb {
  @extend .textS;
  margin-bottom: 15px;
}

/* テキストサイズ最小
----------------------------------------------------------------- */
.textSS {
  font-size: 1.1rem;
}

/* テキストサイズ最小 下マージン 15px
----------------------------------------------------------------- */
.textSS--mb {
  @extend .textSS;
  margin-bottom: 15px;
}

/* テキスト 太サイズ小
----------------------------------------------------------------- */
.textBoldS {
  font-size: 1.2rem;
  font-weight: bold;
}

/* テキスト 太
----------------------------------------------------------------- */
.textBold {
  font-size: 1.4rem;
  font-weight: bold;
}

.textBold em {
  color: $color_red;
}

/* テキスト 太サイズ中
----------------------------------------------------------------- */
.textBoldM {
  font-size: 1.6rem;
  font-weight: bold;
}

/* テキスト 太サイズ中 下マージン 20px
----------------------------------------------------------------- */
.textBoldM--mb {
  @extend .textBoldM;
  margin-bottom: 20px;
}

/* テキスト 太サイズ大
----------------------------------------------------------------- */
.textBoldL {
  font-size: 2rem;
  font-weight: bold;
}

/* 強調テキスト ※赤
----------------------------------------------------------------- */
.textAttentionS {
  color: $color_red;
  font-size: 2rem;
  font-weight: bold;
}

/* 強調テキスト ※赤 数字やアイキャッチに使用する
----------------------------------------------------------------- */
.textAttentionL {
  color: $color_red;
  font-size: 2.8rem;
  font-weight: bold;
}

/* 強調テキスト ※赤 目立たせたい場所に使用する
----------------------------------------------------------------- */
.textAttentionM {
  color: $color_red;
  font-size: 2.4rem;
  font-weight: bold;
}

/* 強調テキスト ※赤
----------------------------------------------------------------- */
.textAttention {
  color: $color_red;
  font-size: 1.6rem;
  font-weight: bold;
}

/* 強調テキスト ※赤  下マージン 20px
----------------------------------------------------------------- */
.textAttention--mb {
  @extend .textAttention;
  margin-bottom: 20px;
}

/* 強調テキスト 見出しなど注目させたいテキスト文言に使用する
----------------------------------------------------------------- */
.textTitle {
  color: #000;
  font-size: 2rem;
  font-weight: bold;
}

/* 強調テキスト tableの見出し部分に使用する
----------------------------------------------------------------- */
.textTableTitle {
  color: $color_heading;
  font-size: 1.4rem;
  font-weight: bold;
}

/* 強調テキスト 目立たせたい場所に使用する
----------------------------------------------------------------- */
.textEmL {
  color: $color_heading;
  font-size: 2.8rem;
  font-weight: bold;
}

/* 強調テキスト 目立たせたい場所に使用する
----------------------------------------------------------------- */
.textEmM {
  color: $color_heading;
  font-size: 2.4rem;
  font-weight: bold;
}

/* 強調テキスト 目立たせたい場所に使用する
----------------------------------------------------------------- */
.textEmS {
  color: $color_heading;
  font-size: 2rem;
  font-weight: bold;
}

/* 強調テキスト 本文中強調したい部分に使用
----------------------------------------------------------------- */
.textEm {
  color: $color_heading;
  font-size: 1.6rem;
  font-weight: bold;
}

/* 注意文言
----------------------------------------------------------------- */
.textCaution {
  color: $color_red;
}

/* 注意文言 下マージン 20px
----------------------------------------------------------------- */
.textCaution--mb {
  @extend .textCaution;
  margin-bottom: 20px;
}

.textCautionCenter--mb {
  @extend .textCaution--mb;
  text-align: center;
}

/* 注意文言 一字下げ
----------------------------------------------------------------- */
.textCaution--one {
  @extend .textCaution;
  padding-left: 1em;
  text-indent: -1em;
}

/* 強調テキスト ※青
----------------------------------------------------------------- */
.textKeyColor {
  color: $color_keyColor;
}

/* 付加情報に使用する
----------------------------------------------------------------- */
.textAddInfo {
  font-size: 1rem;
}

/* 補足テキスト
----------------------------------------------------------------- */
.textSup {
  font-size: 1.2rem;
}

/* リンク ビュレットつき
----------------------------------------------------- */
.linkArrow {
  @include bulletLink;
}

/* リンク ビュレットつき テキストサイズ小
----------------------------------------------------- */
.linkArrowS {
  @include bulletLink($fontSize: 1.2rem);
}

/* リードテキスト
----------------------------------------------------- */
.textLead {
  max-width: 950px;
  margin: 0 auto 20px;
  font-size: 1.4rem;
}

/* リードテキスト小
----------------------------------------------------- */
.textLeadS {
  @extend .textLead;
  font-size: 1.2rem;
}

/* 1行テキスト
----------------------------------------------------- */
.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 複数行テキスト
----------------------------------------------------- */
.textEllipsis--multiline {
  @include textEllipsisMultiline;
}

/* 背景帯テキスト
----------------------------------------------------- */
.textMarker {
  background-color: #f9ec83;
}

/* 背景帯テキスト（スカウト）
----------------------------------------------------- */
.textScout {
  background-color: #81d7ca;
}

/* 枠線テキスト
----------------------------------------------------- */
.textBorder {
  background-color: $color_white;
  border: 1px solid #{$color_border};
}

/* 注釈
----------------------------------------------------- */
.textNote {
  padding-left: 1em;
  font-size: 1.2rem;
  text-indent: -1em;
}

.textEllipsis--multiline3 {
  @include textEllipsisMultiline($height: 52px, $lineHeight: inherit);
}

/* 非活性表示
----------------------------------------------------- */
.textDisabled {
  color: $color_disable02;
}

/* 下線付き 募集要項
----------------------------------------------------- */
.textUnderBorder {
  @extend .text;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid $color_subBorder;
}

/* トピックタイトル 募集要項
----------------------------------------------------- */
.textTopic {
  display: inline-block;
  padding: 1em 0 0;
  color: $color_red;
  font-weight: bold;
}

.textTopic--black {
  @extend .textTopic;
  color: $color_heading;
}

/* inline-blockを設定
----------------------------------------------------------------- */
.textInlineBlock {
  display: inline-block;
}
