@charset "UTF-8";

.bookmarkEyecatch {
  letter-spacing: -.4em;
  margin: 0 auto;
  padding: 15px 30px 15px 0;

  &__box {
    box-sizing: content-box;
    display: inline-block;
    letter-spacing: normal;
    margin-left: 50px;
    width: 230px;
  }

  &__text {
    display: inline-block;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: normal;
    vertical-align: middle;
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }

  &__img {
    height: auto;
    width: 100%;
  }
}
