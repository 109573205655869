@charset "UTF-8";

.hero {
  @include clearfix;

  &__sliderArea {
    float: left;
    width: $top-hero-slider-width;
  }

  &__newArrivalArea {
    float: right;
    width: $top-hero-newArrival-width;
  }
}

.heroSlider {
  position: relative;

  &__item {
    border-radius: 8px;
    height: 340px;
    width: 530px;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    right: 3rem;
    transition:
      -ms-transform 300ms ease-in,
      transform 300ms ease-in,
      opacity 200ms ease-in;
    top: 2rem;

    img {
      height: 100%;
      @media print {
        display: none;
      }
    }

    // チラつきを抑える
    &:first-child[data-hero-slider-item=""] {
      z-index: 11;
    }

    &--current {
      z-index: 10;
    }

    &--before {
      opacity: .5;
      -ms-transform: translateX(-56px) scale(0.9);
      transform: translateX(-56px) scale(0.9);
      z-index: 9;
    }

    &--after {
      opacity: .5;
      -ms-transform: translateX(56px) scale(0.9);
      transform: scale(0.9) translateX(56px);
      z-index: 9;
    }

    &--hidden {
      opacity: 0;
      z-index: 8;
    }
  }

  &__navi {
    background-color: $color_keyColor_dark;
    border: 0;
    border-radius: 99999px;
    box-shadow: 0 1px 4px 0 rgba(107, 107, 107, .4);
    cursor: pointer;
    height: 40px;
    outline: 0;
    position: absolute;
    top: 180px;
    z-index: 12;
    width: 40px;

    &::after {
      border-right: 2px solid $color_white;
      border-top: 2px solid $color_white;
      content: "";
      display: block;
      height: 8.5px;
      left: 14px;
      position: absolute;
      top: 16px;
      width: 8.5px;
    }

    &--prev {
      left: 10px;

      &::after {
        // 真ん中に見えるように調整
        left: 17px;
        transform: rotate(-135deg);
      }
    }

    &--next {
      right: 10px;

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__pagination {
    left: 0;
    position: absolute;
    text-align: center;
    top: 370px;
    width: 100%;
  }

  &__paginationBullet {
    background-color: $color_white;
    border: 1px solid $color_keyColor;
    border-radius: 99999px;
    cursor: pointer;
    display: inline-block;
    height: 10px;
    outline: 0;
    width: 10px;

    &--active {
      background-color: $color_keyColor;
    }
  }

  &__paginationBullet + &__paginationBullet {
    margin-left: 10px;
  }
}
