@charset "UTF-8";

$jobsearch-agent-banner-key-color: #19528d;

.jobSearchAgentBanner {
  $component: &;
  background-color: #f1f7f7;
  color: $color_white;
  overflow: hidden;
  padding: 3rem;
  position: relative;

  &__image {
    display: block;
    position: absolute;
    top: 0;
    left: 450px;
    width: 60%;
  }

  &__title {
    color: $jobsearch-agent-banner-key-color;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.4;
    margin-bottom: 4rem;
  }

  &__title,
  &__text {
    position: relative;
  }

  &__link {
    @include onHover;
    background-color: $jobsearch-agent-banner-key-color;
    border-radius: 99999px;
    color: $color_white;
    display: inline-block;
    font-size: 1.2rem;
    padding: 12px 12px 12px 40px;
    position: relative;
    z-index: 3;
  }
}
