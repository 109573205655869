@charset "UTF-8";

/* キャリQ
----------------------------------------------------------------- */
.containerCareq {
  @extend .container;
  background: none;
  padding-top: 0;
  padding-bottom: 100px;

  h2 {
    background-color: #e72d29;
    color: $color_white;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__inner {
    @extend .container__inner;
    padding-top: 33px;
    text-align: center;

    .text--bold {
      font-weight: bold;
    }

    .btnBox {
      padding: 15px;
    }

    .text--left {
      text-align: left;
    }
  }
}
