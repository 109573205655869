@charset "UTF-8";

.cassetteJobOffer {
  &__bookmarkSaveDate {
    color: #999;
    text-align: right;
    font-size: 1rem;
    height: 1rem;
    line-height: 1rem;
    margin-top: 20px;
  }

  &__bookmarkDelete {
    width: 188px;
    height: 29px;
    display: table-cell;
    gap: 0;
  
    .cassetteJobOffer__bookmarkSaveDate {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 188px;
    }
  
    &--btn {
      align-items: center;
      background: #e5e5e5;
      border-radius: 2px 0 0 0;
      color: $color_text;
      cursor: pointer;
      display: flex;
      font-size: 10px;
      font-weight: 350;
      gap: 6px;
      height: 26px;
      justify-content: center;
      line-height: 14.48px;
      margin-left: 18px;
      padding: 6px 10px 6px 10px;
      width: 54px;
  
      &:hover {
        background-color: rgba(221, 221, 221, .4);
      }
  
      &.iconFont--close::before {
        font-size: 0.8rem;
      }
    }
  }
}
