@charset "UTF-8";

.topSearch {
  &__wrapper {
    @include clearfix;
  }

  &__item {
    display: block;
    float: left;
    position: relative;

    &:last-of-type {
      margin-right: 10px;
    }
  }

  &__item:nth-child(n+2) {
    &::before {
      color: $color_keyColor;
      content: "×";
      font-size: 2.4rem;
      display: block;
      position: absolute;
      text-align: center;
      top: 1rem;
      width: 3.2rem;
    }
  }

  &__item,
  &__button {
    transform: translateY(2px);
  }

  &__text {
    background-color: $top-search-bg-color;
    border: 0;
    border-radius: 2px;
    box-shadow: -1px -2px 0 0 rgba(0, 0, 0, .15);
    font-size: 1.6rem;
    height: 43px;
    padding: 0 15px;
    transform: translateY(2px);
    width: 280px;

    &--freeword {
      @extend .topSearch__text;
      cursor: pointer;
      text-overflow: ellipsis;
      width: 480px;
    }
  }

  &__select {
    @include onHover;
    @include ellipsis;
    background-color: $top-search-bg-color;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    color: inherit;
    display: inline-block;
    font-size: 1.6rem;
    height: 45px;
    margin-left: 3.2rem;
    padding: 15px 25px 15px 15px;
    position: relative;
    width: 135px;

    &::after {
      border-top: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $color_keyColor_dark;
      content: "";
      display: block;
      height: 6px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
    }
  }

  &__button {
    @include onHover;
    background-color: $color_keyColor;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    color: $color_white;
    cursor: pointer;
    height: 45px;
    width: 45px;

    &--freeword {
      @extend .topSearch__button;
      font-size: 1.4rem;
      font-weight: 700;
      width: 150px;
    }
  }
}
