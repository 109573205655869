@charset "UTF-8";

.topIconLink {
  display: inline-block;
  font-size: 1.3rem;
  padding-left: 2.8rem;
  position: relative;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &::before {
    content: "";
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "mynavi";
    font-size: 1.8rem;
    left: 0;
    position: absolute;
    top: -.25em;
  }

  &--caution {
    &::before {
      content: "\e61f";
    }
  }
}
