@charset "UTF-8";

$env: 'production' !default;
$path: 'https://tenshoku.mynavi.jp/lib/tm/pc' !default;

@if ($env == 'production') {
  $path: 'https://tenshoku.mynavi.jp/lib/tm/pc';
}

@if ($env == 'dev') {
  $path: 'https://dev.stg.tenshoku.mynavi.jp/lib/tm/pc';
}

@if ($env == 'try') {
  $path: 'https://try.stg.tenshoku.mynavi.jp/lib/tm/pc';
}

@if ($env == 'mnt') {
  $path: 'https://mnt.stg.tenshoku.mynavi.jp/lib/tm/pc';
}

@if ($env == 'pre') {
  $path: 'https://pre.stg.tenshoku.mynavi.jp/lib/tm/pc';
}

@font-face {
  font-family: "mynavi";
  font-style: normal;
  font-weight: normal;
  src: url("#{$path}/font/mynavi.eot?4az7ya");
  src:
    url("#{$path}/font/mynavi.eot?4az7ya#iefix") format("embedded-opentype"),
    url("#{$path}/font/mynavi.ttf?4az7ya") format("truetype"),
    url("#{$path}/font/mynavi.woff?4az7ya") format("woff"),
    url("#{$path}/font/mynavi.svg?4az7ya#mynavi") format("svg");
}

[class^="iconFont--"],
[class*=" iconFont--"] {
  @include iconFont;
}

/*
 * ここから下を更新してください。
 */

.iconFont--reply::before {
  content: "\e921";
}

.iconFont--arrowUp2::before {
  content: "\e91f";
}

.iconFont--arrowDown2::before {
  content: "\e920";
}

.iconFont--star2::before {
  content: "\e91e";
}

.iconFont--questionnaire::before {
  content: "\e91d";
}

.iconFont--bell::before {
  content: "\e91b";
}

.iconFont--keep::before {
  content: "\e919";
}

.iconFont--receive2::before {
  content: "\e91a";
}

.iconFont--key::before {
  content: "\e918";
}

.iconFont--question::before {
  content: "\e916";
}

.iconFont--checked::before {
  content: "\e917";
}

.iconFont--detail::before {
  content: "\e912";
}

.iconFont--keyword::before {
  content: "\e910";
}

.iconFont--star::before {
  content: "\e911";
}

.iconFont--calendar::before {
  content: "\e904";
}

.iconFont--change::before {
  content: "\e906";
}

.iconFont--criteria::before {
  content: "\e907";
}

.iconFont--employee::before {
  content: "\e908";
}

.iconFont--employment::before {
  content: "\e909";
}

.iconFont--experience::before {
  content: "\e90a";
}

.iconFont--feature::before {
  content: "\e90b";
}

.iconFont--flag::before {
  content: "\e90c";
}

.iconFont--foundation::before {
  content: "\e90d";
}

.iconFont--graph::before {
  content: "\e90e";
}

.iconFont--industries::before {
  content: "\e90f";
}

.iconFont--offer::before {
  content: "\e913";
}

.iconFont--save2::before {
  content: "\e914";
}

.iconFont--tool::before {
  content: "\e915";
}

.iconFont--anonymous::before {
  content: "\e616";
}

.iconFont--arrowDown::before {
  content: "\e602";
}

.iconFont--arrowLeft::before {
  content: "\e603";
}

.iconFont--arrowRight::before {
  content: "\e609";
}

.iconFont--arrow__up::before {
  content: "\e60a";
}

.iconFont--bag::before {
  content: "\e901";
}

.iconFont--caution::before {
  content: "\e61f";
}

.iconFont--close::before {
  content: "\e626";
}

.iconFont--companies::before {
  content: "\e621";
}

.iconFont--complete::before {
  content: "\e61e";
}

.iconFont--conditions::before {
  content: "\e903";
}

.iconFont--effective::before {
  content: "\e608";
}

.iconFont--email::before {
  content: "\e628";
}

.iconFont--flowDown::before {
  content: "\e60b";
}

.iconFont--flowLeft::before {
  content: "\e60c";
}

.iconFont--flowRight::before {
  content: "\e611";
}

.iconFont--flow__up::before {
  content: "\e612";
}

.iconFont--good::before {
  content: "\e605";
}

.iconFont--good2::before {
  content: "\e61c";
}

.iconFont--human_resources::before {
  content: "\e902";
}

.iconFont--heart::before {
  content: "\e91c";
}

.iconFont--interview::before {
  content: "\e622";
}

.iconFont--interview2::before {
  content: "\e607";
}

.iconFont--knowhow::before {
  content: "\e618";
}

.iconFont--location::before {
  content: "\e614";
}

.iconFont--member::before {
  content: "\e632";
}

.iconFont--menu::before {
  content: "\e610";
}

.iconFont--message::before {
  content: "\e620";
}

.iconFont--minus::before {
  content: "\e60d";
}

.iconFont--money::before {
  content: "\e905";
}

.iconFont--money2::before {
  content: "\e900";
}

.iconFont--occupation::before {
  content: "\e606";
}

.iconFont--password::before {
  content: "\e62b";
}

.iconFont--pc::before {
  content: "\e613";
}

.iconFont--plus::before {
  content: "\e60e";
}

.iconFont--potential::before {
  content: "\e617";
}

.iconFont--prev::before {
  content: "\e60f";
}

.iconFont--profile::before {
  content: "\e625";
}

.iconFont--receive::before {
  content: "\e624";
}

.iconFont--register::before {
  content: "\e629";
}

.iconFont--resume::before {
  content: "\e615";
}

.iconFont--save::before {
  content: "\e600";
}

.iconFont--search::before {
  content: "\e623";
}

.iconFont--secret::before {
  content: "\e604";
}

.iconFont--session::before {
  content: "\e61a";
}

.iconFont--settings::before {
  content: "\e61d";
}

.iconFont--skill::before {
  content: "\e62a";
}

.iconFont--view::before {
  content: "\e601";
}

.iconFont--warning::before {
  content: "\e61b";
}

.iconFont--women::before {
  content: "\e627";
}
