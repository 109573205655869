@charset "UTF-8";

.fixedArea {
  &__scoutContents {
    padding: 30px 0;
    color: $scout-white;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    background-color: rgba(0, 0, 0, .6);
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 95;

    & .btnDelete {
      margin-top: 10px;
      padding: 8px 20px;
    }
  }
}
