@charset "UTF-8";

// 注目求人ピックアップページ用スタイル
.featureJobPickUp {
  background-color: $color_white;
  padding-top: 48px;

  // 上部領域
  .heading {
    text-align: center;

    &__update {
      span {
        background-color: $color_white;
        border: $color_keyColor 1px solid;
        border-radius: 4px;
        color: $color_keyColor;
        display: inline-block;
        font-size: 1.3rem;
        padding: 4px 9px 4px 10px;
      }
    }

    &__edition {
      margin-top: 14px;

      span {
        background-color: $color_keyColor;
        border-radius: 10px;
        color: $color_white;
        display: inline-block;
        font-size: 2.6rem;
        padding: 12px 19px 12px 18px;
      }
    }

    &__title {
      color: $color_keyColor;
      font-size: 3.2rem;
      font-weight: 600;
      margin-top: 9px;
    }

    &__desc {
      margin-top: 10px;

      span {
        font-size: 1.3rem;
      }
    }
  }

  // エリア絞り込み領域
  .filter {
    margin-top: 50px;
    text-align: center;
    white-space: nowrap;

    .filter__link {
      background-color: $color_white;
      border: #d4d0d0 1px solid;
      border-radius: 50px;
      color: $color_text;
      display: inline-block;
      font-weight: 600;
      height: 36px;
      line-height: 36px;
      margin-right: 10px;
      max-width: 90px;
      min-width: 84px;
      padding: 0 23px;
      text-align: center;

      &--active {
        @extend .filter__link;
        background-color: $color_keyColor;
        border-color: $color_keyColor;
        color: $color_white;
      }

      &--disabled {
        @extend .filter__link;
        background-color: #ccc6;
        color: #0006;

        &:hover {
          cursor: default;
          opacity: 1 !important;
        }
      }

      &:hover {
        opacity: .5;
      }
    }

    a:last-child {
      .filter__link {
        margin-right: 0;
      }
    }
  }

  .gray {
    background-color: #f6f8f9;
    margin-top: 50px;

    // グループ企業の募集
    .group {
      margin: 0 auto;
      padding-top: 50px;
      width: 1140px;

      &__title {
        display: flex;
        font-weight: 600;

        h3 {
          font-size: 2rem;
        }

        span {
          font-size: 1.3rem;
          line-height: 28px;
          margin-left: 28px;
        }
      }

      &__carousel {
        margin-top: 30px;
        position: relative;

        .swiper-container {
          height: 173px;
        }

        ul {
          height: 170px;

          li {
            height: 170px;
            width: 194px;

            a {
              background-color: $color_white;
              border-radius: 8px;
              box-shadow: 0 1.08px 2.16px 0 #0000001a;
              display: block;
              height: 100%;
              margin-right: 27px;

              img {
                display: block;
                margin: 0 auto;
                max-width: 150px;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }

              img:has(+ span) {
                top: calc(50% - 15px);
              }

              span {
                background-color: #fffbe0;
                border: #a57700 1px solid;
                border-radius: 4px;
                bottom: 15px;
                color: #a57700;
                display: inline-block;
                font-size: 1.1rem;
                margin-left: 41px;
                padding: 2px 7px;
                position: absolute;
              }

              &:hover {
                opacity: .5;
              }
            }
          }

          &:has(> :nth-child(-n+6):last-child) li:last-child {
            width: 170px;

            a {
              margin-right: 0;
            }
          }
        }

        .control {
          background-color: $color_white;
          border: $color_keyColor 1px solid;
          border-radius: 20px;
          cursor: pointer;
          height: 40px;
          outline: 0;
          position: absolute;
          top: 60px;
          width: 40px;
          z-index: 2;

          &--prev {
            left: 0;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);

            &::after {
              left: 15px !important;
              -webkit-transform: rotate(-135deg);
              -ms-transform: rotate(-135deg);
              transform: rotate(-135deg);
            }
          }

          &--next {
            right: 0;
            -webkit-transform: translateX(50%);
            -ms-transform: translateX(50%);
            transform: translateX(50%);

            &::after {
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          &::after {
            border-right: 3px solid $color_keyColor;
            border-top: 3px solid $color_keyColor;
            content: "";
            display: block;
            height: 14px;
            left: 10px;
            position: absolute;
            top: 12px;
            width: 14px;
          }
        }

        .pagination {
          margin-top: 29px;
          text-align: center;

          .paginationBullet {
            background-color: $color_white;
            border-radius: 5px;
            cursor: pointer;
            display: inline-block;
            height: 10px;
            outline: 0;
            width: 10px;

            &--active {
              background-color: $color_keyColor;
            }
          }

          .paginationBullet + .paginationBullet {
            margin-left: 10px;
          }
        }
      }
    }

    // 注目求人
    .highlighted {
      margin: 40px auto 0;
      width: 1140px;

      &__title {
        h3 {
          font-size: 2rem;
          font-weight: 600;
        }
      }

      &__company {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        li {
          background-color: $color_white;
          border-radius: 4px;
          box-shadow: 0 1.08px 2.16px 0 #0000001a;
          display: block;
          width: 364px;
          margin: 0 23px 23px 0;

          .img {
            height: 171px;
            text-align: center;

            img {
              display: block;
              margin: 0 auto;
              max-width: 225px;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }

            &::before {
              background-color: $color_subBorder;
              content: '';
              display: block;
              height: 1px;
              left: 21px;
              position: relative;
              top: 171px;
              width: 324px;
            }
          }

          .detail {
            padding: 19px 27px 21px;

            &__heading {
              h4 {
                color: $color_text;
                font-size: 2rem;
                font-weight: 600;
                overflow: hidden;
                overflow-wrap: break-word;
                text-overflow: ellipsis;
              }

              h5 {
                color: $color_keyColor;
                font-size: 1.3rem;
                margin-top: 4px;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .salary {
              position: relative;
              margin-top: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                font-size: 1.3rem;
                margin-left: 32px;

                &::before {
                  background-image: url(../img/featureJobPickUp/salary.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  content: '';
                  display: block;
                  height: 22px;
                  position: absolute;
                  top: -1px;
                  width: 20px;
                }
              }
            }

            .location {
              @extend .salary;
              margin-top: 10px;

              span {
                &::before {
                  background-image: url(../img/featureJobPickUp/location.svg);
                  left: 2px;
                  width: 15px;
                }
              }
            }

            .a {
              display: block;
              font-size: 1.3rem;
              margin: 20px 0 0 158px;
              min-width: 160px;
              position: relative;

              &::after {
                border-right: 2px solid $color_keyColor;
                border-top: 2px solid $color_keyColor;
                content: "";
                display: block;
                height: 9px;
                position: absolute;
                top: 50%;
                transform: rotate(45deg) translateY(-80%);
                -webkit-transform: rotate(45deg) translateY(-80%);
                -ms-transform: rotate(45deg) translateY(-80%);
                right: 0;
                width: 9px;
              }
            }
          }

          &:nth-of-type(3n) {
            margin-right: 0;
          }

          a:hover {
            opacity: .5;
            text-decoration: none;
          }
        }
      }
    }

    // さまざまな切り口から求人を探す
    .various {
      margin: 19px auto 0;
      width: 1140px;

      &__title {
        display: flex;
        font-weight: 600;
        width: 1140px;

        h3 {
          font-size: 2rem;
        }

        a {
          background-color: $color_white;
          border: $color_subBorder 1px solid;
          border-radius: 50px;
          font-size: 1.3rem;
          margin-left: auto;
          padding: 6px 25px 6px 17px;
          position: relative;

          &::after {
            border-right: 2px solid $color_keyColor;
            border-top: 2px solid $color_keyColor;
            content: "";
            display: block;
            height: 9px;
            position: absolute;
            top: 50%;
            transform: rotate(45deg) translateY(-80%);
            -webkit-transform: rotate(45deg) translateY(-80%);
            -ms-transform: rotate(45deg) translateY(-80%);
            right: 17px;
            width: 9px;
          }
        }
      }

      &__section {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        padding-bottom: 26px;

        li {
          border-radius: 4px;
          box-shadow: 0 1.08px 2.16px 0 #0000001a;
          height: 160px;
          margin: 0 24px 24px 0;
          position: relative;
          width: 364px;

          &:hover {
            opacity: .5;
          }

          div {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 27px;

            h4 {
              color: $color_white;
              font-size: 2rem;
              font-weight: 600;
              margin: 0;
              padding: 0;
            }

            h5 {
              color: $color_white;
              font-size: 1.3rem;
              font-weight: 600;
              margin: 0;
              padding: 0;
            }
          }

          img {
            border-radius: 4px;
            max-height: 160px;
            width: 364px;
          }

          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
