@charset "UTF-8";

.mySearch {
  background-color: $color_white;
  border-radius: 2px;
  color: inherit;
  height: 3.6rem;
  line-height: 1;
  width: 100%;

  &__title {
    @include ellipsis;
    font-size: 1.3rem;
    left: 1rem;
    line-height: 1;
    padding-right: .5rem;
    position: absolute;
    top: 1.2rem;
    width: 12rem;
  }

  &__latest {
    left: 12.5rem;
    line-height: 1;
    position: absolute;
    top: .9rem;
  }

  &__num {
    color: $color_keyColor;
    font-size: 1.6rem;
    line-height: 1;
    margin: 0 .25em;
  }
}
