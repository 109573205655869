@charset "UTF-8";

.jobRelationArea {
  &__item {
    @include clearfix;
    margin-bottom: 3rem;
  }

  &__img {
    border: 1px solid #eee;
    float: left;
    height: 120px;
    padding: 10px;
    text-align: center;
    width: 100px;

    img {
      height: 100%;
      width: 80px;
    }
  }

  &__right {
    float: left;
    line-height: 1.2;
    padding: 2px 0 0 14px;
  }

  &__title {
    color: $color_keyColor;
    display: block;
    font-size: 1.6em;
    font-weight: 700;
    margin-bottom: 10px;

    &--black {
      color: $color_text;
    }

    &--interview {
      color: $color_text;
      padding-top: 10px;
    }
  }

  &__text {
    @include jobText;
  }
}

.relationList {
  &__item {
    display: inline-block;
    padding: 8px 11px;
    margin-bottom: 20px;
    border-right: 1px solid #ddd;
    line-height: 1.4;
    width: 33%;
    vertical-align: top;

    &:nth-child(3n + 1) {
      border-left: 1px solid #ddd;
    }
  }

  &__title {
    color: $color_keyColor;
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.3em;
    color: $color_text;
  }
}

.jobRelationArea,
.relationList {
  a {
    @include onHover;
    color: inherit;
    display: inline-block;
  }
}
