@charset "UTF-8";

.js__mainContentAgsMessage {
  @extend .mainContentScout;
}

// メンテナンス中エリアの表示
.partsAgsMessageArea {
  margin: 0 auto;
}

.scoutContainer__main--agsMessage {
  margin-top: 10px;
}