@charset "UTF-8";

.scoutMailBox {
  &-mb50 {
    margin-bottom: 50px;
  }

  &__pullDownBox {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    text-wrap: nowrap;

    &.marginLeftAuto {
      margin-left: auto;
    }

    &.marginRightAuto {
      margin-right: auto;
    }

    .tooltip {
      text-wrap: wrap;
    }

    .pullDown__list {
      border: none;
      list-style: none !important;

      .pullDown__item {
        border: $color_keyColor solid;
        border-width: 1px 1px 0 1px;

        &:last-child {
          border-width: 1px !important;
        }
      }
    }
  }

  &__premiumCheckbox {
    z-index: 29;
    display: flex;

    &.is-none {
      display: none;
    }

    & .checkbox > input:checked + .checkbox__icon::before {
      background: url(../img/common/iconCheckBox.png) no-repeat;
    }
  }

  &__header {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    flex-direction: column;

    & .header__content {
      display: flex;
      gap: 15px;
      flex-direction: column;
      width: auto;
      margin: 0;

      &.paddingBottom0 {
        padding-bottom: 0;
      }
    }

    & .scoutBtnClear {
      background-color: $color_white;
      color: $color_text;
      height: 29px;
      padding: 6px 10px 6px 10px;
      border-radius: 100px;
      border: 1px solid #dedede;
      box-shadow: none;
      font-size: 11px;
      font-weight: 600;
      line-height: 16.5px;

      &::before {
        content: none;
      }

      &.is-none {
        display: none;
      }
    }
  }

  &__footer {
    margin-bottom: 30px;
  }

  &__operation {
    @include scoutFlex(space-between, center, nowrap);
  }

  &__pager {
    display: inline-block;

    & .pager__list {
      display: inline-block;
    }

    & .pager__text {
      display: inline-block;
      margin-left: 18px;
    }
  }

  &__filter {
    display: inline-block;
  }

  &__sort {
    margin-left: 7px;
    display: inline-block;
  }

  &__date {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 5px;

    &-detail {
      margin-bottom: 10px;
    }
  }

  &__detailHeader {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 5px;

    &-detail {
      margin-bottom: 10px;
    }

    .date {
      vertical-align: middle;
    }

    .scoutLimited {
      @extend .scoutBenefits__icon;
      color: $scout-red;
      border-color: $scout-red;
      margin-left: 10px;
      vertical-align: middle;

      &::before {
        content: url(#{$scout_path}scoutLimited.png);
        vertical-align: top;
      }
    }
  }

  $scoutMailBox: 22;
  @for $i from 1 through $scoutMailBox {
    &__item:nth-child(#{$i}) {
      z-index: $scoutMailBox - $i;
    }
  }

  &__item {
    @include scoutBlock;
    position: relative;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 40px;
    }

    &:first-child {
      margin-bottom: 20px;
    }

    &:first-child:last-child {
      margin-bottom: 20px;
    }

    &.scoutMailBox__item-hope {
      border: none;
      border-left: solid 3px $scout-hope;
      background: $scout-white;
    }

    &.scoutMailBox__item-experience {
      border: none;
      border-left: solid 3px $scout-experience;
      background: $scout-white;
    }

    &.scoutMailBox__item-terms {
      border: none;
      border-left: solid 3px $scout-terms;
      background: $scout-white;
    }

    &.scoutMailBox__item-ags {
      border: none;
      border-left: solid 3px $scout-ags;
      background: $scout-white;
    }

    &.scoutMailBox__item-premium {
      border: solid 3px $scout-premium;
      background: $scout-premium-bg;

      & .scoutMailBox__itemMain {
        width: calc(100% - 107px);
      }

      & .scoutMailBox__itemRemain {
        border-color: $scout-premium;
        width: 107px;
      }
    }

    &.scoutMailBox__item-disable {
      border: none;
      background: $scout-disable;

      & .scoutMailBox__itemMain {
        width: calc(100% - 110px);
      }

      & .scoutMailBox__itemRemain {
        border-color: $scout-title-boder;
        width: 110px;
      }

      & .checkbox {
        width: 36px;
      }
    }

    &Txt {
      word-break: break-word;
    }
  }

  &__itemTable {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;

    & .checkbox {
      display: table-cell;
      vertical-align: middle;
      overflow: hidden;
      width: 33px;
      text-align: center;
      cursor: pointer;
      border-right: 1px solid $scout-title-boder;

      &:hover {
        background-color: rgba(221, 221, 221, 0.4);
      }

      & .checkbox__icon {
        position: relative;
        left: 50%;
        width: 20px;
        height: 20px;
        display: block;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        &::before {
          width: 20px;
          height: 20px;
          background: url(#{$scout_path}check.png) no-repeat;
        }
      }

      & > input:checked + .checkbox__icon::before {
        background: url(#{$scout_path}checked.png) no-repeat;
      }
    }
  }

  &__itemTableCell {
    display: table-cell;
    width: auto;
    letter-spacing: -.4em;
  }

  &__unread {
    position: absolute;
    left: -20px;
    top: -10px;
    color: $scout-red;
    background: url(#{$scout_path}icon_unread.png) $scout-white no-repeat center 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    border-radius: 50%;
    height: 47px;
    width: 47px;
    text-align: center;
    z-index: 2;

    &-txt {
      position: absolute;
      width: 2rem;
      bottom: 6px;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 1rem;
      font-weight: $scout_bold;
      line-height: 1;
    }
  }

  &__detailLink {
    display: inline;
  }

  &__itemMainWrap {
    @include scoutFlex();
  }

  &__itemMain {
    @include scoutFlex($align: center);
    width: calc(100% - 110px);
    letter-spacing: normal;
    vertical-align: middle;
    padding: 17.6px 13px 20px 13px;
    min-height: 140px;

    &-single {
      width: 100%;
    }

    &-ags {
      padding: 20px;
    }

    &-detail {
      padding: 20px;
    }

    & .labelScout-ags {
      margin-left: 0.25em;
    }

    .scoutBenefits__icon {
      cursor: pointer;
    }
  }

  &__agsCompAgent {
    margin-top: 10px;
    display: inline-block;
  }

  &__scoutIcon {
    padding-right: 13px;
  }

  &__remainOver {
    line-height: 60px;
  }

  &__remainDisable {
    line-height: 60px;
  }

  &__remainLimit {
    width: 60px;
    display: inline-block;
  }

  &__itemRemain {
    width: 110px;
    display: inline-block;
    letter-spacing: normal;
    height: calc(100% - 40px);
    position: absolute;
    border-left: 1px solid $scout-title-boder;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: $scout_bold;
    margin: 4px 0;
    padding-top: 4px;
  }

  &__remainInner {
    height: 100%;
    position: relative;
  }

  &__remainDate {
    text-align: center;
    font-weight: $scout_bold;
    line-height: 1.25;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__remainImpact {
    font-size: 3rem;
    font-weight: $scout_bold;
  }

  &__keep {
    color: $scout-keep;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 1.1rem;
    width: 72px;
    height: 20px;
    border-radius: 10px;
    background: $scout-white;
    border: 1px solid $scout-keep;

    &::before {
      content: "";
      display: inline-block;
      font-family: mynavi, sans-serif;
      font-size: 1.45rem;
      line-height: 20px;
      width: 15px;
      margin-left: 5px;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      speak: none;
      text-decoration: none;
      text-rendering: auto;
      text-transform: none;
      vertical-align: top;
    }
  }

  &__agsMailBody {
    @include scoutBlock;
    margin-bottom: 0;
  }

  &__agsMailInner {
    padding: 20px;
  }

  &__agsBtnInner {
    text-align: center;
    margin: 20px 0;
    position: relative;
  }

  &__agsBtn {
    @include scoutBtn($padding: 14px 38px);
    border: none;
    outline: none;
    appearance: none;
    letter-spacing: normal;
  }

  &__declineBtn {
    @include linkBtn;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 98px;
    height: 36px;
    color: $scout-read;
    border-radius: 4px;
    border: 1px solid #bbb;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
}
