@charset "UTF-8";

.scoutDetailInfo {
  $this: &;

  &--interview {
    border-radius: 5px 5px 0 0;

    .requestMessage__heading {
      background-image: none;
      border-radius: 5px 5px 0 0;
      height: 40px;
      padding: 6.5px 0 0 20px;
    }

    &.scoutReadMore--open .scoutReadMore--close .scoutReadMore__content {
      margin-bottom: 0;
      height: auto;

      .scoutDetailInfo__more {
        bottom: 20px;
      }
    }

    #{$this}__wrap {
      border-left: 3px solid $color_requestInterview;
    }

    &.scoutDetailInfo--expired {
      #{$this}__wrap {
        border-left: 3px solid $color_requestInterview;
      }
    }

    #{$this}__detail #{$this}__content--bottom {
      padding: 10px 25px;

      #{$this}__more {
        padding: 20px 0;
      }
    }
  }

  &--apply {
    border-radius: 5px 5px 0 0;

    .requestMessage__heading {
      background-image: none;
      border-radius: 5px 5px 0 0;
      height: 40px;
      padding: 6.5px 0 0 20px;
    }

    &.scoutReadMore--open .scoutReadMore--close .scoutReadMore__content {
      margin-bottom: 0;
      height: auto;

      .scoutDetailInfo__more {
        bottom: 20px;
      }
    }

    #{$this}__wrap {
      border-left: 3px solid $color_requestApply;
    }

    &.scoutDetailInfo--expired {
      #{$this}__wrap {
        border-left: 3px solid $color_requestApply;
      }
    }

    #{$this}__detail #{$this}__content--bottom {
      padding: 10px 25px;

      #{$this}__more {
        padding: 20px 0;
      }
    }
  }
}
