@charset "UTF-8";

/* 表示不可アラート
----------------------------------------------------------------- */
.container {
  &-jobinfo {
    background-color: #fff;

    .container__inner .breadcrumb {
      background-color: #fff;
      padding-bottom: 15px;
    }
  }

  &-previewForm {
    padding-top: 0;
  }

  &-jobinfoAlert {
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    &::before {
      box-shadow: none;
    }

    .container__inner {
      width: 100%;
    }

    .alert {
      max-width: none;
    }

    .alert--multiline {
      max-width: none;
    }
  }
}
