@charset "UTF-8";

.operation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 10px;

  &:last-of-type {
    margin-bottom: 20px;

    &.zeroMarginBottom {
      margin-bottom: 0;
    }
  }

  .pager__list {
    display: inline-block;
  }

  .pager__text {
    display: inline-block;
    margin-left: 18px;
  }

  + .noticeMail {
    margin: 60px 0 0 0;
  }
}

.operation.mb50 {
  margin-bottom: 50px;
}
