@charset "UTF-8";

/* イベント用 見出し要素
----------------------------------------------------------------- */
.eventFair {
  .headingBlock {
    &__icon {
      vertical-align: middle;
    }

    &__text {
      color: $color_keyColor;
      display: inline-block;
      font-weight: 600;
      vertical-align: middle;
      width: 80%;
    }

    &__mainText {
      display: inline-block;
      margin-left: 24px;
    }

    &__subText {
      color: $color_text;
      display: block;
      font-size: 14px;
      font-weight: 300;
      margin-top: 17px;
    }

    &__side {
      display: inline-block;
      text-align: center;
      vertical-align: top;
      width: 19%;
    }

    &__qrText {
      color: $color-gray;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
