@charset "UTF-8";

.newArrival {
  $component: &;
  padding: 1.5rem 3rem;
  position: relative;

  &::before {
    background-color: $color_keyColor;
    content: "";
    display: block;
    height: 50%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &::after {
    background-color: $color_keyColor_light;
    bottom: 0;
    content: "";
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &__header,
  &__main,
  &__footer {
    position: relative;
    z-index: 2;
  }

  &__header {
    @include clearfix;
    margin-bottom: 1rem;
    z-index: 3;
  }

  &__headerLeft {
    float: left;
    height: 4rem;
    position: relative;
    width: 23.2rem;

    #{$component}__title {
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__headerRight {
    float: left;
    height: 40px;
  }

  &__updatedDate {
    margin-bottom: .5rem;
    margin-right: 1rem;
    width: 100%;
  }

  &__tag {
    background-color: $color_white;
    border-radius: 99999px;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1;
    margin-right: 1rem;
    padding: .4rem 1rem;
  }

  &__weekday {
    color: $color_white;
    font-size: 1.3rem;
    font-weight: bold;
  }

  &__title {
    color: $color_white;
    display: inline-block;
    font-size: 2.4rem;
    font-weight: bold;
    letter-spacing: 1px;

    #{$component}__num {
      font-size: 2.8rem;
    }
  }

  &__subtitle {
    color: $color_white;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
  }

  &__num {
    color: #ff0;
  }

  &__main {
    background-color: $color_white;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    padding: 2rem 6rem 2rem 3rem;
    position: relative;
    min-height: 229px;
    width: 100%;
    display: table;

    &::after {
      background: url('../img/top/sprite.png') -10px -205px;
      content: "";
      display: inline-block;
      height: 51px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: 11px;
    }
  }

  &__link {
    display: table-cell;
    vertical-align: middle;
  }

  &__link:hover {
    text-decoration: none;
  }

  &__link:hover p {
    text-decoration: underline;
  }

  &__companies {
    font-size: 1.3rem;
    line-height: 1.8;
    min-height: 114px;
  }

  .logoBlock {
    @include clearfix;
    margin-top: 1.5rem;
    text-align: center;

    img {
      display: inline-block;
      margin: 0 1rem;
      width: 80px;
    }
  }

  &__footer {
    margin-top: 1.8rem;
    text-align: center;
  }

  &__registerCTA {
    @include onHover;
    align-items: center;
    background-color: $color_red;
    border-radius: 2px;
    color: $color_white;
    display: inline-block;
    font-size: 2.2rem;
    font-weight: bold;
    height: 5rem;
    letter-spacing: 1px;
    padding: 1.4rem 4rem;
    position: relative;
    width: 34rem;

    .badge {
      background-color: $color_white;
      border-radius: 99999px;
      color: $color_red;
      display: inline-block;
      font-size: 1.4rem;
      left: 4rem;
      padding: .6rem 0;
      position: absolute;
      text-align: center;
      width: 8.6rem;
    }

    .label {
      left: 8.6rem + 4rem + 1rem;
      position: absolute;
    }

    .badge,
    .label {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--fullWidth {
    padding: 2rem 1rem;

    #{$component}__updatedDate {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 2rem;
      transform: translateY(-4px);
      width: auto;
    }

    #{$component}__header {
      margin-bottom: 1.6rem;
    }

    #{$component}__title {
      margin-right: 2rem;
    }

    #{$component}__tag {
      padding: 8px 24px;
    }

    #{$component}__main {
      background-position: 800px center;
      padding: 2rem 6rem 2rem 2.5rem;
      min-height: auto;

      &::after {
        right: 20px;
      }
    }

    #{$component}__link {
      @include clearfix;
      display: table;
      min-height: 140px;
      position: relative;
    }

    #{$component}__companies {
      display: table-cell;
      min-height: auto;
      padding-left: 25rem;
      vertical-align: middle;
    }

    .logoBlock {
      left: 1rem;
      position: absolute;
      margin-top: 0;
      padding-left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 200px;

      img {
        margin: .5rem .9rem;
      }
    }
  }

  &__tooltip {
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    white-space: unset;

    &:hover {
      text-decoration: none;
    }
  }

  &__tooltipContent {
    color: #000;
  }

  &__tooltipTitle {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.15;
    margin-bottom: 15px;
  }

  &__tooltipText {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.5;
  }
}
