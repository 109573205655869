@charset "UTF-8";

.topSection {
  $component: &;
  margin-bottom: 6rem;

  &--carousel {
    // カルーセルアイテムはPick upラベルのために1remだけ上に余白を取っているため
    // そのぶんセクションヘッダーの下マージンを削る
    #{$component}__header {
      margin-bottom: 2rem;
    }
  }

  &__header {
    @include clearfix;
    margin-bottom: 3rem;

    &--relation {
      line-height: 1.4;
      margin-bottom: 1rem;
    }
  }

  &__title {
    color: $color_heading;
    float: left;
    font-size: 2rem;
    font-weight: bold;

    small {
      font-size: 1.6rem;
    }
  }

  &__subtitle {
    float: right;
    font-size: 1.3rem;

    a:not(#{$component}__imageLink) {
      @include dotArrowSmall();
    }

    #{$component}__imageLink {
      @include onHover;
    }
  }

  &__iconBox {
    @include clearfix;
    color: #ff8401;
    display: block;
    font-size: 1.5rem;
    position: relative;
  }

  &__iconImg {
    background-repeat: no-repeat;
    display: block;
    float: left;
    height: 2.5rem;
    left: 0;
    margin-right: 1rem;
    position: absolute;
    top: -10px;
    width: 2.5rem;
  }

  &__iconTitle {
    margin: 0 0 0.8rem 3.5rem;
    font-weight: 700;
  }

  &__loading {
    padding: 6rem 0;
    text-align: center;
  }
}
