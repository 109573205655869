$env: "prod";
@charset "UTF-8";

/*! =======================================================================
 * File        : style.css
 * DESCRIPTION : コンポーネント用CSS
 * AUTHOR      : i-studio Co.,Ltd.
 * ------------------------------------------------------------------------
 * (C)Mynavi Corporation
========================================================================== */

/* リセット用
----------------------------------------------------------------- */
@import './reset';

/* 基本設定用
----------------------------------------------------------------- */
@import './setting';
@import './base';

/* ヘッター
----------------------------------------------------------------- */
@import './header';

/* フッター
----------------------------------------------------------------- */
@import './footer';

/* フォントアイコン
----------------------------------------------------------------- */
@import './modules/webfont';

/* モジュール レイアウト
----------------------------------------------------------------- */
@import './modules/layout';

/* モジュール 見出し
----------------------------------------------------------------- */
@import './modules/heading';

/* モジュール テキスト
----------------------------------------------------------------- */
@import './modules/text';

/* モジュール ボタン
----------------------------------------------------------------- */
@import './modules/btn';

/* モジュール フォーム
----------------------------------------------------------------- */
@import './modules/form';

/* モジュール テーブル
----------------------------------------------------------------- */
@import './modules/table';

/* モジュール その他
----------------------------------------------------------------- */
@import './modules/others';

/* その他 モーダル用
----------------------------------------------------------------- */
@import './selectcondition';

/* トップページ用
----------------------------------------------------------------- */
@import './top/style';

/* モジュール ラベル
----------------------------------------------------------------- */
@import './modules/label';

/* モジュール リスト
----------------------------------------------------------------- */
@import './modules/list';

/* モジュール ブロック
----------------------------------------------------------------- */
@import './modules/block';

/* モジュール カセット
----------------------------------------------------------------- */
@import './modules/cassette';

/* js用
----------------------------------------------------------------- */
@import './modules/function';

/* 職種大分類用
----------------------------------------------------------------- */
@import './selectJob/style';

/* 気になるページ用
----------------------------------------------------------------- */
@import './bookmark/style';

/* 求職者・AGSスカウト
----------------------------------------------------------------- */
@import './scoutAgs/style';

/* 求人情報用
----------------------------------------------------------------- */
@import './jobInfo/style';

/* イベント用
----------------------------------------------------------------- */
@import './event/style';

/* MP03-0030-001
----------------------------------------------------------------- */
@import './searchJob/style';

/* 企業メッセージ、AGSメッセージ用
----------------------------------------------------------------- */
@import './agsMessage/style';

/* パスワード強度チェック
----------------------------------------------------------------- */
@import './passwordStrengthCheck';

/* 企業用
----------------------------------------------------------------- */
@import './companyInfo/style';

/* キャリQ
----------------------------------------------------------------- */
@import './careq/style';

/* ＋Stories.
----------------------------------------------------------------- */
@import './plst/style';

/* 企業一覧
----------------------------------------------------------------- */
@import './companiesList';

/* 企業情報
----------------------------------------------------------------- */
@import './companyInfo';

/* 注目求人ピックアップ
----------------------------------------------------------------- */
@import './featureJobPickUp/style';

/* 応募完了
----------------------------------------------------------------- */
@import './entryCompleted/entryCompleted';

/* インフォマティブデータ提供モーダル
----------------------------------------------------------------- */
@import './infoConsent';

/* グリッドレイアウトレコメンド
----------------------------------------------------------------- */
@import './gridRecommend/style';
