@charset "UTF-8";

$line-emphasis: #ffe5e6;
$color-blue: #00aaeb;
$color-gray: #555;
$color-black: #333;
$color-yellow: #fff7ca;
$color-white: #fff;
$color-red: #f00;
$color-green: #10b497;

$scout-match-red: #ff5254;
$scout-match-yellow: #fcb653;
$scout-match-gray: #999;

$scout-detail-white-bg: #fff;
$scout-detail-gray-bg: #f0f0f0;
$scout-premium-orange: #f2ad1a;

$scout-lightblue-color: #00aaeb;
$scout-lightblue-bordercolor: #44a9e5;
$scout-lightblue-bordercolor2: #03aaeb;
$scout-lightblue-bgcolor: #dfeff5;
$scout-skyblue-color: #dfeff5;
$scout-gray-bg: #fafafb;
$scout-white: #fff;
$scout-container-border: #bbb;
$scout-title-boder: #ddd;
$scout-red: #f00;
$scout-light-black: #333;

$scout-title-bg: #f9f9f9;

$scout-premium: #f2ad1a;
$scout-premium-bg: #fff7ca;
$scout-hope: #ff5254;
$scout-terms: #999;
$scout-experience: #fcb653;
$scout-keep: #fece30;
$scout-disable: #f0f0f0;
$scout-read: #bbb;
$scout-ags: #10b497;
$scout-note: #666;

$scout_img_path: '../img/scoutAgs/';
$scout_path: '../img/scoutAgs/';
$main_width: 1140px;
$scout_bold: 600;

//checkboxLarge
@mixin checkboxLarge($bgPos: -400px -91px, $bgPosActive: -368px -91px) {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;

  & > input {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    width: 32px;
    height: 32px;
    opacity: 0;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  &__icon::before {
    content: "";
    display: block;
    width: 32px;
    height: 33px;
    background: url(#{$scout_img_path}rere_sprite.png) $bgPos no-repeat;
  }

  & > input:checked + &__icon::before {
    background: url(#{$scout_img_path}rere_sprite.png) $bgPosActive no-repeat;
  }

  & > input:focus {
    outline: 0;
  }

  & > input:focus + &__icon {
    box-shadow: 0 0 4px 1px $color_keyColor;
    border-collapse: separate;
  }

  @content;
}

//scoutReadMore
@mixin scoutReadMore($showHeight: 80px, $margin: 40px) {
  $component: &;
  position: relative;
  padding-bottom: 20px;

  &--close {
    #{$component}__content {
      overflow: hidden;
    }
  }

  &--open {
    #{$component}__content {
      overflow: visible;
    }

    #{$component}__footer::before {
      content: none;
    }
  }

  &__content {
    height: $showHeight;
    margin-bottom: $margin;

    &--hasMovie {
      height: 235px;
    }

    &--hasShortMovie {
      height: 345px;
    }
  }

  @content;
}

@mixin scoutBlock {
  margin: 0 auto 30px auto;
  width: 100%;
  max-width: $main_width;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
  background: $scout-white;
}

@mixin scoutFlex($justify: flex-start, $align: normal, $wrap: nowrap) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: $justify;
  -ms-flex-align: $align;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin linkBtn($width: auto, $padding: 8px 10px, $font-size: 1.4rem, $line-height: 1.3) {
  width: $width;
  padding: $padding;
  font-size: $font-size;
  line-height: $line-height;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  font-weight: $scout_bold;
  text-align: center;
  text-decoration: none;
  border: 1px solid $scout-lightblue-color;
  border-radius: 5px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);
  color: $scout-lightblue-color;
  background-color: $scout-white;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }

  &-disabled {
    @include disabled();
  }
}

@mixin scoutBtn($width: auto, $background: $scout-red, $color: $scout-white, $padding: 1.4rem 2rem) {
  width: $width;
  cursor: pointer;
  font-size: 2.2rem;
  font-weight: $scout_bold;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  background: $background;
  color: $color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  display: inline-block;
  position: relative;
  letter-spacing: 1px;
  padding: $padding;
  line-height: 1;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }

  &-disabled {
    @include disabled();
  }
}

@mixin scoutBtnlabel {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}
