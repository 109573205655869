@charset "UTF-8";

.media {
  $component: &;
  @include clearfix;

  &__item {
    #{$component}--cell & {
      width: 49%;

      &:nth-child(odd) {
        float: left;
      }

      &:nth-child(even) {
        float: right;
      }

      &:nth-child(n+3) {
        margin-top: 1.5rem;
      }
    }
  }

  &__img {
    float: left;
    margin-right: 2rem;
    width: 20%;

    &--colorbox {
      width: 31%;
    }

    &--interview {
      width: 15%;
    }
  }

  &__rightBox {
    float: right;
    width: 77%;

    #{$component}--cell & {
      width: 63%;
    }

    &--interview {
      width: 82%;
      padding-top: 10px;
    }
  }

  &__title {
    @include jobTitle;

    &--black {
      color: #000;
      font-size: 1.5em;
    }

    &--interview {
      color: #000;
      padding-top: 10px;
    }
  }

  &__text {
    @include jobText;

    &--roundMultiline {
      -webkit-box-orient: vertical; /* autoprefixer: off */
      -webkit-line-clamp: 2; /* autoprefixer: off */
      display: -webkit-box;
      overflow: hidden;
    }

    &--md {
      font-size: 1.3rem;
      line-height: 1.4em;
    }
  }

  &__period {
    font-size: 1.2rem;
    line-height: 2em;
    color: #555;
    margin-top: 1rem;

    &::before {
      @include tagBase;
      background-color: $job-blue-color;
      content: "掲載期間";
    }
  }
}
