@charset "UTF-8";

/* バッジを表示する際に使用
----------------------------------------------------------------- */
.labelNumber {
  @include labelNumber() {
    background-color: $color_red;
  }
}

/* 強調の際に使用する(枠線あり) 新着
----------------------------------------------------------------- */
.labelNew {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: $color_red;
  }
}

/* 強調の際に使用する 締切日
----------------------------------------------------------------- */
.labelClosingDate {
  @include label() {
    padding: 2px 9px;
    color: $color_red;
    border: 1px solid #{$color_red};
  }
}

/* 分類を分ける際に使用する 応募準備完了
----------------------------------------------------------------- */
.labelReadiness {
  @include label() {
    padding: 2px 9px;
    color: $color_red;
    border: 1px solid #{$color_red};
    background-color: $color_white;
  }
}

/* 雇用形態ラベル
----------------------------------------------------------------- */
.labelEmploymentStatus {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: $color_condition;
  }
}

/* 追加コンテンツラベル
----------------------------------------------------------------- */
.labelProInterview {
  @include label() {
    background-color: $color_white;
    border-radius: 10px;
    color: #f09300;
    line-height: 1.2;
    margin-top: -2px !important;
    padding: 2px 10px;

    span::before {
      font-size: 1.2rem;
    }
  }

  &--large {
    @extend .labelProInterview;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: 2px 10px;

    span::before {
      font-size: 2rem;
    }
  }
  
  &--red {
    @extend .labelProInterview;
     background-color: red;
     color: #fff;
  }

  .icon-movie {
    background-image: url("../img/glyphicons-halflings.png");
    background-position: -192px 0;
    background-repeat: no-repeat;
    display: inline-block;
    filter: invert(54%) sepia(82%) saturate(1590%) hue-rotate(6deg) brightness(0) invert(1) contrast(101%);
    height: 14px;
    line-height: 14px;
    margin-right: 5px;
    margin-top: -4px;
    vertical-align: middle;
    width: 14px;
  }
}

/* 雇用条件ラベル
----------------------------------------------------------------- */
.labelCondition {
  @include label() {
    padding: 3px 9px;
    color: $color_condition;
    border: 1px solid #{$color_condition};
  }
}
// iPad表示対応
@media only screen and (max-width: 1200px) {
  .labelCondition {
    font-size: 1rem;
  }
}

/* 女性のおしごとラベル
----------------------------------------------------------------- */
.labelWoman {
  @include label() {
    padding: 3px 9px;
    color: $color_woman;
    border: 1px solid $color_woman;
    text-decoration: none;
  }
}
// iPad表示対応
@media only screen and (max-width: 1200px) {
  .labelWoman {
    font-size: 1rem;
    margin-top: 3px;
  }
}

/* 社員の声ラベル
----------------------------------------------------------------- */
.labelBlack {
  @include label() {
    padding: 3px 9px;
    color: $color_text;
    border: 1px solid #{$color_text};
  }
}

.labelBlackArrow {
  @extend .labelBlack;
  padding-left: 24px;
  background: url(#{$img_path}arrowRightBlack.png) no-repeat 10px 50%;
}

/* アイコン付きで分類を分ける際に使用する
----------------------------------------------------------------- */
.labelInfo {
  @include labelInfo;
}

/* アイコン付きで分類を分ける際に使用する 面接保証
----------------------------------------------------------------- */
.labelInfo--interview {
  @include labelInfo($color: #{$color_red});
}

/* 入力必須
----------------------------------------------------------------- */
.labelRequired {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: $color_red;
  }
}

/* 入力未入力ラベル（横幅固定）
----------------------------------------------------------------- */
.labelNoEntered {
  @include label() {
    width: 76px;
    padding: 3px 0;
    color: $color_white;
    text-align: center;
    background-color: #{$color_red};
  }
}

/* 入力OKラベル（横幅固定）
----------------------------------------------------------------- */
.labelEntered {
  @include label() {
    width: 61px;
    padding: 3px 0;
    color: $color_white;
    text-align: center;
    background-color: $color_keyColor;
  }
}

/* 応募準備完了ラベル
----------------------------------------------------------------- */
.labelReadyEntry {
  background: #fff7c9;
  border: solid 1px #a57700;
  border-radius: 3px;
  color: #a57700;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  padding: 2px 8px 2px 8px;
  position: relative;
  z-index: 1;
}

/* スカウト特典のみに使用する（王冠付き）
----------------------------------------------------------------- */
.labelScoutCrown {
  @include label() {
    position: relative;
    z-index: 1;
    padding: 2px 8px 2px 22px;
    color: $color_crown;
    border: 1px solid $color_crown;
    background-color: $color_white;
  }
}

.labelScoutCrown::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 6px;
  width: 11px;
  height: 8px;
  background: url(#{$img_path}sprite.png) no-repeat -66px -160px;
  transform: translateY(-50%);
}

/* スカウト特典ラベル
----------------------------------------------------------------- */
.labelScoutBenefit {
  @include labelScout;
}

/* スカウト特典内容ラベル
----------------------------------------------------------------- */
.labelScout {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    text-align: center;
    background-color: $color_keyColor;
  }
}

/* プレミアム特典のみに使用する
----------------------------------------------------------------- */
.labelPremiumBenefit {
  @include labelScout($titleColor: #{$color_premium}, $textColor: #{$color_premium}, $bgColor: #{$color_premium3}, $borderColor: #{$color_premium2});
}

/* スカウト種別に使用する （王冠)
----------------------------------------------------------------- */
.labelCrown {
  @include labelCrown;
}

.labelCrown__full {
  background: url(#{$img_path}sprite.png) no-repeat 0 -160px;
}

.labelCrown__half {
  background: url(#{$img_path}sprite.png) no-repeat -22px -160px;
}

.labelCrown__none {
  background: url(#{$img_path}sprite.png) no-repeat -44px -160px;
}

/* スカウト限定求人ラベル
----------------------------------------------------------------- */
.labelScoutLimited {
  @include label() {
    padding: 2px 9px;
    color: $color_red;
    background-color: $color_white;
    border: 1px solid $color_red;
  }
}

/* 注目のみに使用する
----------------------------------------------------------------- */
.labelAttention {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: #f09300;
  }

  &__large {
    @include label() {
      vertical-align: middle;
      padding: 5px 10px;
      border-radius: 5px;
      color: $color_white;
      background-color: $color_red;
    }
  }
}

/* メール配信に使用する（不定期）
----------------------------------------------------------------- */
.labelIrregular {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: $color_disable02;
  }
}

/* メール配信に使用する（曜日）
----------------------------------------------------------------- */
.labelWeekDelivery {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: #fcb653;
  }
}

/* 未設定・停止中
----------------------------------------------------------------- */
.labelStopped {
  @include label() {
    padding: 3px 10px;
    color: $color_text;
    background-color: $color_disable02;
  }
}

/* アンケート項目アイコン Q
----------------------------------------------------------------- */
.labelQuestion {
  @include label() {
    position: relative;
    z-index: 1;
    min-width: 24px;
    padding: 6px 2px;
    color: $color_white;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    background-color: $color_keyColor;
  }
}

.labelQuestion::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  border-color: transparent;
  border-left-color: $color_keyColor;
  border-style: solid;
  border-width: 3px 4px;
  transform: translate(100%, -50%);
}

/* アンケート項目アイコン A
----------------------------------------------------------------- */
.labelAnswer {
  @include label() {
    padding: 2px 9px;
    color: $color_keyColor;
    background-color: $color_white;
    border: 1px solid $color_keyColor;
  }
}

.labelAnswer--type2 {
  @extend .labelQuestion;
  color: $color_keyColor;
  background-color: #dfeff5;
}

.labelAnswer--type2::before {
  border-left-color: #dfeff5;
}

/* 状況表示
----------------------------------------------------------------- */
.labelStateDone {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: #fcb653;
  }
}

.labelStateFail {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: $color_disable02;
  }
}

/* 選択中
----------------------------------------------------------------- */
.labelSelected {
  @include labelNumber() {
    padding: 3px 6px 0;
    font-size: 1.2rem;
    background-color: $color_keyColor;
  }
}

/* 既読
----------------------------------------------------------------- */
.labelAlready {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: $color_subText;
  }
}

/* 締切間近
----------------------------------------------------------------- */
.labelDeadline {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    background-color: $color_red;
  }
}

/* 更新
----------------------------------------------------------------- */
.labelUpdate {
  @include label() {
    padding: 3px 10px;
    color: $color_white;
    text-align: center;
    background-color: $color_keyColor;
  }
}

/* 特色
----------------------------------------------------------------- */
.labelFeature {
  @include label() {
    padding: 3px 8px;
    color: #b27dcd;
    text-align: center;
    background-color: $color_white;
    border: 1px solid #b27dcd;
  }
}

.labelFeature--type2 {
  @extend .labelFeature;
  color: #10b497;
  border-color: #10b497;
}

.labelFeature--type3 {
  @extend .labelFeature;
  color: #9e8b75;
  border-color: #9e8b75;
}

.labelFeature--type4 {
  @extend .labelFeature;
  color: #ff5254;
  border-color: #ff5254;
}

.labelFeature--type5 {
  @extend .labelFeature;
  color: #5cacc4;
  border-color: #5cacc4;
}

/* 先行の特徴
----------------------------------------------------------------- */
.labelFeaturesSelection {
  @include label() {
    padding: 2px 9px;
    color: $color_red;
    border: 1px solid #{$color_red};
    background-color: $color_white;
  }
}