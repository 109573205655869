@charset "UTF-8";

.topContainer {
  margin: 0 auto;
  width: $top-width;

  &--hero {
    width: $top-hero-width;
    @include clearfix;
  }

  &--short {
    width: $top-short-width;
  }

  &--subsite {
    padding: 0 15px;
    width: $top-subsite-width;
  }
}
