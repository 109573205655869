@charset "UTF-8";

.topSubMenuList {
  @include clearfix;
  margin-top: .6rem;

  &__item {
    float: left;

    a {
      font-size: 1.3rem;
      padding: 0 1rem;
    }
  }

  &__item + &__item {
    border-left: 1px solid #ccc;
  }
}
