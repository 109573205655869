@charset "UTF-8";

.btnArea {
  @include scoutBlock;

  &-tp {
    background: transparent;
    box-shadow: none;
  }

  &__btnBox {
    text-align: center;
    padding: 30px;

    & > a {
      vertical-align: top;
    }
  }

  &__linkBox {
    text-align: center;
  }

  &__btnTitle {
    font-size: 2rem;
    font-weight: $scout_bold;
    letter-spacing: 0.05em;
    line-height: 1;
    margin-bottom: 2rem;
  }

  &__registerBtn {
    @include scoutBtn($width: 34rem);
    margin-right: 10px;
    height: 5rem;

    & .badge {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: $scout-white;
      border-radius: 99999px;
      color: $scout-red;
      display: inline-block;
      font-size: 1.4rem;
      left: 5rem;
      padding: .6rem 1.2rem;
      position: absolute;
      text-align: center;
      line-height: 1;
    }

    & .label {
      @include scoutBtnlabel;
      left: 14.6rem;

      &-sub {
        font-size: 1.3rem;
      }
    }
  }

  &__loginBtn {
    @include scoutBtn($width: 34rem, $background: $scout-lightblue-color);
    margin-left: 10px;
    height: 5rem;

    & .label {
      @include scoutBtnlabel;
      left: 0;
      right: 0;
    }
  }

  &__linkBtn {
    @include linkBtn(340px, 20px 0, 1.6rem, 1);
    margin-right: 18px;

    &:nth-child(2) {
      margin-left: 18px;
      margin-right: 0;
    }

    & .label {
      display: block;
      font-size: 2.8rem;
      line-height: 1;
      margin-bottom: 12px;
      letter-spacing: 0.1em;
      font-weight: $scout_bold;
    }
  }
}
