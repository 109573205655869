@charset "UTF-8";

.jobBoxedList {
  width: 100%;
  display: inline-block;

  &__item {
    @include onHover;
    display: inline-block;
    padding: 10px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 1rem;
  }

  &__title {
    color: #000;
    display: block;
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
