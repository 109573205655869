@charset "UTF-8";

.topSearchCondition {
  @include ellipsis;
  font-size: 1.3rem;
  padding-left: 2.2em;
  position: relative;

  &::before {
    color: $color_keyColor;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "mynavi";
    font-size: 1.8rem;
    left: 0;
    position: absolute;
    top: -0.25em;
  }

  &--salary {
    &::before {
      content: "\e905";
    }
  }

  .topBalloon &--salary {
    &::before {
      content: "初年度年収：";
    }
  }

  &--area {
    &::before {
      content: "\e614";
    }
  }

  .topBalloon &--area {
    &::before {
      content: "勤務地：";
    }
  }

  &--occupation {
    &::before {
      content: "\e606";
    }
  }

  .topBalloon &--occupation {
    &::before {
      content: "職種：";
    }
  }

  &--employmentType {
    &::before {
      content: "\e909";
    }
  }

  .topBalloon &--employmentType {
    &::before {
      content: "雇用形態：";
    }
  }

  &--company {
    font-weight: bold;

    &::before {
      content: "\e90d";
    }
  }

  .topBalloon &--company {
    font-weight: bold;

    &::before {
      content: "会社名：";
    }
  }

  &--industry {
    &::before {
      content: "\e90f";
    }
  }

  .topBalloon &--industry {
    &::before {
      content: "業種：";
    }
  }

  &--headOffice {
    &::before {
      content: "\e90c";
    }
  }

  .topBalloon &--headOffice {
    &::before {
      content: "本社所在地：";
    }
  }

  &--employeeNumber {
    &::before {
      content: "\e908";
    }
  }

  .topBalloon &--employeeNumber {
    &::before {
      content: "従業員数：";
    }
  }

  &--establishment {
    &::before {
      content: "\e90d";
    }
  }

  .topBalloon &--establishment {
    &::before {
      content: "設立：";
    }
  }

  &--feature {
    &::before {
      content: "\e90b";
    }
  }

  .topBalloon &--feature {
    &::before {
      content: "特徴：";
    }
  }

  &--ITEngineer {
    &::before {
      content: "\e915";
    }
  }

  .topBalloon &--ITEngineer {
    &::before {
      content: "ITエンジニア向け条件：";
    }
  }

  &--keyword {
    &::before {
      content: "\e910";
    }
  }

  .topBalloon &--keyword {
    &::before {
      content: "キーワード：";
    }
  }

  &--new {
    &::before {
      content: "\e904";
    }
  }

  .topBalloon &--new {
    &::before {
      content: "新着・更新：";
    }
  }

  &--mysearch {
    &::before {
      content: "\e907";
    }
  }

  .topBalloon &--mysearch {
    &::before {
      content: "条件名：";
    }
  }

  &--typeMatch {
    &::before {
      content: "\e90e";
    }
  }

  .topBalloon &--typeMatch {
    &::before {
      content: "適正：";
    }
  }

  &--experienceMatch {
    &::before {
      content: "\e90a";
    }
  }

  .topBalloon &--experienceMatch {
    &::before {
      content: "経験：";
    }
  }

  &--specialFeature {
    &::before {
      content: "\e913";
    }
  }

  .topBalloon &--specialFeature {
    &::before {
      content: "大手・優良企業：";
    }
  }

  &--access {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--access {
    &::before {
      content: "交通アクセス：";
    }
  }

  &--holiday {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--holiday {
    &::before {
      content: "休日・休暇：";
    }
  }

  &--monthHolidays {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--monthHolidays {
    &::before {
      content: "月の休日数：";
    }
  }

  &--overTime {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--overTime {
    &::before {
      content: "残業時間：";
    }
  }

  &--bonus {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--bonus {
    &::before {
      content: "賞与回数：";
    }
  }

  &--sales {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--sales {
    &::before {
      content: "売上高：";
    }
  }

  &--capital {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--capital {
    &::before {
      content: "資本金：";
    }
  }

  &--other {
    &::before {
      content: "\e912";
    }
  }

  .topBalloon &--other {
    &::before {
      content: "詳細条件：";
    }
  }
}

.topSearchCondition + .topSearchCondition {
  margin-top: 10px;
}

.topBalloon .topSearchCondition + .topSearchCondition {
  margin-top: 0;
}

.topBalloon .topSearchCondition {
  padding-left: 1rem;
  line-height: 1.2;
}

.topBalloon .topSearchCondition::before {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  top: 0;
  position: relative;
}
