@charset "UTF-8";

@mixin topCardLabel {
  &::before {
    background-color: $color_white;
    border: 1px solid $color_red;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    color: $color_red;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bold;
    left: 1rem;
    padding: 8px 0;
    position: absolute;
    text-align: center;
    top: -1rem;
    width: 6.7rem;
    z-index: 3;
  }

  &::after {
    border: 10px solid transparent;
    border-top-color: $color_red;
    content: "";
    display: inline-block;
    left: 66px;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    z-index: 1;
  }
}

.topCard {
  $component: &;
  position: relative;
  width: $top-card-width;

  &--shadow {
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);

    #{$component}__title {
      padding: 0 1rem;
    }

    #{$component}__text {
      padding: 0 1rem 2rem 1rem;
    }
  }

  &--new {
    @include topCardLabel;

    &::before {
      content: "NEW";
    }
  }

  &--pickup {
    @include topCardLabel;

    &::before {
      content: "Pick up";
    }
  }

  &__eyeCatch {
    height: 17rem;
    position: relative;
    z-index: 2;
    width: 25rem;

    img {
      width: 100%;
    }
  }

  &__title {
    align-items: center;
    color: $color_keyColor;
    font-size: $top-card-title-size;
    font-weight: bold;
    line-height: $top-card-title-line-height;
    margin-top: 2rem;

    &--block {
      background-color: $color_white;
      border-bottom: 1px solid rgba(0, 0, 0, .15);
      color: $color_text;
      font-size: 1.3rem;
      margin: 0 auto -2rem auto;
      padding: 1rem 2rem;
      position: relative;
      text-align: center;
      transform: translateY(-2rem);
      width: 23rem;
      // アイキャッチより上
      z-index: 4;
    }

    //気になる画面レコメンド
    &--renew {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      min-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__text {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-top: 1.7rem;
  }

  &--hasClient #{$component}__eyeCatch {
    height: 19rem;
  }
}

//TOP画面のfigureの高さを17remに戻す
.topPageWrapper .topSection .topCard--hasClient .topCard__eyeCatch {
  height: 17rem;
}

.topPageWrapper .topSection #js__historyRecommendItem .topCard__eyeCatch {
  height: 19rem;
}
