@charset "UTF-8";

.tabNaviScout {

  &__badge {
    @include labelNumber() {
      background-color: $color_red;
      margin-left: 1rem;
      padding: .4rem .8rem;
    }
  }
}