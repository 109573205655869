/* パスワード強度チェック
----------------------------------------------------------------- */
.strengthBarRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 20px;
}

.barPiece {
  background-color: lightgrey;
  height: 8px;
  margin: 0 2px;
  width: 10%;
}

.barPiece.barAdjust {
  width: 15%;
}

.strengthBarRow.very-weak .barPiece:nth-child(-n+1) {
  background-color: red;
}

.strengthBarRow.weak .barPiece:nth-child(-n+2) {
  background-color: orange;
}

.strengthBarRow.good .barPiece:nth-child(-n+3) {
  background-color: forestgreen;
}

.strengthBarRow.strong .barPiece:nth-child(-n+4) {
  background-color: forestgreen;
}

// *****ツールチップ表示********************************
.passwordTooltipBox {
  border: 1px solid #f2ad1a;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);
  display: none;
  font-weight: 700;
  line-height: 1.7;
  position: absolute;
  top: -108px;
  width: 390px;
  z-index: 1;

  &__content {
    background-color: #fff7ca;
    border-radius: 3px;
    padding: 12px;
    position: relative;
    z-index: 2;
  }

  &::before {
    border-style: solid;
    border-color: #f39800 transparent;
    border-width: 12px 12px 0 0;
    bottom: -12px;
    content: '';
    display: block;
    left: 13%;
    margin-left: -10px;
    position: absolute;
    z-index: 1;
  }
}

.passwordBox {
  position: relative;
}

.tooltipCondition {
  color: green;
}

.js__passwordStrengthInput:focus + .passwordTooltipBox {
  display: block;
}

.js__pwdNote {
  display: none;
}

// *****パスワード表示********************************
.js__passwordStrengthInput {
  &::-ms-reveal {
    display: none;
  }
}
