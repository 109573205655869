$color_white: #fff;
$color_red: #f00;
$color_keyColor: #00aaeb;
$color_keyColor_light: #dfeff5;
$color_keyColor_dark: #08b;
$color_heading: #333;
$color_text: #555;
$color_text_light: #666;
$color_subText: #999;
$color_border: #bbb;
$color_subBorder: #ddd;
$color_crown: #f09300;
$color_keep: #fece30;
$color_premium: #a57700;
$color_premium2: #f2ad1a;
$color_premium3: #fff7ca;
$color_woman: #d8006c;
$color_subWoman: #fae4ef;
$color_condition: #7dbd00;
$color_disable: #555;
$color_disable02: #bbb;
$color_error: #fcf1ef;
$color_matching: #ff7f7f;
$color_matchingLight: #ffe5e5;
$color_requestApply: #ff7f7f;
$color_requestApplyLight: #ffe5e5;
$color_requestInterview: #b27dcd;
$color_requestInterviewLight: #f4eaf8;
$color_agsMessage_keyColor: #10b497;

$borderRadius_defult: 5px;
$boxShadow_defult: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
$btnShadow: 0 1px 2px 0 #bbb6a8;

$img_path: '../img/common/';

// clearfix
@mixin clearfix {
  &::after {
    content: " ";
    display: block;
    clear: both;
  }
}

// placeholderColor
@mixin placeholderColor($color: $color_subText) {
  &::-webkit-input-placeholder {
    color: $color;
    font-weight: normal;
  }

  &:-ms-input-placeholder {
    color: $color;
    font-weight: normal;
  }

  @content;
}

// arrowLink--large
@mixin arrowLink--large($wrap_width: 950px, $list_width: 242px, $list_margin: 0 0 30px) {
  width: $wrap_width;
  font-size: 0;

  li {
    display: inline-block;
    vertical-align: top;
    width: $list_width;
    margin: $list_margin;
  }

  a {
    position: relative;
    padding-left: 15px;
    font-size: 1.5rem;
    background: url(#{$img_path}arrowRight.png) no-repeat;
    background-position: 0 50%;
  }
}

// arrowLink--small
@mixin arrowLink--small($padding: 40px 0 28px) {
  overflow: hidden;
  padding: $padding;
  color: #aaa;
  font-size: 1.2rem;
  line-height: 1.5;

  &__item {
    float: left;
    margin: 0 8px 12px 0;
    padding-right: 8px;
    border-right: 1px solid #999;
  }

  &__item:last-child {
    margin-right: 0;
    border-right: none;
  }

  a {
    padding-left: 10px;
    font-size: 12px;
    background: url(#{$img_path}arrowRight.png) 0 50% no-repeat;
  }
}

// slideDown
@mixin slideDown($display: none, $top_position: 64px) {
  position: absolute;
  z-index: 50;
  top: $top_position;
  left: 0;
  display: $display;
  width: 100%;
  background-color: #e0eff5;
  box-shadow: $btnShadow;
}

// navlink
@mixin navlink($width: 188px) {
  position: relative;
  display: inline-block;
  color: $color_white;
  font-size: 1.5rem;

  a {
    display: inline-block;
    width: $width;
    height: 56px;
    padding: 20px 0 20px 40px;
    color: $color_white;
    text-align: center;
    border-right: 1px solid #4dc4f1;
    box-sizing: border-box;
  }

  &:hover {
    a {
      color: $color_keyColor;
      text-decoration: none;
      background-position: 0 0;
      background-color: #e0eff5;
    }
  }

  &.js--gnaviHover {
    a {
      color: $color_keyColor;
      text-decoration: none;
      background-color: #e0eff5;
    }
  }
}

// boxCenter
@mixin boxCenter($width: 950px) {
  width: $width;
  margin: 0 auto;
  box-sizing: border-box;
}

// block
@mixin block($width: 950px, $margin: 0 auto 20px, $bgColor: #fff, $border: 1px solid #ececed, $borderTop: 2px solid $color_keyColor, $innerPadding: 20px 30px) {
  max-width: $width;
  margin: $margin;
  border: $border;
  border-top: $borderTop;
  background-color: $bgColor;
  box-shadow: $boxShadow_defult;
  box-sizing: border-box;

  &__content {
    padding: $innerPadding;
  }
}

// button
@mixin button($display: block, $width: 100%, $maxWidth: 290px, $padding: 13px 10px 12px, $color: $color_white, $fontSize: 1.8rem, $fontWeight: bold, $textAlign: center, $bg: $color_keyColor, $border: none, $boxShadow: $boxShadow_defult) {
  display: $display;
  width: $width;
  max-width: $maxWidth;
  padding: $padding;
  color: $color;
  cursor: pointer;
  font-size: $fontSize;
  font-weight: $fontWeight;
  text-align: $textAlign;
  background: $bg;
  border: $border;
  border-radius: $borderRadius_defult;
  box-shadow: $boxShadow_defult;
  box-sizing: border-box;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }
}

// tag
@mixin tag() {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  padding: 2px 8px;
  color: $color_white;
  font-size: 1.2rem;
  border-radius: 2px;

  &:first-child {
    margin-left: 0;
  }
}

// columnList
@mixin columnList($width: auto, $margin: 0 0 30px) {
  letter-spacing: -0.4em;

  &__item {
    display: inline-block;
    vertical-align: top;
    width: $width;
    margin: $margin;
    letter-spacing: 0;
    box-sizing: border-box;
  }
}

// listItem
@mixin listItem() {
  border-bottom: 1px solid #{$color_subBorder};

  @content;
}

// icon
@mixin icon($top: 8px, $right: 0, $left: 0, $margin: 0 auto, $width: 32px, $height: 22px, $bg--x: -78px, $bg--y: 0) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: $top;
    right: $right;
    left: $left;
    margin: $margin;
    width: $width;
    height: $height;
    background: url(#{$img_path}sprite.png) $bg--x $bg--y no-repeat;
  }
}

// loading
@mixin loading($width: 2px, $height: 13px, $top: 0) {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  & > * {
    position: absolute;
    top: $top;
    left: 50%;
    width: $width;
    height: $height;
    opacity: 0.5;
    transform-origin: bottom center;
  }

  & > *::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 27%;
    left: 0;
    width: $width;
    height: 27%;
    background-color: #4d4d4d;
  }

  @for $i from 1 through 12 {
    & > *:nth-child(#{$i}) {
      transform: translate(-50%) rotateZ(#{$i * 30 - 30}deg);
      animation: switchLoading 2s infinite #{$i * 0.181 - 0.181}s linear;
    }
  }
}

// loading animation
@keyframes switchLoading {
  0%,
  100% {
    opacity: 1;
  }

  5% {
    opacity: 0.88;
  }

  10% {
    opacity: 0.5;
  }
}

//iconFont
@mixin iconFont($fontSize: 2rem, $icon: "\e603") {
  &::before {
    content: $icon;
    display: inline-block;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "mynavi";
    font-size: $fontSize;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    speak: none;
    text-decoration: none;
    text-rendering: auto;
    text-transform: none;
    vertical-align: top;
  }

  @content;
}
@mixin scoutFilterIconFont($fontSize: 2rem, $icon: "\e603") {
  &::before {
    content: $icon;
    display: inline-block;
    font-family: "mynavi";
    font-size: $fontSize;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    speak: none;
    text-decoration: none;
    text-rendering: auto;
    text-transform: none;
    vertical-align: top;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  @content;
}

//iconKey
@mixin iconKey($fontSize: 1.3rem, $icon: "\e918") {
  &::before {
    content: $icon;
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 3px;
    color: $color_white;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "mynavi";
    font-size: $fontSize;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    speak: none;
    text-align: center;
    text-decoration: none;
    text-rendering: auto;
    text-transform: none;
    vertical-align: top;
    background-color: $color_keyColor;
  }

  @content;
}

//bulletLink
@mixin bulletLink($fontSize: 1.4rem) {
  padding: 0 0 0 10px;
  font-size: $fontSize;
  background: url(#{$img_path}arrowRight.png) no-repeat 0 50%;

  @content;
}

// contentMargin
@mixin contentMargin() {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

// card
@mixin card($width: 950px, $margin: 0 auto 20px, $bgColor: $color_white) {
  max-width: $width;
  margin: $margin;
  background-color: $bgColor;
  box-shadow: $boxShadow_defult;

  &.fixed-min-width {
    min-width: $width;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
  }

  @content;
}

// heading
@mixin heading($width: 950px) {
  max-width: $width;
  color: $color_heading;
  font-weight: bold;
  word-break: break-all;
  white-space: normal;
  word-wrap: break-word;

  @content;
}

//disabled
@mixin disabled() {
  background: $color_disable02;
  box-shadow: none;
  border-color: $color_border;
  color: $color_disable;
  cursor: default;
  pointer-events: none;
}

@mixin disabledOpacity() {
  cursor: default;
  box-shadow: none;
  opacity: 0.5;
  pointer-events: none;
}

@mixin btn($display: inline-block, $width: auto, $padding: 13px 20px, $fontSize: 1.7rem, $boxShadow: $btnShadow) {
  display: $display;
  vertical-align: top;
  width: $width;
  padding: $padding;
  cursor: pointer;
  font-size: $fontSize;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: $borderRadius_defult;
  box-shadow: $boxShadow;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }

  &--fluid {
    width: 100%;
  }

  @content;
}

//btnSns
@mixin btnSns($display: inline-block, $width: 130px, $padding: 0 0 0 40px, $iconPos: -274px -92px) {
  position: relative;
  z-index: 1;
  display: $display;
  width: $width;
  height: 40px;
  padding: $padding;
  cursor: pointer;
  color: $color_white;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.8;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: $borderRadius_defult;
  box-shadow: $btnShadow;

  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 1px;
    width: 28px;
    height: 28px;
    background: url(#{$img_path}sprite.png) #{$iconPos} no-repeat;
    transform: translateY(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 40px;
    height: 38px;
    background-color: $color_white;
    border-radius: 5px 0 0 5px;
  }

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }

  @content;
}

//label
@mixin label($fontSize: 1.2rem) {
  display: inline-block;
  font-size: $fontSize;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;

  @content;
}

//labelNumber
@mixin labelNumber() {
  display: inline-block;
  margin: 0 4px;
  min-height: 18px;
  min-width: 18px;
  padding: 4px 6px 0;
  color: #fff;
  font-size: 1.1rem;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  border-radius: 32px;

  @content;
}

//labelInfo
@mixin labelInfo($fontSize: 1.2rem, $color: $color_keyColor) {
  display: inline-block;
  color: $color;
  font-size: $fontSize;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  border: 1px solid #{$color};

  & > [class^="iconFont--"]::before {
    vertical-align: middle;
    padding: 1px;
    color: $color_white;
    font-size: 1.4rem;
    background-color: $color;
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
    padding: 0 9px;
  }

  @content;
}

//labelCrown
@mixin labelCrown() {
  letter-spacing: -0.4em;

  > [class^="labelCrown"] {
    display: inline-block;
    width: 22px;
    height: 15px;
    margin-right: 6px;
    letter-spacing: 0;
  }

  @content;
}

//labelScout
@mixin labelScout($fontSize: 1.2rem, $titleColor: $color_white, $textColor: $color_keyColor, $bgColor: $color_keyColor, $borderColor: $color_keyColor) {
  display: inline-block;
  font-size: $fontSize;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  border: 1px solid #{$borderColor};

  > * {
    display: inline-block;
    vertical-align: top;
    line-height: 1.3;
  }

  &__title {
    padding: 1px 9px 0;
    color: $titleColor;
    background-color: $bgColor;
  }

  &__text {
    padding: 1px 9px 0;
    color: $textColor;
    background-color: $color_white;
    border-radius: 0 2px 2px 0;
  }

  @content;
}

//inputText
@mixin inputText($display: inline-block, $fontSize: 1.2rem, $color: $color_heading, $borderColor: $color_border) {
  display: $display;
  height: 34px;
  padding: 5px 10px;
  color: $color;
  font-size: $fontSize;
  border: 1px solid #{$borderColor};

  @content;
}

//modal
@mixin modal($margin: 30px auto, $padding: 30px) {
  display: none;
  position: absolute;
  z-index: 101;
  width: 950px;
  padding: $padding;
  background: $color_white;

  .btnClose {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
  }

  &--small {
    width: 475px; // 950の半分
  }

  @content;
}

//gather
@mixin gather($margin: 60px) {
  position: relative;
  z-index: 1;
  margin-bottom: $margin;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -45px;
    left: 50%;
    width: 72px;
    height: 29px;
    margin-left: -36px;
    background: url(#{$img_path}arrowDownGray.png) left top no-repeat;
  }

  @content;
}

//cassetteBanner
@mixin cassetteBanner($margin: 0 -10px 0 0, $itemWidth: 215px, $itemMargin: 0 10px 15px 0) {
  position: relative;
  z-index: 1;
  margin: $margin;

  &__point {
    position: absolute;
    background-color: #ffe5e5;
    border-radius: 14.5px;
    color: $color_red;
    font-size: 1.2rem;
    height: 29px;
    width: 29px;
    padding-top: 4px;
    text-align: center;
    left: -9px;
    top: -9px;
    z-index: 10;
  }

  &__item {
    float: left;
    display: block;
    width: $itemWidth;
    margin: $itemMargin;
    border: 1px solid #99ddf7;
  }

  &__item:hover {
    opacity: 0.5;
    text-decoration: none;
  }

  &__item img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }

  &__text {
    overflow: hidden;
    display: block;
    padding: 9px 12px;
    color: $color_keyColor;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @content;
}

//formError
@mixin formError() {
  border-color: $color_border;
  background-color: $color_error;
}

//checkbox
@mixin checkbox($bgPos: -93px -160px, $bgPosActive: -110px -160px) {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;

  & > input {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    opacity: 0;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 16px;
    height: 16px;
    border-radius: 4px;

    &.topAuto {
      top: auto;
    }

    &.t2 {
      top: 2px;
    }
  }

  &__icon::before {
    content: "";
    display: block;
    width: 16px;
    height: 17px;
    background: url(#{$img_path}sprite.png) $bgPos no-repeat;
  }

  & > input:checked + &__icon::before {
    background: url(#{$img_path}sprite.png) $bgPosActive no-repeat;
  }

  & > input:focus {
    outline: 0;
  }

  & > input:focus + &__icon {
    box-shadow: 0 0 4px 1px $color_keyColor;
    border-collapse: separate;
  }

  @content;
}

//checkboxLarge
@mixin checkboxLarge($bgPos: -336px -91px, $bgPosActive: -368px -91px) {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;

  & > input {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    width: 32px;
    height: 32px;
    opacity: 0;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  &__icon::before {
    content: "";
    display: block;
    width: 32px;
    height: 33px;
    background: url(#{$img_path}sprite.png) $bgPos no-repeat;
  }

  & > input:checked + &__icon::before {
    background: url(#{$img_path}sprite.png) $bgPosActive no-repeat;
  }

  & > input:focus {
    outline: 0;
  }

  & > input:focus + &__icon {
    box-shadow: 0 0 4px 1px $color_keyColor;
    border-collapse: separate;
  }

  @content;
}

//一括応募用チェックボックス
@mixin checkboxBlue($bgPos: -336px -91px, $bgPosActive: -368px -91px) {
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: top;
  z-index: 1;

  & > input {
    display: inline-block;
    height: 16px;
    opacity: 0;
    position: relative;
    vertical-align: top;
    width: 16px;
    z-index: 1;
  }

  & > input:checked + &__icon::before {
    background: url(#{$img_path}sprite.png) $bgPosActive no-repeat;
  }

  & > input:focus {
    outline: 0;
  }

  & > input:focus + &__icon {
    border-collapse: separate;
    box-shadow: 0 0 4px 1px $color_keyColor;
  }

  &__icon {
    border-radius: 4px;
    height: 32px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32px;
    z-index: 2;
  }

  &__icon::before {
    background: url(#{$img_path}sprite.png) $bgPos no-repeat;
    content: "";
    display: block;
    height: 33px;
    width: 32px;
  }

  @content;
}

//textEllipsisMultiline
@mixin textEllipsisMultiline($height: 39px, $lineHeight: 19px ) {
  position: relative;
  display: block;
  height: $height;
  overflow: hidden;
  line-height: $lineHeight;
  word-break: break-all;
  white-space: normal;

  &::before,
  &::after {
    position: absolute;
    background: #fff;
  }

  &::before {
    content: "...";
    bottom: 0;
    right: 0;
    width: 1.6em;
    padding-left: 2px;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
  }

  @content;
}
