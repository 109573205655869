@charset "UTF-8";

/*
----------------------------------------------------------------- */
.cassetteRecruit {
  &__attribute {
    &-jobinfo {
      letter-spacing: -.4em;

      & .cassetteRecruit__attributeLabel {
        padding-right: 10px;

        &:last-child {
          padding-right: 0;
        }
      }

      a:hover {
        opacity: .5;
        text-decoration: none;
      }
    }
  }
}

.filter-gray {
  filter: grayscale(100%) opacity(80%);
}

/* IE11のみ適用させる */
@media all and (-ms-high-contrast: none) {
  .jobPointArea .interviewPostscript {
    &__body {
      &__suitable h3::after {
        content: '';
        background: url(../img/jobInfo/interviewPostscript_suitableIE11.png);
        position: relative;
        width: 42px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        top: -59px;
        left: 20px;
      }

      &__unsuitable h3::after {
        content: '';
        background: url(../img/jobInfo/interviewPostscript_unsuitableIE11.png);
        position: relative;
        margin-top: 1px;
        padding-right: 8px;
        width: 42px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        top: -59px;
        left: 20px;
      }
    }
  }

  // 求人情報から探すwebfont
  .jobPointIndexBox {
    &__index {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);

      a span::before {
        content: "\e603";
        position: absolute;
        margin: 9px 0 0 214px;
        color: #a1a1a1;
        font-family: mynavi; /* stylelint-disable-line */
        font-size: 1.5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1;
        speak: none;
        text-decoration: none;
        text-rendering: auto;
        text-transform: none;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  .container .grayIE11 {
    &__title {
      color: #767676;

      &::after {
        background-color: #a1a1a1;
      }
    }

    &__subTitle {
      color: #5b5b5b;
    }

    &__color {
      color: #a5a5a5;
    }

    // リンク右矢印
    &__arrowS {
      background: url(#{$img_path}arrowRightIE11.png) no-repeat;
      background-position: 0 50%;
    }

    &__backColor {
      background-color: #a1a1a1;

      &--line {
        border-color: #a3a3a3;
        color: #aeaeae;
      }

      // 求人情報から探す
      &--index {
        a:nth-of-type(n + 2) {
          border-top: solid 1px #efefef;
        }

        a::before {
          background: url(../img/jobInfo/icon_indexIE11.png) left center no-repeat;
          background-size: contain;
        }
      }
    }

    // ツールチップ
    &__iconQuestion {
      background-color: #a2a2a2;

      &::before {
        background-color: #a2a2a2;
      }

      &--content {
        background-color: #efefef;
      }

      &--tip {
        display: relative;

        &::before {
          border-bottom-color: #efefef;
        }
      }
    }

    // ここがポイント！
    &__jobPointBox {
      border: 1px solid #a1a1a1;

      &--line {
        &::before {
          background-image: url(../img/jobInfo/borderIE11.png);
          background-size: contain;
        }
      }
    }

    &__white {
      background-color: #fafafa;
    }

    // 取材担当者からみた「向いている人」「向いていない人」をお伝えします！
    &__interviewPostscript {
      background-color: #f6f6f6;
      border: 1px solid #c3c3c4;

      &--suitable {
        background-color: #e5e5e5;
      }

      &--text {
        color: #8b8b8b;
      }

      // マイナビ転職編集部より
      &--img {
        border-left: solid #c3c3c4 1px;

        &::before {
          background: url(../img/jobInfo/interviewPostscriptIE11.png);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }

  // 選考ステップ
  .stepTable {
    td:first-child:not(.last) {
      background-image: url(../img/jobInfo/bg_stepIE11.png);
      background-size: contain;
    }

    li.grayIE11__step {
      background-color: #cacaca;

      &:not(.last)::after {
        border-color: #cacaca transparent transparent transparent;
      }
    }
  }
}
