@charset "UTF-8";

.privilegeList {
  &__box {
    display: table;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-top: 1px solid $scout-title-boder;
  }

  &__item {
    display: table-row;
    border-left: 1px solid $scout-title-boder;
    border-right: 1px solid $scout-title-boder;
  }

  &__icon {
    width: 200px;
    background-color: $scout-lightblue-bgcolor;
    display: table-cell;
    padding: 20px 15px;
    border-bottom: 1px solid $scout-title-boder;
    border-right: 1px solid $scout-title-boder;
    vertical-align: middle;
    text-align: center;

    > img {
      vertical-align: middle;
    }

    & .scoutIcon {
      border: 1px solid $scout-lightblue-color;
      background: $scout-lightblue-color;
      color: $scout-white;
      display: inline-block;
      padding: 5px 10px;
      font-size: 1.1rem;
      font-weight: $scout_bold;
      line-height: 1;
      border-radius: 5px;
      width: 126px;
    }
  }

  &__example {
    display: table-cell;
    padding: 18px 20px 20px 20px;
    border-bottom: 1px solid $scout-title-boder;
    vertical-align: middle;

    .example {
      font-size: 14px;
      line-height: 1.6em;
      margin: 20px 0 0 0;
    }

    .exampleImg {
      margin-top: -2px;
      margin-left: 20px;
      vertical-align: middle;
      width: 526px;
    }
  }

  &__exampleScoutItem {
    letter-spacing: normal;
    border: 1px solid $scout-lightblue-color;
    color: $scout-lightblue-color;
    display: inline-block;
    margin-right: 10px;
    margin-top: 15px;
    padding: 0 10px 0 24px;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.8rem;
    border-radius: 5px;
    background: url(../img/scoutAgs/listicon_privilege.png) no-repeat 7px center;
  }

  & .scoutBenefit__item {
    width: auto;
    vertical-align: middle;
    padding: 5px 5px 0 0;

    & .labelScout {
      margin: 0;
      background-color: $scout-white;
    }

    & .tooltip {
      left: 0;
      top: 48px;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);

      &::before {
        right: auto;
        left: 15px;
      }

      &::after {
        right: auto;
        left: 15px;
      }
    }
  }

  &__note {
    margin-bottom: 5px;
  }
}
