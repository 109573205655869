@charset "UTF-8";

.topFeatureList {
  $component: &;

  &--job {
    #{$component}__item {
      a {
        font-size: 1.3rem;
        display: inline-block;
        line-height: 1.4;
      }
    }
  }

  &--agent {
    #{$component}__item {
      a {
        font-size: 1.3rem;
        display: inline-block;
        line-height: 1.4;
      }
    }
  }

  &--relation {
    #{$component}__item {
      a {
        font-size: 1.3rem;
        display: inline-block;
        line-height: 1.4;
      }
    }
  }
}
