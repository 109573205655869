@charset "UTF-8";

.jobTagRed {
  @include tagBase;
  background-color: $job-red-color;
}

.jobTagBlue {
  @include tagBase;
  background-color: $job-blue-color;
}
