@charset "UTF-8";

/*! =======================================================================
 * File        : MP03-0030-001
 * DESCRIPTION : 画面下部メール説明部分用CSS
========================================================================== */

/* main
============================================================ */

/* あなたの希望条件に合った新着求人をメールでお知らせします
------------------------------------------------------------ */
.grid {
  @include clearfix;

  div.main {
    float: left;
    padding-top: 10px;
    width: 710px;
  }

  div.send_mail {
    background-image: url(https://tenshoku.mynavi.jp/lib/css/2012/img/content/background_send_mail_01.gif);
    height: 110px;
    margin-top: 20px;
    padding-left: 18px;
    padding-top: 13px;
  }

  div.send_mail dl {
    _height: 1%;
    *zoom: 1;
  }

  div.send_mail dl:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  div.send_mail dl dt {
    float: left;
  }

  div.send_mail dl dd {
    padding-left: 100px;
    padding-top: 7px;
  }

  div.send_mail dl dd form input.text {
    color: #777;
    *height: 19px;
    font-size: 1.333em /* = 16px */;
    font-weight: bold;
    margin-right: 5px;
    margin-top: 10px;
    padding: 4px;
    width: 392px;
  }

  dl, form, p {
      margin-bottom: 0;
      margin-top: 0;
  }


  div.send_mail dl dt {
      float: left;
  }

  dd {
      margin-left: 0;
  }

  dt {
      display: block;
  }

  input, select, textarea {
      font-family: sans-serif;
      font-size: 100%;
      /* vertical-align: bottom; */
  }


  /* 希望に合う求人が見つからない」「忙しくて求人を探す時間がない」方へ
  ------------------------------------------------------------ */
  div.nothing_time {
    margin-top: 10px;
  }

  /* マイナビ転職のスカウト
  ------------------------------------------------------------ */
  div.box_scout {
    background:url(https://tenshoku.mynavi.jp/lib/css/2012/img/content/background_box_scout_01.gif) no-repeat left top;
    border:3px solid #00AAEB;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    margin-top:10px;
    padding:20px 10px 10px 20px;
    color:#030E33;
    _height: 1%;
    *zoom: 1;
  }

  div.box_scout.box_hover {
    cursor:pointer;
  }

  div.box_scout:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  div.box_scout div.heading {
    float:left;
    width:200px;
  }

  div.box_scout div.heading p {
    text-shadow: 0px 0px 10px #FFF;
    -moz-text-shadow: 0px 0px 10px #FFF;
    -webkit-text-shadow: 0px 0px 10px #FFF;
  }
  div.box_scout div.heading p.logo {
    margin-bottom:10px;
  }

  div.box_scout div.contents {
    float:right;
    width:400px;
    margin-top:-5px;
  }

  div.box_scout div.contents ul li {
    background:url(https://tenshoku.mynavi.jp/lib/css/2012/img/content/background_box_scout_02.png) no-repeat left 45%;
    margin-bottom:5px;
    padding-left:40px;
  }

  div.box_scout div.contents ul li p {
    border-bottom:1px solid #74A7D0;
    padding:5px 0;
  }

  div.box_scout div.contents ul li span {
    color:#FF0015;
    position:relative;
    left:-5px;
    font-weight:bold;
  }

  div.box_scout div.contents p.more {
    margin-top:15px;
    text-align: right;
  }
  div.box_scout div.contents p.more a {
    background: url(https://tenshoku.mynavi.jp/img/icon_link_a_01.png) 0 49% no-repeat;
    padding-left: 10px;
  }

  /* 転職支援
  ------------------------------------------------------------ */
  .main {
    padding-top: 0;
    float: none;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }

  .send_mail .button {
    border: none;
    margin-bottom: 0;
    vertical-align: bottom;
  }

  div.support {
    margin-top: 10px;
  }

  div.support div.content {
    _height: 1%;
    margin-top: 10px;
    *zoom: 1;
  }
  div.support div.content:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }
  div.support div.content dl {
    border: 3px solid #eee;
    border-radius: 6px;
    box-sizing: content-box;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    float: left;
    margin-right: 10px;
    padding: 12px 12px 14px;
    width: 200px;
    behavior: url(https://tenshoku.mynavi.jp/lib/js/2012/PIE/PIE.htc);
    position: relative;
  }
  div.support div.content dl.last_child {
    margin-right: 0;
  }
  div.support div.content dl:last-child {
    margin-right: 0;
  }
  div.support div.content dl dt {
    /* background: url(https://tenshoku.mynavi.jp/img/icon_link_a_01.png) 0 0.5em no-repeat; */
    margin-left: 55px;
    padding-left: 10px;
    position: relative;
  }
  div.support div.content dl dt img {
    left: -55px;
    position: absolute;
  }
  div.support div.content dl dd {
    margin-left: 65px;
  }

  div.send_mail {
    background-repeat: no-repeat;
  }

  div.grid div.main div.send_mail {
    margin-top: 0;
    box-sizing: content-box;
  }
}
