@charset "UTF-8";

.naviGroup {
  $component: &;

  &__header {
    border-bottom: 1px solid $color_keyColor_light;
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 15px;

    #{$component}__title {
      color: $color_heading;
    }

    &--strong {
      background-color: $color_keyColor;
      border: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 1.4rem;
      padding: 10px;

      #{$component}__title {
        color: $color_white;
      }
    }
  }

  &--outline {
    border: 1px solid $color_keyColor;
    border-radius: 2px;

    #{$component}__header {
      border-bottom: 1px solid $color_keyColor;
    }

    #{$component}__title {
      padding-top: 2rem;
      text-align: center;
    }

    .navItem {
      padding-left: 2rem;

      &::after {
        right: 26px;
      }
    }
  }

  .navItem + .topPopup__item {
    z-index: 10;
  }

  .navItem + .topPopup__item .topBalloon {
    border-bottom: 2px solid $color_white;
  }

  .navItemList {
    &__item {
      border-bottom: 1px solid $color_keyColor_light;
    }
  }

  .navItem > .navItem__text:nth-child(n+5) {
    display: none;
  }

  .topBalloon .navItem > .navItem__text:nth-child(n+5) {
    display: block;
  }

  .topBalloon .navItem--blur::before {
    content: none;
  }

  .topBalloon .navItem {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    line-height: 1.2;
  }

  .topBalloon__box--left {
    width: 46px;
    margin-right: 1px;
  }

  .topBalloon__box--right {
    width: calc(100% - 47px);
    padding: 1rem 0;
    border-left: 1px solid #dfeff5;
  }

  .topBalloon .navItem::after {
    content: none;
  }
}
