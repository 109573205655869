@charset "UTF-8";

/* テキスト入力フォーム
----------------------------------------------------- */
.inputText {
  @include inputText($fontSize: 1.4rem) {
    width: 100%;
  }
}

/* テキスト入力フォーム 年月日
----------------------------------------------------- */
.inputTextSSS {
  @include inputText($fontSize: 1.4rem) {
    width: 40px;
  }
}

/* テキスト入力フォーム 年月日
----------------------------------------------------- */
.inputTextSS {
  @include inputText($fontSize: 1.4rem) {
    width: 64px;
  }
}

/* テキスト入力フォーム 姓 名など
----------------------------------------------------- */
.inputTextS {
  @include inputText($fontSize: 1.4rem) {
    width: 160px;
  }
}

/* テキスト入力フォーム メールアドレスなど
----------------------------------------------------- */
.inputTextM {
  @include inputText($fontSize: 1.4rem) {
    width: 381px;
  }
}

/* テキスト入力フォーム エラー
----------------------------------------------------- */
.inputText--error {
  @extend .inputText;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputTextSSS--error {
  @extend .inputTextSSS;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputTextSS--error {
  @extend .inputTextSS;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputTextS--error {
  @extend .inputTextS;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputTextM--error {
  @extend .inputTextM;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

/* テキスト入力フォーム 非活性
----------------------------------------------------- */
.inputText--disabled {
  @extend .inputText;
  @include disabledOpacity;
}

.inputTextSSS--disabled {
  @extend .inputTextSSS;
  @include disabledOpacity;
}

.inputTextSS--disabled {
  @extend .inputTextSS;
  @include disabledOpacity;
}

.inputTextS--disabled {
  @extend .inputTextS;
  @include disabledOpacity;
}

.inputTextM--disabled {
  @extend .inputTextM;
  @include disabledOpacity;
}

/* テキスト入力フォーム フリーワード検索
----------------------------------------------------- */
.inputText--freeword {
  @extend .inputText;
  border: none;
  display: inline-block;
  height: 50px;
  margin: 0 0 0 10px;
  outline: none;
  padding: 0;
  position: relative;
  width: 400px;
  z-index: 1;
}

/* テキスト表示フォーム フリーワード検索結果一覧
----------------------------------------------------- */
.specified {
  @extend .inputText;
  background-color: #f0f0f3ea;
  border-color: #f0f0f3ea;
  border-radius: 10px;
  display: flex;
  height: 50px;
  padding: 0 0 0 20px;
  position: relative;
  z-index: 1;

  .specifiedFreeword {
    margin-top: 5px;
    overflow: hidden;
    white-space: nowrap;

    label {
      padding: 9px 0;

      span {
        border: 1px solid #ddd;
        border-radius: 15px;
        margin-left: 3px;
        padding: 2px 10px;
      }
    }
  }

  .respecified {
    margin: auto 20px;
    white-space: nowrap;
  }
}

/* 住所
----------------------------------------------------- */
.inputAddress {
  @include inputText($fontSize: 1.4rem) {
    width: 211px;
  }
}

.inputAddress--error {
  @extend .inputAddress;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputAddressL {
  @include inputText($fontSize: 1.4rem) {
    width: 478px;
  }
}

.inputAddressL--error {
  @extend .inputAddressL;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

/* 電話
----------------------------------------------------- */
.inputTel {
  @include inputText($fontSize: 1.4rem) {
    width: 190px;
  }
}

.inputTel--error {
  @extend .inputTel;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

/* 学校名
----------------------------------------------------- */
.inputSchool {
  @include inputText($fontSize: 1.4rem) {
    width: 343px;
  }
}

.inputSchool--error {
  @extend .inputSchool;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputSchool--disabled {
  @extend .inputSchool;
  @include placeholderColor(#bbb) {
    cursor: default;
  }
}

/* 社名
----------------------------------------------------- */
.inputCompany {
  @include inputText($fontSize: 1.4rem) {
    width: 407px;
  }
}

.inputCompany--error {
  @extend .inputCompany;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputCompany--disabled {
  @extend .inputCompany;
  @include disabledOpacity;
}

/* 資格
----------------------------------------------------- */
.inputQualification {
  @include inputText($fontSize: 1.4rem) {
    width: 200px;
  }
}

.inputQualification--error {
  @extend .inputQualification;
  @include placeholderColor(#bbb) {
    @include formError;
  }
}

.inputQualification--disabled {
  @extend .inputCompany;
  @include disabledOpacity;
}

/* テキストエリア
----------------------------------------------------- */
.textArea {
  @include placeholderColor() {
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 300px;
    min-height: 105px;
    padding: 10px;
    font-size: 1.4rem;
    border: 1px solid #bbb;
  }
}

.textArea--error {
  @extend .textArea;
  @include formError;
}

.textArea + .textSup {
  margin-top: 8px;
}

/* テキストエリア サイズ大
----------------------------------------------------- */
.textAreaL {
  @include placeholderColor() {
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 300px;
    min-height: 150px;
    padding: 10px;
    font-size: 1.4rem;
    border: 1px solid #bbb;
  }
}

.textAreaL--error {
  @extend .textAreaL;
  @include formError;
}

.textAreaL + .textSup {
  margin-top: 8px;
}

/* プルダウン select
----------------------------------------------------- */
.select {
  @include iconFont($fontSize: 1.4rem, $icon: "\e602") {
    position: relative;
    display: block;
    height: 34px;
    color: $color_heading;
    line-height: 1;
    background-color: $color_white;
    border: 1px solid #bbb;
    border-radius: 3px;
  }
}

.select::before {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 8px;
  color: $color_subText;
  transform: translateY(-50%);
}

.select select {
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  border: none;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
}

.select__text {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  line-height: 33px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select__text--placeholder {
  @extend .select__text;
  color: $color_subText;
}

.select select:focus + [class*="select"] {
  box-shadow: 0 0 4px 1px $color_keyColor;
  border-collapse: separate;
}

//非活性
.select--disabled {
  @extend .select;
  color: $color_disable02;
}

.select--disabled::before {
  color: $color_disable02;
}

.select--disabled select {
  cursor: default;
}

.select--disabled__text,
.select--disabled__text--placeholder {
  @extend .select__text;
  color: $color_disable02;
}

//エラー
.select--error {
  @extend .select;
  @include formError;
}

.select--error__text {
  @extend .select__text;
}

.select--error__text--placeholder {
  @extend .select__text--placeholder;
}

/* プルダウン select サイズ固定
----------------------------------------------------- */
.selectM {
  @extend .select;
  display: inline-block;
  vertical-align: middle;
  width: 186px;
}

.selectM__text {
  @extend .select__text;
}

.selectM__text--placeholder {
  @extend .selectM__text;
  color: $color_subText;
}

.selectM--disabled {
  @extend .selectM;
  color: $color_disable02;
}

.selectM--disabled select {
  cursor: default;
}

.selectM--disabled__text,
.selectM--disabled__text--placeholder {
  @extend .select__text;
  color: $color_disable02;
}

.selectM + .text,
.selectM + .textSup {
  margin-left: 10px;
}

//エラー
.selectM--error {
  @extend .selectM;
  @include formError;
}

.selectM--error__text {
  @extend .selectM__text;
}

.selectM--error__text--placeholder {
  @extend .selectM__text--placeholder;
}

/* プルダウン select サイズ固定
----------------------------------------------------- */
.selectL {
  @extend .select;
  display: inline-block;
  vertical-align: middle;
  width: 250px;
}

.selectL__text {
  @extend .select__text;
}

.selectL__text--placeholder {
  @extend .selectL__text;
  color: $color_subText;
}

.selectL--disabled {
  @extend .selectL;
  color: $color_disable02;
}

.selectL--disabled select {
  cursor: default;
}

.selectL--disabled__text,
.selectL--disabled__text--placeholder {
  @extend .select__text;
  color: $color_disable02;
}

.selectL + .text,
.selectL + .textSup {
  margin-left: 10px;
}

//エラー
.selectL--error {
  @extend .selectL;
  @include formError;
}

.selectL--error__text {
  @extend .selectL__text;
}

.selectL--error__text--placeholder {
  @extend .selectL__text--placeholder;
}

/* プルダウン select サイズ固定 小
----------------------------------------------------- */
.selectS {
  @extend .select;
  display: inline-block;
  vertical-align: middle;
  width: 154px;
}

.selectS__text {
  @extend .select__text;
  padding-left: 15px;
  font-size: 1.3rem;
}

.selectS__text--placeholder {
  @extend .selectS__text;
  color: $color_subText;
}

.selectS--disabled {
  @extend .selectS;
  color: $color_disable02;
}

.selectS--disabled select {
  cursor: default;
}

.selectS--disabled__text,
.selectS--disabled__text--placeholder {
  @extend .select__text;
  color: $color_disable02;
}

//エラー
.selectS--error {
  @extend .selectS;
  @include formError;
}

.selectS--error__text {
  @extend .selectS__text;
}

.selectS--error__text--placeholder {
  @extend .selectS__text--placeholder;
}

/* プルダウン select サイズ固定 最小
----------------------------------------------------- */
.selectSS {
  @extend .select;
  display: inline-block;
  vertical-align: middle;
  width: 129px;
}

.selectSS__text {
  @extend .select__text;
  padding-left: 8px;
  font-size: 1.4rem;
}

.selectSS__text--placeholder {
  @extend .selectSS__text;
  color: $color_subText;
}

.selectSS--disabled {
  @extend .selectS;
  color: $color_disable02;
}

.selectSS--disabled select {
  cursor: default;
}

.selectSS--disabled__text,
.selectSS--disabled__text--placeholder {
  @extend .select__text;
  color: $color_disable02;
}

//エラー
.selectSS--error {
  @extend .selectSS;
  @include formError;
}

.selectSS--error__text {
  @extend .selectSS__text;
}

.selectSS--error__text--placeholder {
  @extend .selectSS__text--placeholder;
}

/* プルダウン select サイズ固定 skill
----------------------------------------------------- */
.selectSkill {
  @extend .select;
  display: inline-block;
  vertical-align: middle;
  width: 180px;
}

.selectSkill__text {
  @extend .select__text;
  padding-left: 10px;
  padding-right: 15px;
  font-size: 1.3rem;
}

.selectSkill__text--placeholder {
  @extend .selectSkill__text;
  color: $color_subText;
}

.selectSkill--disabled {
  @extend .selectSkill;
  color: $color_disable02;
}

.selectSkill--disabled select {
  cursor: default;
}

.selectSkill--disabled__text,
.selectSkill--disabled__text--placeholder {
  @extend .selectSkill__text;
  color: $color_disable02;
}

//エラー
.selectSkill--error {
  @extend .selectSkill;
  @include formError;
}

.selectSkill--error__text {
  @extend .selectSkill__text;
}

.selectSkill--error__text--placeholder {
  @extend .selectSkill__text--placeholder;
}

/* プルダウン select サイズ固定 skill
----------------------------------------------------- */
.selectExperienceJob {
  @extend .select;
  display: inline-block;
  vertical-align: middle;
  width: 350px;
}

.selectExperienceJob__text {
  @extend .select__text;
  padding-left: 10px;
  padding-right: 15px;
  font-size: 1.3rem;
}

.selectExperienceJob__text--placeholder {
  @extend .selectExperienceJob__text;
  color: $color_subText;
}

.selectExperienceJob--disabled {
  @extend .selectExperienceJob;
  color: $color_disable02;
}

.selectExperienceJob--disabled select {
  cursor: default;
}

.selectExperienceJob--disabled__text,
.selectExperienceJob--disabled__text--placeholder {
  @extend .selectExperienceJob__text;
  color: $color_disable02;
}

//エラー
.selectExperienceJob--error {
  @extend .selectExperienceJob;
  @include formError;
}

.selectExperienceJob--error__text {
  @extend .selectSkill__text;
}

.selectExperienceJob--error__text--placeholder {
  @extend .selectExperienceJob__text--placeholder;
}

/* スライドボタン
----------------------------------------------------- */
.toggleSwitch {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 64px;
  border: 1px solid #ccc;
  border-radius: $borderRadius_defult;
  box-sizing: border-box;
}

.toggleSwitch__box {
  width: 96px;
  cursor: pointer;
}

.toggleSwitch__box input {
  display: none;
}

.toggleSwitch__on {
  float: left;
  vertical-align: middle;
  width: 32px;
  height: 26px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2.2;
  text-align: center;
  background-color: $color_keyColor;
}

.toggleSwitch__off {
  float: left;
  vertical-align: middle;
  width: 32px;
  height: 26px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2.2;
  text-align: center;
  background-color: #ccc;
}

.toggleSwitch__switch {
  float: left;
  width: 30px;
  height: 26px;
  line-height: 2.2;
  background-color: #fff;
  box-shadow: $boxShadow_defult;
}

.toggleSwitch__loading {
  @include loading;
}

.toggleSwitch .toggleSwitch__loading {
  position: absolute;
}

/* チェックボックス
----------------------------------------------------- */
.checkbox {
  @include checkbox;
}

.checkbox__text {
  margin-left: 10px;
}

.checkbox__text-only {
  margin: 0;
}

.checkbox__slash {
  margin: 0;
}

//非活性
.checkbox--disabled {
  @include checkbox;
  opacity: 0.5;
  cursor: default;
}

.checkbox--disabled__text {
  @extend .checkbox__text;
}

/* チェックボックス(大)
----------------------------------------------------- */
.checkboxLarge {
  @include checkboxLarge;
}

.checkboxLarge__text {
  margin-left: 10px;
}

//非活性
.checkboxLarge--disabled {
  @include checkboxLarge;
  opacity: 0.5;
  cursor: default;
}

.checkboxLarge--disabled__text {
  @extend .checkboxLarge__text;
}

/* ラジオボタン
----------------------------------------------------- */
.radio {
  @include checkbox($bgPos: -143px -160px, $bgPosActive: -127px -160px);
}

.radio__text {
  margin-left: 10px;
  font-size: 1.4rem;
}

.radio__icon {
  top: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.radio > input:focus + .radio__icon {
  box-shadow: 0 0 4px 2px $color_keyColor;
}

//非活性
.radio--disabled {
  @include checkbox;
  opacity: 0.5;
  cursor: default;
}

.radio--disabled__text {
  @extend .checkbox__text;
}

/* フォーム ラジオボタン リスト
----------------------------------------------------------------- */
.radioList {
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-top: 6px;
  padding: 6px 0 24px;
  border-radius: 3px;
}

.radioList__item {
  position: relative;
  display: table-cell;
  font-size: 1.2rem;
  text-align: center;
}

.radioList__item--active {
  @extend .radioList__item;
}

.radioList__item label {
  display: block;
}

.radioList__item label > input {
  display: none;
}

.radioList__text {
  position: relative;
  z-index: 3;
  display: block;
  margin-bottom: 5px;
  padding: 3px;
  line-height: 1.1;
  border-right: solid 1px #e0dddd;
  cursor: pointer;
}

.radioList__text:last-child {
  border-right: none;
}

.radioList__item::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 50%;
  z-index: 1;
  display: block;
  width: 100%;
  margin-left: -100%;
  border: solid 3px #01aaeb;
}

.radioList__item:first-child::before {
  content: none;
}

.radioList__text::before {
  background: #01aaeb;
  border: solid 2px #01aaeb;
  border-radius: 50%;
  content: "";
  height: 20px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  top: -6px;
  width: 20px;
  z-index: 2;
}

.radioList__item--active ~ .radioList__item::before {
  border-color: #e0e0e0;
}

.radioList__item--active ~ .radioList__item .radioList__text::before {
  background: $color_white;
  border: solid 2px #e0e0e0;
}

.radioList__item label input:checked + .radioList__text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background: $color_white;
  border-radius: 50%;
  box-shadow: 0 1px 1px rgba(7, 68, 92, 0.75);
}

/* スライドメニューボタン
----------------------------------------------------- */
.btnSelect {
  @include btn($display: block, $width: 100%, $padding: 0 45px 0 25px, $fontSize: 1.4rem, $boxShadow: none) {
    position: relative;
    z-index: 1;
    height: 34px;
    font-weight: normal;
    text-align: left;
    background-color: $color_white;
    border-radius: 3px;
    border: 1px solid #{$color_disable02};
  }
}

.btnSelect::before,
.btnSelect::after {
  content: "\e603";
  position: absolute;
  top: 50%;
  z-index: 1;
  display: inline-block;
  color: $color_subText;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "mynavi";
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  speak: none;
  text-decoration: none;
  text-rendering: auto;
  text-transform: none;
  vertical-align: top;
  transform: translateY(-50%);
}

.btnSelect::before {
  right: 6px;
}

.btnSelect::after {
  right: 11px;
}

.btnSelect__text {
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: block;
  color: $color_heading;
  line-height: 33px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btnSelect__text--placeholder {
  @extend .btnSelect__text;
  color: #999;
}

//エラー表示
.btnSelect--error {
  @extend .btnSelect;
  @include formError;
}

.btnSelect--error__text--placeholder {
  @extend .btnSelect__text;
  color: #999;
}

/* 交通アクセス 駅名
----------------------------------------------------------------- */
.selectedStation {
  height: 152px;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 5px;
  width: 640px;

  & &__text {
    cursor: pointer;
    display: inline-block;
    line-height: 2rem;
    margin: 0 1rem 0 .5rem;
  }

  .iconFont--close::before {
    color: $color_keyColor;
    font-size: 1rem;
    font-weight: 700;
    margin-right: 0.5rem;
    vertical-align: middle;
  }
}

/* フリーワード検索 選択値
----------------------------------------------------------------- */
.selectedFreewordWrapper {
  border: 1px solid $color_border;
  border-radius: 10px;
  margin: 0 30px 30px;
  overflow-x: auto;
  width: 890px;
  white-space: nowrap;

  .selectedFreeword {
    display: inline;
    margin: 0 0 0 30px;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: nowrap;

    .checkbox {
      vertical-align: middle;
    }

    &__text {
      border: solid 1px $color_subBorder;
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      font-size: 1.2rem;
      padding: 5px 10px;

      &::after {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 10px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }

    .iconFont--close::before {
      font-size: 1rem;
      margin-left: 0.5rem;
      vertical-align: middle;
    }

    &::after {
      background-color: $color-white;
      content: '';
      height: 30px;
      left: 32px;
      position: absolute;
      top: 10px;
      width: 39px;
      z-index: 1;
    }
  }
}
