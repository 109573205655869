@charset "UTF-8";

/* 表示不可アラート
----------------------------------------------------------------- */
.alertAddition {
  padding-top: 10px;
  color: #555;

  a {
    text-decoration: underline;
  }

  &__summary {
    padding-bottom: 20px;
  }

  &__listWrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__list {
    padding: 30px 0;
  }

  &__term {
    padding: 5px 10px;
    box-sizing: border-box;
  }

  &__description {
    padding: 5px 10px;
    margin: 0;
    box-sizing: border-box;
  }

  &__mail {
    padding-top: 10px;
  }

  &__txtCenter {
    text-align: center;
  }
}
