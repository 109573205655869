@charset "UTF-8";

.topSection {
  &-jobinfo {
    & .topCarousel__list {
      display: flex;
    }

    & .topCarousel__item {
      display: flex;
      flex-direction: column;

      .jobLink {
        padding-bottom: 20px;
      }
    }

    & .btnInterst {
      margin-top: auto;
      min-width: 250px;
      min-height: 40px;
      padding: 8px 20px;
    }
  }
}
