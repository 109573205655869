@charset "UTF-8";

.buttonFixedWrapper {
  border: 1px solid transparent;
}

.buttonFixed {
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  width: 240px;
  z-index: 2;
}

.btnBG.buttonFixed {
  padding: 10px 10px;
  background-color: rgba ( 0, 0, 0, .4 );
  z-index: 2;
}

.buttonFixed .btnPrimary {
  padding: 13px;

  &:hover {
    opacity: 0.8;
  }
}
